export enum PlacementType {
  Left = "Left",
  Right = "Right",
  Top = "Top",
  Bottom = "Bottom",
}

export enum HorizontalAlign {
  Center = "Center",
  Left = "Left",
  Right = "Right",
  Stretch = "Stretch",
}
