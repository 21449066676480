import customElement from "@ui5/webcomponents-base/dist/decorators/customElement.js";
import property from "@ui5/webcomponents-base/dist/decorators/property.js";
import litRender from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import WizardStep from "@ui5/webcomponents-fiori/dist/WizardStep.js";

// Styles
import wizardStepCss from "@ui5/webcomponents-fiori/dist/generated/themes/Wizard.css.js";

/**
 * @class
 *
 * <h3 class="comment-api-title">Overview</h3>
 *
 *
 * <h3>Usage</h3>
 *
 * For the <code>udex-wizard-step</code>
 * <h3>ES6 Module Import</h3>
 *
 * <code>import "@udex/web-components/dist/WizardStep.js";</code>
 *
 * @constructor
 * @extends WizardStep
 * @public
 */
@customElement({
  tag: "udex-wizard-step",
  renderer: litRender,
  styles: wizardStepCss,
})
class UDExWizardStep extends WizardStep {
  /**
   * Defines if the Wizard step is completed.
   *
   * @default false
   * @public
   */
  @property({ type: Boolean })
    completed!: boolean;
}

UDExWizardStep.define();

export default UDExWizardStep;
