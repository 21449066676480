/* eslint no-unused-vars: 0 */
import { html, svg, repeat, classMap, styleMap, ifDefined, unsafeHTML, scopeTag } from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import type UI5Element from "@ui5/webcomponents-base/dist/UI5Element.js";
	type DatePickerPopover = any;
	import type { ClassMapValue } from "@ui5/webcomponents-base/dist/types.js";
	
function block0 (this: DatePickerPopover, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<${scopeTag("udex-dropdown", tags, suffix)} id="udex-${ifDefined(this._id)}-calendar__popover" class="udex-date-picker__popover" placement-type="Bottom" horizontal-align="Left" @after-close="${this._afterDropdownClose}" @after-open="${this._afterDropdownOpen}"><${scopeTag("ui5-calendar", tags, suffix)} class="udex-date-picker__calendar" @ui5-selected-dates-change="${ifDefined(this.handleCalendarChange)}" selection-mode="${ifDefined(this.selectionMode)}" min-date="${ifDefined(this.minDate)}" max-date="${ifDefined(this.maxDate)}" format-pattern="${ifDefined(this.formatPattern)}">${ repeat(this._calendarSelectedDates, (item, index) => (item as typeof item & {_id?: any})._id || index, (item, index: number) => block1.call(this, context, tags, suffix, item, index)) }</${scopeTag("ui5-calendar", tags, suffix)}></${scopeTag("udex-dropdown", tags, suffix)}>` : html`<udex-dropdown id="udex-${ifDefined(this._id)}-calendar__popover" class="udex-date-picker__popover" placement-type="Bottom" horizontal-align="Left" @after-close="${this._afterDropdownClose}" @after-open="${this._afterDropdownOpen}"><ui5-calendar class="udex-date-picker__calendar" @ui5-selected-dates-change="${ifDefined(this.handleCalendarChange)}" selection-mode="${ifDefined(this.selectionMode)}" min-date="${ifDefined(this.minDate)}" max-date="${ifDefined(this.maxDate)}" format-pattern="${ifDefined(this.formatPattern)}">${ repeat(this._calendarSelectedDates, (item, index) => (item as typeof item & {_id?: any})._id || index, (item, index: number) => block1.call(this, context, tags, suffix, item, index)) }</ui5-calendar></udex-dropdown>`;}
function block1 (this: DatePickerPopover, context: UI5Element, tags: string[], suffix: string | undefined, item: any, index: number) { return suffix ? html`<${scopeTag("ui5-date", tags, suffix)} value="${ifDefined(item)}"></${scopeTag("ui5-date", tags, suffix)}>` : html`<ui5-date value="${ifDefined(item)}"></ui5-date>`;}


export default block0;