import customElement from "@ui5/webcomponents-base/dist/decorators/customElement.js";
import litRender from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import RangeSlider from "@ui5/webcomponents/dist/RangeSlider.js";
import SliderBase from "@ui5/webcomponents/dist/SliderBase.js";
import { isDesktop } from "@ui5/webcomponents-base/dist/Device.js";

// Styles
import rangeSliderCss from "@ui5/webcomponents/dist/generated/themes/RangeSlider.css.js";
import SliderCss from "./generated/themes/Slider.css.js";

/**
 * @class
 *
 * <h3 class="comment-api-title">Overview</h3>
 *
 *
 * <h3>Usage</h3>
 *
 * For the <code>udex-range-slider</code>
 * <h3>ES6 Module Import</h3>
 *
 * <code>import "@udex/web-components/dist/RangeSlider.js";</code>
 *
 * @constructor
 * @extends RangeSlider
 * @public
 */
@customElement({
  tag: "udex-range-slider",
  renderer: litRender,
  styles: [SliderBase.styles, rangeSliderCss, SliderCss],
  dependencies: [],
})
class UDExRangeSlider extends RangeSlider {
  onEnterDOM(): void {
    if (isDesktop()) {
      this.setAttribute("desktop", "");
    }
  }
}

UDExRangeSlider.define();

export default UDExRangeSlider;
