import UI5Element from "@ui5/webcomponents-base/dist/UI5Element.js";
import customElement from "@ui5/webcomponents-base/dist/decorators/customElement.js";
import property from "@ui5/webcomponents-base/dist/decorators/property.js";
import litRender from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import Integer from "@ui5/webcomponents-base/dist/types/Integer.js";
import event from "@ui5/webcomponents-base/dist/decorators/event.js";
import Icon from "@ui5/webcomponents/dist/Icon.js";
import Float from "@ui5/webcomponents-base/dist/types/Float.js";
import type I18nBundle from "@ui5/webcomponents-base/dist/i18nBundle.js";
import { getI18nBundle } from "@ui5/webcomponents-base/dist/i18nBundle.js";
import {
  VALUE_STATE_SUCCESS,
  VALUE_STATE_INFORMATION,
  VALUE_STATE_WARNING,
  VALUE_STATE_ERROR,
  READ_ONLY,
} from "./generated/i18n/i18n-defaults.js";
import UDExControlButton from "./ControlButton.js";
import ValueState from "./types/common.js";

// Template
import TextFieldTemplate from "./generated/templates/TextFieldTemplate.lit";

// Styles
import TextFieldCss from "./generated/themes/TextField.css.js";
import UDExStatusMessage from "./StatusMessage.js";

/**
 * @class
 *
 * @constructor
 * @extends UI5Element
 * @tagname udex-text-field
 * @public
 */
@customElement({
  tag: "udex-text-field",
  renderer: litRender,
  styles: TextFieldCss,
  template: TextFieldTemplate,
  dependencies: [UDExControlButton, Icon, UDExStatusMessage],
})

/**
   * Fired event when the user clicks the trigger.
   *
   * @public
   */
@event("click-trigger")
/**
   * Fired when the value of the component changes at each keystroke.
   *
   * @public
   */
@event("input")
/**
   * Fired when the input operation has finished by pressing Enter or on focusout.
   *
   * @public
   */
@event("change")
class UDExTextField extends UI5Element {
  _labelActiveClassName = "udex-text-field__label_active";

  /**
   * Defines whether the component is in disabled state.
   * <br><br>
   * <b>Note:</b> A disabled component is completely noninteractive.
   *
   * @default false
   * @public
   */
  @property({ type: Boolean })
    disabled!: boolean;

  /**
   * Defines the value of the component.
   * <br><br>
   * <b>Note:</b> The property is updated upon typing.
   *
   * @default ""
   * @formEvents input
   * @formProperty
   * @public
   */
  @property({ type: String })
    value!: string;

  /**
   * Sets the maximum number of characters available in the input field.
   * <br><br>
   * <b>Note:</b> This property is not compatible with the ui5-input type InputType.Number. If the ui5-input type is set to Number, the maxlength value is ignored.
   *
   * @public
   */
  @property({ validator: Integer })
    maxlength?: number;

  /**
   * Defines label text for the input field.
   *
   * @default ""
   * @public
   */
  @property({ type: String })
    label!: string;

  /**
   * Defines the accessible ARIA name of the component.
   *
   * @default ""
   * @public
   */
  @property()
    accessibleName!: string;

  /**
   * Receives id (or many ids) of the elements that label the component.
   * @default ""
   * @public
   */
  @property({ defaultValue: "" })
    accessibleNameRef!: string;

  /**
   * Defines the accessible ARIA name of the component.
   *
   * @default ""
   * @public
   */
  @property()
    accessibleIconName!: string;

  /**
   * Defines whether the component is read-only.
   * <br><br>
   * <b>Note:</b> A read-only component is not editable,
   * but still provides visual feedback upon user interaction.
   *
   * @default false
   * @public
   */
  @property({ type: Boolean })
    readonly!: boolean;

  /**
   * Defines whether the component is display-only.
   * <br><br>
   * <b>Note:</b> A display-only component is not editable,
   * but still provides visual feedback upon user interaction, input border is not visible.
   *
   * @default false
   * @public
   */
  @property({ type: Boolean })
    displayonly!: boolean;

  /**
   * Defines whether the component is required.
   *
   * @default false
   * @public
   */
  @property({ type: Boolean })
    required!: boolean;

  /**
   * Defines the value state of the component.
   *
   * @default "Standard"
   * @public
   */
  @property({ type: ValueState, defaultValue: "Standard" })
    valueState!: "Standard" | "Warning" | "Error" | "Success";

  /**
   * Defines supporting text under the input field.
   *
   * @default ""
   * @public
   */
  @property({ type: String })
    supportingText!: string;

  /**
   * Defines the icon name in the text-field.
   *
   * @default ""
   * @public
   */
  @property({ type: String })
    icon!: string;

  /**
   * Defines if the text-field is active
   *
   * @default false
   * @public
   */
  @property({ type: Boolean })
    active!: boolean;

  /**
   * Defines the inner stored class of the label.
   *
   * @default ""
   * @private
   */
  @property({ type: String, noAttribute: true, defaultValue: "" })
    labelActiveClass!: string;

  /**
   * Defines the inner stored value of the readonly attribute of the input.
   *
   * @default false
   * @private
   */
  @property({ type: Boolean, noAttribute: true })
    isInputReadonly!: boolean;

  /**
   * Defines the inner stored value of the classes of the label.
   *
   * @default ""
   * @private
   */
  @property({ type: String, noAttribute: true, defaultValue: "" })
    labelClasses!: string;

  /**
   * Defines the inner stored value of the classes of the label.
   *
   * @default "text"
   * @private
   */
  @property({ type: String, defaultValue: "text" })
    type!: string;

  /**
   * Defines direction of the input.
   *
   * @public
   */
  @property({ type: String, noAttribute: true })
    direction?: string;

  /**
   * Defines the max value for the "number" type of the input.
   */
  @property({ validator: Float })
    max?: number;

  /**
   * Defines the min value for the "number" type of the input.
   */
  @property({ validator: Float })
    min?: number;

  /**
   * Defines the step for the "number" type of the input.
   */
  @property({ validator: Float })
    step?: number;

  /**
   * Allows setting the aria-describedby property on the internal input node.
   *
   * @default ""
   * @public
   */
  @property()
    ariaDescribedBy?: string;

  static i18nBundle: I18nBundle;

  static async onDefine() {
    UDExTextField.i18nBundle = await getI18nBundle("sap-ui-webcomponents-bundle");
  }

  get valueStateTypeMappings() {
    return {
      "Success": UDExTextField.i18nBundle.getText(VALUE_STATE_SUCCESS),
      "Standard": UDExTextField.i18nBundle.getText(VALUE_STATE_INFORMATION),
      "Error": UDExTextField.i18nBundle.getText(VALUE_STATE_ERROR),
      "Warning": UDExTextField.i18nBundle.getText(VALUE_STATE_WARNING),
    };
  }

  get hasValueState() {
    return this.valueState !== ValueState.Standard;
  }

  get valueStateText() {
    return this.valueState !== ValueState.Standard ? this.valueStateTypeMappings[this.valueState] : undefined;
  }

  get readOnlyStateText() {
    return UDExTextField.i18nBundle.getText(READ_ONLY);
  }

  get ariaDescribedById(): string {
    return `${this.supportingTextId} ${this.valueStateTextId} ${this.readOnlyStateTextId}`.trim();
  }

  get supportingTextId():string {
    return this.supportingText ? `${this._id}-supportingTextDesc` : "";
  }

  get valueStateTextId():string {
    return this.hasValueState ? `${this._id}-valueStateDesc` : "";
  }

  get readOnlyStateTextId():string {
    return this.readonly ? `${this._id}-stateReadOnlyDesc` : "";
  }

  get accInfo() {
    return {
      "ariaInvalid": this.valueState === ValueState.Error ? "true" : undefined,
      "ariaDescribedBy": this.ariaDescribedBy ? this.ariaDescribedBy : this.ariaDescribedById || undefined,
      "ariaLabelledBy": this.accessibleNameRef,
      "ariaLabel": this.accessibleName || undefined,
    };
  }

  handleChange = () => {
    this.fireEvent("change");
  };

  handleInput = (inputEvent: InputEvent) => {
    this.value = (inputEvent.target as HTMLInputElement)?.value;
    this.fireEvent("input");
  };

  handleFocus = () => {
    if (!this.disabled && !this.readonly && !this.displayonly) {
      this.labelActiveClass = this._labelActiveClassName;
      this.active = true;
    }
  };

  handleBlur = () => {
    this.active = false;

    if (this.value) {
      this.labelActiveClass = this._labelActiveClassName;
    } else {
      this.labelActiveClass = "";
    }
  };

  handleKeyDown = (keyboardEvent: KeyboardEvent) => {
    keyboardEvent.stopPropagation();
    this.fireEvent("click-trigger", keyboardEvent);
  };

  onBeforeRendering() {
    if ((this.value || this.active) && this.labelActiveClass === "") {
      this.labelActiveClass = this._labelActiveClassName;
    } else if (!this.active && !this.value && this.labelActiveClass.length) {
      this.labelActiveClass = "";
    }

    this.isInputReadonly = this.readonly || this.displayonly;

    this.labelClasses = `udex-text-field__label ${this.labelActiveClass}`;
  }

  onAfterRendering(): void {
    const directionStyles: string = window
      .getComputedStyle(this)
      .getPropertyValue("direction");

    if (!directionStyles) {
      this.direction = "ltr";
    } else {
      this.direction = directionStyles;
    }
  }
}

UDExTextField.define();

export default UDExTextField;
