import customElement from "@ui5/webcomponents-base/dist/decorators/customElement.js";
import event from "@ui5/webcomponents-base/dist/decorators/event.js";
import property from "@ui5/webcomponents-base/dist/decorators/property.js";
import slot from "@ui5/webcomponents-base/dist/decorators/slot.js";
import type I18nBundle from "@ui5/webcomponents-base/dist/i18nBundle.js";
import {
  getI18nBundle,
  I18nText,
} from "@ui5/webcomponents-base/dist/i18nBundle.js";
import litRender from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import MessageStrip from "@ui5/webcomponents/dist/MessageStrip.js";
import messageStripCss from "@ui5/webcomponents/dist/generated/themes/MessageStrip.css.js";
import UDExButton from "./Button.js";
import {
  MESSAGE_STRIP_CLOSE_BUTTON,
  MESSAGE_STRIP_ERROR,
  MESSAGE_STRIP_INFORMATION,
  MESSAGE_STRIP_SUCCESS,
  MESSAGE_STRIP_WARNING,
} from "./generated/i18n/i18n-defaults.js";

import MessageStripTemplate from "./generated/templates/MessageStripTemplate.lit.js";

// Styles
import MessageStripCss from "./generated/themes/MessageStrip.css.js";

export enum MessageStripDesign {
  /**
   * Message should be just an information
   * @public
   */
  Information = "Information",
  /**
   * Message is a success message
   * @public
   */
  Positive = "Positive",
  /**
   * Message is an error
   * @public
   */
  Negative = "Negative",
  /**
   * Message is a warning
   * @public
   */
  Warning = "Warning",
  /**
   * Message is an action
   * @public
   */
  Action = "Action",
}

export enum UdexMessageStripStatusType {
  Icon = "Icon",
  Text = "Text",
  None = "None",
}

type textStatusType = Record<Exclude<MessageStripDesign, "Action">, string>;

/**
 * @class
 *
 * @constructor
 * @extends MessageStrip
 * @tagname udex-message-strip
 * @public
 */
@customElement({
  tag: "udex-message-strip",
  renderer: litRender,
  styles: [messageStripCss, MessageStripCss],
  template: MessageStripTemplate,
  dependencies: [UDExButton],
})
/**
 * Fired when the close button is pressed either with a
 * click/tap or by using the Enter or Space key.
 *
 * @public
 */
@event("close")
class UDExMessageStrip extends MessageStrip {
  /**
   * Defines the component type.
   *
   * @public
   */
  @property({
    type: MessageStripDesign,
    defaultValue: MessageStripDesign.Action,
  })
  /** @ts-expect-error */
    design!: `${MessageStripDesign}`;

  /**
   * Defines the component Status Type.
   *
   * @public
   */
  @property({
    type: UdexMessageStripStatusType,
    defaultValue: UdexMessageStripStatusType.Icon,
  })
    statusType!: `${UdexMessageStripStatusType}`;

  /**
   * Defines whether the MessageStrip renders close button.
   *
   * @public
   */
  @property({ type: Boolean })
    hideCloseButton!: boolean;

  /**
   * Defines display the icon for the Message Strip.
   *
   * @private
   */
  @property({ type: Boolean })
    hideIcon!: boolean;

  /**
   * Defines the content of the component.
   *
   * @slot
   * @public
   */
  @slot({ type: Node }) default!: Node;

  static i18nBundle: I18nBundle;

  static async onDefine() {
    UDExMessageStrip.i18nBundle = await getI18nBundle(
      "sap-ui-webcomponents-bundle",
    );
  }

  getTranslation(text: I18nText): string {
    return UDExMessageStrip.i18nBundle.getText(text);
  }

  textStatusTypeMapping(): textStatusType {
    return {
      Information: this.getTranslation(MESSAGE_STRIP_INFORMATION),
      Positive: this.getTranslation(MESSAGE_STRIP_SUCCESS),
      Negative: this.getTranslation(MESSAGE_STRIP_ERROR),
      Warning: this.getTranslation(MESSAGE_STRIP_WARNING),
    };
  }

  onBeforeRendering() {
    if (
      this.isDesignAction()
      || this.statusType !== UdexMessageStripStatusType.Icon
    ) {
      this.hideIcon = true;
    }
  }

  isDesignAction() {
    return this.design === MessageStripDesign.Action;
  }

  get shouldHideIcon() {
    if (
      this.isDesignAction()
      || this.statusType !== UdexMessageStripStatusType.Icon
    ) {
      return this.hideIcon || this.icon.length === 0;
    }

    return this.hideIcon;
  }

  get hideTextStatus() {
    return (
      this.statusType !== UdexMessageStripStatusType.Text
      || this.isDesignAction()
    );
  }

  get textStatus(): string | undefined {
    if (this.design !== MessageStripDesign.Action) {
      return this.textStatusTypeMapping()[this.design];
    }
  }

  get _closeButtonText() {
    return this.getTranslation(MESSAGE_STRIP_CLOSE_BUTTON);
  }

  get iconProvided() {
    return this.icon.length > 0;
  }

  _closeClick() {
    this.fireEvent("close");
  }
}

UDExMessageStrip.define();

export default UDExMessageStrip;
