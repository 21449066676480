import customElement from "@ui5/webcomponents-base/dist/decorators/customElement.js";
import property from "@ui5/webcomponents-base/dist/decorators/property.js";
import slot from "@ui5/webcomponents-base/dist/decorators/slot.js";
import event from "@ui5/webcomponents-base/dist/decorators/event.js";
import litRender from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import UI5Icon from "@ui5/webcomponents/dist/Icon.js";
import {
  isLeft,
  isRight,
  isUp,
  isDown,
  isEscape,
  isSpace,
  isEnter,
  isHome,
  isEnd,
} from "@ui5/webcomponents-base/dist/Keys.js";
import UDExSecondaryNavigationSubItem from "./SecondaryNavigationSubItem.js";
import UDExSecondaryNavigationItemBase, { NavItem } from "./SecondaryNavigationItemBase.js";
import "@ui5/webcomponents-icons/dist/slim-arrow-down.js";

import SecondaryNavigationItemTemplate from "./generated/templates/SecondaryNavigationItemTemplate.lit.js";

// Styles
import SecondaryNavigationItemCss from "./generated/themes/SecondaryNavigationItem.css.js";

export const menuItemRole: string = "menuitem";

/**
 * @class
 *
 * <h3 class="comment-api-title">Overview</h3>
 *
 *
 * <h3>Usage</h3>
 *
 * For the <code>udex-secondary-navigation-item</code>
 * <h3>ES6 Module Import</h3>
 *
 * <code>import "@udex/web-components/dist/SecondaryNavigationItem.js";</code>
 *
 * @constructor
 * @extends UDExSecondaryNavigationItemBase
 * @tagname udex-secondary-navigation-item
 * @private
 */
@customElement({
  tag: "udex-secondary-navigation-item",
  renderer: litRender,
  styles: SecondaryNavigationItemCss,
  template: SecondaryNavigationItemTemplate,
  dependencies: [UI5Icon, UDExSecondaryNavigationSubItem],
})

/**
 * Fires when item is clicked
 *
 * @allowPreventDefault
 * @public
 */
@event("nav-item-click", {
  detail: {
    item: { type: HTMLElement },
  },
})

/**
 * Fires when dropdown item is clicked
 *
 * @allowPreventDefault
 * @public
 */
@event("sub-item-click", {
  detail: {
    item: { type: Array },
  },
})

class UDExSecondaryNavigationItem extends UDExSecondaryNavigationItemBase {
  /**
   * Defines an aria label for item
   *
   * @public
   */
  @property({ type: String })
    ariaLabel!: string;

  /**
   * Defines a role for item
   *
   * @public
   */
  @property({ type: String })
    role!: string;

  /**
   * Defines the content of the item.
   *
   * @slot content
   * @public
   */
  @slot({ type: HTMLElement })
    buttons!: Array<HTMLElement>;

  /**
   * List of the sub items
   *
   * @default "[]"
   * @public
   */
  @property({ type: Object, multiple: true })
    subItems!: Array<NavItem>;

  /**
   * Defines if dropdown is opened.
   *
   * @public
   * @default false
   */
  @property({ type: Boolean })
    isOpen!: boolean;

  /**
   * Defines processed items.
   *
   * @private
   * @default []
   */
  @property({ type: Object })
    _processedItems: Array<NavItem> = [];

  _dropdownHistory: Array<Array<NavItem>> = [];
  _shouldFocusFirstSubItem!: boolean;
  _subTitle!: string | undefined;

  onAfterRendering(): void {
    if (this._shouldFocusFirstSubItem && this.isOpen) {
      this.onFirstSubItemFocus();
      this._shouldFocusFirstSubItem = false;
    }
  }

  onBeforeRendering() : void {
    this._subTitle = this.subActiveItemTitle || this._subTitle;
  }

  onItemClick(): void {
    if (this.hasNestedItems) {
      this.setDropdownItems();
      this.toggleDropdown();
    }

    this.fireEvent("nav-item-click", { item: this });
  }

  onSubItemClick(e: Event): void {
    const { index } = e.target as UDExSecondaryNavigationSubItem;
    const clickedItem = this._processedItems[index];

    if (clickedItem.items) {
      this.setDropdownHistory();
      this.setNestedItems(clickedItem);
    }

    this.fireEvent("sub-item-click", {
      item: clickedItem,
    });
  }

  onBackClick(): void {
    this.setPrevNestedItems();
  }

  toggleDropdown(): void {
    this.isOpen = !this.isOpen;
  }

  closeDropdown(): void {
    this.isOpen = false;
  }

  handleItemLinkKeyDown(e: KeyboardEvent): void {
    if (isEnter(e) || isSpace(e)) {
      e.preventDefault();
      this.onItemClick();
    }
  }

  handleItemButtonKeyDown(e: KeyboardEvent): void {
    if (isEnter(e) || isSpace(e)) {
      this.onShouldFocusFirstSubItem();
    }
  }

  handleSubItemKeyDown(e: KeyboardEvent): void {
    e.stopPropagation();
    if (isEscape(e)) {
      this.handleEscapeKey();
    }
    if (isLeft(e)) {
      this.handleLeftArrow(e);
    }
    if (isRight(e)) {
      this.handleRightArrow(e);
    }
    if (isUp(e)) {
      e.preventDefault();
      this.handleUpArrow(e);
    }
    if (isDown(e)) {
      e.preventDefault();
      this.handleDownArrow(e);
    }
    if (isHome(e)) {
      e.preventDefault();
      this.onFirstSubItemFocus();
    }
    if (isEnd(e)) {
      e.preventDefault();
      this.onLastSubItemFocus();
    }
    if (isEnter(e) || isSpace(e)) {
      e.preventDefault();
      this.handleEnterAndSpace(e);
    }
  }

  handleUpArrow(e: KeyboardEvent): void {
    const { index } = e.target as UDExSecondaryNavigationSubItem;
    const lastIndex = this.domNavItems.length - 1;
    const previousIndex = index - 1 < 0 ? lastIndex : index - 1;
    if (!(lastIndex === previousIndex)) {
      this.domNavItems[previousIndex].focus();
    }
  }

  handleDownArrow(e: KeyboardEvent): void {
    const { index } = e.target as UDExSecondaryNavigationSubItem;
    const nextIndex = index + 1 > this.domNavItems.length - 1 ? 0 : index + 1;
    if (!(nextIndex === 0)) {
      this.domNavItems[nextIndex].focus();
    }
  }

  handleLeftArrow(e: KeyboardEvent): void {
    const { isBack } = e.target as UDExSecondaryNavigationSubItem;
    if (isBack) {
      this.setPrevNestedItems();
      this.onShouldFocusFirstSubItem();
    }
  }

  handleRightArrow(e: KeyboardEvent): void {
    const { hasItems, isBack } = e.target as UDExSecondaryNavigationSubItem;
    if (hasItems && !isBack) {
      this.onSubItemClick(e);
      this.onShouldFocusFirstSubItem();
    }
  }

  handleEnterAndSpace(e: KeyboardEvent): void {
    const { hasItems, isBack } = e.target as UDExSecondaryNavigationSubItem;
    if (isBack) {
      this.onBackClick();
      this.onShouldFocusFirstSubItem();
      return;
    }

    this.onSubItemClick(e);

    if (hasItems) {
      this.onShouldFocusFirstSubItem();
    } else {
      this.closeDropdown();
      this.focus();
    }
  }

  handleEscapeKey(): void {
    this.closeDropdown();
    this.focus();
  }

  setDropdownHistory(): void {
    this._dropdownHistory.push(this._processedItems);
  }

  setPrevNestedItems(): void {
    this._processedItems = this._dropdownHistory.pop()!;
  }

  setNestedItems({ title, items } : NavItem): void {
    if (items) {
      this._processedItems = [{ title, isBack: true }, ...items];
    }
  }

  setDropdownItems(): void {
    this._processedItems = this.subItems;
  }

  onFirstSubItemFocus(): void {
    this.domNavItems[0].focus();
  }

  onLastSubItemFocus(): void {
    this.domNavItems[this.domNavItems.length - 1].focus();
  }

  onShouldFocusFirstSubItem(): void {
    this._shouldFocusFirstSubItem = true;
  }

  get domNavItems(): Array<UDExSecondaryNavigationSubItem> {
    const domNavItems = this.shadowRoot?.querySelectorAll<UDExSecondaryNavigationSubItem>("udex-secondary-navigation-sub-item");
    return domNavItems ? Array.from(domNavItems) : [];
  }

  get hasNestedItems(): boolean {
    return this.subItems.length > 0;
  }

  get isSubItemActive(): boolean {
    return !!this.subActiveItem?.active;
  }

  get isActive(): boolean {
    return this.isSubItemActive || this.active;
  }

  get subActiveItemTitle(): string | undefined {
    return this.subActiveItem?.title;
  }

  get subActiveItem(): NavItem | undefined {
    return this.getActiveSubItem(this.subItems);
  }

  get isLink(): boolean {
    return !this.hasNestedItems && !!this.path;
  }

  get itemRole(): string {
    return this.role ? this.role : this.menuItemRole;
  }

  get menuItemRole(): string {
    return menuItemRole;
  }

  get title(): string {
    return this.label;
  }
}

UDExSecondaryNavigationItem.define();

export default UDExSecondaryNavigationItem;
