/* eslint no-unused-vars: 0 */
import { html, svg, repeat, classMap, styleMap, ifDefined, unsafeHTML, scopeTag } from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import type UI5Element from "@ui5/webcomponents-base/dist/UI5Element.js";
	import type TableHeaderCell from "../../TableHeaderCell.js";
	import type { ClassMapValue } from "@ui5/webcomponents-base/dist/types.js";
	
function block0 (this: TableHeaderCell, context: UI5Element, tags: string[], suffix: string | undefined) { return html`${ this._hasIcons ? block1.call(this, context, tags, suffix) : block4.call(this, context, tags, suffix) } `;}
function block1 (this: TableHeaderCell, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<div class="udex-table-header-cell-wrap">${ this.iconStart ? block2.call(this, context, tags, suffix) : undefined }<slot></slot>${ this.iconEnd ? block3.call(this, context, tags, suffix) : undefined }</div>`;}
function block2 (this: TableHeaderCell, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<${scopeTag("ui5-icon", tags, suffix)} class="udex-table-header-cell-wrap__icon" name="${ifDefined(this.iconStart)}"></${scopeTag("ui5-icon", tags, suffix)}>` : html`<ui5-icon class="udex-table-header-cell-wrap__icon" name="${ifDefined(this.iconStart)}"></ui5-icon>`;}
function block3 (this: TableHeaderCell, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<${scopeTag("ui5-icon", tags, suffix)} class="udex-table-header-cell-wrap__icon" name="${ifDefined(this.iconEnd)}"></${scopeTag("ui5-icon", tags, suffix)}>` : html`<ui5-icon class="udex-table-header-cell-wrap__icon" name="${ifDefined(this.iconEnd)}"></ui5-icon>`;}
function block4 (this: TableHeaderCell, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<slot></slot>`;}


export default block0;