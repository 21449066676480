import CallbackFunction from "../types/callback.js";

const debounce = <T extends CallbackFunction>(cb: T, timeout = 300) => {
  let timer: ReturnType<typeof setTimeout>;
  return (...args: any) => {
    clearTimeout(timer);
    timer = setTimeout(() => { cb(args); }, timeout);
  };
};

export default debounce;
