/* eslint no-unused-vars: 0 */
import { html, svg, repeat, classMap, styleMap, ifDefined, unsafeHTML, scopeTag } from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import type UI5Element from "@ui5/webcomponents-base/dist/UI5Element.js";
	import type SearchableListBase from "../../SearchableListBase.js";
	import type { ClassMapValue } from "@ui5/webcomponents-base/dist/types.js";
	
function block0 (this: SearchableListBase, context: UI5Element, tags: string[], suffix: string | undefined) { return html`${ this.showSearch ? block1.call(this, context, tags, suffix) : undefined }${ this.isListEmpty ? block2.call(this, context, tags, suffix) : block3.call(this, context, tags, suffix) } `;}
function block1 (this: SearchableListBase, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<${scopeTag("udex-search", tags, suffix)} slot="header" mode="Standard" class="udex-filter-menu__search" label="${ifDefined(this.searchPlaceholder)}" label-animation-disabled non-interactive-submit-button show-submit-button></${scopeTag("udex-search", tags, suffix)}>` : html`<udex-search slot="header" mode="Standard" class="udex-filter-menu__search" label="${ifDefined(this.searchPlaceholder)}" label-animation-disabled non-interactive-submit-button show-submit-button></udex-search>`;}
function block2 (this: SearchableListBase, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<div class="udex-no-result"><span>${ifDefined(this.getNoResultText)}&lrm;</span></div>`;}
function block3 (this: SearchableListBase, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<${scopeTag("udex-list", tags, suffix)} id="udex-list" class="udex-filter-menu__list" ?controlled="${this.isControlled}" mode="${ifDefined(this.mode)}">${ repeat(this._filteredItems, (item, index) => (item as typeof item & {_id?: any})._id || index, (item, index: number) => block4.call(this, context, tags, suffix, item, index)) }</${scopeTag("udex-list", tags, suffix)}>` : html`<udex-list id="udex-list" class="udex-filter-menu__list" ?controlled="${this.isControlled}" mode="${ifDefined(this.mode)}">${ repeat(this._filteredItems, (item, index) => (item as typeof item & {_id?: any})._id || index, (item, index: number) => block4.call(this, context, tags, suffix, item, index)) }</udex-list>`;}
function block4 (this: SearchableListBase, context: UI5Element, tags: string[], suffix: string | undefined, item: any, index: number) { return html`${ item.isGroup ? block5.call(this, context, tags, suffix, item, index) : block10.call(this, context, tags, suffix, item, index) }`;}
function block5 (this: SearchableListBase, context: UI5Element, tags: string[], suffix: string | undefined, item: any, index: number) { return html`${ item.item.isSelectAllHeader ? block6.call(this, context, tags, suffix, item, index) : block8.call(this, context, tags, suffix, item, index) }`;}
function block6 (this: SearchableListBase, context: UI5Element, tags: string[], suffix: string | undefined, item: any, index: number) { return suffix ? html`<${scopeTag("udex-list-select-all", tags, suffix)} _additional-class-name="${ifDefined(this.additionalClassName)}" data-token-id="${ifDefined(item.item._id)}" label="${ifDefined(item.item.label)}" ?controlled="${item.item.controlled}" supporting-text="${ifDefined(item.item.supportingText)}" ?disabled="${item.item.disabled}" ?selected="${item.item.selected}" ?indeterminate="${item.item.indeterminate}">${ repeat(item.groupItems, (item, index) => (item as typeof item & {_id?: any})._id || index, (item, index: number) => block7.call(this, context, tags, suffix, item, index)) }</${scopeTag("udex-list-select-all", tags, suffix)}>` : html`<udex-list-select-all _additional-class-name="${ifDefined(this.additionalClassName)}" data-token-id="${ifDefined(item.item._id)}" label="${ifDefined(item.item.label)}" ?controlled="${item.item.controlled}" supporting-text="${ifDefined(item.item.supportingText)}" ?disabled="${item.item.disabled}" ?selected="${item.item.selected}" ?indeterminate="${item.item.indeterminate}">${ repeat(item.groupItems, (item, index) => (item as typeof item & {_id?: any})._id || index, (item, index: number) => block7.call(this, context, tags, suffix, item, index)) }</udex-list-select-all>`;}
function block7 (this: SearchableListBase, context: UI5Element, tags: string[], suffix: string | undefined, item: any, index: number) { return suffix ? html`<${scopeTag("udex-list-item", tags, suffix)} data-token-id="${ifDefined(item._id)}" ?controlled="${item.controlled}" mode="${ifDefined(item.mode)}" supporting-text="${ifDefined(item.supportingText)}" ?divider="${item.divider}" ?disabled="${item.disabled}" ?selected="${item.selected}" label="${ifDefined(item.label)}">${unsafeHTML(item.innerHTML)}</${scopeTag("udex-list-item", tags, suffix)}>` : html`<udex-list-item data-token-id="${ifDefined(item._id)}" ?controlled="${item.controlled}" mode="${ifDefined(item.mode)}" supporting-text="${ifDefined(item.supportingText)}" ?divider="${item.divider}" ?disabled="${item.disabled}" ?selected="${item.selected}" label="${ifDefined(item.label)}">${unsafeHTML(item.innerHTML)}</udex-list-item>`;}
function block8 (this: SearchableListBase, context: UI5Element, tags: string[], suffix: string | undefined, item: any, index: number) { return suffix ? html`<${scopeTag("udex-list-group-header", tags, suffix)} role="list" _additional-class-name="udex-searchable-list__header" class="${ifDefined(this.groupClassName)}" ?non-interactive="${item.item.nonInteractive}" data-token-id="${ifDefined(item.item._id)}" label="${ifDefined(item.item.label)}" supporting-text="${ifDefined(item.item.supportingText)}" ?controlled="${item.item.controlled}" ?disabled="${item.item.disabled}" ?selected="${item.item.selected}" ?indeterminate="${item.item.indeterminate}">${ repeat(item.groupItems, (item, index) => (item as typeof item & {_id?: any})._id || index, (item, index: number) => block9.call(this, context, tags, suffix, item, index)) }</${scopeTag("udex-list-group-header", tags, suffix)}>` : html`<udex-list-group-header role="list" _additional-class-name="udex-searchable-list__header" class="${ifDefined(this.groupClassName)}" ?non-interactive="${item.item.nonInteractive}" data-token-id="${ifDefined(item.item._id)}" label="${ifDefined(item.item.label)}" supporting-text="${ifDefined(item.item.supportingText)}" ?controlled="${item.item.controlled}" ?disabled="${item.item.disabled}" ?selected="${item.item.selected}" ?indeterminate="${item.item.indeterminate}">${ repeat(item.groupItems, (item, index) => (item as typeof item & {_id?: any})._id || index, (item, index: number) => block9.call(this, context, tags, suffix, item, index)) }</udex-list-group-header>`;}
function block9 (this: SearchableListBase, context: UI5Element, tags: string[], suffix: string | undefined, item: any, index: number) { return suffix ? html`<${scopeTag("udex-list-item", tags, suffix)} role="none" data-token-id="${ifDefined(item._id)}" ?controlled="${item.controlled}" mode="${ifDefined(item.mode)}" supporting-text="${ifDefined(item.supportingText)}" ?divider="${item.divider}" ?disabled="${item.disabled}" ?selected="${item.selected}" label="${ifDefined(item.label)}">${unsafeHTML(item.innerHTML)}</${scopeTag("udex-list-item", tags, suffix)}>` : html`<udex-list-item role="none" data-token-id="${ifDefined(item._id)}" ?controlled="${item.controlled}" mode="${ifDefined(item.mode)}" supporting-text="${ifDefined(item.supportingText)}" ?divider="${item.divider}" ?disabled="${item.disabled}" ?selected="${item.selected}" label="${ifDefined(item.label)}">${unsafeHTML(item.innerHTML)}</udex-list-item>`;}
function block10 (this: SearchableListBase, context: UI5Element, tags: string[], suffix: string | undefined, item: any, index: number) { return suffix ? html`<${scopeTag("udex-list-item", tags, suffix)} data-token-id="${ifDefined(item.item._id)}" supporting-text="${ifDefined(item.item.supportingText)}" ?disabled="${item.item.disabled}" ?controlled="${item.controlled}" ?divider="${item.divider}" ?selected="${item.item.selected}" label="${ifDefined(item.item.label)}">${unsafeHTML(item.item.innerHTML)}</${scopeTag("udex-list-item", tags, suffix)}>` : html`<udex-list-item data-token-id="${ifDefined(item.item._id)}" supporting-text="${ifDefined(item.item.supportingText)}" ?disabled="${item.item.disabled}" ?controlled="${item.controlled}" ?divider="${item.divider}" ?selected="${item.item.selected}" label="${ifDefined(item.item.label)}">${unsafeHTML(item.item.innerHTML)}</udex-list-item>`;}


export default block0;