import UI5Element from "@ui5/webcomponents-base/dist/UI5Element.js";
import customElement from "@ui5/webcomponents-base/dist/decorators/customElement.js";
import litRender from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import "@ui5/webcomponents/dist/Switch.js";
import property from "@ui5/webcomponents-base/dist/decorators/property.js";
import event from "@ui5/webcomponents-base/dist/decorators/event.js";
import { isDesktop } from "@ui5/webcomponents-base/dist/Device.js";

import Icon from "@ui5/webcomponents/dist/Icon.js";
import "@ui5/webcomponents-icons/dist/accept.js";
import "@ui5/webcomponents-icons/dist/decline.js";
import "@ui5/webcomponents-icons/dist/less.js";

// Template
import SwitchTemplate from "./generated/templates/SwitchTemplate.lit.js";

// Styles
import SwitchCss from "./generated/themes/Switch.css.js";

export enum SwitchColor {
  NonSemantic = "Non-Semantic",
  Semantic = "Semantic",
}

@customElement({
  tag: "udex-switch",
  renderer: litRender,
  styles: SwitchCss,
  template: SwitchTemplate,
  dependencies: [Icon],
})
/**
 * Fired when the component checked state changes.
 *
 * @allowPreventDefault
 * @public
 */
@event("change")
class UDExSwitch extends UI5Element {
  /**
   * Defines the label near switch.
   *
   * @default ""
   * @public
   */
  @property({ type: String, defaultValue: "" })
    label!: string;

  /**
   * Defines the color of the component.
   *
   * @default "Non-Semantic"
   * @public
   */
  @property({ type: SwitchColor, defaultValue: SwitchColor.NonSemantic })
    color!: `${SwitchColor}`;

  /**
   * Sets the accessible ARIA name of the component.
   *
   * @default ""
   * @public
   */
  @property({ type: String, defaultValue: "" })
    accessibleName!: string;

  /**
   * Receives id (or many ids) of the elements that label the component.
   * @default ""
   * @public
   */
  @property({ defaultValue: "" })
    accessibleNameRef!: string;

  /**
   * Defines if the component is checked.
   *
   * @default false
   * @public
   */
  @property({ type: Boolean })
    checked!: boolean;

  /**
   * Defines whether the component is disabled.
   *
   * @default false
   * @public
   */
  @property({ type: Boolean })
    disabled!: boolean;

  /**
   * Defines the tooltip of the component.
   *
   * @default ""
   * @public
   */
  @property({ type: String, defaultValue: "" })
    tooltip!: string;

  onEnterDOM(): void {
    if (isDesktop()) {
      this.setAttribute("desktop", "");
    }
  }

  handleSwitchClick(): void {
    if (!this.disabled) {
      this.checked = !this.checked;
      this.fireEvent("change", null, true);
    }
  }

  handleSwitchKeyDown(e: KeyboardEvent): void {
    if (e.key === "Enter") {
      this.handleSwitchClick();
    }
  }

  get switchTabIndex(): "0" | undefined {
    return this.disabled ? undefined : "0";
  }

  get isActiveTrackClass():
    | "udex-switch__track--active"
    | "udex-switch__track--inactive" {
    return this.checked ? "udex-switch__track--active" : "udex-switch__track--inactive";
  }

  get isSemanticColor(): boolean {
    return this.color === SwitchColor.Semantic;
  }
}

UDExSwitch.define();

export default UDExSwitch;
