/* eslint no-unused-vars: 0 */
import { html, svg, repeat, classMap, styleMap, ifDefined, unsafeHTML, scopeTag } from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import type UI5Element from "@ui5/webcomponents-base/dist/UI5Element.js";
	import type MastheadShell from "../../MastheadShell.js";
	import type { ClassMapValue } from "@ui5/webcomponents-base/dist/types.js";
	
function block0 (this: MastheadShell, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<div class="udex-masthead-shell" @keydown="${this.handleKeyDown}"><div class="udex-masthead-shell__main-content">${ this.logoUrl ? block1.call(this, context, tags, suffix) : undefined }${ this.siteLabel ? block4.call(this, context, tags, suffix) : undefined }${ !this._isSearchVisible ? block7.call(this, context, tags, suffix) : undefined }</div>${ this._isSearchVisible ? block9.call(this, context, tags, suffix) : block10.call(this, context, tags, suffix) }</div> `;}
function block1 (this: MastheadShell, context: UI5Element, tags: string[], suffix: string | undefined) { return html`${ this.homeUrl ? block2.call(this, context, tags, suffix) : block3.call(this, context, tags, suffix) }`;}
function block2 (this: MastheadShell, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<a role="link" href="${ifDefined(this.homeUrl)}" target="_self" aria-label="${ifDefined(this.homepageAriaLabel)}" class="udex-masthead-shell__logo udex-masthead-shell__logo--link"><img src="${ifDefined(this.logoUrl)}" class="udex-masthead-shell__logo-image" alt="${ifDefined(this.logoAlt)}" /></a>`;}
function block3 (this: MastheadShell, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<div class="udex-masthead-shell__logo"><img src="${ifDefined(this.logoUrl)}" class="udex-masthead-shell__logo-image" alt="${ifDefined(this.logoAlt)}" /></div>`;}
function block4 (this: MastheadShell, context: UI5Element, tags: string[], suffix: string | undefined) { return html`${ this.homeUrl ? block5.call(this, context, tags, suffix) : block6.call(this, context, tags, suffix) }`;}
function block5 (this: MastheadShell, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<a role="link" href="${ifDefined(this.siteLabelUrl)}" target="_self" aria-label="${ifDefined(this.siteLabelAriaLabel)}" class="udex-masthead-shell__site-label udex-masthead-shell__site-label--link">${ifDefined(this.siteLabel)}</a>`;}
function block6 (this: MastheadShell, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<div class="udex-masthead-shell__site-label">${ifDefined(this.siteLabel)}</div>`;}
function block7 (this: MastheadShell, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<div class="udex-masthead-shell__main-menu" data-menubar-item-expanded="true" role="menubar">${ repeat(this.menuItems, (item, index) => (item as typeof item & {_id?: any})._id || index, (item, index: number) => block8.call(this, context, tags, suffix, item, index)) }</div>`;}
function block8 (this: MastheadShell, context: UI5Element, tags: string[], suffix: string | undefined, item: any, index: number) { return html`<div @click="${this.setSelectedItem}" class="udex-masthead-shell__item" role="menuitem"><slot name="${ifDefined(item._individualSlot)}"></slot></div>`;}
function block9 (this: MastheadShell, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<div class="udex-masthead-shell__search-container"><slot name="search"></slot><${scopeTag("udex-control-button", tags, suffix)} size="Small" icon="decline" accessible-name="${ifDefined(this.closeButtonAccessibleName)}" @click=${this.hideSearch}></${scopeTag("udex-control-button", tags, suffix)}></div>` : html`<div class="udex-masthead-shell__search-container"><slot name="search"></slot><udex-control-button size="Small" icon="decline" accessible-name="${ifDefined(this.closeButtonAccessibleName)}" @click=${this.hideSearch}></udex-control-button></div>`;}
function block10 (this: MastheadShell, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<div class="udex-masthead-shell__right-menu"><div class="udex-masthead-shell__explore" @click="${this.setSelectedItem}"><slot name="exploreSAP"></slot></div>${ this.hasSearch ? block11.call(this, context, tags, suffix) : undefined }${ this.hasCart ? block12.call(this, context, tags, suffix) : undefined }${ this.hasProfile ? block13.call(this, context, tags, suffix) : undefined }${ this.hasMobileMenuButtons ? block14.call(this, context, tags, suffix) : undefined }</div>`;}
function block11 (this: MastheadShell, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<div class="udex-masthead-shell__search-icon"><${scopeTag("udex-masthead-item", tags, suffix)} class="udex-masthead-shell__search-btn" icon="search" @click="${this.handleSearchClick}" accessible-name="search"></${scopeTag("udex-masthead-item", tags, suffix)}></div>` : html`<div class="udex-masthead-shell__search-icon"><udex-masthead-item class="udex-masthead-shell__search-btn" icon="search" @click="${this.handleSearchClick}" accessible-name="search"></udex-masthead-item></div>`;}
function block12 (this: MastheadShell, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<div class="udex-masthead-shell__cart" @click="${this.handleCartClick}"><slot name="cart"></slot></div>`;}
function block13 (this: MastheadShell, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<div class="udex-masthead-shell__profile" @click="${this.handleProfileClick}"><slot name="profile"></slot></div>`;}
function block14 (this: MastheadShell, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<${scopeTag("udex-masthead-item", tags, suffix)} @click="${this.handleMobileMenuClick}" class="udex-masthead-shell__menu-btn" accessible-role="button" icon="menu2" part="menu-open-btn" accessible-name="menu"><slot slot="dropdown" name="mobileMenu"></slot></${scopeTag("udex-masthead-item", tags, suffix)}><${scopeTag("udex-masthead-item", tags, suffix)} @click="${this.handleMobileCloseClick}" class="udex-masthead-shell__close-btn" accessible-role="button" icon="decline" accessible-name="close" part="menu-close-btn" hide-active-border></${scopeTag("udex-masthead-item", tags, suffix)}>` : html`<udex-masthead-item @click="${this.handleMobileMenuClick}" class="udex-masthead-shell__menu-btn" accessible-role="button" icon="menu2" part="menu-open-btn" accessible-name="menu"><slot slot="dropdown" name="mobileMenu"></slot></udex-masthead-item><udex-masthead-item @click="${this.handleMobileCloseClick}" class="udex-masthead-shell__close-btn" accessible-role="button" icon="decline" accessible-name="close" part="menu-close-btn" hide-active-border></udex-masthead-item>`;}


export default block0;