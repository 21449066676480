/* eslint no-unused-vars: 0 */
import { html, svg, repeat, classMap, styleMap, ifDefined, unsafeHTML, scopeTag } from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import type UI5Element from "@ui5/webcomponents-base/dist/UI5Element.js";
	import type Carousel from "../../Carousel.js";
	import type { ClassMapValue } from "@ui5/webcomponents-base/dist/types.js";
	
function block0 (this: Carousel, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<div class="${ifDefined(this.carouselClass)}" @keydown=${this._onkeydown}>${ this.showNavigationButtons.top ? block1.call(this, context, tags, suffix) : undefined }<${scopeTag("ui5-carousel", tags, suffix)} id="carousel" hide-navigation-arrows arrows-placement="Navigation" accessible-name="${ifDefined(this.accessibleName)}" ?hide-page-indicator=${this._hidePageIndicator}>${ repeat(this.items, (item, index) => (item as typeof item & {_id?: any})._id || index, (item, index: number) => block2.call(this, context, tags, suffix, item, index)) }</${scopeTag("ui5-carousel", tags, suffix)}>${ this.showNavigationButtons.bottom ? block3.call(this, context, tags, suffix) : undefined }</div> ` : html`<div class="${ifDefined(this.carouselClass)}" @keydown=${this._onkeydown}>${ this.showNavigationButtons.top ? block1.call(this, context, tags, suffix) : undefined }<ui5-carousel id="carousel" hide-navigation-arrows arrows-placement="Navigation" accessible-name="${ifDefined(this.accessibleName)}" ?hide-page-indicator=${this._hidePageIndicator}>${ repeat(this.items, (item, index) => (item as typeof item & {_id?: any})._id || index, (item, index: number) => block2.call(this, context, tags, suffix, item, index)) }</ui5-carousel>${ this.showNavigationButtons.bottom ? block3.call(this, context, tags, suffix) : undefined }</div> `;}
function block1 (this: Carousel, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<div class="udex-carousel__navigation-wrapper" part="navigation"><${scopeTag("udex-button", tags, suffix)} size="Large" design=${ifDefined(this.buttonDesign)} arrow-back class="udex-carousel__navigation-button" icon="slim-arrow-left" tooltip="${ifDefined(this.previousPageText)}" part="prev-button" @click=${this._prevButtonClick} ?disabled=${this.isPrevButtonDisabled}></${scopeTag("udex-button", tags, suffix)}><${scopeTag("udex-button", tags, suffix)} size="Large" design=${ifDefined(this.buttonDesign)} arrow-forward class="udex-carousel__navigation-button" icon="slim-arrow-right" tooltip="${ifDefined(this.nextPageText)}" part="next-button" @click=${this._nextButtonClick} ?disabled=${this.isNextButtonDisabled}></${scopeTag("udex-button", tags, suffix)}></div>` : html`<div class="udex-carousel__navigation-wrapper" part="navigation"><udex-button size="Large" design=${ifDefined(this.buttonDesign)} arrow-back class="udex-carousel__navigation-button" icon="slim-arrow-left" tooltip="${ifDefined(this.previousPageText)}" part="prev-button" @click=${this._prevButtonClick} ?disabled=${this.isPrevButtonDisabled}></udex-button><udex-button size="Large" design=${ifDefined(this.buttonDesign)} arrow-forward class="udex-carousel__navigation-button" icon="slim-arrow-right" tooltip="${ifDefined(this.nextPageText)}" part="next-button" @click=${this._nextButtonClick} ?disabled=${this.isNextButtonDisabled}></udex-button></div>`;}
function block2 (this: Carousel, context: UI5Element, tags: string[], suffix: string | undefined, item: any, index: number) { return html`<slot name="${ifDefined(item._individualSlot)}"></slot>`;}
function block3 (this: Carousel, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<div class="udex-carousel__navigation-wrapper" part="navigation"><${scopeTag("udex-button", tags, suffix)} size="Large" design=${ifDefined(this.buttonDesign)} arrow-back class="udex-carousel__navigation-button" icon="slim-arrow-left" tooltip="${ifDefined(this.previousPageText)}" part="prev-button" @click=${this._prevButtonClick} ?disabled=${this.isPrevButtonDisabled}></${scopeTag("udex-button", tags, suffix)}><${scopeTag("udex-button", tags, suffix)} size="Large" design=${ifDefined(this.buttonDesign)} arrow-forward class="udex-carousel__navigation-button" icon="slim-arrow-right" tooltip="${ifDefined(this.nextPageText)}" part="next-button" @click=${this._nextButtonClick} ?disabled=${this.isNextButtonDisabled}></${scopeTag("udex-button", tags, suffix)}></div>` : html`<div class="udex-carousel__navigation-wrapper" part="navigation"><udex-button size="Large" design=${ifDefined(this.buttonDesign)} arrow-back class="udex-carousel__navigation-button" icon="slim-arrow-left" tooltip="${ifDefined(this.previousPageText)}" part="prev-button" @click=${this._prevButtonClick} ?disabled=${this.isPrevButtonDisabled}></udex-button><udex-button size="Large" design=${ifDefined(this.buttonDesign)} arrow-forward class="udex-carousel__navigation-button" icon="slim-arrow-right" tooltip="${ifDefined(this.nextPageText)}" part="next-button" @click=${this._nextButtonClick} ?disabled=${this.isNextButtonDisabled}></udex-button></div>`;}


export default block0;