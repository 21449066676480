import type { StyleData } from "@ui5/webcomponents-base/dist/types.js";
import { registerThemePropertiesLoader } from "@ui5/webcomponents-base/dist/asset-registries/Themes.js";

import defaultThemeBase from "@ui5/webcomponents-theming/dist/generated/themes/sap_horizon/parameters-bundle.css.js";
import defaultTheme from "./sap_horizon/parameters-bundle.css.js";

registerThemePropertiesLoader("@ui5/webcomponents-theming", "sap_horizon", async () => defaultThemeBase);
registerThemePropertiesLoader("@ui5/webcomponents", "sap_horizon", async () => defaultTheme);

const styleData: StyleData = {packageName:"@ui5/webcomponents",fileName:"themes/NavigationMenu.css.ts",content:`.ui5-hidden-text{position:absolute;clip:rect(1px,1px,1px,1px);user-select:none;left:-1000px;top:-1000px;pointer-events:none;font-size:0}.ui5-navigation-menu .ui5-navigation-menu-main{padding:var(--_ui5-v1-24-12_side_navigation_parent_popup_padding)}.ui5-navigation-menu .ui5-menu-item.ui5-navigation-menu-item::part(native-li){padding-left:.5rem;width:auto}.ui5-navigation-menu .ui5-menu-item.ui5-navigation-menu-item::part(native-li):after{border-radius:.375rem;inset:0}.ui5-navigation-menu .ui5-navigation-menu-item{display:flex;align-items:center;width:100%;box-sizing:border-box;position:relative;height:var(--_ui5-v1-24-12_side_navigation_item_height);min-height:var(--_ui5-v1-24-12_side_navigation_item_height);border-radius:.375rem;transition:var(--_ui5-v1-24-12_side_navigation_item_transition);color:var(--sapList_TextColor);font-weight:700;font-size:var(--sapFontSize);border-bottom:none}.ui5-navigation-menu .ui5-navigation-menu-item a{display:flex;align-items:center;text-decoration:none;color:var(--sapList_TextColor)}.ui5-navigation-menu[sub-menu] .ui5-navigation-menu-item{font-weight:400}.ui5-menu-rp.ui5-navigation-menu{box-shadow:var(--_ui5-v1-24-12_side_navigation_popup_box_shadow);width:auto;min-width:10rem;height:auto;background:var(--sapGroup_ContentBackground);border:none;border-radius:.75rem}.ui5-navigation-menu .ui5-navigation-menu-item .ui5-menu-item-icon-end{position:relative;inset-inline-end:auto;width:.75rem;height:.75rem;color:var(--sapList_TextColor)}.ui5-navigation-menu .ui5-navigation-menu-item::part(icon){color:var(--sapList_TextColor);min-width:1rem;min-height:1rem}.ui5-navigation-menu .ui5-menu-item[focused]:not([active]){background:none}.ui5-sn-item-external-link-icon{color:var(--_ui5-v1-24-12_side_navigation_external_link_icon_color);min-width:2rem;height:.875rem}
`};
export default styleData;
	