import type { StyleData } from "@ui5/webcomponents-base/dist/types.js";
import { registerThemePropertiesLoader } from "@ui5/webcomponents-base/dist/asset-registries/Themes.js";

import defaultThemeBase from "@ui5/webcomponents-theming/dist/generated/themes/sap_horizon/parameters-bundle.css.js";
import defaultTheme from "./sap_horizon/parameters-bundle.css.js";

registerThemePropertiesLoader("@ui5/webcomponents-theming", "sap_horizon", async () => defaultThemeBase);
registerThemePropertiesLoader("@sapudex/web-components", "sap_horizon", async () => defaultTheme);

const styleData: StyleData = {packageName:"@sapudex/web-components",fileName:"themes/MastheadMobileMenu.css.ts",content:`:host{--udex-masthead-mobile-menu-padding-m: var(--udexSpacer24);--udex-masthead-mobile-menu-padding-s: var(--udexSpacer24) var(--udexSpacer48);--udex-masthead-mobile-menu-padding-xs: var(--udexSpacer24);--udex-masthead-mobile-menu-background: var(--sapShell_Navigation_Background, #FFF);--udex-masthead-mobile-search-margin-bottom: var(--udexSpacer16, 16);--udex-masthead-mobile-explore-btn-position: sticky}.udex-masthead-mobile-menu__list-search{margin-bottom:var(--udex-masthead-mobile-search-margin-bottom)}.udex-masthead-mobile-menu{background:var(--udex-masthead-mobile-menu-background);box-sizing:border-box;overflow:auto;display:flex;flex-direction:column;height:100%}.udex-masthead-mobile-menu__nav-holder{padding:var(--udex-masthead-mobile-menu-padding-m)}.udex-masthead-mobile-menu__explore-btn{position:var(--udex-masthead-mobile-explore-btn-position);margin-block-start:auto;inset-block-end:0;inline-size:100%}.udex-masthead-mobile-menu__list{margin:0;padding:0;list-style:none}udex-masthead-menu-item{--udex-masthead-menu-item-padding-desktop: var(--udexSpacer16) var(--udexSpacer12) var(--udexSpacer16) var(--udexSpacer8)}udex-masthead-menu-item::part(menu-item-link){width:100%}.udex-masthead-mobile-menu__back-item::part(menu-item-button){font-weight:var(--udexTypographyFontWeightMedium, 500);justify-content:space-between}.udex-masthead-mobile-menu__back-item::part(menu-item-button):after{content:""}.udex-masthead-mobile-menu__back-item::part(left){margin-inline-end:inherit}@media (max-width: 979px){.udex-masthead-mobile-menu__nav-holder{padding:var(--udex-masthead-mobile-menu-padding-s)}}@media (max-width: 639px){.udex-masthead-mobile-menu__nav-holder{padding:var(--udex-masthead-mobile-menu-padding-xs)}}
`};
export default styleData;
	