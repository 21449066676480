import type UI5Element from "@ui5/webcomponents-base/dist/UI5Element";
import { registerFeature } from "@ui5/webcomponents-base/dist/FeaturesRegistry.js";
import Button from "@ui5/webcomponents/dist/Button.js";
import Popover from "@ui5/webcomponents/dist/Popover.js";

interface SuggestionComponent extends UI5Element {
  open: boolean;
  value?: string;
  suggestionItems?: string;
}

type SearchInputSuggestion = {
  handleSelectItem: () => void;
  text: string;
}

/**
 * A class to manage the <code>Search</code suggestion items.
 *
 * @class
 * @private
 * @author SAP SE
 */
class Suggestion {
  component: SuggestionComponent;
  popover?: Popover | null;
  selectedItemIndex: number;
  focusabledList?: HTMLDivElement;
  lists?: Array<HTMLDivElement>;

  constructor(component: SuggestionComponent) {
    this.component = component;
    this._getSuggestionPopover();
    this.selectedItemIndex = -1;
  }

  _getComponent(): SuggestionComponent {
    return this.component;
  }

  async _getSuggestionPopover() {
    if (this.popover) {
      return this.popover;
    }

    const staticAreaItem = await this._getComponent().getStaticAreaItemDomRef();
    this.popover = staticAreaItem!.querySelector<Popover>("[ui5-popover]");
    return this.popover;
  }

  _getItems() {
    return this.popover ? [...this.popover?.querySelectorAll("[ui5-button]")] : [];
  }

  open(): void {
    this._getComponent().open = true;
    this.popover!.showAt(this._getComponent());
    this.initLists();
  }

  initLists(): void {
    const list = this.popover?.querySelectorAll(".udex-search-suggestions__list") ? this.popover?.querySelectorAll<HTMLDivElement>(".udex-search-suggestions__list") : [];
    this.lists = Array.from(list);
  }

  close(): void {
    this._getComponent().open = false;
    this.popover?.close(true);
    this.selectedItemIndex = -1;
  }

  resetFocusState() {
    this.lists = [];
    this.selectedItemIndex = -1;
    this.focusabledList = undefined;
  }

  onFocusInput(): void {
    this.component.shadowRoot?.querySelector("input")?.focus();
  }

  onEnter(e: KeyboardEvent) {
    e.preventDefault();
    if (this.selectedItemIndex >= 0) {
      this.component.value = this._getItems()[this.selectedItemIndex].getAttribute("title")?.replace(/<[^>]*>?/gm, "");
    }
  }

  onTab(e: KeyboardEvent) {
    if (this.lists?.length && this._getComponent().open) {
      e.preventDefault();
      if (this.focusabledList && this.selectedItemIndex === -1) {
        this.focusabledList.focus();
        return true;
      }
      if (this.focusabledList && this.selectedItemIndex >= 0) {
        const focusabledItems = this.getFocusableItems();
        const button = focusabledItems[this.selectedItemIndex].querySelector("[ui5-button]") as UI5Element;

        button.focus();
        return true;
      }

      this.focusabledList = this.lists[0];
      this.focusabledList.focus();
    }
  }

  getFocusableItems() {
    const items = this.focusabledList?.children ? this.focusabledList?.children : [];
    return Array.from(items);
  }

  onEscape(e: KeyboardEvent) {
    e.preventDefault();
    this.close();
    this.onFocusInput();
  }

  onKeyTabList(e: KeyboardEvent) {
    e.preventDefault();
    if (this.lists?.length === 2) {
      if (this.focusabledList === this.lists[1]) {
        (this.component?.shadowRoot?.querySelector("[ui5-button]") as UI5Element).focus();
        this.close();
      }
      if (this.focusabledList === this.lists[0]) {
        this.focusabledList = this.lists[1];
        this.focusabledList.focus();
        this.selectedItemIndex = -1;
      }
    }
    if (this.lists?.length === 1) {
      (this.component?.shadowRoot?.querySelector("[ui5-button]") as UI5Element).focus();
      this.close();
    }
  }

  onPrevKeyTabList(e: KeyboardEvent) {
    e.preventDefault();
    if (this.lists?.length === 2) {
      if (this.focusabledList === this.lists[0]) {
        this.onFocusInput();
      }
      if (this.focusabledList === this.lists[1]) {
        this.focusabledList = this.lists[0];
        this.focusabledList.focus();
        this.selectedItemIndex = -1;
      }
    }
    if (this.lists?.length === 1) {
      this.onFocusInput();
    }
  }

  onKeyDown(e: KeyboardEvent) {
    e.preventDefault();
    const nextSelectedIdx = this.selectedItemIndex;
    const focusabledItems = this.getFocusableItems();
    const itemCount = focusabledItems.length - 1;

    if (this.lists?.length) {
      if (nextSelectedIdx === -1) {
        const button = focusabledItems[0] as UI5Element;

        button.setAttribute("aria-selected", "true");
        button.focus();
        this.selectedItemIndex = 0;
        this.focusabledList?.setAttribute("aria-activedescendant", button.id);
        return true;
      }
      if (nextSelectedIdx >= 0 && nextSelectedIdx !== itemCount) {
        const button = focusabledItems[nextSelectedIdx + 1] as UI5Element;

        focusabledItems[nextSelectedIdx].removeAttribute("aria-selected");
        button.setAttribute("aria-selected", "true");
        button.focus();
        this.selectedItemIndex = nextSelectedIdx + 1;
        this.focusabledList?.setAttribute("aria-activedescendant", button.id);
        return true;
      }
      if (nextSelectedIdx === itemCount && this.focusabledList === this.lists[0] && this.lists.length === 2) {
        focusabledItems[nextSelectedIdx].removeAttribute("aria-selected");
        this.focusabledList?.removeAttribute("aria-activedescendant");
        this.focusabledList = this.lists[1];
        this.focusabledList.focus();
        this.selectedItemIndex = -1;
        return true;
      }
    }
  }

  onKeyUp(e: KeyboardEvent) {
    e.preventDefault();
    const focusabledItems = this.getFocusableItems();
    const itemCount = focusabledItems.length - 1;
    const previousSelectedIdx = this.selectedItemIndex;

    if (this.lists?.length) {
      if (previousSelectedIdx === -1) {
        const button = focusabledItems[0] as UI5Element;

        button.setAttribute("aria-selected", "true");
        button.focus();
        this.selectedItemIndex = 0;
        this.focusabledList?.setAttribute("aria-activedescendant", button.id);
        return true;
      }
      if (previousSelectedIdx > 0 && previousSelectedIdx <= itemCount) {
        const button = focusabledItems[previousSelectedIdx - 1] as UI5Element;

        focusabledItems[previousSelectedIdx].removeAttribute("aria-selected");
        button.setAttribute("aria-selected", "true");
        button.focus();
        this.selectedItemIndex = previousSelectedIdx - 1;
        this.focusabledList?.setAttribute("aria-activedescendant", button.id);
        return true;
      }
      if (previousSelectedIdx === 0 && this.lists.length === 2 && this.focusabledList === this.lists[1]) {
        focusabledItems[previousSelectedIdx].removeAttribute("aria-selected");
        this.focusabledList?.removeAttribute("aria-activedescendant");
        this.focusabledList = this.lists[0];
        this.focusabledList.focus();
        this.selectedItemIndex = -1;
        return true;
      }
    }
  }

  handlePrevTabReset(e: KeyboardEvent) {
    e.preventDefault();
    this.focusabledList?.focus();
  }

  static get dependencies() {
    return [
      Popover,
      Button,
    ];
  }
}

registerFeature("SearchInputSuggestion", Suggestion);

export default Suggestion;

export type {
  SuggestionComponent,
  SearchInputSuggestion,
};
