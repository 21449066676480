/* eslint no-unused-vars: 0 */
import { html, svg, repeat, classMap, styleMap, ifDefined, unsafeHTML, scopeTag } from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import type UI5Element from "@ui5/webcomponents-base/dist/UI5Element.js";
	import type SideNavigationMobile from "../../SideNavigationMobile.js";
	import type { ClassMapValue } from "@ui5/webcomponents-base/dist/types.js";
	
function block0 (this: SideNavigationMobile, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<div class="udex-side-navigation-mobile__trigger" slot="trigger" accessible-role="button" aria-haspopup="true" ?active="${this._dropdownOpened}" tabindex="0" @keydown="${this.handleKeydown}" @click="${this.handleTriggerClick}"><div class="udex-side-navigation-mobile__selected-item">${ this._selectedItem ? block1.call(this, context, tags, suffix) : undefined }</div><${scopeTag("ui5-icon", tags, suffix)} class="udex-side-navigation-mobile__trigger-toggle-icon" name="slim-arrow-down"></${scopeTag("ui5-icon", tags, suffix)}></div>` : html`<div class="udex-side-navigation-mobile__trigger" slot="trigger" accessible-role="button" aria-haspopup="true" ?active="${this._dropdownOpened}" tabindex="0" @keydown="${this.handleKeydown}" @click="${this.handleTriggerClick}"><div class="udex-side-navigation-mobile__selected-item">${ this._selectedItem ? block1.call(this, context, tags, suffix) : undefined }</div><ui5-icon class="udex-side-navigation-mobile__trigger-toggle-icon" name="slim-arrow-down"></ui5-icon></div>`;}
function block1 (this: SideNavigationMobile, context: UI5Element, tags: string[], suffix: string | undefined) { return html`${ this._selectedItem?.icon ? block2.call(this, context, tags, suffix) : undefined }<span>${ifDefined(this._selectedItem?.text)}</span>`;}
function block2 (this: SideNavigationMobile, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<${scopeTag("ui5-icon", tags, suffix)} class="udex-side-navigation-mobile__selected-item-icon" name="${ifDefined(this._selectedItem?.icon)}"></${scopeTag("ui5-icon", tags, suffix)}>` : html`<ui5-icon class="udex-side-navigation-mobile__selected-item-icon" name="${ifDefined(this._selectedItem?.icon)}"></ui5-icon>`;}


export default block0;