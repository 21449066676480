import type { StyleData } from "@ui5/webcomponents-base/dist/types.js";
import { registerThemePropertiesLoader } from "@ui5/webcomponents-base/dist/asset-registries/Themes.js";

import defaultThemeBase from "@ui5/webcomponents-theming/dist/generated/themes/sap_horizon/parameters-bundle.css.js";
import defaultTheme from "./sap_horizon/parameters-bundle.css.js";

registerThemePropertiesLoader("@ui5/webcomponents-theming", "sap_horizon", async () => defaultThemeBase);
registerThemePropertiesLoader("@sapudex/web-components", "sap_horizon", async () => defaultTheme);

const styleData: StyleData = {packageName:"@sapudex/web-components",fileName:"themes/Modal.css.ts",content:`:host{--udexModalAndOverlayModalContentPaddingHorizontal: var(--udexGridSGutters, 32px);--udexModalAndOverlayModalContentPaddingVertical: var(--udexGridXSMargins, 24px);--udexModalAndOverlayModalFooterPaddingHorizontal: var(--udexGridSGutters, 32px);--udexModalAndOverlayModalFooterPaddingVertical: var(--udexGridXSMargins, 24px);--udexModalAndOverlayModalHeaderPaddingHorizontal: var(--udexGridSGutters, 32px);--udexModalAndOverlayModalHeaderPaddingVertical: var(--udexGridXSMargins, 24px);--udexModalBorderRadius: 0;--browser_scrollbar_border_radius: var(--udexRadiusM, 8px)}:host .ui5-popup-root .ui5-popup-footer-root{padding:var(--udexModalAndOverlayModalFooterPaddingVertical) var(--udexModalAndOverlayModalFooterPaddingHorizontal)}:host .ui5-popup-root{border-radius:var(--udexModalBorderRadius)}:host .ui5-popup-root .ui5-popup-header-root{padding:var(--udexModalAndOverlayModalHeaderPaddingVertical) var(--udexModalAndOverlayModalHeaderPaddingHorizontal)}:host .ui5-popup-root .ui5-popup-content{padding:var(--udexModalAndOverlayModalContentPaddingVertical) var(--udexModalAndOverlayModalContentPaddingHorizontal)}:host([type="basic"]){--sapObjectHeader_BorderColor: transparent;--sapPageFooter_BorderColor: transparent}:host([type="basic"]) .ui5-popup-root .ui5-popup-content{padding:0 var(--udexModalAndOverlayModalContentPaddingHorizontal)}:host ::slotted([slot="footer"]){height:auto}
`};
export default styleData;
	