import customElement from "@ui5/webcomponents-base/dist/decorators/customElement.js";
import litRender from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import property from "@ui5/webcomponents-base/dist/decorators/property.js";

import SegmentedButton, { SegmentedButtonSelectionChangeEventDetail } from "@ui5/webcomponents/dist/SegmentedButton.js";
import SegmentedButtonItem from "@ui5/webcomponents/dist/SegmentedButtonItem.js";
import { getScopedVarName } from "@ui5/webcomponents-base/dist/CustomElementsScope.js";

// Styles
import SegmentedButtonMode from "@ui5/webcomponents/dist/types/SegmentedButtonMode.js";
import SegmentedButtonCss from "./generated/themes/SegmentedButton.css.js";
import UDExSegmentedButtonItem, { SegmentedButtonItemSize } from "./SegmentedButtonItem.js";

export enum SegmentedButtonSelectionMode {
  /**
   * There is always one selected. Selecting one deselects the previous one.
   * @public
   */
  Single = "Single",

  /**
   * Multiple items can be selected at a time. All items can be deselected.
   * @public
   */
  Multiple = "Multiple",
}

/**
 * @class
 *
 * <h3 class="comment-api-title">Overview</h3>
 *
 *
 * <h3>Usage</h3>
 *
 * For the <code>udex-segmented-button</code>
 * <h3>ES6 Module Import</h3>
 *
 * <code>import "@udex/web-components/dist/SegmentedButton.js";</code>
 *
 * @constructor
 * @extends UI5Element
 * @public
 */
@customElement({
  tag: "udex-segmented-button",
  renderer: litRender,
  styles: [SegmentedButton.styles, SegmentedButtonCss],
  dependencies: [UDExSegmentedButtonItem],
})
class UDExSegmentedButton extends SegmentedButton {
  /**
   * Defines the size of the button.
   *
   * @default "Medium"
   * @public
   */
  @property({ type: SegmentedButtonItemSize, defaultValue: SegmentedButtonItemSize.Medium })
    size!: `${SegmentedButtonItemSize}`;

  onBeforeRendering() {
    const items = this.getSlottedNodes<UDExSegmentedButtonItem>("items");

    items.forEach((item, index, arr) => {
      item.size = this.size;
      item.posInSet = index + 1;
      item.sizeOfSet = arr.length;
    });

    this.normalizeSelection();

    this.style.setProperty(getScopedVarName("--udex-segmented-button-items-count"), `${items.length}`);
  }

  _onmousedown(e: MouseEvent) {
    const eventTarget = e.target as SegmentedButtonItem;
    const isTargetSegmentedButtonItem = eventTarget.hasAttribute("udex-segmented-button-item");

    if (isTargetSegmentedButtonItem) {
      eventTarget.focus();
      this._itemNavigation.setCurrentItem(eventTarget);
      this.hasPreviouslyFocusedItem = true;
    }
  }

  _selectItem(e: MouseEvent | KeyboardEvent) {
    // Method to be update with @ui5/webcomponents v.2.0 , due to ToggleButton dependency change (pressed -> selected)
    const target = e.target as SegmentedButtonItem;
    const isTargetSegmentedButtonItem = target.hasAttribute("udex-segmented-button-item");

    if (target.disabled || target === this.getDomRef() || !isTargetSegmentedButtonItem) {
      return;
    }

    switch (this.mode) {
    case SegmentedButtonMode.MultiSelect:
      if (e instanceof KeyboardEvent) {
        target.pressed = !target.pressed;
      }
      break;
    default:
      this._applySingleSelection(target);
    }

    this.fireEvent<SegmentedButtonSelectionChangeEventDetail>("selection-change", {
      selectedItem: target,
      selectedItems: this.selectedItems,
    });

    this._itemNavigation.setCurrentItem(target);
    target.focus();

    return this;
  }
}

UDExSegmentedButton.define();

export default UDExSegmentedButton;
