/* eslint no-unused-vars: 0 */
import { html, svg, repeat, classMap, styleMap, ifDefined, unsafeHTML, scopeTag } from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import type UI5Element from "@ui5/webcomponents-base/dist/UI5Element.js";
	import type TableCell from "../../TableCell.js";
	import type { ClassMapValue } from "@ui5/webcomponents-base/dist/types.js";
	
function block0 (this: TableCell, context: UI5Element, tags: string[], suffix: string | undefined) { return html`${ this.icon ? block1.call(this, context, tags, suffix) : undefined }<slot></slot>`;}
function block1 (this: TableCell, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<${scopeTag("ui5-icon", tags, suffix)} class="udex-table-cell__icon" name="${ifDefined(this.icon)}"></${scopeTag("ui5-icon", tags, suffix)}>` : html`<ui5-icon class="udex-table-cell__icon" name="${ifDefined(this.icon)}"></ui5-icon>`;}


export default block0;