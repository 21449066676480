import UI5Element from "@ui5/webcomponents-base/dist/UI5Element.js";
import customElement from "@ui5/webcomponents-base/dist/decorators/customElement.js";
import property from "@ui5/webcomponents-base/dist/decorators/property.js";
import slot from "@ui5/webcomponents-base/dist/decorators/slot.js";
import litRender from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";

import Icon from "@ui5/webcomponents/dist/Icon.js";
import type I18nBundle from "@ui5/webcomponents-base/dist/i18nBundle.js";
import { getI18nBundle } from "@ui5/webcomponents-base/dist/i18nBundle.js";
import UDExCheckbox from "./Checkbox.js";
import UDExRadioButton from "./RadioButton.js";
import UDExStatusMessage from "./StatusMessage.js";
import InputGroupTemplate from "./generated/templates/InputGroupTemplate.lit.js";
import {
  VALUE_STATE_ERROR,
  VALUE_STATE_SUCCESS,
} from "./generated/i18n/i18n-defaults.js";

// Styles
import InputGroupCss from "./generated/themes/InputGroup.css.js";

export enum GroupValueState {
  Standard = "Standard",
  Error = "Error",
  Success = "Success",
}

export enum Orientation {
  Vertical = "Vertical",
  Horizontal = "Horizontal",
}

/**
 * @class
 *
 * <h3 class="comment-api-title">Overview</h3>
 *
 *
 * <h3>Usage</h3>
 *
 * For the <code>udex-input-group</code>
 * <h3>ES6 Module Import</h3>
 *
 * <code>import "@udex/web-components/dist/InputGroup.js";</code>
 *
 * @constructor
 * @extends UI5Element
 * @public
 */

@customElement({
  tag: "udex-input-group",
  renderer: litRender,
  styles: InputGroupCss,
  template: InputGroupTemplate,
  dependencies: [Icon, UDExStatusMessage],
})

class UDExInputGroup extends UI5Element {
  /**
   * Defines the group name of the component.
   *
   * @public
   */
  @property({ type: String })
    groupName?: string;

  /**
   * Defines the orientation of the component.
   *
   * @default "Vertical"
   * @public
   */
  @property({ type: Orientation, defaultValue: Orientation.Vertical })
    orientation!: `${Orientation}`;

  /**
   * Defines the value state of the component.
   *
   * @default "Standard"
   * @public
   */
  @property({ type: GroupValueState, defaultValue: GroupValueState.Standard })
    valueState!: `${GroupValueState}`;

  /**
   * Defines supporting text under the Input Group.
   *
   * @default ""
   * @public
   */
  @property({ type: String })
    supportingText!: string;

  /**
   * Slot for input elements items.
   *
   * @slot
   * @public
   */
  @slot({ type: HTMLElement, "default": true, individualSlots: true })
    items!: Array<UDExCheckbox | UDExRadioButton>;

  static i18nBundle: I18nBundle;

  static async onDefine() {
    UDExInputGroup.i18nBundle = await getI18nBundle("sap-ui-webcomponents-bundle");
  }

  get _items() {
    return this.items.map(el => {
      if (this.groupName) { el.name = this.groupName; }
      return {
        item: el,
      };
    });
  }

  get valueStateTypeMappings(): Record<string, string> {
    return {
      "Success": UDExInputGroup.i18nBundle.getText(VALUE_STATE_SUCCESS),
      "Error": UDExInputGroup.i18nBundle.getText(VALUE_STATE_ERROR),
    };
  }

  get hasValueState(): boolean {
    return this.valueState !== GroupValueState.Standard;
  }

  get valueStateText(): string | undefined {
    return this.supportingText || this.valueStateTypeMappings[this.valueState];
  }
}

UDExInputGroup.define();

export default UDExInputGroup;
