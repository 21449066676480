import UI5Element from "@ui5/webcomponents-base/dist/UI5Element.js";
import customElement from "@ui5/webcomponents-base/dist/decorators/customElement.js";
import property from "@ui5/webcomponents-base/dist/decorators/property.js";
import slot from "@ui5/webcomponents-base/dist/decorators/slot.js";
import litRender from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import { isSpace } from "@ui5/webcomponents-base/dist/Keys.js";
import "@ui5/webcomponents/dist/Icon.js";
import "@ui5/webcomponents-icons/dist/media-play.js";
import "@ui5/webcomponents-icons/dist/media-pause.js";

import HeroBannerTemplate from "./generated/templates/HeroBannerTemplate.lit.js";

// Styles
import HeroBannerCss from "./generated/themes/HeroBanner.css.js";

export enum imageLoadingStrategies {
  eager = "eager",
  lazy = "lazy",
}

/**
 * @class
 *
 * @constructor
 * @extends UI5Element
 * @tagname udex-hero-banner
 * @public
 */
@customElement({
  tag: "udex-hero-banner",
  renderer: litRender,
  styles: HeroBannerCss,
  template: HeroBannerTemplate,
})
class UDExHeroBanner extends UI5Element {
  /**
   * Allows control over the background image source.
   *
   * @public
   */
  @property({ type: String })
    backgroundImage?: string;

  /**
   * Allows control over the background image loading strategy.
   *
   * @public
   */
  @property({
    type: imageLoadingStrategies,
    defaultValue: imageLoadingStrategies.eager,
  })
    backgroundImageLoadingStrategy?: `${imageLoadingStrategies}`;

  /**
   * Used as the label for background image.
   *
   * @public
   */
  @property({ type: String })
    backgroundImageLabel?: string;

  /**
   * Allows control over the background video source.
   *
   * @public
   */
  @property({ type: String })
    backgroundVideo?: string;

  /**
   * Used as the label for background video.
   *
   * @public
   */
  @property({ type: String })
    backgroundVideoLabel?: string;

  /**
   * Allows control over the background color.
   * Value provided should be compatible with standard CSS rendering, e.g:
   *  - HEX: "#FFF", "#FFFFFF", "FFFFFFFF"
   *  - RGB/A: "rgb(255, 255, 255)", "rgba(255, 255, 255, 1)"
   *  - HSL/A: "hsl(0, 100%, 100%)", "hsla(0, 100%, 100%, 1"
   *  - Variables: "var(--sapShell_Background)", "var(--udexColorNeutralWhite)"
   *  - Text values: "white", "red"
   *
   * @public
   */
  @property({ type: String })
    backgroundColor?: string;

  @property({ type: Boolean, noAttribute: true })
    _isVideoRunning?: boolean;

  /**
   * Slot for primary content.
   *
   * @slot
   * @public
   */
  @slot()
    content?: Array<HTMLElement>;

  /**
   * Slot for any additional content.
   *
   * @slot
   * @public
   */
  @slot()
    additionalContent?: Array<HTMLElement>;

  /**
   * Slot background picture override.
   *
   * @slot
   * @public
   */
  @slot()
    backgroundPicture?: Array<HTMLElement>;

  get hasBackgroundImage() {
    return !!this.backgroundImage;
  }
  get hasBackgroundVideo() {
    return !!this.backgroundVideo;
  }
  get wrapperBackgroundColor() {
    return this.backgroundColor ? this.backgroundColor : "transparent";
  }

  /**
   * Determines if the video should autoplay based on the user's global preference.
   */
  get shouldVideoAutoplay() {
    return !window.matchMedia("(prefers-reduced-motion: reduce)").matches;
  }

  get heroBannerClasses(): string {
    const heroBannerClasses = ["udex-hero-banner"];
    if (this.hasBackgroundVideo) {
      heroBannerClasses.push("udex-hero-banner--video");
    }
    return heroBannerClasses.join(" ");
  }
  get hasAdditionalContent() {
    return !!this.additionalContent?.length;
  }

  get hasCustomBackgroundPicture() {
    return !!this.backgroundPicture?.length;
  }

  get mediaButtonIconName() {
    return this._isVideoRunning ? "media-pause" : "media-play";
  }
  get _video() {
    return this.shadowRoot?.querySelector<HTMLVideoElement>("video");
  }

  _toggleMediaButton(): void {
    if (this._video?.paused) {
      this._video?.play();
    } else {
      this._video?.pause();
    }
    this._calculateMediaButtonState();
  }

  _calculateMediaButtonState() {
    this._isVideoRunning = !this._video?.paused ?? false;
  }

  _isHTMLDivElement = (element: HTMLElement): element is HTMLDivElement => {
    return element.tagName === "DIV";
  };

  _handleKeydown(event: KeyboardEvent): void {
    const target = <HTMLElement>event.target;
    if (this._isHTMLDivElement(target) && isSpace(event)) {
      event.preventDefault();
      event.stopPropagation();
      this._toggleMediaButton();
    }
  }

  onEnterDOM() {
    if (this.backgroundVideo) {
      this._video?.addEventListener("play", () => {
        this._calculateMediaButtonState();
      });
    }
  }
}
UDExHeroBanner.define();

export default UDExHeroBanner;
