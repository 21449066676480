import customElement from "@ui5/webcomponents-base/dist/decorators/customElement.js";
import litRender from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import ProgressIndicator from "@ui5/webcomponents/dist/ProgressIndicator.js";

// Styles
import ProgressBarCss from "./generated/themes/ProgressBar.css.js";

/**
 * @class
 *
 * <h3 class="comment-api-title">Overview</h3>
 *
 *
 * <h3>Usage</h3>
 *
 * For the <code>udex-progress-bar</code>
 * <h3>ES6 Module Import</h3>
 *
 * <code>import "@udex/web-components/dist/ProgressBar.js";</code>
 *
 * @constructor
 * @extends ProgressIndicator
 * @public
 */
@customElement({
  tag: "udex-progress-bar",
  renderer: litRender,
  styles: [ProgressIndicator.styles, ProgressBarCss],
})
class UDExProgressBar extends ProgressIndicator {
}

UDExProgressBar.define();

export default UDExProgressBar;
