import UI5Element from "@ui5/webcomponents-base/dist/UI5Element.js";
import customElement from "@ui5/webcomponents-base/dist/decorators/customElement.js";
import type { StyleData } from "@ui5/webcomponents-base/dist/types.js";
import property from "@ui5/webcomponents-base/dist/decorators/property.js";
import slot from "@ui5/webcomponents-base/dist/decorators/slot.js";
import event from "@ui5/webcomponents-base/dist/decorators/event.js";
import litRender from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import { getI18nBundle } from "@ui5/webcomponents-base/dist/i18nBundle.js";
import ResizeHandler from "@ui5/webcomponents-base/dist/delegate/ResizeHandler.js";
import ResponsivePopover from "@ui5/webcomponents/dist/ResponsivePopover.js";
import Integer from "@ui5/webcomponents-base/dist/types/Integer.js";
import { isDesktop } from "@ui5/webcomponents-base/dist/Device.js";
import {
  isUp,
  isDown,
  isRight,
  isLeft,
  isEnter,
  isSpace,
  isEscape,
  isTabNext,
  isTabPrevious,
  isHome,
  isEnd,
} from "@ui5/webcomponents-base/dist/Keys.js";
import Tab, { ITab } from "./Tab.js";
import "@ui5/webcomponents/dist/Icon.js";
import "@ui5/webcomponents-icons/dist/navigation-down-arrow.js";
import { MORE } from "./generated/i18n/i18n-defaults.js";

// Templates
import TabNavigationTemplate from "./generated/templates/TabNavigationTemplate.lit.js";
import TabNavigationPopover from "./generated/templates/TabNavigationPopoverTemplate.lit.js";

// Styles
import TabNavigationCss from "./generated/themes/TabNavigation.css.js";

const staticAreaTabStyles: Array<StyleData> = [];

export enum SizeMoreButton {
  Small = "Small",
  Medium = "Medium",
}

export enum Mode {
  Light = "Light",
  Dark = "Dark",
}

/**
 * @class
 *
 * <h3 class="comment-api-title">Overview</h3>
 *
 * <h3>Usage</h3>
 *
 * For the <code>udex-tab-navigation</code>
 * <h3>ES6 Module Import</h3>
 *
 * <code>import @udex/web-components/dist/TabNavigation.js";</code>
 *
 * @constructor
 * @author SAP SE
 * @extends UI5Element
 * @tagname udex-tab-navigation
 * @public
 */
@customElement({
  tag: "udex-tab-navigation",
  renderer: litRender,
  styles: TabNavigationCss,
  template: TabNavigationTemplate,
  staticAreaTemplate: TabNavigationPopover,
  staticAreaStyles: [staticAreaTabStyles],
  dependencies: [ResponsivePopover],
})
/**
 * Fires when the tab was clicked.
 *
 * @allowPreventDefault
 * @param { Number }
 * @public
 */
@event("click", {
  detail: {
    currentPage: { type: Number },
  },
})
class UDExTabNavigation extends UI5Element {
  /**
   * Defines the size of the "More" button.
   *
   * @public
   * @default "Small"
  */
  @property({ type: SizeMoreButton, defaultValue: "Small" })
    sizeMoreButton!: `${SizeMoreButton}`;

  /**
   * Defines the mode of the component.
   *
   * @public
   * @default "Light"
   */
  @property({ type: Mode, defaultValue: "Light" })
    mode!: Mode;

  /**
   * Defines the background color of the component.
   *
   * @public
   * @default false
   */
  @property({ type: Boolean })
    iconWithoutBackground!: boolean;

  @property({ type: String, defaultValue: "auto" })
    tabWidth!: string;

  /**
   * Defines selected tab.
   *
   * @private
   */
  @property({ type: Object })
    _selectedTab!: Tab;

  /**
   * Defines the width of component.
   *
   * @private
   */
  @property({ validator: Integer, noAttribute: true })
    _width?: number;

  /**
   * Defines the overflow items.
   *
   * @private
   */
  @property({ type: Object, multiple: true })
    _overflowItems!: Array<ITab>;

  /**
   * Defines the tabs and content.
   *
   * @slot items
   * @public
   */
  @slot({
    type: HTMLElement,
    "default": true,
    individualSlots: true,
    invalidateOnChildChange: true,
  })
    items!: Array<ITab>;

  static i18nBundle: any;
  _handleResizeBound: () => void;
  _responsivePopover?: ResponsivePopover;
  _visibleTabs?: Array<ITab | HTMLElement>;
  _isExpanded!: boolean;
  _maxTitleLength: number = 25;
  _maxTitleLengthInSecondLines: number = this._maxTitleLength * 2;

  constructor() {
    super();
    this._handleResizeBound = this._handleResize.bind(this);
  }

  static async onDefine(): Promise<void> {
    UDExTabNavigation.i18nBundle = await getI18nBundle("udex-tab-navigation");
  }

  onEnterDOM(): void {
    ResizeHandler.register(this.getHeader(), this._handleResizeBound);
    if (isDesktop()) {
      this.setAttribute("desktop", "");
    }
  }

  onExitDOM(): void {
    ResizeHandler.deregister(this.getHeader(), this._handleResizeBound);
  }

  _handleResize() {
    this.handleClosePopover();
    this._width = this.offsetWidth;
  }

  emitClickEvent(pageIndex: number) {
    this.fireEvent("click", { currentPage: pageIndex });
  }

  onBeforeRendering(): void {
    this.setSelectedTab();
  }

  setSelectedTab() {
    if (!this.items.length) {
      return;
    }

    this.resetSelectedTabs();
    this.setCurrentSelectedTab();
    this.setRealTabToItems();
    this.setElementInHeader();
  }

  resetSelectedTabs() {
    this.items.forEach(tab => {
      tab._selected = false;
    });
  }

  setCurrentSelectedTab() {
    const selectedTabs = this.items.filter(tab => tab.selected);
    this._selectedTab = selectedTabs.length ? selectedTabs[0] as Tab : this.items[0] as Tab;
    this._selectedTab._selected = Boolean(selectedTabs.length ? selectedTabs.length : this.items[0]);
  }

  setRealTabToItems() {
    const contentId = this.__id!;

    this.items.forEach((tab, index, arr) => {
      tab._iconWithoutBackground = this.iconWithoutBackground;
      tab._posinset = index + 1;
      tab._setsize = arr.length;
      tab._contentId = `udex-${contentId}-content`;
      tab._realTab = this._selectedTab;
      tab._width = this.tabWidth;
    });
  }

  setElementInHeader() {
    this.items.forEach(item => {
      item.getElementInHeader = () => this.getDomRef()!.querySelector(`[id="${item.__id!}"]`);
    });
  }

  onAfterRendering(): void {
    if (this.items.length) {
      this.setItemsInHeader();
      this.initOutsideClick();
      this.setVisibleTabs();
      this.applyTabIndex();
    } else {
      throw new Error("You have to add the 'udex-tab' as a default slot to the udex-tab-navigation.");
    }
  }

  initOutsideClick() {
    if (this._responsivePopover?.opened) {
      this.handleOutsideClick();
    }
  }

  handleOutsideClick(): void {
    document.addEventListener("click", () => {
      if (this.getMoreButton()) {
        this.getMoreButton().classList.remove("udex-tab-navigation__button-more--toggled");
      }
    });
  }

  onTabClick(e: Event) {
    const tab = this.getTab(e.target as HTMLElement);
    if (!tab) {
      return;
    }

    e.stopPropagation();
    e.preventDefault();

    const selectedTabIndex = (this.items as Array<Tab>).findIndex(item => item.__id === tab.id);
    this._selectedTab = this.items[selectedTabIndex] as Tab;
    this.updateSelectedTab(selectedTabIndex);
    this.emitClickEvent(selectedTabIndex + 1);
  }

  getTab(el: HTMLElement | null) {
    while (el) {
      if (el.localName === "button" && el.getAttribute("role") === "tab") {
        return el as Tab;
      }

      el = el.parentElement;
    }

    return false;
  }

  updateSelectedTab(selectedTabIndex: number) {
    this.items.forEach((item, index) => {
      item.selected = selectedTabIndex === index;

      if (item._selected) {
        item._selected = false;
      }
    });
    this.setTabsIndex(selectedTabIndex);
  }

  setItemsInHeader() {
    const tabHeader = this.getTabHeader();
    let allItemsWidth = 0;

    if (!this._selectedTab) {
      return;
    }

    const itemsDomRefs = this.items.map(item => item.getTabInHeaderDomRef()!);

    this.getMoreButton()?.setAttribute("hidden", "");
    this.removeHiddenAttributeInAllTabs(itemsDomRefs);
    allItemsWidth += this.getTotalItemsWidth(itemsDomRefs);

    const hasOverflow = tabHeader.offsetWidth < allItemsWidth;

    if (!hasOverflow) {
      return;
    }

    this.getMoreButton()?.removeAttribute("hidden");
    this.hideLastVisibleTab(itemsDomRefs);
  }

  getTabHeader() {
    return this.shadowRoot!.querySelector<HTMLElement>(`#${this.__id!}-header-tabs`)!;
  }

  removeHiddenAttributeInAllTabs(itemsDomRefs: Array<ITab>) {
    for (let i = 0; i < itemsDomRefs.length; i++) {
      itemsDomRefs[i]?.removeAttribute("hidden");
    }
  }

  getTotalItemsWidth(itemsDomRefs: Array<ITab>) {
    let totalItemsWidth = 0;
    itemsDomRefs.forEach(item => {
      totalItemsWidth += this.getTabWidth(item);
    });
    return totalItemsWidth;
  }

  getTabWidth(itemDomRef: HTMLElement): number {
    const percentWidth = this.tabWidth.match(/[^a-zA-Z\d]+/g)?.join("");

    if (this.tabWidth === "auto" || percentWidth || !this.tabWidth) {
      return this.getItemWidth(itemDomRef);
    }

    const { marginInlineEnd } = this.getItemIndentation(itemDomRef);
    itemDomRef.style.width = this.tabWidth;
    return this.convertToPx(this.tabWidth, this.getButtonFontSize(itemDomRef)) + marginInlineEnd;
  }

  getButtonFontSize(itemDomRef: HTMLElement) {
    const style = window.getComputedStyle(itemDomRef);
    return parseFloat(style.fontSize);
  }

  convertToPx(value: string, baseFontSize: number) {
    const unit = value.match(/[a-zA-Z]+/)?.[0];
    const numericValue = parseFloat(value);

    if (unit === "px") {
      return numericValue;
    }

    return numericValue * baseFontSize;
  }

  getItemWidth(itemDomRef: HTMLElement) {
    const tabTitle = itemDomRef.innerText;
    const context = this.getItemContext();
    const { indentation, marginInlineEnd } = this.getItemIndentation(itemDomRef);

    if (tabTitle.length > this._maxTitleLength && tabTitle.length <= this._maxTitleLengthInSecondLines) {
      const maxWidth = this.getMaxWidthItemTitle(tabTitle, context, indentation);

      this.setItemWidth(itemDomRef, maxWidth, marginInlineEnd);

      return maxWidth;
    }

    if (tabTitle.length > this._maxTitleLengthInSecondLines) {
      const maxWidth = this.getOverloadMaxWidthItemTitle(tabTitle, context, indentation);

      this.setItemWidth(itemDomRef, maxWidth, marginInlineEnd);

      return maxWidth;
    }

    const textWidth = context.measureText(tabTitle).width;
    return Math.ceil(textWidth) + indentation;
  }

  private setItemWidth(itemDomRef: HTMLElement, maxWidth: number, marginInlineEnd: number) {
    itemDomRef.style.maxWidth = `${maxWidth - marginInlineEnd}px`;
  }

  private getItemContext() {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d")!;
    context.font = "700 16px '72 Brand Variable'";
    return context;
  }

  private getItemIndentation(itemDomRef: HTMLElement) {
    const styles = window.getComputedStyle(itemDomRef);
    const paddingInlineStart = Number.parseInt(styles.paddingInlineStart);
    const paddingInlineEnd = Number.parseInt(styles.paddingInlineEnd);
    const marginInlineEnd = Number.parseInt(styles.marginInlineEnd);
    const indentation = paddingInlineStart + paddingInlineEnd + marginInlineEnd;
    return { indentation, marginInlineEnd };
  }

  private getMaxWidthItemTitle(tabTitle: string, context: CanvasRenderingContext2D, indentation: number) {
    const slicedFirstLineText = tabTitle.slice(0, this._maxTitleLength);
    return this.getItemTitleWidth(slicedFirstLineText, context, tabTitle, indentation);
  }

  private getOverloadMaxWidthItemTitle(tabTitle: string, context: CanvasRenderingContext2D, indentation: number) {
    const slicedFirstLineText = tabTitle.slice(0, Math.ceil(tabTitle.length / 2));
    return this.getItemTitleWidth(slicedFirstLineText, context, tabTitle, indentation);
  }

  private getItemTitleWidth(slicedFirstLineText: string, context: CanvasRenderingContext2D, tabTitle: string, indentation: number) {
    const findLastSpaceIndex = slicedFirstLineText.lastIndexOf(" ");
    const firstLineText = slicedFirstLineText.slice(0, findLastSpaceIndex);
    const firstLineWidth = Math.ceil(context.measureText(firstLineText).width);

    const secondLineText = tabTitle.slice(findLastSpaceIndex, tabTitle.length);
    const secondLineWidth = Math.ceil(context.measureText(secondLineText).width);

    return firstLineWidth > secondLineWidth ? firstLineWidth + indentation : secondLineWidth + indentation;
  }

  hideLastVisibleTab(itemsDomRefs: Array<ITab>) {
    const selectedTab = this.getRootTab(this._selectedTab);
    const selectedTabDomRef = selectedTab.getTabInHeaderDomRef()!;
    const containerWidth = this.getTabHeader().offsetWidth;
    const selectedItemWidth = this.getSelectedItemWidth(itemsDomRefs, selectedTabDomRef);
    const lastVisibleTabIndex = this.findLastVisibleItem(itemsDomRefs, containerWidth, selectedItemWidth);
    this.hideInappropriateTabs(lastVisibleTabIndex, itemsDomRefs);
  }

  getRootTab(tab: Tab) {
    while (tab.hasAttribute("udex-tab")) {
      if (tab.parentElement!.hasAttribute("udex-tab-navigation")) {
        break;
      }

      tab = tab.parentElement as Tab;
    }

    return tab;
  }

  getSelectedItemWidth(itemsDomRefs: Array<ITab>, selectedTabDomRef: ITab) {
    const index = itemsDomRefs.indexOf(selectedTabDomRef);
    const width = this.getTabWidth(selectedTabDomRef);

    itemsDomRefs.splice(index, 1);

    return width;
  }

  findLastVisibleItem(itemsDomRefs: Array<ITab>, containerWidth: number, selectedItemWidth: number, startIndex = 0) {
    let lastVisibleIndex = startIndex - 1;
    let index = startIndex;

    for (; index < itemsDomRefs.length; index++) {
      const itemWidth = this.getTabWidth(itemsDomRefs[index]);

      if (containerWidth < selectedItemWidth + itemWidth) {
        break;
      }

      selectedItemWidth += itemWidth;
      lastVisibleIndex = index;
    }

    return lastVisibleIndex;
  }

  hideInappropriateTabs(lastVisibleTabIndex: number, itemsDomRefs: Array<ITab>) {
    for (let i = lastVisibleTabIndex + 1; i < itemsDomRefs.length; i++) {
      itemsDomRefs[i].setAttribute("hidden", "");
    }
  }

  setVisibleTabs() {
    this._visibleTabs = this.items.map(tab => tab.getTabInHeaderDomRef()!)
      .filter(tab => !tab.hasAttribute("hidden"));

    if (!this.getMoreButton().hasAttribute("hidden")) {
      this._visibleTabs.push(this.getMoreButton());
    }
  }

  applyTabIndex() {
    if (!this.shadowRoot!.contains(document.activeElement)) {
      const itemsDomRefs = this.getRootTab(this._selectedTab);
      const currentIndex = this.items.indexOf(itemsDomRefs);

      this.setTabsIndex(currentIndex);
    }
  }

  setTabsIndex(currentIndex: number) {
    this.items.forEach((tab, index) => {
      tab._tabIndex = index === currentIndex ? "0" : "-1";
    });
  }

  handleOverflowItemClick(e: Event) {
    if ((e.target as HTMLElement).localName === "button") {
      e.preventDefault();
      this.onItemSelect((e.target as HTMLElement).id);
    }
    this.handleClosePopover();
  }

  onItemSelect(selectedId: string) {
    const [selectedTab] = this.items.filter(item => item.__id === selectedId);
    let selectedTabIndex = 0;

    this._selectedTab = selectedTab as Tab;

    this.items.forEach((item, index) => {
      item.selected = item.__id === selectedId;
      if (item.__id === selectedId) {
        selectedTabIndex = index;
      }
    });

    this.setVisibleTabs();
    this.setTabsIndex(selectedTabIndex);
    this.emitClickEvent(selectedTabIndex + 1);
  }

  getMoreButton() {
    return this.shadowRoot!.querySelector<HTMLElement>(".udex-tab-navigation__button-more")!;
  }

  getHeader() {
    return this.shadowRoot!.querySelector<HTMLElement>(`#${this.__id!}-header`)!;
  }

  async handleMoreButton() {
    this._responsivePopover = await this.getResponsePopover();
    this.updateOverflowItems();
    this.handleTogglePopover();
  }

  updateOverflowItems() {
    this._overflowItems = this.items.filter(item => {
      const headerRef = item.getTabInHeaderDomRef();
      return headerRef && headerRef.hasAttribute("hidden");
    });
  }

  handleTogglePopover() {
    if (this._responsivePopover?.opened) {
      this.handleClosePopover();
    } else {
      this.handleOpenPopover();
    }
  }

  handleClosePopover() {
    if (this._responsivePopover?.opened) {
      this._responsivePopover.close();
      this.getMoreButton().classList.remove("udex-tab-navigation__button-more--toggled");
      this._isExpanded = false;
    }
  }

  async handleOpenPopover() {
    if (!this._responsivePopover?.opened) {
      await this._responsivePopover!.showAt(this.getMoreButton());
      this.getMoreButton().classList.add("udex-tab-navigation__button-more--toggled");
      this._isExpanded = true;
    }
  }

  handleKeyDown(e: KeyboardEvent) {
    this.handleRightArrow(e);
    this.handleLeftArrow(e);
    this.handleTabNext(e);
    this.handleTabPrevious(e);
    this.handleHomeKey(e);
    this.handleEndKey(e);
  }

  handleRightArrow(e: KeyboardEvent) {
    if (isRight(e)) {
      e.preventDefault();
      e.stopPropagation();

      this.onFocusRightItem(e.target as HTMLElement);
    }
  }

  onFocusRightItem(target: HTMLElement) {
    const previousSelectedIndex = this.getTabIndexById(target.id);
    const lastIndex = this._visibleTabs!.length;
    const nextSelectIndex = previousSelectedIndex + 1 < lastIndex ? previousSelectedIndex + 1 : 0;

    this._visibleTabs![nextSelectIndex].focus();
  }

  getTabIndexById(id: string) {
    return this._visibleTabs!.findIndex(tab => tab.id === id);
  }

  handleLeftArrow(e: KeyboardEvent) {
    if (isLeft(e)) {
      e.preventDefault();
      e.stopPropagation();
      this.onFocusLeftItem(e.target as HTMLElement);
    }
  }

  onFocusLeftItem(target: HTMLElement) {
    const previousSelectedIndex = this.getTabIndexById(target.id);
    const lastIndex = this._visibleTabs!.length - 1;
    const previousSelectIndex = previousSelectedIndex - 1 < 0 ? lastIndex : previousSelectedIndex - 1;

    this._visibleTabs![previousSelectIndex].focus();
  }

  handleTabNext(e: KeyboardEvent) {
    if (isTabNext(e)) {
      const previousSelectedIndex = this.getTabIndexById((e.target as HTMLElement).id);
      this.onTabNext(e, previousSelectedIndex);
    }
  }

  onTabNext(e: KeyboardEvent, previousSelectedIndex: number) {
    const isMoreButton = this._visibleTabs![this._visibleTabs!.length - 1] === this.getMoreButton();
    const nextSelectIndex = previousSelectedIndex + 1;

    if (nextSelectIndex < this._visibleTabs!.length || (isMoreButton && nextSelectIndex < this._visibleTabs!.length)) {
      e.preventDefault();
      this._visibleTabs![nextSelectIndex].focus();
    }
  }

  handleTabPrevious(e: KeyboardEvent) {
    if (isTabPrevious(e)) {
      const previousSelectedIndex = this.getTabIndexById((e.target as HTMLElement).id);
      this.onTabPrevious(e, previousSelectedIndex);
    }
  }

  onTabPrevious(e: KeyboardEvent, previousSelectedIndex: number) {
    const previousSelectIndex = previousSelectedIndex - 1;

    if (previousSelectIndex >= 0) {
      e.preventDefault();
      this._visibleTabs![previousSelectIndex].focus();
    }
  }

  handleHomeKey(e: KeyboardEvent) {
    if (isHome(e)) {
      this._visibleTabs![0].focus();
    }
  }

  handleEndKey(e: KeyboardEvent) {
    if (isEnd(e)) {
      this.onEndKey();
    }
  }

  onEndKey() {
    if (!this.getMoreButton().hasAttribute("hidden")) {
      this.getMoreButton().focus();
    } else {
      const lastVisibleTab = this._visibleTabs!.length - 1;
      this._visibleTabs![lastVisibleTab].focus();
    }
  }

  async handleFocusMoreButton(e: KeyboardEvent) {
    await this.handleUpMoreButton(e);
    await this.handleDownMoreButton(e);
    this.handleTabPreviouMoreButton(e);
    this.handleHomeKeyMoreButton(e);
    this.handleEnterKeyMoreButton(e);
  }

  async handleUpMoreButton(e: KeyboardEvent) {
    if (isUp(e)) {
      e.preventDefault();
      e.stopPropagation();
      this._responsivePopover = await this.getResponsePopover();
      await this.handleOpenPopover();
      this.updateOverflowItems();
      this.onFocusLastItemPopover();
    }
  }

  async onFocusLastItemPopover() {
    if (this._responsivePopover?.opened) {
      const popoverItems = await this.getItemsFromPopover();
      const lastOverflowTabIndex = popoverItems.length - 1;
      popoverItems[lastOverflowTabIndex].focus();
    }
  }

  handleTabPreviouMoreButton(e: KeyboardEvent) {
    if (isTabPrevious(e)) {
      e.preventDefault();
      e.stopPropagation();

      const lastVisibleTab = this._visibleTabs!.length - 2;
      this._visibleTabs![lastVisibleTab].focus();
    }
  }

  handleHomeKeyMoreButton(e: KeyboardEvent) {
    if (isHome(e)) {
      e.preventDefault();
      e.stopPropagation();
      this._visibleTabs![0].focus();
    }
  }

  async handleEnterKeyMoreButton(e: KeyboardEvent) {
    if (isEnter(e)) {
      e.preventDefault();
      this._responsivePopover = await this.getResponsePopover();
      this.updateOverflowItems();
      this.handleOpenPopover();
    }
  }

  onOpenPopover() {
    if (!this._responsivePopover?.opened) {
      this.handleMoreButton();
    }
  }

  async handleDownMoreButton(e: KeyboardEvent) {
    if (isDown(e) || isEnter(e)) {
      this._responsivePopover = await this.getResponsePopover();
      await this.handleOpenPopover();
      this.updateOverflowItems();
      this.onFocusFirstItemPopover();
    }
  }

  async onFocusFirstItemPopover() {
    if (this._responsivePopover?.opened) {
      const popoverItems = await this.getItemsFromPopover();
      popoverItems[0].focus();
    }
  }

  handleFocusPopover(e: KeyboardEvent) {
    e.stopPropagation();
    this.handleUp(e);
    this.handleDown(e);
    this.handleEnterOrSpace(e);
    this.handleEscape(e);
    this.handleHome(e);
    this.handleEnd(e);
  }

  handleUp(e: KeyboardEvent) {
    if (isUp(e)) {
      this.onPreviousItemInPopover(e.target as HTMLElement);
    }
  }

  async onPreviousItemInPopover(target: HTMLElement) {
    const popoverItems = await this.getItemsFromPopover();
    const currentSelectedTabIndex = this.getPopoverTabIndexById(popoverItems, target.id);
    const previousSelectIndex = currentSelectedTabIndex - 1 < 0 ? popoverItems.length - 1 : currentSelectedTabIndex - 1;

    popoverItems[previousSelectIndex].focus();
  }

  async getItemsFromPopover(): Promise<HTMLElement[]> {
    const responsivePopover = await this.getResponsePopover();
    const [contentFromPopover] = responsivePopover.content;
    return Array.from(contentFromPopover.children) as Array<HTMLElement>;
  }

  getPopoverTabIndexById(popoverItems: Array<HTMLElement>, id: string) {
    return popoverItems.findIndex(tab => tab.id === id);
  }

  handleDown(e: KeyboardEvent) {
    if (isDown(e)) {
      this.onNextItemInPopover(e.target as HTMLElement);
    }
  }

  async onNextItemInPopover(target: HTMLElement) {
    const popoverItems = await this.getItemsFromPopover();
    const currentSelectedTabIndex = this.getPopoverTabIndexById(popoverItems, target.id);
    const nextSelectIndex = currentSelectedTabIndex + 1 > popoverItems.length - 1 ? 0 : currentSelectedTabIndex + 1;

    popoverItems[nextSelectIndex].focus();
  }

  handleEnterOrSpace(e: KeyboardEvent) {
    if (isEnter(e) || isSpace(e)) {
      e.preventDefault();
      this.onItemSelect((e.target as HTMLElement).id);
      this.handleClosePopover();
    }
  }

  handleEscape(e: KeyboardEvent) {
    if (isEscape(e)) {
      e.preventDefault();
      this.handleClosePopover();
      this._visibleTabs![this._visibleTabs!.length - 1].focus();
    }
  }

  async handleHome(e: KeyboardEvent) {
    if (isHome(e)) {
      e.preventDefault();
      const popoverItems = await this.getItemsFromPopover();
      popoverItems[0].focus();
    }
  }

  async handleEnd(e: KeyboardEvent) {
    if (isEnd(e)) {
      e.preventDefault();
      const popoverItems = await this.getItemsFromPopover();
      popoverItems[popoverItems.length - 1].focus();
    }
  }

  async getResponsePopover() {
    const staticAreaItem = await this.getStaticAreaItemDomRef();
    return staticAreaItem!.querySelector<ResponsivePopover>(`#${this.__id!}-overflow-popover`)!;
  }

  get moreButtonText(): string {
    return UDExTabNavigation.i18nBundle.getText(MORE) as string;
  }

  static registerStaticAreaTabStyles(styles: StyleData) {
    staticAreaTabStyles.push(styles);
  }
}

UDExTabNavigation.define();

export default UDExTabNavigation;
