import UI5Element from "@ui5/webcomponents-base/dist/UI5Element.js";
import customElement from "@ui5/webcomponents-base/dist/decorators/customElement.js";
import property from "@ui5/webcomponents-base/dist/decorators/property.js";
import slot from "@ui5/webcomponents-base/dist/decorators/slot.js";
import litRender from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import { isSpace } from "@ui5/webcomponents-base/dist/Keys.js";
import event from "@ui5/webcomponents-base/dist/decorators/event.js";
import { isDesktop } from "@ui5/webcomponents-base/dist/Device.js";

import TileTemplate from "./generated/templates/TileTemplate.lit.js";

// Styles
import TileCss from "./generated/themes/Tile.css.js";

type AnchorNode = HTMLAnchorElement | undefined | null;

/**
 * @class
 *
 * @constructor
 * @extends UI5Element
 * @tagname udex-tile
 * @public
 */
@customElement({
  tag: "udex-tile",
  renderer: litRender,
  styles: TileCss,
  template: TileTemplate,
})

/**
 * Fires when the user clicks inside the component in interactive mode.
 * @public
 */
@event("click", {
  detail: {
    originalEvent: { type: MouseEvent },
  },
})
class UDExTile extends UI5Element {
  /**
   * Defines display the shadow for the Tile.
   *
   * @public
   */
  @property({ type: Boolean })
    hideShadow!: boolean;

  /**
   * Defines if the component would be interactive,
   * e.g. gets hover effect, gets focus outline and <code>click</code> event is fired, when pressed.
   *
   * @default false
   * @public
   */
  @property({ type: Boolean })
    interactive!: boolean;

  /**
   * Defines the component href.
   *
   * <b>This property must only be used when the <code>interactive</code> property is set.</b>
   *
   * <br><br>
   * <b>Note:</b> Standard hyperlink behavior is supported.
   * @public
   */
  @property({ type: String })
    href!: string;

  /**
   * Defines the component target.
   * <br><br>
   * <b>Notes:</b>
   *
   * <ul>
   * <li><code>_self</code></li>
   * <li><code>_top</code></li>
   * <li><code>_blank</code></li>
   * <li><code>_parent</code></li>
   * <li><code>_search</code></li>
   * </ul>
   *
   * <b>This property must only be used when the <code>interactive</code> property is set.</b>
   *
   * <br><br>
   * <b>Note:</b> Standard hyperlink behavior is supported.
   * @public
   */
  @property({ type: String })
    target!: string;

  /**
   * Defines the component aria-label accessibility attribute.
   *
   * <b>This property must only be used when the <code>interactive</code> property is set.</b>
   *
   * @public
   */
  @property({ type: String })
    accessibleName!: string;

  /**
   * Defines the content of the component.
   *
   * @slot
   * @public
   */
  @slot({ type: Node }) default!: Node;

  onEnterDOM(): void {
    if (isDesktop()) {
      this.setAttribute("desktop", "");
    }
  }

  _onkeydown(e: KeyboardEvent) {
    if (isSpace(e)) {
      const component: AnchorNode = this.shadowRoot?.querySelector("a.udex-tile");

      if (component) {
        e.preventDefault(); // prevent scrolling
        component.click();
      }
    }
  }

  _onclick(e: MouseEvent) {
    e.stopImmediatePropagation();

    if (this.interactive) {
      this.fireEvent("click", { originalEvent: e }, true);
    }
  }
}

UDExTile.define();

export default UDExTile;
