import customElement from "@ui5/webcomponents-base/dist/decorators/customElement.js";
import property from "@ui5/webcomponents-base/dist/decorators/property.js";
import litRender from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import Dialog from "@ui5/webcomponents/dist/Dialog.js";
import Popup from "@ui5/webcomponents/dist/Popup.js";
import Bar from "@ui5/webcomponents-fiori/dist/Bar.js";
import Icon from "@ui5/webcomponents/dist/Icon.js";

// Styles
import getEffectiveScrollbarStyle from "@ui5/webcomponents-base/dist/util/getEffectiveScrollbarStyle.js";
import PopupsCommonCss from "@ui5/webcomponents/dist/generated/themes/PopupsCommon.css.js";
import dialogCSS from "@ui5/webcomponents/dist/generated/themes/Dialog.css.js";
import ModalCss from "./generated/themes/Modal.css.js";

export enum ModalType {
  default = "default",
  basic = "basic"
}

/**
 * @class
 *
 * <h3 class="comment-api-title">Overview</h3>
 *
 * The <code>udex-modal</code> component is a demo component that displays some text.
 *
 * @constructor
 * @extends Dialog
 * @tagname udex-modal
 * @public
 */
@customElement({
  tag: "udex-modal",
  renderer: litRender,
  styles: [
    Popup.styles,
    getEffectiveScrollbarStyle(),
    PopupsCommonCss,
    dialogCSS,
    ModalCss,
  ],
  dependencies: [Bar, Icon],
})
class UDExModal extends Dialog {
  /**
  * Defines modal display type
  * <br><br>
  * <b>Note:</b> The <code>basic</code> type is simplified version of styling.
  *
  * @public
  * @default "default"
  */
  @property({ defaultValue: ModalType.default })
    type!: `${ModalType}`;
}

UDExModal.define();

export default UDExModal;
