import type { StyleData } from "@ui5/webcomponents-base/dist/types.js";
import { registerThemePropertiesLoader } from "@ui5/webcomponents-base/dist/asset-registries/Themes.js";

import defaultThemeBase from "@ui5/webcomponents-theming/dist/generated/themes/sap_horizon/parameters-bundle.css.js";
import defaultTheme from "./sap_horizon/parameters-bundle.css.js";

registerThemePropertiesLoader("@ui5/webcomponents-theming", "sap_horizon", async () => defaultThemeBase);
registerThemePropertiesLoader("@sapudex/web-components", "sap_horizon", async () => defaultTheme);

const styleData: StyleData = {packageName:"@sapudex/web-components",fileName:"themes/TableCell.css.ts",content:`:host{--udex-table-cell-color: var(--udexColorGrey9, #223548);--udex-table-cell-border-color: var(--sapList_BorderColor, var(--udexColorGrey4, #a9b4be ));--udex-table-cell-padding: var(--udexSpacer8, 8px) var(--udexSpacer16, 16px);--udex-table-cell-background: var(--sapList_HeaderBackground, var(--udexCoreBase, #fff ));--udex-table-cell-highlighted-background: var(--sapIndicationColor_9_Selected_Background, var(--udexColorGrey1, #f5f6f7));--udex-table-cell-highlighted-border-color: var(--sapList_BorderColor, var(--udexColorGrey4, #a9b4be));--udex-table-cell-highlighted-column-background: var(--sapList_SelectionBackgroundColor, var(--udexColorBlue1, #ebf8ff));--udex-table-cell-icon-width: 18px;--udex-table-cell-icon-height: 18px}:host{font-family:var(--sapFontFamily);font-size:var(--udexTypographyBodySFontSize);line-height:var(--udexTypographyBodyLineHeight);border-color:var(--udex-table-cell-border-color);padding:var(--udex-table-cell-padding);background:var(--udex-table-cell-background);color:var(--udex-table-cell-color)}:host([_highlighted]){background:var(--udex-table-cell-highlighted-background);border-color:var(--udex-table-cell-highlighted-border-color)}.udex-table-cell__icon{min-width:var(--udex-table-cell-icon-width);height:var(--udex-table-cell-icon-height);margin-inline-end:var(--udexSpacer4);color:var(--udex-table-cell-color);margin-top:4px;margin-bottom:auto}
`};
export default styleData;
	