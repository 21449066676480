/* eslint no-unused-vars: 0 */
import { html, svg, repeat, classMap, styleMap, ifDefined, unsafeHTML, scopeTag } from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import type UI5Element from "@ui5/webcomponents-base/dist/UI5Element.js";
	type TabInOverflow = any;
	import type { ClassMapValue } from "@ui5/webcomponents-base/dist/types.js";
	
function block0 (this: TabInOverflow, context: UI5Element, tags: string[], suffix: string | undefined) { return html`${ this.hasNotHref ? block1.call(this, context, tags, suffix) : block5.call(this, context, tags, suffix) } `;}
function block1 (this: TabInOverflow, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<button id="${ifDefined(this._id)}" class="udex-tab-overflow__button" role="tab">${ this.hasIcon ? block2.call(this, context, tags, suffix) : undefined }${ifDefined(this.text)}${ this.hasNotIcon ? block4.call(this, context, tags, suffix) : undefined }</button>`;}
function block2 (this: TabInOverflow, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<span class="udex-tab-overflow__button-icon"><${scopeTag("ui5-icon", tags, suffix)} name="${ifDefined(this.icon)}"></${scopeTag("ui5-icon", tags, suffix)}>${ this.showAttentionBadge ? block3.call(this, context, tags, suffix) : undefined }</span>` : html`<span class="udex-tab-overflow__button-icon"><ui5-icon name="${ifDefined(this.icon)}"></ui5-icon>${ this.showAttentionBadge ? block3.call(this, context, tags, suffix) : undefined }</span>`;}
function block3 (this: TabInOverflow, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<span class="udex-tab-overflow__button-badge"></span>`;}
function block4 (this: TabInOverflow, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<span class="udex-tab-overflow__button-badge"></span>`;}
function block5 (this: TabInOverflow, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<a href="${ifDefined(this.href)}" target="${ifDefined(this.target)}" class="udex-tab-overflow__button" role="tab">${ this.hasIcon ? block6.call(this, context, tags, suffix) : undefined }${ifDefined(this.text)}${ this.hasNotIcon ? block8.call(this, context, tags, suffix) : undefined }</a>`;}
function block6 (this: TabInOverflow, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<span class="udex-tab-overflow__button-icon"><${scopeTag("ui5-icon", tags, suffix)} name="${ifDefined(this.icon)}"></${scopeTag("ui5-icon", tags, suffix)}>${ this.showAttentionBadge ? block7.call(this, context, tags, suffix) : undefined }</span>` : html`<span class="udex-tab-overflow__button-icon"><ui5-icon name="${ifDefined(this.icon)}"></ui5-icon>${ this.showAttentionBadge ? block7.call(this, context, tags, suffix) : undefined }</span>`;}
function block7 (this: TabInOverflow, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<span class="udex-tab-overflow__button-badge"></span>`;}
function block8 (this: TabInOverflow, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<span class="udex-tab-overflow__button-badge"></span>`;}


export default block0;