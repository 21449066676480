import type { StyleData } from "@ui5/webcomponents-base/dist/types.js";
import { registerThemePropertiesLoader } from "@ui5/webcomponents-base/dist/asset-registries/Themes.js";

import defaultThemeBase from "@ui5/webcomponents-theming/dist/generated/themes/sap_horizon/parameters-bundle.css.js";
import defaultTheme from "./sap_horizon/parameters-bundle.css.js";

registerThemePropertiesLoader("@ui5/webcomponents-theming", "sap_horizon", async () => defaultThemeBase);
registerThemePropertiesLoader("@sapudex/web-components", "sap_horizon", async () => defaultTheme);

const styleData: StyleData = {packageName:"@sapudex/web-components",fileName:"themes/WizardTab.css.ts",content:`:host{--udex-wizard-title-color: var(--udexColorGrey9, #223548);--udex-wizard-disabled-number-color: var(--udexColorGrey6, #5B738B);--udex-wizard-disabled-border-color: var(--udexColorGrey4, #A9B4BE);--udex-wizard-completed-number-color: var(--udexColorBlue9, #0040B0);--udex-wizard-completed-border-color: var(--udexColorBlue9, #0040B0)}:host .ui5-wiz-step-title-text{font-size:var(--udexTypographyNavigationMediumMFontSize);font-weight:var(--udexTypographyFontWeightMedium)}:host .ui5-wiz-step-icon-circle{margin:0 6px}:host .ui5-wiz-step-texts{padding-left:2px}:host .ui5-wiz-step-hr{margin-left:.25rem;margin-right:.25rem}:host([selected]:not([disabled])) .ui5-wiz-step-title-text,:host([disabled]) .ui5-wiz-step-title-text{color:var(--udex-wizard-title-color)}:host([selected]:not([disabled])) .ui5-wiz-step-icon-circle:after{border-bottom:.188rem solid var(--udexColorBlue7)}:host([disabled]) .ui5-wiz-step-number,:host([disabled]) .ui5-wiz-step-icon{color:var(--udex-wizard-disabled-number-color)}:host([disabled]) .ui5-wiz-step-icon-circle{border-color:var(--udex-wizard-disabled-border-color)}:host(:last-child) .ui5-wiz-step-hr{border:none;background-image:linear-gradient(to right,var(--udexColorGrey6) 50%,transparent 0%);background-size:4px 2px}:host([completed]:not([selected])) .ui5-wiz-step-number,:host([completed]:not([selected])) .ui5-wiz-step-icon{color:var(--udex-wizard-completed-number-color)}:host([completed]:not([selected])) .ui5-wiz-step-icon-circle{border-color:var(--udex-wizard-completed-border-color)}:host([breakpoint="S"]) .ui5-wiz-step-texts{display:none}:host([breakpoint="S"]) .ui5-wiz-step-texts{padding-left:4px}:host([breakpoint="S"]) .ui5-wiz-step-icon-circle{margin:0 2px}
`};
export default styleData;
	