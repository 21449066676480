/* eslint no-unused-vars: 0 */
import { html, svg, repeat, classMap, styleMap, ifDefined, unsafeHTML, scopeTag } from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import type UI5Element from "@ui5/webcomponents-base/dist/UI5Element.js";
	type BreadcrumbsPopover = any;
	import type { ClassMapValue } from "@ui5/webcomponents-base/dist/types.js";
	
function block0 (this: BreadcrumbsPopover, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<${scopeTag("udex-dropdown", tags, suffix)} id="udex-${ifDefined(this._id)}-breadcrumbs__popover" class="udex-breadcrumbs__popover ui5-breadcrumbs-popover" placement-type="Bottom" horizontal-align="Left" @after-open="${this._afterDropdownOpen}" @after-close="${this._afterDropdownClose}"><div class="udex-breadcrumbs__popover-content">${ repeat(this._dropdownItems, (item, index) => (item as typeof item & {_id?: any})._id || index, (item, index: number) => block1.call(this, context, tags, suffix, item, index)) }</div></${scopeTag("udex-dropdown", tags, suffix)}>` : html`<udex-dropdown id="udex-${ifDefined(this._id)}-breadcrumbs__popover" class="udex-breadcrumbs__popover ui5-breadcrumbs-popover" placement-type="Bottom" horizontal-align="Left" @after-open="${this._afterDropdownOpen}" @after-close="${this._afterDropdownClose}"><div class="udex-breadcrumbs__popover-content">${ repeat(this._dropdownItems, (item, index) => (item as typeof item & {_id?: any})._id || index, (item, index: number) => block1.call(this, context, tags, suffix, item, index)) }</div></udex-dropdown>`;}
function block1 (this: BreadcrumbsPopover, context: UI5Element, tags: string[], suffix: string | undefined, item: any, index: number) { return html`${ item.icon ? block2.call(this, context, tags, suffix, item, index) : block3.call(this, context, tags, suffix, item, index) }`;}
function block2 (this: BreadcrumbsPopover, context: UI5Element, tags: string[], suffix: string | undefined, item: any, index: number) { return suffix ? html`<${scopeTag("udex-link", tags, suffix)} id="${ifDefined(item._id)}-li" @ui5-click="${ifDefined(this._onDropdownLinkPress)}" href="${ifDefined(item.href)}" target="${ifDefined(item.target)}" class="udex-breadcrumbs__item udex-breadcrumbs__item--${index} udex-breadcrumbs__item--with-icon" icon=${ifDefined(item.icon)} icon-placement="start" accessible-name="${ifDefined(item.textContent)}" data-ui5-stable="${ifDefined(item.stableDomRef)}">${ifDefined(item.textContent)}</${scopeTag("udex-link", tags, suffix)}>` : html`<udex-link id="${ifDefined(item._id)}-li" @ui5-click="${ifDefined(this._onDropdownLinkPress)}" href="${ifDefined(item.href)}" target="${ifDefined(item.target)}" class="udex-breadcrumbs__item udex-breadcrumbs__item--${index} udex-breadcrumbs__item--with-icon" icon=${ifDefined(item.icon)} icon-placement="start" accessible-name="${ifDefined(item.textContent)}" data-ui5-stable="${ifDefined(item.stableDomRef)}">${ifDefined(item.textContent)}</udex-link>`;}
function block3 (this: BreadcrumbsPopover, context: UI5Element, tags: string[], suffix: string | undefined, item: any, index: number) { return html`${ item._isCurrentPageItem ? block4.call(this, context, tags, suffix, item, index) : block5.call(this, context, tags, suffix, item, index) }`;}
function block4 (this: BreadcrumbsPopover, context: UI5Element, tags: string[], suffix: string | undefined, item: any, index: number) { return html`<span id="${ifDefined(item._id)}-li" class="udex-breadcrumbs__item udex-breadcrumb__item--current udex-breadcrumbs__item--${index}" accessible-name="${ifDefined(item.accessibleName)}" data-ui5-stable="${ifDefined(item.stableDomRef)}">${ifDefined(item.textContent)}</span>`;}
function block5 (this: BreadcrumbsPopover, context: UI5Element, tags: string[], suffix: string | undefined, item: any, index: number) { return suffix ? html`<${scopeTag("udex-link", tags, suffix)} id="${ifDefined(item._id)}-li" @ui5-click="${ifDefined(this._onDropdownLinkPress)}" href="${ifDefined(item.href)}" target="${ifDefined(item.target)}" class="udex-breadcrumbs__item udex-breadcrumbs__item--${index}" accessible-name="${ifDefined(item.accessibleName)}" data-ui5-stable="${ifDefined(item.stableDomRef)}">${ifDefined(item.textContent)}</${scopeTag("udex-link", tags, suffix)}>` : html`<udex-link id="${ifDefined(item._id)}-li" @ui5-click="${ifDefined(this._onDropdownLinkPress)}" href="${ifDefined(item.href)}" target="${ifDefined(item.target)}" class="udex-breadcrumbs__item udex-breadcrumbs__item--${index}" accessible-name="${ifDefined(item.accessibleName)}" data-ui5-stable="${ifDefined(item.stableDomRef)}">${ifDefined(item.textContent)}</udex-link>`;}


export default block0;