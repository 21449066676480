import UI5Element from "@ui5/webcomponents-base/dist/UI5Element.js";
import customElement from "@ui5/webcomponents-base/dist/decorators/customElement.js";
import property from "@ui5/webcomponents-base/dist/decorators/property.js";
import slot from "@ui5/webcomponents-base/dist/decorators/slot.js";
import litRender from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";

// Template
import QuoteTemplate from "./generated/templates/QuoteTemplate.lit.js";

// Styles
import QuoteCss from "./generated/themes/Quote.css.js";

export enum SizeType {
  small = "small",
  large = "large"
}

export enum MaskType {
  avatar = "avatar",
  rectangle = "rectangle"
}

/**
 * @class
 *
 * <h3 class="comment-api-title">Overview</h3>
 *
 * The <code>udex-quote</code> component is a demo component that displays some text.
 *
 * @constructor
 * @extends UI5Element
 * @tagname udex-quote
 * @public
 */
@customElement({
  tag: "udex-quote",
  renderer: litRender,
  styles: QuoteCss,
  template: QuoteTemplate,
})
class UDExQuote extends UI5Element {
  /**
    * Defines the text content of the Quote.
    *
    * @slot content
    * @public
    */
  @slot({ type: HTMLElement, "default": true })
    content!: Array<HTMLElement>;

  /**
    * Defines the link in the Quote
    *
    * @slot
    * @public
  */
  @slot()
    link!: Array<HTMLElement>;

  /**
    * Defines author's name and company
    *
    * @public
    * @slot
  */
  @slot()
    author!: Array<HTMLElement>;

  /**
    * Defines image near quote text
    *
    * @public
    * @slot
  */
  @slot()
    image!: Array<HTMLElement>;

  /**
    * Defines image (Avatar or company logo) size
    *
    * @public
    * @default "large"
  */
  @property({ type: SizeType, defaultValue: "large" })
    imageSize!: `${SizeType}`;

  /**
    * Defines text size
    *
    * @public
    * @default "large"
  */
  @property({ type: SizeType, defaultValue: "large" })
    textSize!: `${SizeType}`;

  /**
    * Defines image mask
    *
    * @public
    * @default "avatar"
  */
  @property({ type: MaskType, defaultValue: "avatar" })
    mask!: `${MaskType}`;
}

UDExQuote.define();

export default UDExQuote;
