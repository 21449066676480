import type { StyleData } from "@ui5/webcomponents-base/dist/types.js";
import { registerThemePropertiesLoader } from "@ui5/webcomponents-base/dist/asset-registries/Themes.js";

import defaultThemeBase from "@ui5/webcomponents-theming/dist/generated/themes/sap_horizon/parameters-bundle.css.js";
import defaultTheme from "./sap_horizon/parameters-bundle.css.js";

registerThemePropertiesLoader("@ui5/webcomponents-theming", "sap_horizon", async () => defaultThemeBase);
registerThemePropertiesLoader("@sapudex/web-components", "sap_horizon", async () => defaultTheme);

const styleData: StyleData = {packageName:"@sapudex/web-components",fileName:"themes/SideNavigationGroup.css.ts",content:`:host{--udex-side-navigation-item-height: 2.5rem;--udex-side-navigation-icon-color: var(--udexColorGrey9, #223548);--udex-side-navigation-item-focus-border-offset: 0;color:var(--udexColorGrey7, #475E75)}.ui5-sn-item-separator:has(+.ui5-sn-item-group){background-color:var(--udexCoreDividerLight);margin:var(--udexSpacer16) 0;min-height:auto;height:1px}.ui5-sn-item-separator:not(:has(+.ui5-sn-item-group)){display:none}.ui5-sn-item.ui5-sn-item-group{height:var(--udex-side-navigation-item-height);min-height:auto;padding:var(--udexSpacer8) 0;padding-inline-start:var(--udexSpacer16);margin-block-end:0;gap:0;font-size:var(--udexTypographyEyebrowSFontSize);font-style:normal;font-weight:var(--udexTypographyFontWeightMedium);letter-spacing:.36px;text-transform:uppercase;padding-inline-end:var(--udexSpacer16)}:host .ui5-sn-item-toggle-icon{height:1rem;width:1rem;min-width:1rem;color:var(--udex-side-navigation-icon-color);padding-inline-start:var(--udexSpacer12)}.ui5-sn-item:focus:after{inset:var(--udex-side-navigation-item-focus-border-offset)}:host(:not([expanded]):last-child){margin-block-end:var(--udexSpacer16)}
`};
export default styleData;
	