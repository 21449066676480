import UI5Element from "@ui5/webcomponents-base/dist/UI5Element.js";
import customElement from "@ui5/webcomponents-base/dist/decorators/customElement.js";
import property from "@ui5/webcomponents-base/dist/decorators/property.js";
import slot from "@ui5/webcomponents-base/dist/decorators/slot.js";
import litRender from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import executeTemplate from "@ui5/webcomponents-base/dist/renderer/executeTemplate.js";
import I18nBundle, { getI18nBundle } from "@ui5/webcomponents-base/dist/i18nBundle.js";
import TabNavigation from "./TabNavigation.js";
import UDExBadge from "./Badge.js";

import TabTemplate from "./generated/templates/TabTemplate.lit.js";
import TabHeaderTemplate from "./generated/templates/TabHeaderTemplate.lit.js";
import TabInOverflowTemplate from "./generated/templates/TabInOverflowTemplate.lit.js";
import { ATTENTION_BADGE } from "./generated/i18n/i18n-defaults.js";

// Styles
import TabInOverflowCss from "./generated/themes/TabInOverflow.css.js";

export enum Target {
  _blank = "_blank",
  _parent = "_parent",
  _self = "_self",
  _top = "_top",
}

interface ITab extends UI5Element {
  getTabInHeaderDomRef: () => ITab | null;
  disabled?: boolean;
  icon?: string;
  selected?: boolean;
  numberBadge?: string;
  text?: string;
  showAttentionBadge?: boolean;
  accessibleName?: string;
  href?: string;
  content?: Array<Node>;
  hasIcon: boolean;
  isOnSelectedTab: boolean;
  hasNotIcon: boolean;
  hasNotHref: boolean;
  isSelected: boolean;
  selectedClass: string;
  tabNavigation: any;
  _individualSlot: string;
  _effectiveSlotName: string;
  _slotName: string;
  _selected?: boolean;
  _tabIndex: string;
  _iconWithoutBackground: boolean;
  _posinset?: number;
  _setsize?: number;
  _contentId?: string;
  getElementInHeader?: () => ITab | null;
  _realTab?: UDExTab;
  _width?: string;
}

/**
 * @class
 *
 * <h3 class="comment-api-title">Overview</h3>
 *
 *
 * <h3>Usage</h3>
 *
 * For the <code>udex-tab</code>
 * <h3>ES6 Module Import</h3>
 *
 * <code>import @udex/web-components/dist/Tab.js";</code>
 *
 * @constructor
 * @author SAP SE
 * @extends UI5Element
 * @tagname udex-tab
 * @public
 */
@customElement({
  tag: "udex-tab",
  renderer: litRender,
  template: TabTemplate,
  dependencies: [UDExBadge],
})

class UDExTab extends UI5Element implements ITab {
  /**
   * Defines the Badge at the end of the Tab item.
   *
   * @public
   */
  @property({ type: String })
    numberBadge?: string;

  /**
   * Defines the text of tab.
   *
   * @public
   */
  @property({ type: String })
    text!: string;
  /**
   * Accessible name for tab.
   *
   * @public
   */
  @property({ type: String })
    accessibleName!: string;

  /**
   * Defines the selected tab.
   *
   * @public
   * @default false
   */
  @property({ type: Boolean })
    selected!: boolean;

  /**
   * Defines the displaying attention badge.
   *
   * @public
   * @default false
   */
  @property({ type: Boolean })
    showAttentionBadge!: boolean;

  /**
   * Defines the icon in the tab.
   *
   * @public
   */
  @property({ type: String })
    icon!: string;

  /**
   * Defines the href of the tab.
   *
   * @public
   */
  @property({ type: String })
    href!: string;

  /**
   * Define the target of the tab
   *
   * @public
   */
  @property({ type: Target, defaultValue: Target._self })
    target!: `${Target}`;

  /**
   * Defines the tab.
   *
   * @private
   */
  @property({ type: Object })
    _realTab!: UDExTab;

  /**
   * Defines the selected tab for private use.
   *
   * @private
   * @default false
   */
  @property({ type: Boolean })
    _selected!: boolean;

  /**
   * Defines the tabindex of the tab.
   *
   * @private
   * @default false
   */
  @property({ type: String })
    _tabIndex!: string;

  /**
   * Defines the background color of the component.
   *
   * @private
   * @default false
  */
  @property({ type: Boolean })
    _iconWithoutBackground!: boolean;

  /**
   * Defines the content of the tab.
   *
   * @slot content
   * @public
   */
  @slot({ type: Node, "default": true })
    content!: Array<Node>;

  getElementInHeader?: () => ITab | null;
  _individualSlot!: string;

  getTabInHeaderDomRef(): ITab | null {
    if (this.getElementInHeader) {
      return this.getElementInHeader();
    }

    return null;
  }

  static i18nBundle: I18nBundle;

  static async onDefine(): Promise<void> {
    UDExTab.i18nBundle = await getI18nBundle("sap-ui-webcomponents-bundle");
  }

  get isOnSelectedTab(): boolean {
    return this._realTab === this;
  }

  get _effectiveSlotName() {
    return this.isOnSelectedTab ? this._individualSlot : `disabled-${this._individualSlot}`;
  }

  get hasIcon(): boolean {
    return !!this.icon;
  }

  get classWithIcon(): string {
    return this.hasIcon ? "udex-tab-navigation__button-text--icon" : "";
  }

  get buttonClassWithIcon(): string {
    return this.hasIcon ? " udex-tab-navigation__button-tab--icon" : "";
  }

  get hasNotIcon(): boolean {
    return !this.icon && this.showAttentionBadge;
  }

  get hasNotHref(): boolean {
    return !this.href;
  }

  get isSelected() {
    return this.selected || this._selected;
  }

  get selectedClass(): string {
    return this.isSelected ? "udex-tab-navigation__button-tab--active" : "";
  }

  get classWithIconWithoutBackground(): string {
    return this.hasIcon && !this._iconWithoutBackground ? " udex-tab-navigation__button-tab--icon-tab" : "";
  }

  get tabNavigation() {
    return executeTemplate(UDExTab.headerTemplate, this);
  }

  get tabInOverflow() {
    return executeTemplate(UDExTab.overflowTemplate, this);
  }

  get _slotName() {
    return this.isOnSelectedTab ? this._individualSlot : `disabled-${this._individualSlot}`;
  }

  get effectiveSelected() {
    return this.selected || this._selected;
  }

  get ariaLabelledBy() {
    const labels = [];

    if (this.text) {
      labels.push(`${this.__id!}-text`);
    }

    if (this.icon) {
      labels.push(`${this.__id!}-icon`);
    }

    return labels.join(" ");
  }

  get accessibleNameText() {
    return this.accessibleName || (this.showAttentionBadge ? `, ${UDExTab.i18nBundle.getText(ATTENTION_BADGE)}` : "");
  }

  static get headerTemplate() {
    return TabHeaderTemplate;
  }

  static get overflowTemplate() {
    return TabInOverflowTemplate;
  }
}

UDExTab.define();

TabNavigation.registerStaticAreaTabStyles(TabInOverflowCss);

export default UDExTab;
export { ITab };
