/* eslint no-unused-vars: 0 */
import { html, svg, repeat, classMap, styleMap, ifDefined, unsafeHTML, scopeTag } from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import type UI5Element from "@ui5/webcomponents-base/dist/UI5Element.js";
	import type Wizard from "../../Wizard.js";
	import type { ClassMapValue } from "@ui5/webcomponents-base/dist/types.js";
	
function block0 (this: Wizard, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<div class="${classMap(this.classes.root as ClassMapValue)}" aria-label="${ifDefined(this.ariaLabelText)}" role="region" ?indeterminate="${this.indeterminate}"><nav class="ui5-wiz-nav" part="navigator" aria-label="${ifDefined(this.navAriaLabelText)}" tabindex="-1"><div class="ui5-wiz-nav-list" role="list" aria-label="${ifDefined(this.listAriaLabelText)}" aria-describedby="wiz-nav-descr" aria-controls="${ifDefined(this._id)}-wiz-content">${ repeat(this._stepsInHeader, (item, index) => (item as typeof item & {_id?: any})._id || index, (item, index: number) => block1.call(this, context, tags, suffix, item, index)) }</div></nav><span id="wiz-nav-descr" class="ui5-hidden-text">${ifDefined(this.navAriaDescribedbyText)}</span><div id="${ifDefined(this._id)}-wiz-content" class="ui5-wiz-content" @scroll="${this.onScroll}">${ repeat(this._steps, (item, index) => (item as typeof item & {_id?: any})._id || index, (item, index: number) => block2.call(this, context, tags, suffix, item, index)) }</div></div>`;}
function block1 (this: Wizard, context: UI5Element, tags: string[], suffix: string | undefined, item: any, index: number) { return suffix ? html`<${scopeTag("udex-wizard-tab", tags, suffix)} title-text="${ifDefined(item.titleText)}" subtitle-text="${ifDefined(item.subtitleText)}" icon="${ifDefined(item.icon)}" number="${ifDefined(item.number)}" breakpoint="${ifDefined(item.breakpoint)}" ?completed="${item.completed}" ?disabled="${item.disabled}" ?selected="${item.selected}" ?hide-separator="${item.hideSeparator}" ?active-separator="${item.activeSeparator}" ?branching-separator="${item.branchingSeparator}" ._wizardTabAccInfo="${ifDefined(item.accInfo)}" data-ui5-content-ref-id="${ifDefined(item.refStepId)}" data-ui5-index="${ifDefined(item.pos)}" _tab-index="${ifDefined(item.tabIndex)}" @ui5-selection-change-requested="${ifDefined(this.onSelectionChangeRequested)}" @ui5-focused="${ifDefined(this.onStepInHeaderFocused)}" @click="${this._onGroupedTabClick}" style=${styleMap(item.styles)}></${scopeTag("udex-wizard-tab", tags, suffix)}>` : html`<udex-wizard-tab title-text="${ifDefined(item.titleText)}" subtitle-text="${ifDefined(item.subtitleText)}" icon="${ifDefined(item.icon)}" number="${ifDefined(item.number)}" breakpoint="${ifDefined(item.breakpoint)}" ?completed="${item.completed}" ?disabled="${item.disabled}" ?selected="${item.selected}" ?hide-separator="${item.hideSeparator}" ?active-separator="${item.activeSeparator}" ?branching-separator="${item.branchingSeparator}" ._wizardTabAccInfo="${ifDefined(item.accInfo)}" data-ui5-content-ref-id="${ifDefined(item.refStepId)}" data-ui5-index="${ifDefined(item.pos)}" _tab-index="${ifDefined(item.tabIndex)}" @ui5-selection-change-requested="${ifDefined(this.onSelectionChangeRequested)}" @ui5-focused="${ifDefined(this.onStepInHeaderFocused)}" @click="${this._onGroupedTabClick}" style=${styleMap(item.styles)}></udex-wizard-tab>`;}
function block2 (this: Wizard, context: UI5Element, tags: string[], suffix: string | undefined, item: any, index: number) { return html`<div class="ui5-wiz-content-item" ?hidden="${item.disabled}" ?selected="${item.selected}" ?stretch="${item.stretch}" part="step-content" aria-label="${ifDefined(item.stepContentAriaLabel)}" role="region" data-ui5-content-item-ref-id="${ifDefined(item._id)}"><div class="ui5-wiz-content-item-wrapper"><slot name="${ifDefined(item._individualSlot)}"></slot></div></div>`;}


export default block0;