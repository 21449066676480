import type { StyleData } from "@ui5/webcomponents-base/dist/types.js";
import { registerThemePropertiesLoader } from "@ui5/webcomponents-base/dist/asset-registries/Themes.js";

import defaultThemeBase from "@ui5/webcomponents-theming/dist/generated/themes/sap_horizon/parameters-bundle.css.js";
import defaultTheme from "./sap_horizon/parameters-bundle.css.js";

registerThemePropertiesLoader("@ui5/webcomponents-theming", "sap_horizon", async () => defaultThemeBase);
registerThemePropertiesLoader("@sapudex/web-components", "sap_horizon", async () => defaultTheme);

const styleData: StyleData = {packageName:"@sapudex/web-components",fileName:"themes/Toast.css.ts",content:`:host{--udex-toast-font-size: var(--udexTypographyBodyXSFontSize, 14px);--udex-toast-color: var(--udexColorGrey9, #223548);--udex-toast-text-line-height: 1.313rem;--udex-toast-border-width: 1px;--udex-toast-border-color: var(--udexColorGrey3, #D5DADD);--udex-toast-close-icon-color: var(--udexColorGrey7, #475E75);--udex-toast-status-icon-size: var(--udexCoreSizeIconInput, 18px);border:var(--udex-toast-border-width) solid var(--udex-toast-border-color);padding:0}:host([desktop]) .udex-toast:focus,.udex-toast:focus-visible{outline:var(--udexSpacer2, 2px) solid var(--udexColorBlue9, #0040B0);outline-offset:-2px}.udex-toast{display:flex;align-items:center;padding:var(--udexSpacer16);border-radius:inherit;line-height:var(--udex-toast-text-line-height);font-size:var(--udexTypographyBodyXSFontSize, 14px);--sapFontSize: var(--udex-toast-font-size)}.udex-toast .udex-toast__icon:dir(ltr){margin-right:var(--udexSpacer16)}.udex-toast .udex-toast__icon:dir(rtl){margin-left:var(--udexSpacer16)}.udex-toast .udex-toast__button{height:var(--udex-toast-text-line-height);--udex-button-color-tertiary-standard-default-text-and-icon: var(--udex-toast-close-icon-color)}.udex-toast .udex-toast__button:dir(ltr){margin:0 0 0 9px}.udex-toast .udex-toast__button:dir(rtl){margin:0 9px 0 0}
`};
export default styleData;
	