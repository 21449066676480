import UI5Element from "@ui5/webcomponents-base/dist/UI5Element.js";
import litRender from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import customElement from "@ui5/webcomponents-base/dist/decorators/customElement.js";
import property from "@ui5/webcomponents-base/dist/decorators/property.js";
import event from "@ui5/webcomponents-base/dist/decorators/event.js";
import ResizeHandler, { ResizeObserverCallback } from "@ui5/webcomponents-base/dist/delegate/ResizeHandler.js";

import "@ui5/webcomponents-icons/dist/headset.js";
import "@ui5/webcomponents-icons/dist/email.js";
import "@ui5/webcomponents-icons/dist/back-to-top.js";
import "@ui5/webcomponents-icons/dist/discussion-2.js";
import "@ui5/webcomponents-icons/dist/navigation-right-arrow.js";
import { isDesktop } from "@ui5/webcomponents-base/dist/Device.js";
import { getI18nBundle } from "@ui5/webcomponents-base/dist/i18nBundle.js";
import type I18nBundle from "@ui5/webcomponents-base/dist/i18nBundle.js";

// Template
import FooterTemplate from "./generated/templates/FooterTemplate.lit.js";

// Styles
import FooterCss from "./generated/themes/Footer.css";
import UDExAccordionItem from "./AccordionItem.js";
import {
  ABOUT_SAP,
  CHAT_WITH_US,
  CONTACT_US,
  EMAIL,
  LOCAL_COUNTRY_NAMES,
  PHONE_NUMBER,
  QUICK_LINKS,
  SEE_COMPLETE_LIST,
  SITE_INFORMATION,
  SOCIAL_LINK_ICON,
  TRENDING,
} from "./generated/i18n/i18n-defaults.js";

const BreakpointSizeS: number = 979;

export type FooterLink = {
  title: string;
  url: string;
};

export type FooterSocialLink = {
  url: string;
  iconUrl: string;
};

export type FooterContactNumber = {
  number: string;
  country: string;
};

export const FooterBaseEvent = {
  detail: {
    originalEvent: { type: PointerEvent },
  },
};

export const FooterDetailedEvent = {
  detail: {
    originalEvent: { type: PointerEvent },
    name: { type: String },
    sourceNode: { type: HTMLElement },
  },
};

/**
 * @class
 *
 * <h3 class="comment-api-title">Overview</h3>
 *
 *
 * <h3>Usage</h3>
 *
 * For the <code>udex-footer</code>
 * <h3>ES6 Module Import</h3>
 *
 * <code>import @udex/web-components/dist/Footer.js";</code>
 *
 * @constructor
 * @author SAP SE
 * @extends UI5Element
 * @tagname udex-footer
 * @public
 */
@customElement({
  tag: "udex-footer",
  renderer: litRender,
  styles: [FooterCss],
  template: FooterTemplate,
  dependencies: [UDExAccordionItem],
})
/**
 * Triggered on click interaction with logo in footer
 * @public
 * @allowPreventDefault
 */
@event("logo-click", FooterBaseEvent)
/**
 * Triggered on click interaction with "Back to Top" button
 * @public
 * @allowPreventDefault
 */
@event("back-to-top-click", FooterBaseEvent)

/**
 * Triggered on click interaction with phone number link in footer
 * @public
 * @allowPreventDefault
 */
@event("contact-phone-click", FooterBaseEvent)
/**
 * Triggered on click interaction with local country numbers list link
 * @public
 * @allowPreventDefault
 */
@event("contact-phone-local-numbers-click", FooterBaseEvent)
/**
 * Triggered on click interaction with "Contact us by email" link
 * @public
 * @allowPreventDefault
 */
@event("contact-email-click", FooterBaseEvent)
/**
 * Triggered on click interaction with "Contact us by chat" link
 * @public
 * @allowPreventDefault
 */
@event("contact-chat-click", FooterBaseEvent)

/**
 * Triggered on click interaction with a link within "Quick Links" category
 * @public
 * @allowPreventDefault
 */
@event("quick-links-click", FooterDetailedEvent)
/**
 * Triggered on click interaction with a link within "Trending" category
 * @public
 * @allowPreventDefault
 */
@event("trending-click", FooterDetailedEvent)
/**
 * Triggered on click interaction with a link within "About SAP" category
 * @public
 * @allowPreventDefault
 */
@event("about-sap-click", FooterDetailedEvent)
/**
 * Triggered on click interaction with a link within "Site Information" category
 * @public
 * @allowPreventDefault
 */
@event("site-information-click", FooterDetailedEvent)

/**
 * Triggered on click interaction with any of the social media links within footer
 * @public
 * @allowPreventDefault
 */
@event("social-media-link-click", FooterDetailedEvent)
class UDExFooter extends UI5Element {
  /**
   * Defines the Logo URL
   *
   * @default ""
   * @public
   */
  @property({ type: String, defaultValue: "" })
    logoUrl!: string;

  /**
   * Defines the optional component href link for the logo.
   *
   * @default ""
   * @public
   */
  @property({ type: String, defaultValue: "" })
    logoLink?: string;

  /**
   * List of the Quick links
   *
   * @default "[]"
   * @public
   */
  @property({ type: String, defaultValue: "[]" })
    quickLinks!: string;

  /**
   * List of the Trending links
   *
   * @default "[]"
   * @public
   */
  @property({ type: String, defaultValue: "[]" })
    trendingLinks!: string;

  /**
   * List of the About SAP links
   *
   * @default "[]"
   * @public
   */
  @property({ type: String, defaultValue: "[]" })
    aboutLinks!: string;

  /**
   * List of the Site Information links
   *
   * @default "[]"
   * @public
   */
  @property({ type: String, defaultValue: "[]" })
    siteInformationLinks!: string;

  /**
   * List of the contact numbers
   *
   * @default "[]"
   * @public
   */
  @property({ type: String, defaultValue: "[]" })
    contactNumbersList!: string;

  /**
   * Defines the local country numbers link's href
   *
   * @default ""
   * @public
   */
  @property({ type: String, defaultValue: "" })
    localCountryNumbersLink!: string;

  /**
   * Defines the Contact us link's href
   *
   * @default ""
   * @public
   */
  @property({ type: String, defaultValue: "" })
    contactUsLink!: string;

  /**
   * Defines the Chat with us link's href
   *
   * @default ""
   * @public
   */
  @property({ type: String, defaultValue: "" })
    chatWithUsLink!: string;

  /**
   * List of the Social links and their icons
   *
   * @default "[]"
   * @public
   */
  @property({ type: String, defaultValue: "[]" })
    socialLinks!: string;

  /**
   * Alt for Logo
   *
   * @default ""
   * @public
   */
  @property({ type: String, defaultValue: "" })
    logoAlt!: string;

  /**
   * Defines should render the mobile navigation.
   *
   * @default false
   * @private
   */
  @property({ type: Boolean })
    _shouldRenderMobileMenu!: boolean;

  static i18nBundle: I18nBundle;

  static async onDefine(): Promise<void> {
    UDExFooter.i18nBundle = await getI18nBundle("sap-ui-webcomponents-bundle");
  }

  _handleResizeBound: ResizeObserverCallback;

  constructor() {
    super();
    this._handleResizeBound = this._handleResize.bind(this);
  }

  onExitDOM(): void {
    ResizeHandler.deregister(this, this._handleResizeBound);
  }

  onEnterDOM() {
    if (isDesktop()) {
      this.setAttribute("desktop", "");
    }
    ResizeHandler.register(this, this._handleResizeBound);
  }

  _handleResize(): void {
    this._shouldRenderMobileMenu = this.isMobile;
  }

  onBackToTopClick(e: Event): void {
    this.fireEvent("back-to-top-click", { originalEvent: e }, true);

    window.scrollTo(0, 0);
  }

  parseArrayFromString<T>(string: string): T[] {
    return string ? JSON.parse(string) as T[] : [];
  }

  get parsedRef() {
    return (this.logoLink && this.logoLink.length > 0) ? this.logoLink : undefined;
  }

  get quickLinksArray(): FooterLink[] {
    return this.parseArrayFromString<FooterLink>(this.quickLinks);
  }

  get trendingLinksArray(): FooterLink[] {
    return this.parseArrayFromString<FooterLink>(this.trendingLinks);
  }

  get aboutLinksArray(): FooterLink[] {
    return this.parseArrayFromString<FooterLink>(this.aboutLinks);
  }

  get siteInformationLinksArray(): FooterLink[] {
    return this.parseArrayFromString<FooterLink>(this.siteInformationLinks);
  }

  get contactNumbersArray(): FooterContactNumber[] {
    return this.parseArrayFromString<FooterContactNumber>(this.contactNumbersList);
  }

  get socialLinksArray(): FooterSocialLink[] {
    return this.parseArrayFromString<FooterSocialLink>(this.socialLinks);
  }

  get isContactContainerVisible(): boolean {
    return !!this.contactNumbersArray.length || !!this.contactUsLink || !!this.chatWithUsLink;
  }

  get socialIconAccessibleName() {
    return ` ${UDExFooter.i18nBundle.getText(SOCIAL_LINK_ICON)}`;
  }

  get phoneNumberAccessibleName() {
    return `${UDExFooter.i18nBundle.getText(PHONE_NUMBER)} `;
  }

  get contactUsAccessibleName() {
    return UDExFooter.i18nBundle.getText(CONTACT_US);
  }

  get contactUsAccessibleLabel() {
    if (this.contactUsLink.includes("mailto:")) {
      return `${this.contactUsAccessibleName} ${UDExFooter.i18nBundle.getText(EMAIL)}`;
    }
    return `${this.contactUsAccessibleName}`;
  }

  get chatWithUsAccessibleName() {
    return UDExFooter.i18nBundle.getText(CHAT_WITH_US);
  }

  get quickLinksTitle() {
    return UDExFooter.i18nBundle.getText(QUICK_LINKS);
  }

  get trendingTitle() {
    return UDExFooter.i18nBundle.getText(TRENDING);
  }

  get aboutSapTitle() {
    return UDExFooter.i18nBundle.getText(ABOUT_SAP);
  }

  get siteInformationTitle() {
    return UDExFooter.i18nBundle.getText(SITE_INFORMATION);
  }

  get completeListTitle() {
    return `${UDExFooter.i18nBundle.getText(SEE_COMPLETE_LIST)} `;
  }

  get localCountryTitle() {
    return UDExFooter.i18nBundle.getText(LOCAL_COUNTRY_NAMES);
  }

  get isMobile(): boolean {
    return window.innerWidth <= BreakpointSizeS;
  }

  _onLogoClick(e: Event): void {
    this.fireEvent("logo-click", { originalEvent: e }, true);
  }

  _onContactPhoneClick(e: Event): void {
    this.fireEvent("contact-phone-click", { originalEvent: e }, true);
  }
  _onContactPhoneLocalNumbersClick(e: Event): void {
    this.fireEvent("contact-phone-local-numbers-click", { originalEvent: e }, true);
  }
  _onContactEmailClick(e: Event): void {
    this.fireEvent("contact-email-click", { originalEvent: e }, true);
  }
  _onContactChatClick(e: Event): void {
    this.fireEvent("contact-chat-click", { originalEvent: e }, true);
  }

  _onQuickLinksClick(e: Event): void {
    const sourceNode = e.composedPath()[0]as HTMLElement;

    this.fireEvent("quick-links-click", {
      name: sourceNode.innerText,
      originalEvent: e,
      sourceNode,
    }, true);
  }

  _onTrendingClick(e: Event): void {
    const sourceNode = e.composedPath()[0]as HTMLElement;

    this.fireEvent("trending-click", {
      name: sourceNode.innerText,
      originalEvent: e,
      sourceNode,
    }, true);
  }

  _onAboutSapClick(e: Event): void {
    const sourceNode = e.composedPath()[0]as HTMLElement;

    this.fireEvent("about-sap-click", {
      name: sourceNode.innerText,
      originalEvent: e,
      sourceNode,
    }, true);
  }

  _onSiteInformationClick(e: Event): void {
    const sourceNode = e.composedPath()[0]as HTMLElement;

    this.fireEvent("site-information-click", {
      name: sourceNode.innerText,
      originalEvent: e,
      sourceNode,
    }, true);
  }

  _onSocialMediaLinkClick(e: Event): void {
    const nodes = e.composedPath() as HTMLElement[];
    let sourceNode = nodes[0];

    if (nodes[0] instanceof HTMLImageElement) { sourceNode = nodes[1]; }

    this.fireEvent("social-media-link-click", {
      name: sourceNode?.dataset?.name,
      originalEvent: e,
      sourceNode,
    }, true);
  }
}

UDExFooter.define();

export default UDExFooter;
