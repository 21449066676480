import customElement from "@ui5/webcomponents-base/dist/decorators/customElement.js";
import litRender from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import UDExListGroupHeaderBase from "./ListGroupHeaderBase.js";

/**
 * @class
 *
 * <h3 class="comment-api-title">Overview</h3>
 *
 *
 * <h3>Usage</h3>
 *
 * For the <code>udex-filter-menu-select-all</code>
 * <h3>ES6 Module Import</h3>
 *
 * <code>import "@udex/web-components/dist/FilterMenuSelectAll.js";</code>
 *
 * @constructor
 * @extends UDExListGroupHeaderBase
 * @public
 */
@customElement({
  tag: "udex-filter-menu-select-all",
  renderer: litRender,
})

class UDExFilterMenuSelectAll extends UDExListGroupHeaderBase {
  get isSelectAllHeader() {
    return true;
  }

  get isFilterMenuSelectAllHeader() {
    return true;
  }
}

UDExFilterMenuSelectAll.define();

export default UDExFilterMenuSelectAll;
