/* eslint no-unused-vars: 0 */
import { html, svg, repeat, classMap, styleMap, ifDefined, unsafeHTML, scopeTag } from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import type UI5Element from "@ui5/webcomponents-base/dist/UI5Element.js";
	import type MastheadItem from "../../MastheadItem.js";
	import type { ClassMapValue } from "@ui5/webcomponents-base/dist/types.js";
	
function block0 (this: MastheadItem, context: UI5Element, tags: string[], suffix: string | undefined) { return html`${ this.hasHref ? block1.call(this, context, tags, suffix) : block5.call(this, context, tags, suffix) } `;}
function block1 (this: MastheadItem, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<a id="${ifDefined(this._id)}" class="${ifDefined(this.additionalClass)}" tabindex="0" role="${ifDefined(this.accessibleRoleName)}" target="${ifDefined(this.target)}" href="${ifDefined(this.href)}" aria-labelledby="${ifDefined(this.ariaLabelledBy)}" aria-label="${ifDefined(this.accessibleName)}">${ this.icon ? block2.call(this, context, tags, suffix) : block3.call(this, context, tags, suffix) }</a>`;}
function block2 (this: MastheadItem, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<span class="udex-masthead-item__icon-container"><${scopeTag("ui5-icon", tags, suffix)} class="udex-masthead-item__icon" name="${ifDefined(this.icon)}" show-tooltip="${ifDefined(this.iconAlt)}" accessible-name="${ifDefined(this.iconAlt)}"></${scopeTag("ui5-icon", tags, suffix)}></span>` : html`<span class="udex-masthead-item__icon-container"><ui5-icon class="udex-masthead-item__icon" name="${ifDefined(this.icon)}" show-tooltip="${ifDefined(this.iconAlt)}" accessible-name="${ifDefined(this.iconAlt)}"></ui5-icon></span>`;}
function block3 (this: MastheadItem, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<span class="udex-masthead-item__text"><slot></slot>${ this.hasArrowDown ? block4.call(this, context, tags, suffix) : undefined }</span>`;}
function block4 (this: MastheadItem, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<${scopeTag("ui5-icon", tags, suffix)} class="udex-masthead-item__dropdown-icon" name="slim-arrow-down"></${scopeTag("ui5-icon", tags, suffix)}>` : html`<ui5-icon class="udex-masthead-item__dropdown-icon" name="slim-arrow-down"></ui5-icon>`;}
function block5 (this: MastheadItem, context: UI5Element, tags: string[], suffix: string | undefined) { return html`${ this.isProfileBtn ? block6.call(this, context, tags, suffix) : block11.call(this, context, tags, suffix) }`;}
function block6 (this: MastheadItem, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<span id="${ifDefined(this._id)}" class="udex-masthead-item__btn ${ifDefined(this.additionalClass)}" aria-expanded="${ifDefined(this.selected)}" aria-labelledby="${ifDefined(this.ariaLabelledBy)}" aria-haspopup="${ifDefined(this.hasDropdownSlot)}" aria-label="${ifDefined(this.accessibleName)}" @keydown="${this.handleKeyDown}" @click="${this.onClick}">${ this.icon ? block7.call(this, context, tags, suffix) : block8.call(this, context, tags, suffix) }</span>${ this.showDropdown ? block10.call(this, context, tags, suffix) : undefined }`;}
function block7 (this: MastheadItem, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<span class="udex-masthead-item__icon-container"><${scopeTag("ui5-icon", tags, suffix)} class="udex-masthead-item__icon" name="${ifDefined(this.icon)}" show-tooltip="${ifDefined(this.iconAlt)}" accessible-name="${ifDefined(this.iconAlt)}"></${scopeTag("ui5-icon", tags, suffix)}></span>` : html`<span class="udex-masthead-item__icon-container"><ui5-icon class="udex-masthead-item__icon" name="${ifDefined(this.icon)}" show-tooltip="${ifDefined(this.iconAlt)}" accessible-name="${ifDefined(this.iconAlt)}"></ui5-icon></span>`;}
function block8 (this: MastheadItem, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<span class="udex-masthead-item__text"><slot></slot>${ this.hasArrowDown ? block9.call(this, context, tags, suffix) : undefined }</span>`;}
function block9 (this: MastheadItem, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<${scopeTag("ui5-icon", tags, suffix)} class="udex-masthead-item__dropdown-icon" name="slim-arrow-down"></${scopeTag("ui5-icon", tags, suffix)}>` : html`<ui5-icon class="udex-masthead-item__dropdown-icon" name="slim-arrow-down"></ui5-icon>`;}
function block10 (this: MastheadItem, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<div class="udex-masthead-item__dropdown" @keydown="${this.handleKeyDown}"><div class="udex-masthead-item__dropdown-holder"><slot name="dropdown"></slot></div></div>`;}
function block11 (this: MastheadItem, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<button role="${ifDefined(this.accessibleRoleName)}" id="${ifDefined(this._id)}" class="udex-masthead-item__btn ${ifDefined(this.additionalClass)}" aria-expanded="${ifDefined(this.selected)}" aria-labelledby="${ifDefined(this.ariaLabelledBy)}" aria-haspopup="${ifDefined(this.hasDropdownSlot)}" aria-label="${ifDefined(this.accessibleName)}" @keydown="${this.handleKeyDown}" @click="${this.onClick}">${ this.icon ? block12.call(this, context, tags, suffix) : block13.call(this, context, tags, suffix) }</button>${ this.showDropdown ? block15.call(this, context, tags, suffix) : undefined }`;}
function block12 (this: MastheadItem, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<span class="udex-masthead-item__icon-container"><${scopeTag("ui5-icon", tags, suffix)} class="udex-masthead-item__icon" name="${ifDefined(this.icon)}" show-tooltip="${ifDefined(this.iconAlt)}" accessible-name="${ifDefined(this.iconAlt)}"></${scopeTag("ui5-icon", tags, suffix)}></span>` : html`<span class="udex-masthead-item__icon-container"><ui5-icon class="udex-masthead-item__icon" name="${ifDefined(this.icon)}" show-tooltip="${ifDefined(this.iconAlt)}" accessible-name="${ifDefined(this.iconAlt)}"></ui5-icon></span>`;}
function block13 (this: MastheadItem, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<span class="udex-masthead-item__text"><slot></slot>${ this.hasArrowDown ? block14.call(this, context, tags, suffix) : undefined }</span>`;}
function block14 (this: MastheadItem, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<${scopeTag("ui5-icon", tags, suffix)} class="udex-masthead-item__dropdown-icon" name="slim-arrow-down"></${scopeTag("ui5-icon", tags, suffix)}>` : html`<ui5-icon class="udex-masthead-item__dropdown-icon" name="slim-arrow-down"></ui5-icon>`;}
function block15 (this: MastheadItem, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<div class="udex-masthead-item__dropdown" @keydown="${this.handleKeyDown}"><div class="udex-masthead-item__dropdown-holder"><slot name="dropdown"></slot></div></div>`;}


export default block0;