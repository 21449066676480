import type { StyleData } from "@ui5/webcomponents-base/dist/types.js";
import { registerThemePropertiesLoader } from "@ui5/webcomponents-base/dist/asset-registries/Themes.js";

import defaultThemeBase from "@ui5/webcomponents-theming/dist/generated/themes/sap_horizon/parameters-bundle.css.js";
import defaultTheme from "./sap_horizon/parameters-bundle.css.js";

registerThemePropertiesLoader("@ui5/webcomponents-theming", "sap_horizon", async () => defaultThemeBase);
registerThemePropertiesLoader("@sapudex/web-components", "sap_horizon", async () => defaultTheme);

const styleData: StyleData = {packageName:"@sapudex/web-components",fileName:"themes/DatePicker.css.ts",content:`:host{--udex-date-picker-width: 354px;position:relative;font-family:var(--sapFontFamily)}.udex-date-picker__container{display:flex;flex-direction:column}.udex-date-picker__calendar{min-width:var(--udex-date-picker-width);background-color:var(--udexNeutralColorWhite, #ffffff);border-radius:8px;box-shadow:0 2px 8px #2236494d;position:absolute;top:42px;z-index:100}.udex-date-picker__input{--udex-input-padding: 9px 48px 9px 12px}
`};
export default styleData;
	