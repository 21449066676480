/* eslint no-unused-vars: 0 */
import { html, svg, repeat, classMap, styleMap, ifDefined, unsafeHTML, scopeTag } from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import type UI5Element from "@ui5/webcomponents-base/dist/UI5Element.js";
	import type SecondaryNavigationItem from "../../SecondaryNavigationItem.js";
	import type { ClassMapValue } from "@ui5/webcomponents-base/dist/types.js";
	
function block0 (this: SecondaryNavigationItem, context: UI5Element, tags: string[], suffix: string | undefined) { return html`${ this.isLink ? block1.call(this, context, tags, suffix) : block2.call(this, context, tags, suffix) } `;}
function block1 (this: SecondaryNavigationItem, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<a class="udex-secondary-navigation__item" role="${ifDefined(this.menuItemRole)}" ?active="${this.isActive}" href="${ifDefined(this.path)}" target="${ifDefined(this.target)}" @keydown="${this.handleItemLinkKeyDown}" @click="${this.onItemClick}">${ifDefined(this.label)}</a>`;}
function block2 (this: SecondaryNavigationItem, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<button class="udex-secondary-navigation__item" role="${ifDefined(this.itemRole)}" ?active="${this.isActive}" aria-haspopup="${ifDefined(this.hasNestedItems)}" aria-expanded="${ifDefined(this.isOpen)}" aria-label="${ifDefined(this.ariaLabel)}" @keydown="${this.handleItemButtonKeyDown}" @click="${this.onItemClick}"><span class="udex-secondary-navigation__item-text-wrap">${ifDefined(this.label)}${ this._subTitle ? block3.call(this, context, tags, suffix) : undefined }${ this.hasNestedItems ? block4.call(this, context, tags, suffix) : undefined }</span></button>${ this.isOpen ? block5.call(this, context, tags, suffix) : undefined }`;}
function block3 (this: SecondaryNavigationItem, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<span class="udex-secondary-navigation__item-sub-title">${ifDefined(this._subTitle)}</span>`;}
function block4 (this: SecondaryNavigationItem, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<${scopeTag("ui5-icon", tags, suffix)} class="udex-secondary-navigation__item-icon" name="slim-arrow-down"></${scopeTag("ui5-icon", tags, suffix)}>` : html`<ui5-icon class="udex-secondary-navigation__item-icon" name="slim-arrow-down"></ui5-icon>`;}
function block5 (this: SecondaryNavigationItem, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<div class="udex-secondary-navigation__dropdown" @keydown="${this.handleSubItemKeyDown}"><ul role="menu" class="udex-secondary-navigation__dropdown-list">${ repeat(this._processedItems, (item, index) => (item as typeof item & {_id?: any})._id || index, (item, index: number) => block6.call(this, context, tags, suffix, item, index)) }</ul><slot></slot></div>`;}
function block6 (this: SecondaryNavigationItem, context: UI5Element, tags: string[], suffix: string | undefined, item: any, index: number) { return suffix ? html`<li role="none"><${scopeTag("udex-secondary-navigation-sub-item", tags, suffix)} index="${index}" @ui5-sub-item-click="${ifDefined(this.onSubItemClick)}" @ui5-back-item-click="${ifDefined(this.onBackClick)}" is-back="${ifDefined(item.isBack)}" label="${ifDefined(item.title)}" .nestedItems="${ifDefined(item.items)}" path="${ifDefined(item.path)}" target="${ifDefined(item.target)}" ?is-active="${item.active}"></${scopeTag("udex-secondary-navigation-sub-item", tags, suffix)}></li>` : html`<li role="none"><udex-secondary-navigation-sub-item index="${index}" @ui5-sub-item-click="${ifDefined(this.onSubItemClick)}" @ui5-back-item-click="${ifDefined(this.onBackClick)}" is-back="${ifDefined(item.isBack)}" label="${ifDefined(item.title)}" .nestedItems="${ifDefined(item.items)}" path="${ifDefined(item.path)}" target="${ifDefined(item.target)}" ?is-active="${item.active}"></udex-secondary-navigation-sub-item></li>`;}


export default block0;