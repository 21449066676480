/* eslint no-unused-vars: 0 */
import { html, svg, repeat, classMap, styleMap, ifDefined, unsafeHTML, scopeTag } from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import type UI5Element from "@ui5/webcomponents-base/dist/UI5Element.js";
	import type MonthPicker from "../../MonthPicker.js";
	import type { ClassMapValue } from "@ui5/webcomponents-base/dist/types.js";
	
function block0 (this: MonthPicker, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<div class="ui5-mp-root" role="grid" aria-roledescription="${ifDefined(this.roleDescription)}" aria-readonly="false" aria-multiselectable="false" @keydown=${this._onkeydown} @keyup=${this._onkeyup} @click=${this._selectMonth}>${ repeat(this._months, (item, index) => (item as typeof item & {_id?: any})._id || index, (item, index: number) => block1.call(this, context, tags, suffix, item, index)) }</div>`;}
function block1 (this: MonthPicker, context: UI5Element, tags: string[], suffix: string | undefined, item: any, index: number) { return html`<div role="row" class="ui5-mp-quarter">${ repeat(item, (item, index) => (item as typeof item & {_id?: any})._id || index, (item, index: number) => block2.call(this, context, tags, suffix, item, index)) }</div>`;}
function block2 (this: MonthPicker, context: UI5Element, tags: string[], suffix: string | undefined, item: any, index: number) { return html`<div data-sap-timestamp=${ifDefined(item.timestamp)} tabindex=${ifDefined(item._tabIndex)} ?data-sap-focus-ref="${item.focusRef}" class="${ifDefined(item.classes)}" role="gridcell" aria-selected="${ifDefined(item.ariaSelected)}" aria-disabled="${ifDefined(item.ariaDisabled)}"><span class="ui5-dp-monthtext">${ifDefined(item.name)}</span>${ item.nameInSecType ? block3.call(this, context, tags, suffix, item, index) : undefined }</div>`;}
function block3 (this: MonthPicker, context: UI5Element, tags: string[], suffix: string | undefined, item: any, index: number) { return html`<span class="ui5-dp-monthtext ui5-dp-monthsectext">${ifDefined(item.nameInSecType)}</span>`;}


export default block0;