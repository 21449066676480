import customElement from "@ui5/webcomponents-base/dist/decorators/customElement.js";
import event from "@ui5/webcomponents-base/dist/decorators/event.js";
import litRender from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import { isEnter, isSpace } from "@ui5/webcomponents-base/dist/Keys.js";
import Icon from "@ui5/webcomponents/dist/Icon.js";
import UDExListGroupHeaderBase from "./ListGroupHeaderBase.js";
import ListGroupHeaderTemplate from "./generated/templates/ListGroupHeaderTemplate.lit.js";
import { ListItemSize } from "./types/List.js";

// Styles
import ListGroupHeaderCss from "./generated/themes/ListGroupHeader.css.js";
/**
 * @class
 *
 * <h3 class="comment-api-title">Overview</h3>
 *
 *
 * <h3>Usage</h3>
 *
 * For the <code>udex--list-group-header</code>
 * <h3>ES6 Module Import</h3>
 *
 * <code>import @udex/web-components/dist/ListGroupHeader.js";</code>
 *
 * @constructor
 * @author SAP SE
 * @extends UDExListGroupHeaderBase
 * @tagname udex-list-group-header
 * @public
 */
@customElement({
  tag: "udex-list-group-header",
  renderer: litRender,
  styles: ListGroupHeaderCss,
  template: ListGroupHeaderTemplate,
  dependencies: [Icon],
})

@event("header-press", {
  detail: {
    item: { type: HTMLElement },
  },
})

class UDExListGroupHeader extends UDExListGroupHeaderBase {
  handleHeaderPress() {
    if (!this.controlled) {
      this.handleSelectionGroupState();
    }
    this.fireHeaderPress();
  }

  handleKeyDownPress(e: KeyboardEvent) {
    if (isSpace(e)) {
      e.preventDefault();
    }
    if (isSpace(e) || isEnter(e)) {
      this.handleHeaderPress();
    }
  }

  fireHeaderPress() {
    this.fireEvent("header-press", {
      item: this,
    });
  }

  getHandleGroupItemsClick() {
    const hasHandle = this.modeMultiSelect && !this.controlled;
    return hasHandle ? this.handleHeaderState() : undefined;
  }

  get sizeClass() {
    return this.size === ListItemSize.Large ? "udex-list__group_large" : "";
  }
}

UDExListGroupHeader.define();

export default UDExListGroupHeader;
