/* eslint no-unused-vars: 0 */
import { html, svg, repeat, classMap, styleMap, ifDefined, unsafeHTML, scopeTag } from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import type UI5Element from "@ui5/webcomponents-base/dist/UI5Element.js";
	import type SideNavigation from "../../SideNavigation.js";
	import type { ClassMapValue } from "@ui5/webcomponents-base/dist/types.js";
	
function block0 (this: SideNavigation, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<nav class="ui5-sn-root ${classMap(this.classes.root as ClassMapValue)}" role="${ifDefined(this._rootRole)}">${ this.showHeader ? block1.call(this, context, tags, suffix) : undefined }${ this.collapsed ? block2.call(this, context, tags, suffix) : block3.call(this, context, tags, suffix) }${ this.hasFixedItems ? block4.call(this, context, tags, suffix) : undefined }</nav> `;}
function block1 (this: SideNavigation, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<slot name="header"></slot>`;}
function block2 (this: SideNavigation, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<div role="menubar" class="ui5-sn-list ui5-sn-flexible" aria-orientation="vertical" aria-roledescription="${ifDefined(this.ariaRoleDescNavigationList)}"><slot></slot><${scopeTag("ui5-side-navigation-item", tags, suffix)} is-overflow id="${ifDefined(this._id)}-sn-overflow-item" text="${ifDefined(this.overflowAccessibleName)}" @click="${this._handleOverflowClick}" class="ui5-sn-item-overflow" side-nav-collapsed icon="overflow"></${scopeTag("ui5-side-navigation-item", tags, suffix)}></div>` : html`<div role="menubar" class="ui5-sn-list ui5-sn-flexible" aria-orientation="vertical" aria-roledescription="${ifDefined(this.ariaRoleDescNavigationList)}"><slot></slot><ui5-side-navigation-item is-overflow id="${ifDefined(this._id)}-sn-overflow-item" text="${ifDefined(this.overflowAccessibleName)}" @click="${this._handleOverflowClick}" class="ui5-sn-item-overflow" side-nav-collapsed icon="overflow"></ui5-side-navigation-item></div>`;}
function block3 (this: SideNavigation, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<ul role="tree" class="ui5-sn-list ui5-sn-flexible" aria-roledescription="${ifDefined(this.ariaRoleDescNavigationList)}"><slot></slot></ul>`;}
function block4 (this: SideNavigation, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<div role="separator" class="ui5-sn-spacer"></div>${ this.collapsed ? block5.call(this, context, tags, suffix) : block6.call(this, context, tags, suffix) }`;}
function block5 (this: SideNavigation, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<div role="menubar" class="ui5-sn-list ui5-sn-fixed" aria-orientation="vertical" aria-roledescription="${ifDefined(this.ariaRoleDescNavigationList)}"><slot name="fixedItems"></slot></div>`;}
function block6 (this: SideNavigation, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<ul role="tree" class="ui5-sn-list ui5-sn-fixed" aria-roledescription="${ifDefined(this.ariaRoleDescNavigationList)}"><slot name="fixedItems"></slot></ul>`;}


export default block0;