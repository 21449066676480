import customElement from "@ui5/webcomponents-base/dist/decorators/customElement.js";
import litRender from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import slot from "@ui5/webcomponents-base/dist/decorators/slot.js";
import UDExListItemBase from "./ListItemBase.js";

/**
 * @class
 *
 * <h3 class="comment-api-title">Overview</h3>
 *
 *
 * <h3>Usage</h3>
 *
 * For the <code>udex-select-box-item</code>
 * <h3>ES6 Module Import</h3>
 *
 * <code>import "@udex/web-components/dist/SelectBoxItem.js";</code>
 *
 * @constructor
 * @author SAP SE
 * @extends UDExListItemBase
 * @tagname udex-select-box-item
 * @public
 */
@customElement({
  tag: "udex-select-box-item",
  renderer: litRender,
})

class UDExSelectBoxItem extends UDExListItemBase {
  /**
   * Slot for item content.
   *
   * @slot
   * @public
   */
  @slot({ type: Node, "default": true })
    content!: Array<Node>;
}

UDExSelectBoxItem.define();

export default UDExSelectBoxItem;
