import type { StyleData } from "@ui5/webcomponents-base/dist/types.js";
import { registerThemePropertiesLoader } from "@ui5/webcomponents-base/dist/asset-registries/Themes.js";

import defaultThemeBase from "@ui5/webcomponents-theming/dist/generated/themes/sap_horizon/parameters-bundle.css.js";
import defaultTheme from "./sap_horizon/parameters-bundle.css.js";

registerThemePropertiesLoader("@ui5/webcomponents-theming", "sap_horizon", async () => defaultThemeBase);
registerThemePropertiesLoader("@sapudex/web-components", "sap_horizon", async () => defaultTheme);

const styleData: StyleData = {packageName:"@sapudex/web-components",fileName:"themes/SegmentedButton.css.ts",content:`:host{--udex-segmented-button-border-color: var(--udexColorGrey4, #A9B4BE);--udex-segmented-button-medium-width: 36px;--udex-segmented-button-large-width: 42px;--udex-segmented-button-small-width: 25px}:host ::slotted(udex-segmented-button-item){--udex-segmented-button-item-medium-width: var(--udex-segmented-button-medium-width);--udex-segmented-button-item-small-width: var(--udex-segmented-button-small-width);--udex-segmented-button-item-large-width: var(--udex-segmented-button-large-width)}:host(:not([hidden])){min-width:calc(var(--udex-segmented-button-items-count)* var(--udex-segmented-button-medium-width))}:host([size="Small"]:not([hidden])){min-width:calc(var(--udex-segmented-button-items-count)* var(--udex-segmented-button-small-width))}:host([size="Large"]:not([hidden])){min-width:calc(var(--udex-segmented-button-items-count)* var(--udex-segmented-button-large-width))}.ui5-segmented-button-root{grid-template-columns:repeat(var(--udex-segmented-button-items-count),minmax(var(--udex-segmented-button-medium-width),1fr))}:host([size="Small"]) .ui5-segmented-button-root{grid-template-columns:repeat(var(--udex-segmented-button-items-count),minmax(var(--udex-segmented-button-small-width),1fr))}:host([size="Large"]) .ui5-segmented-button-root{grid-template-columns:repeat(var(--udex-segmented-button-items-count),minmax(var(--udex-segmented-button-large-width),1fr))}.ui5-segmented-button-root{box-shadow:inset 0 0 0 var(--sapButton_BorderWidth) var(--udex-segmented-button-border-color)}
`};
export default styleData;
	