import type { StyleData } from "@ui5/webcomponents-base/dist/types.js";
import { registerThemePropertiesLoader } from "@ui5/webcomponents-base/dist/asset-registries/Themes.js";

import defaultThemeBase from "@ui5/webcomponents-theming/dist/generated/themes/sap_horizon/parameters-bundle.css.js";
import defaultTheme from "./sap_horizon/parameters-bundle.css.js";

registerThemePropertiesLoader("@ui5/webcomponents-theming", "sap_horizon", async () => defaultThemeBase);
registerThemePropertiesLoader("@sapudex/web-components", "sap_horizon", async () => defaultTheme);

const styleData: StyleData = {packageName:"@sapudex/web-components",fileName:"themes/TruncatedText.css.ts",content:`:host{--udex-truncated-text-font-size: var(--udexTypographyBodySFontSize, 16px);--udex-truncated-text-line-height: var(--udexTypographyBodyLineHeight, 24px)}.udex-truncated-text{font-family:var(--sapFontFamily);font-size:var(--udex-truncated-text-font-size);line-height:var(--udex-truncated-text-line-height);font-weight:var(--udexTypographyFontWeightRegular)}.udex-truncated-text__visible-text{vertical-align:middle}.udex-truncated-text__toggle-button{--udex-button-color-tertiary-standard-hover-background: transparent;--udex-button-color-tertiary-standard-hover-border: transparent;--udex-button-color-tertiary-standard-default-text-and-icon: var(--udexColorBlue7);--udex-button-color-tertiary-standard-active-border: transparent;--udex-button-color-tertiary-standard-default-border: transparent;--udex-button-padding-small: 0 3px;--udexTypographyFontWeightMedium: 400;display:inline-block}
`};
export default styleData;
	