import customElement from "@ui5/webcomponents-base/dist/decorators/customElement.js";
import property from "@ui5/webcomponents-base/dist/decorators/property.js";
import litRender from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import UI5WizardTab from "@ui5/webcomponents-fiori/dist/WizardTab.js";

// Styles
import wizardTabCss from "@ui5/webcomponents-fiori/dist/generated/themes/WizardTab.css.js";
import WizardTabCss from "./generated/themes/WizardTab.css.js";

export enum WindowSize {
  S = "S",
  M = "M",
  L = "L",
  XL = "XL"
}

/**
 * @class
 *
 * <h3 class="comment-api-title">Overview</h3>
 *
 *
 * <h3>Usage</h3>
 *
 * For the <code>udex-wizard</code>
 * <h3>ES6 Module Import</h3>
 *
 * <code>import "@udex/web-components/dist/Wizard.js";</code>
 *
 * @constructor
 * @author SAP SE
 * @extends UI5WizardTab
 * @tagname udex-wizard-tab
 * @public
 */
@customElement({
  tag: "udex-wizard-tab",
  renderer: litRender,
  styles: [wizardTabCss, WizardTabCss],
  dependencies: [UI5WizardTab],
})
class UDExWizardTab extends UI5WizardTab {
  /**
   * Determines if the title and subtitle should be displayed, based on window size.
   *
   * @default "L"
   * @public
   */
  @property({ type: WindowSize, defaultValue: WindowSize.L })
    breakpoint!: `${WindowSize}`;
}

UDExWizardTab.define();

export default UDExWizardTab;
