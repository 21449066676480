/* eslint no-unused-vars: 0 */
import { html, svg, repeat, classMap, styleMap, ifDefined, unsafeHTML, scopeTag } from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import type UI5Element from "@ui5/webcomponents-base/dist/UI5Element.js";
	import type Switch from "../../Switch.js";
	import type { ClassMapValue } from "@ui5/webcomponents-base/dist/types.js";
	
function block0 (this: Switch, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<div class="udex-switch">${ this.label ? block1.call(this, context, tags, suffix) : undefined }<div class="udex-switch__container" role="switch" aria-label="${ifDefined(this.accessibleName)}" aria-labelledby="${ifDefined(this.accessibleNameRef)}" aria-checked="${ifDefined(this.checked)}" aria-disabled="${ifDefined(this.disabled)}" tabindex="${ifDefined(this.switchTabIndex)}" title="${ifDefined(this.tooltip)}" @click="${this.handleSwitchClick}" @keydown="${this.handleSwitchKeyDown}"><div class="udex-switch__track ${ifDefined(this.isActiveTrackClass)}"><div class="udex-switch__handle">${ this.isSemanticColor ? block2.call(this, context, tags, suffix) : block5.call(this, context, tags, suffix) }</div></div><input id="udex-switch__input" type="checkbox" ?checked="${this.checked}" class="udex-switch__input" /></div></div>`;}
function block1 (this: Switch, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<label for="udex-switch__input" class="udex-switch__label">${ifDefined(this.label)}</label>`;}
function block2 (this: Switch, context: UI5Element, tags: string[], suffix: string | undefined) { return html`${ this.checked ? block3.call(this, context, tags, suffix) : block4.call(this, context, tags, suffix) }`;}
function block3 (this: Switch, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<${scopeTag("ui5-icon", tags, suffix)} name="accept" class="udex-switch__icon"></${scopeTag("ui5-icon", tags, suffix)}>` : html`<ui5-icon name="accept" class="udex-switch__icon"></ui5-icon>`;}
function block4 (this: Switch, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<${scopeTag("ui5-icon", tags, suffix)} name="decline" class="udex-switch__icon"></${scopeTag("ui5-icon", tags, suffix)}>` : html`<ui5-icon name="decline" class="udex-switch__icon"></ui5-icon>`;}
function block5 (this: Switch, context: UI5Element, tags: string[], suffix: string | undefined) { return html`${ this.checked ? block6.call(this, context, tags, suffix) : block7.call(this, context, tags, suffix) }`;}
function block6 (this: Switch, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<${scopeTag("ui5-icon", tags, suffix)} name="accept" class="udex-switch__icon"></${scopeTag("ui5-icon", tags, suffix)}>` : html`<ui5-icon name="accept" class="udex-switch__icon"></ui5-icon>`;}
function block7 (this: Switch, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<${scopeTag("ui5-icon", tags, suffix)} name="less" class="udex-switch__icon"></${scopeTag("ui5-icon", tags, suffix)}>` : html`<ui5-icon name="less" class="udex-switch__icon"></ui5-icon>`;}


export default block0;