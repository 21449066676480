/* eslint no-unused-vars: 0 */
import { html, svg, repeat, classMap, styleMap, ifDefined, unsafeHTML, scopeTag } from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import type UI5Element from "@ui5/webcomponents-base/dist/UI5Element.js";
	import type SideNavigationSubItem from "../../SideNavigationSubItem.js";
	import type { ClassMapValue } from "@ui5/webcomponents-base/dist/types.js";
	
function block0 (this: SideNavigationSubItem, context: UI5Element, tags: string[], suffix: string | undefined) { return html`${ !this.sideNavCollapsed ? block1.call(this, context, tags, suffix) : undefined }`;}
function block1 (this: SideNavigationSubItem, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<li id="${ifDefined(this._id)}" class="ui5-sn-list-li" role="none">${ this._href ? block2.call(this, context, tags, suffix) : block5.call(this, context, tags, suffix) }</li>`;}
function block2 (this: SideNavigationSubItem, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<a class="ui5-sn-item ui5-sn-item-level2 ${ifDefined(this._classes)}" role="treeitem" data-sap-focus-ref @keydown="${this._onkeydown}" @keyup="${this._onkeyup}" @click="${this._onclick}" @focusin="${this._onfocusin}" tabindex="${ifDefined(this.effectiveTabIndex)}" aria-current="${ifDefined(this._ariaCurrent)}" aria-selected="${ifDefined(this.selected)}" title="${ifDefined(this._tooltip)}" href="${ifDefined(this._href)}" target="${ifDefined(this._target)}">${ this.icon ? block3.call(this, context, tags, suffix) : undefined }<div class="ui5-sn-item-text">${ifDefined(this.text)}</div><${scopeTag("ui5-icon", tags, suffix)} class="ui5-sn-item-selection-icon" name="circle-task-2"></${scopeTag("ui5-icon", tags, suffix)}>${ this.isExternalLink ? block4.call(this, context, tags, suffix) : undefined }</a>` : html`<a class="ui5-sn-item ui5-sn-item-level2 ${ifDefined(this._classes)}" role="treeitem" data-sap-focus-ref @keydown="${this._onkeydown}" @keyup="${this._onkeyup}" @click="${this._onclick}" @focusin="${this._onfocusin}" tabindex="${ifDefined(this.effectiveTabIndex)}" aria-current="${ifDefined(this._ariaCurrent)}" aria-selected="${ifDefined(this.selected)}" title="${ifDefined(this._tooltip)}" href="${ifDefined(this._href)}" target="${ifDefined(this._target)}">${ this.icon ? block3.call(this, context, tags, suffix) : undefined }<div class="ui5-sn-item-text">${ifDefined(this.text)}</div><ui5-icon class="ui5-sn-item-selection-icon" name="circle-task-2"></ui5-icon>${ this.isExternalLink ? block4.call(this, context, tags, suffix) : undefined }</a>`;}
function block3 (this: SideNavigationSubItem, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<${scopeTag("ui5-icon", tags, suffix)} class="ui5-sn-item-icon" name="${ifDefined(this.icon)}"></${scopeTag("ui5-icon", tags, suffix)}>` : html`<ui5-icon class="ui5-sn-item-icon" name="${ifDefined(this.icon)}"></ui5-icon>`;}
function block4 (this: SideNavigationSubItem, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<${scopeTag("ui5-icon", tags, suffix)} class="ui5-sn-item-external-link-icon" name="arrow-right"></${scopeTag("ui5-icon", tags, suffix)}>` : html`<ui5-icon class="ui5-sn-item-external-link-icon" name="arrow-right"></ui5-icon>`;}
function block5 (this: SideNavigationSubItem, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<div class="ui5-sn-item ui5-sn-item-level2 ${ifDefined(this._classes)}" role="treeitem" data-sap-focus-ref @keydown="${this._onkeydown}" @keyup="${this._onkeyup}" @click="${this._onclick}" @focusin="${this._onfocusin}" tabindex="${ifDefined(this.effectiveTabIndex)}" aria-current="${ifDefined(this._ariaCurrent)}" aria-selected="${ifDefined(this.selected)}" title="${ifDefined(this._tooltip)}">${ this.icon ? block6.call(this, context, tags, suffix) : undefined }<div class="ui5-sn-item-text">${ifDefined(this.text)}</div><${scopeTag("ui5-icon", tags, suffix)} class="ui5-sn-item-selection-icon" name="circle-task-2"></${scopeTag("ui5-icon", tags, suffix)}>${ this.isExternalLink ? block7.call(this, context, tags, suffix) : undefined }</div>` : html`<div class="ui5-sn-item ui5-sn-item-level2 ${ifDefined(this._classes)}" role="treeitem" data-sap-focus-ref @keydown="${this._onkeydown}" @keyup="${this._onkeyup}" @click="${this._onclick}" @focusin="${this._onfocusin}" tabindex="${ifDefined(this.effectiveTabIndex)}" aria-current="${ifDefined(this._ariaCurrent)}" aria-selected="${ifDefined(this.selected)}" title="${ifDefined(this._tooltip)}">${ this.icon ? block6.call(this, context, tags, suffix) : undefined }<div class="ui5-sn-item-text">${ifDefined(this.text)}</div><ui5-icon class="ui5-sn-item-selection-icon" name="circle-task-2"></ui5-icon>${ this.isExternalLink ? block7.call(this, context, tags, suffix) : undefined }</div>`;}
function block6 (this: SideNavigationSubItem, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<${scopeTag("ui5-icon", tags, suffix)} class="ui5-sn-item-icon" name="${ifDefined(this.icon)}"></${scopeTag("ui5-icon", tags, suffix)}>` : html`<ui5-icon class="ui5-sn-item-icon" name="${ifDefined(this.icon)}"></ui5-icon>`;}
function block7 (this: SideNavigationSubItem, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<${scopeTag("ui5-icon", tags, suffix)} class="ui5-sn-item-external-link-icon" name="arrow-right"></${scopeTag("ui5-icon", tags, suffix)}>` : html`<ui5-icon class="ui5-sn-item-external-link-icon" name="arrow-right"></ui5-icon>`;}


export default block0;