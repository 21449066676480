import type { StyleData } from "@ui5/webcomponents-base/dist/types.js";
import { registerThemePropertiesLoader } from "@ui5/webcomponents-base/dist/asset-registries/Themes.js";

import defaultThemeBase from "@ui5/webcomponents-theming/dist/generated/themes/sap_horizon/parameters-bundle.css.js";
import defaultTheme from "./sap_horizon/parameters-bundle.css.js";

registerThemePropertiesLoader("@ui5/webcomponents-theming", "sap_horizon", async () => defaultThemeBase);
registerThemePropertiesLoader("@sapudex/web-components", "sap_horizon", async () => defaultTheme);

const styleData: StyleData = {packageName:"@sapudex/web-components",fileName:"themes/TableHeaderCell.css.ts",content:`:host{--udex-table-header-cell-color: var(--udexColorGrey9, #223548);--udex-table-header-cell-border-color: var(--sapList_HeaderBorderColor, var(--udexColorGrey4, #a9b4be ));--udex-table-header-cell-padding: var(--udexSpacer12, 12px) var(--udexSpacer16, 16px);--udex-table-header-cell-background: var(--sapList_HeaderBackground, var(--udexCoreBase, #fff ));--udex-table-header-cell-highlighted-border-color: var(--sapIndicationColor_9_Selected_BorderColor, var(--udexColorBlue9, #0040B0));--udex-table-header-cell-icon-width: 18px;--udex-table-header-cell-icon-height: 18px;--udex-table-header-cell-line-height: 18px}:host{font-family:var(--sapFontFamily);font-size:var(--udexTypographyNavigationMediumXLFontSize);line-height:var(--udex-table-header-cell-line-height);font-weight:var(--udexTypographyFontWeightMedium);border-color:var(--udex-table-header-cell-border-color);padding:var(--udex-table-header-cell-padding);background:var(--udex-table-header-cell-background);color:var(--udex-table-header-cell-color);position:relative}:host([highlighted]){border-color:var(--udex-table-header-cell-highlighted-border-color)}:host([highlighted]):before{content:"";position:absolute;left:0;right:0;bottom:0;height:1px;background:var(--udex-table-header-cell-highlighted-border-color)}.udex-table-header-cell-wrap{display:flex;align-items:center;gap:var(--udexSpacer8)}.udex-table-header-cell-wrap__icon{color:var(--udex-table-header-cell-color);min-width:var(--udex-table-header-cell-icon-width);height:var(--udex-table-header-cell-icon-height)}
`};
export default styleData;
	