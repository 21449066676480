/* eslint no-unused-vars: 0 */
import { html, svg, repeat, classMap, styleMap, ifDefined, unsafeHTML, scopeTag } from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import type UI5Element from "@ui5/webcomponents-base/dist/UI5Element.js";
	import type Link from "../../Link.js";
	import type { ClassMapValue } from "@ui5/webcomponents-base/dist/types.js";
	
function block0 (this: Link, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<a class="ui5-link-root ${ifDefined(this.linkClass)}" role="${ifDefined(this.effectiveAccRole)}" href="${ifDefined(this.parsedRef)}" target="${ifDefined(this.target)}" rel="${ifDefined(this._rel)}" tabindex="${ifDefined(this.effectiveTabIndex)}" title="${ifDefined(this.title)}" ?disabled="${this.disabled}" aria-label="${ifDefined(this.ariaLabelText)}" aria-haspopup="${ifDefined(this._hasPopup)}" aria-expanded="${ifDefined(this.accessibilityAttributes.expanded)}" @focusin=${this._onfocusin} @focusout=${this._onfocusout} @click=${this._onclick} @keydown=${this._onkeydown} @keyup=${this._onkeyup}>${ this.startIcon ? block1.call(this, context, tags, suffix) : undefined }<span class="udex-link__content"><slot></slot></span>${ this.endIcon ? block2.call(this, context, tags, suffix) : undefined }${ this.hasLinkType ? block3.call(this, context, tags, suffix) : undefined }</a>`;}
function block1 (this: Link, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<${scopeTag("ui5-icon", tags, suffix)} class="udex-link__icon udex-link__icon--icon-start" name="${ifDefined(this.icon)}"></${scopeTag("ui5-icon", tags, suffix)}>` : html`<ui5-icon class="udex-link__icon udex-link__icon--icon-start" name="${ifDefined(this.icon)}"></ui5-icon>`;}
function block2 (this: Link, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<${scopeTag("ui5-icon", tags, suffix)} class="udex-link__icon udex-link__icon--icon-end" name="${ifDefined(this.icon)}"></${scopeTag("ui5-icon", tags, suffix)}>` : html`<ui5-icon class="udex-link__icon udex-link__icon--icon-end" name="${ifDefined(this.icon)}"></ui5-icon>`;}
function block3 (this: Link, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<span class="ui5-hidden-text">${ifDefined(this.linkTypeText)}</span>`;}


export default block0;