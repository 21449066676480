import UI5Element from "@ui5/webcomponents-base/dist/UI5Element.js";
import customElement from "@ui5/webcomponents-base/dist/decorators/customElement.js";
import property from "@ui5/webcomponents-base/dist/decorators/property.js";
import slot from "@ui5/webcomponents-base/dist/decorators/slot.js";
import litRender from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import Icon from "@ui5/webcomponents/dist/Icon.js";
import UDExSideNavigation from "./SideNavigation.js";
import UDExDropdown from "./Dropdown.js";
import "@ui5/webcomponents-icons/dist/slim-arrow-down.js";

import SideNavigationMobileTemplate from "./generated/templates/SideNavigationMobileTemplate.lit.js";
import SideNavigationMobileDropdownTemplate from "./generated/templates/SideNavigationMobileDropdownTemplate.lit.js";
import UDExSideNavigationItem from "./SideNavigationItem.js";
import UDExSideNavigationSubItem from "./SideNavigationSubItem.js";
import UDExSideNavigationGroup from "./SideNavigationGroup.js";

// Styles
import SideNavigationMobileCss from "./generated/themes/SideNavigationMobile.css.js";
import SideNavigationMobileDropdownCss from "./generated/themes/SideNavigationMobileDropdown.css.js";

type SelectedItem = UDExSideNavigationItem | UDExSideNavigationSubItem | null;

/**
 * @class
 *
 * <h3 class="comment-api-title">Overview</h3>
 *
 *
 * <h3>Usage</h3>
 *
 * For the <code>udex-side-navigation-mobile</code>
 * <h3>ES6 Module Import</h3>
 *
 * <code>import "@udex/web-components/dist/SideNavigation.js";</code>
 *
 * @constructor
 * @extends UI5Element
 * @public
 */
@customElement({
  tag: "udex-side-navigation-mobile",
  renderer: litRender,
  template: SideNavigationMobileTemplate,
  styles: SideNavigationMobileCss,
  staticAreaTemplate: SideNavigationMobileDropdownTemplate,
  staticAreaStyles: [SideNavigationMobileCss, SideNavigationMobileDropdownCss],
  dependencies: [UDExSideNavigation, UDExDropdown, Icon],
})

class UDExSideNavigationMobile extends UI5Element {
  /**
   * Indicates if dropdown is opened
   *
   * @default false
   * @private
   */
  @property({ noAttribute: true, type: Boolean })
    _dropdownOpened!: boolean;

  /**
   * Defines the main items of the `ui5-side-navigation`. Use the `ui5-side-navigation-item` component
   * for the top-level items, and the `ui5-side-navigation-sub-item` component for second-level items, nested
   * inside the items.
   *
   * @public
   */
  @slot({ type: HTMLElement, invalidateOnChildChange: true, "default": true })
    navItems!: Array<UDExSideNavigationItem | UDExSideNavigationGroup>;

  /**
   * Defines the fixed items at the bottom of the `ui5-side-navigation`. Use the `ui5-side-navigation-item` component
   * for the fixed items, and optionally the `ui5-side-navigation-sub-item` component to provide second-level items inside them.
   *
   * **Note:** In order to achieve the best user experience, it is recommended that you keep the fixed items "flat" (do not pass sub-items)
   *
   * @public
   */
  @slot({ type: HTMLElement, invalidateOnChildChange: true })
    fixedItems!: Array<UDExSideNavigationItem | UDExSideNavigationGroup>;

  /**
   * Indicates selected item element
   *
   * @default false
   * @private
   */
  @property({ noAttribute: true, type: Object })
    _selectedItem!: SelectedItem;

  _navItems!: Array<UDExSideNavigationItem | UDExSideNavigationGroup>;
  _initialized = false;
  _dropdown!: UDExDropdown;

  /**
   * Getter for the interactive element that opens the dropdown
   * @private
   */
  get _dropdownTrigger() {
    return this.shadowRoot!.querySelector<HTMLElement>(".udex-side-navigation-mobile__trigger")!;
  }

  async _getResponsePopover() {
    const staticAreaItem = await this.getStaticAreaItemDomRef();
    return staticAreaItem!.querySelector<UDExDropdown>(`udex-dropdown`)!;
  }

  _afterDropdownOpen() {
    this._dropdownOpened = true;
  }

  _afterDropdownClose() {
    this._dropdownOpened = false;
  }

  async handleTriggerClick() {
    this._dropdown = await this._getResponsePopover();
    if (this._dropdown?.open) {
      this._dropdown.onClose();
    } else {
      this._dropdown.onOpen(this._dropdownTrigger);
    }
  }

  async handleKeydown(event: KeyboardEvent) {
    if ((event.key === "Enter" || event.key === " ")) {
      await this.handleTriggerClick();
    }
  }

  async _handleSelectionChanged(event: {detail: { item: UDExSideNavigationItem | UDExSideNavigationSubItem }}) {
    this._dropdown = await this._getResponsePopover();
    this._dropdown.onClose();
    this._selectedItem = event.detail.item;
  }

  getSelectedItem() {
    const items = this._navItems.reduce((result, item) => {
      return (result).concat(item.allItems as UDExSideNavigationSubItem[]);
    }, new Array<UDExSideNavigationItem | UDExSideNavigationSubItem>());
    return items.find(item => item._selected);
  }

  onBeforeRendering() {
    if (!this._initialized) {
      const items = this.getSlottedNodes<UDExSideNavigationItem | UDExSideNavigationGroup>("navItems");
      const fixedItems = this.getSlottedNodes<UDExSideNavigationItem | UDExSideNavigationGroup>("fixedItems");
      this._navItems = items.concat(fixedItems);
      this._selectedItem = this.getSelectedItem() || null;
    }
    this._initialized = true;
  }

  async onAfterRendering() {
    this._dropdown = await this._getResponsePopover();
  }
}

UDExSideNavigationMobile.define();

export default UDExSideNavigationMobile;
