import UI5Element from "@ui5/webcomponents-base/dist/UI5Element.js";
import customElement from "@ui5/webcomponents-base/dist/decorators/customElement.js";
import property from "@ui5/webcomponents-base/dist/decorators/property.js";
import litRender from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import Icon from "@ui5/webcomponents/dist/Icon.js";
import ValueState from "./types/common.js";
import "@ui5/webcomponents-icons/dist/accept.js";
import "@ui5/webcomponents-icons/dist/error.js";
import "@ui5/webcomponents-icons/dist/alert.js";

import StatusMessageTemplate from "./generated/templates/StatusMessageTemplate.lit.js";

// Styles
import StatusMessageCss from "./generated/themes/StatusMessage.css.js";

export enum IconMapping {
  Success = "accept",
  Error = "error",
  Warning = "alert",
}

/**
 * @class
 *
 * <h3 class="comment-api-title">Overview</h3>
 *
 *
 * <h3>Usage</h3>
 *
 * For the <code>udex-status-message</code>
 * <h3>ES6 Module Import</h3>
 *
 * <code>import "@udex/web-components/dist/StatusMessage.js";</code>
 *
 * @constructor
 * @extends UI5Element
 * @public
 */
@customElement({
  tag: "udex-status-message",
  renderer: litRender,
  styles: StatusMessageCss,
  template: StatusMessageTemplate,
  dependencies: [Icon],
})
class UDExStatusMessage extends UI5Element {
  /**
   * Defines supporting text under the input field.
   *
   * @default ""
   * @public
   */
  @property({ type: String })
    supportingText!: string;

  /**
   * Defines supporting text alignment. It aligns only Standard state text.
   *
   * @default ""
   * @public
   */
  @property({ type: String, defaultValue: "left" })
    supportingTextAlignment!: "Left" | "Right";

  /**
   * Defines if component should have disabled styles.
   *
   * @default ""
   * @public
   */
  @property({ type: String })
    disabled!: string;
  /**
   * Defines the value state of the component.
   *
   * @default "Standard"
   * @public
   */
  @property({ type: ValueState, defaultValue: "Standard" })
    valueState!: "Standard" | "Warning" | "Error" | "Success";

  get iconFieldName(): string {
    if (this.valueState !== ValueState.Standard) {
      return IconMapping[this.valueState];
    }
    return "";
  }
}

UDExStatusMessage.define();

export default UDExStatusMessage;
