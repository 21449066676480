/* eslint no-unused-vars: 0 */
import { html, svg, repeat, classMap, styleMap, ifDefined, unsafeHTML, scopeTag } from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import type UI5Element from "@ui5/webcomponents-base/dist/UI5Element.js";
	import type InputGroup from "../../InputGroup.js";
	import type { ClassMapValue } from "@ui5/webcomponents-base/dist/types.js";
	
function block0 (this: InputGroup, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<div id="${ifDefined(this._id)}-input-group" class="udex-input-group__container" role="group" aria-describedby="${ifDefined(this._id)}-valueStateDesc"><div class="udex-input-group__content">${ repeat(this._items, (item, index) => (item as typeof item & {_id?: any})._id || index, (item, index: number) => block1.call(this, context, tags, suffix, item, index)) }</div>${ this.valueStateText ? block2.call(this, context, tags, suffix) : undefined }</div>`;}
function block1 (this: InputGroup, context: UI5Element, tags: string[], suffix: string | undefined, item: any, index: number) { return html`<div class="udex-input-group__item"><slot name="${ifDefined(item.item._individualSlot)}"></slot></div>`;}
function block2 (this: InputGroup, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<${scopeTag("udex-status-message", tags, suffix)} supporting-text="${ifDefined(this.valueStateText)}" value-state="${ifDefined(this.valueState)}"></${scopeTag("udex-status-message", tags, suffix)}><span id="${ifDefined(this._id)}-valueStateDesc" class="hidden-text">${ifDefined(this.valueStateText)}</span>` : html`<udex-status-message supporting-text="${ifDefined(this.valueStateText)}" value-state="${ifDefined(this.valueState)}"></udex-status-message><span id="${ifDefined(this._id)}-valueStateDesc" class="hidden-text">${ifDefined(this.valueStateText)}</span>`;}


export default block0;