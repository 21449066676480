/* eslint no-unused-vars: 0 */
import { html, svg, repeat, classMap, styleMap, ifDefined, unsafeHTML, scopeTag } from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import type UI5Element from "@ui5/webcomponents-base/dist/UI5Element.js";
	import type Toast from "../../Toast.js";
	import type { ClassMapValue } from "@ui5/webcomponents-base/dist/types.js";
	
function block0 (this: Toast, context: UI5Element, tags: string[], suffix: string | undefined) { return html`${ this.open ? block1.call(this, context, tags, suffix) : undefined } `;}
function block1 (this: Toast, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<div class="ui5-toast-root udex-toast" role="alert" tabindex="0">${ this.withIcon ? block2.call(this, context, tags, suffix) : undefined }<bdi><slot></slot></bdi>${ this.showCloseIcon ? block3.call(this, context, tags, suffix) : undefined }</div>`;}
function block2 (this: Toast, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<${scopeTag("ui5-icon", tags, suffix)} class="udex-toast__icon" name="${ifDefined(this.iconName)}" design="${ifDefined(this.iconDesign)}" ui5-icon=""></${scopeTag("ui5-icon", tags, suffix)}>` : html`<ui5-icon class="udex-toast__icon" name="${ifDefined(this.iconName)}" design="${ifDefined(this.iconDesign)}" ui5-icon=""></ui5-icon>`;}
function block3 (this: Toast, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<${scopeTag("udex-button", tags, suffix)} class="udex-toast__button" size="Small" icon="decline" design="Tertiary" accessible-name="close" @click=${this.closeToast}></${scopeTag("udex-button", tags, suffix)}>` : html`<udex-button class="udex-toast__button" size="Small" icon="decline" design="Tertiary" accessible-name="close" @click=${this.closeToast}></udex-button>`;}


export default block0;