/* eslint no-unused-vars: 0 */
import { html, svg, repeat, classMap, styleMap, ifDefined, unsafeHTML, scopeTag } from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import type UI5Element from "@ui5/webcomponents-base/dist/UI5Element.js";
	import type RatingIndicator from "../../RatingIndicator.js";
	import type { ClassMapValue } from "@ui5/webcomponents-base/dist/types.js";
	
function block0 (this: RatingIndicator, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<div class="udex-rating-indicator ui5-rating-indicator-root ${ifDefined(this.classNames)}" role="slider" aria-roledescription="${ifDefined(this._ariaRoleDescription)}" aria-valuemin="0" aria-valuenow="${ifDefined(this.value)}" aria-valuemax="${ifDefined(this.max)}" aria-valuetext="${ifDefined(this.value)} of ${ifDefined(this.max)}" aria-orientation="horizontal" aria-disabled="${ifDefined(this._ariaDisabled)}" aria-readonly="${ifDefined(this.ariaReadonly)}" aria-description="${ifDefined(this._ariaDescription)}" tabindex="${ifDefined(this.effectiveTabIndex)}" @focusin="${this._onfocusin}" @focusout="${this._onfocusout}" @click="${this._onclick}" @keydown="${this._onkeydown}" title="${ifDefined(this.ratingTooltip)}" aria-label="${ifDefined(this._ariaLabel)}"><ul class="udex-rating-indicator__list ui5-rating-indicator-list" aria-hidden="true">${ repeat(this._stars, (item, index) => (item as typeof item & {_id?: any})._id || index, (item, index: number) => block1.call(this, context, tags, suffix, item, index)) }</ul>${ this.totalCount ? block10.call(this, context, tags, suffix) : undefined }</div>`;}
function block1 (this: RatingIndicator, context: UI5Element, tags: string[], suffix: string | undefined, item: any, index: number) { return html`${ item.selected ? block2.call(this, context, tags, suffix, item, index) : block3.call(this, context, tags, suffix, item, index) }`;}
function block2 (this: RatingIndicator, context: UI5Element, tags: string[], suffix: string | undefined, item: any, index: number) { return suffix ? html`<li data-ui5-value="${ifDefined(item.index)}" class="udex-rating-indicator__item udex-rating-indicator__item-sel ui5-rating-indicator-item ui5-rating-indicator-item-sel"><${scopeTag("ui5-icon", tags, suffix)} data-ui5-value="${ifDefined(item.index)}" name="favorite"></${scopeTag("ui5-icon", tags, suffix)}></li>` : html`<li data-ui5-value="${ifDefined(item.index)}" class="udex-rating-indicator__item udex-rating-indicator__item-sel ui5-rating-indicator-item ui5-rating-indicator-item-sel"><ui5-icon data-ui5-value="${ifDefined(item.index)}" name="favorite"></ui5-icon></li>`;}
function block3 (this: RatingIndicator, context: UI5Element, tags: string[], suffix: string | undefined, item: any, index: number) { return html`${ item.halfStar ? block4.call(this, context, tags, suffix, item, index) : block5.call(this, context, tags, suffix, item, index) }`;}
function block4 (this: RatingIndicator, context: UI5Element, tags: string[], suffix: string | undefined, item: any, index: number) { return suffix ? html`<li class="udex-rating-indicator__item udex-rating-indicator__item-half ui5-rating-indicator-item ui5-rating-indicator-item-half"><${scopeTag("ui5-icon", tags, suffix)} data-ui5-value="${ifDefined(item.index)}" name="unfavorite"></${scopeTag("ui5-icon", tags, suffix)}><div class="udex-rating-indicator__half-icon-wrapper ui5-rating-indicator-half-icon-wrapper"><${scopeTag("ui5-icon", tags, suffix)} data-ui5-value="${ifDefined(item.index)}" name="favorite" class="udex-rating-indicator__half-icon ui5-rating-indicator-half-icon"></${scopeTag("ui5-icon", tags, suffix)}></div></li>` : html`<li class="udex-rating-indicator__item udex-rating-indicator__item-half ui5-rating-indicator-item ui5-rating-indicator-item-half"><ui5-icon data-ui5-value="${ifDefined(item.index)}" name="unfavorite"></ui5-icon><div class="udex-rating-indicator__half-icon-wrapper ui5-rating-indicator-half-icon-wrapper"><ui5-icon data-ui5-value="${ifDefined(item.index)}" name="favorite" class="udex-rating-indicator__half-icon ui5-rating-indicator-half-icon"></ui5-icon></div></li>`;}
function block5 (this: RatingIndicator, context: UI5Element, tags: string[], suffix: string | undefined, item: any, index: number) { return html`${ this.readonly ? block6.call(this, context, tags, suffix, item, index) : block7.call(this, context, tags, suffix, item, index) }`;}
function block6 (this: RatingIndicator, context: UI5Element, tags: string[], suffix: string | undefined, item: any, index: number) { return suffix ? html`<li class="udex-rating-indicator__item udex-rating-indicator__item-unsel ui5-rating-indicator-item ui5-rating-indicator-item-unsel"><${scopeTag("ui5-icon", tags, suffix)} data-ui5-value="${ifDefined(item.index)}" name="unfavorite"></${scopeTag("ui5-icon", tags, suffix)}></li>` : html`<li class="udex-rating-indicator__item udex-rating-indicator__item-unsel ui5-rating-indicator-item ui5-rating-indicator-item-unsel"><ui5-icon data-ui5-value="${ifDefined(item.index)}" name="unfavorite"></ui5-icon></li>`;}
function block7 (this: RatingIndicator, context: UI5Element, tags: string[], suffix: string | undefined, item: any, index: number) { return html`${ this.disabled ? block8.call(this, context, tags, suffix, item, index) : block9.call(this, context, tags, suffix, item, index) }`;}
function block8 (this: RatingIndicator, context: UI5Element, tags: string[], suffix: string | undefined, item: any, index: number) { return suffix ? html`<li class="udex-rating-indicator__item udex-rating-indicator__item-unsel ui5-rating-indicator-item ui5-rating-indicator-item-unsel"><${scopeTag("ui5-icon", tags, suffix)} data-ui5-value="${ifDefined(item.index)}" name="unfavorite"></${scopeTag("ui5-icon", tags, suffix)}></li>` : html`<li class="udex-rating-indicator__item udex-rating-indicator__item-unsel ui5-rating-indicator-item ui5-rating-indicator-item-unsel"><ui5-icon data-ui5-value="${ifDefined(item.index)}" name="unfavorite"></ui5-icon></li>`;}
function block9 (this: RatingIndicator, context: UI5Element, tags: string[], suffix: string | undefined, item: any, index: number) { return suffix ? html`<li data-ui5-value="${ifDefined(item.index)}" class="udex-rating-indicator__item udex-rating-indicator__item-unsel ui5-rating-indicator-item ui5-rating-indicator-item-unsel"><${scopeTag("ui5-icon", tags, suffix)} data-ui5-value="${ifDefined(item.index)}" name="unfavorite"></${scopeTag("ui5-icon", tags, suffix)}></li>` : html`<li data-ui5-value="${ifDefined(item.index)}" class="udex-rating-indicator__item udex-rating-indicator__item-unsel ui5-rating-indicator-item ui5-rating-indicator-item-unsel"><ui5-icon data-ui5-value="${ifDefined(item.index)}" name="unfavorite"></ui5-icon></li>`;}
function block10 (this: RatingIndicator, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<div class="udex-rating-indicator__total">&#40;${ifDefined(this.totalCount)}&#41;</div>`;}


export default block0;