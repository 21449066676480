import customElement from "@ui5/webcomponents-base/dist/decorators/customElement.js";
import litRender from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import UDExTableHeaderCell from "./TableHeaderCell.js";
import UDExTableRowBase from "./TableRowBase.js";
import TableHeaderRowTemplate from "./generated/templates/TableHeaderRowTemplate.lit.js";

/**
 * @class
 *
 * <h3 class="comment-api-title">Overview</h3>
 *
 * <h3>Usage</h3>
 *
 * For the <code>udex-table-header-row</code>
 * <h3>ES6 Module Import</h3>
 *
 * <code>import "@udex/web-components/dist/TableHeaderRow.js";</code>
 *
 * @constructor
 * @extends UDExTableRowBase
 * @public
 */
@customElement({
  tag: "udex-table-header-row",
  renderer: litRender,
  styles: [UDExTableRowBase.styles],
  template: TableHeaderRowTemplate,
  dependencies: [UDExTableHeaderCell],
})

class UDExTableHeaderRow extends UDExTableRowBase {
  get isGroup() {
    return false;
  }
}

UDExTableHeaderRow.define();

export default UDExTableHeaderRow;
