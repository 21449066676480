export enum ListMode {
  SingleSelect = "SingleSelect",
  MultiSelect = "MultiSelect",
  SingleContent = "SingleContent",
  RadioGroup = "RadioGroup",
}

export enum ListStyleType {
  None = "None",
  Circle = "Circle",
  Number = "Number",
  Icon = "Icon",
}

export enum ListItemSize {
  Standard = "Standard",
  Large = "Large",
}

export enum AdditionalTextState {
  None = "None",
  Success = "Success",
  Warning = "Warning",
  Error = "Error",
  Information = "Information",
}
