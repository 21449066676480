/* eslint no-unused-vars: 0 */
import { html, svg, repeat, classMap, styleMap, ifDefined, unsafeHTML, scopeTag } from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import type UI5Element from "@ui5/webcomponents-base/dist/UI5Element.js";
	import type PasswordField from "../../PasswordField.js";
	import type { ClassMapValue } from "@ui5/webcomponents-base/dist/types.js";
	
function block0 (this: PasswordField, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<div class="udex-password-field"><${scopeTag("udex-text-field", tags, suffix)} type=${ifDefined(this.type)} value="${ifDefined(this.value)}" label="${ifDefined(this.passwordLabel)}" ?disabled="${this.disabled}" ?readonly="${this.readonly}" ?displayonly="${this.displayonly}" maxlength="${ifDefined(this.maxlength)}" value-state="${ifDefined(this.valueState)}" supporting-text="${ifDefined(this.supportingText)}" accessible-name="${ifDefined(this.accessibleName)}" accessible-name-ref="${ifDefined(this.accessibleNameRef)}"></${scopeTag("udex-text-field", tags, suffix)}>${ this.renderShowButton ? block1.call(this, context, tags, suffix) : undefined }</div>` : html`<div class="udex-password-field"><udex-text-field type=${ifDefined(this.type)} value="${ifDefined(this.value)}" label="${ifDefined(this.passwordLabel)}" ?disabled="${this.disabled}" ?readonly="${this.readonly}" ?displayonly="${this.displayonly}" maxlength="${ifDefined(this.maxlength)}" value-state="${ifDefined(this.valueState)}" supporting-text="${ifDefined(this.supportingText)}" accessible-name="${ifDefined(this.accessibleName)}" accessible-name-ref="${ifDefined(this.accessibleNameRef)}"></udex-text-field>${ this.renderShowButton ? block1.call(this, context, tags, suffix) : undefined }</div>`;}
function block1 (this: PasswordField, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<${scopeTag("udex-control-button", tags, suffix)} ?disabled="${this.disabled}" id="show-button" design="Default" class="udex-password-field__button udex-password-field__button_show" icon=${ifDefined(this.icon)} size="Large" accessible-name="${ifDefined(this.showButtonTooltip)}" tooltip=${ifDefined(this.showButtonTooltip)} @click="${this.handleShowPassword}"></${scopeTag("udex-control-button", tags, suffix)}>` : html`<udex-control-button ?disabled="${this.disabled}" id="show-button" design="Default" class="udex-password-field__button udex-password-field__button_show" icon=${ifDefined(this.icon)} size="Large" accessible-name="${ifDefined(this.showButtonTooltip)}" tooltip=${ifDefined(this.showButtonTooltip)} @click="${this.handleShowPassword}"></udex-control-button>`;}


export default block0;