/* eslint no-unused-vars: 0 */
import { html, svg, repeat, classMap, styleMap, ifDefined, unsafeHTML, scopeTag } from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import type UI5Element from "@ui5/webcomponents-base/dist/UI5Element.js";
	import type TextArea from "../../TextArea.js";
	import type { ClassMapValue } from "@ui5/webcomponents-base/dist/types.js";
	
function block0 (this: TextArea, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<div class="udex-textarea">${ this.label ? block1.call(this, context, tags, suffix) : undefined }<textarea id="textarea" class="udex-textarea__field" part="textarea" .value="${ifDefined(this.value)}" maxlength="${ifDefined(this.maxlength)}" rows="${ifDefined(this.rows)}" ?disabled="${this.disabled}" ?readonly="${this.isInputReadonly}" ?required="${this.required}" aria-label="${ifDefined(this.accessibleName)}" aria-describedby="${ifDefined(this.ariaDescribedBy)}" aria-labelledby="${ifDefined(this.accessibleNameRef)}" aria-required="${ifDefined(this.required)}" aria-readonly="${ifDefined(this.isInputReadonly)}" aria-disabled="${ifDefined(this.disabled)}" aria-invalid="${ifDefined(this.ariaInvalid)}" @input="${this.handleInput}" @focus="${this.handleFocus}" @blur="${this.handleBlur}" @change="${this.handleChange}"></textarea>${ this._supportingText ? block3.call(this, context, tags, suffix) : undefined }${ this.hasValueState ? block4.call(this, context, tags, suffix) : undefined }${ this.readonly ? block5.call(this, context, tags, suffix) : undefined }</div>`;}
function block1 (this: TextArea, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<label class="${ifDefined(this.labelClasses)}" for="textarea" part="label">${ifDefined(this.label)}${ this.required ? block2.call(this, context, tags, suffix) : undefined }</label>`;}
function block2 (this: TextArea, context: UI5Element, tags: string[], suffix: string | undefined) { return html` * `;}
function block3 (this: TextArea, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<${scopeTag("udex-status-message", tags, suffix)} supporting-text-alignment="Right" ?disabled="${this.disabled}" supporting-text="${ifDefined(this._supportingText)}" value-state="${ifDefined(this.fieldState)}"></${scopeTag("udex-status-message", tags, suffix)}><span id="${ifDefined(this._id)}-supportingTextDesc" class="hidden-text">${ifDefined(this._supportingText)}</span>` : html`<udex-status-message supporting-text-alignment="Right" ?disabled="${this.disabled}" supporting-text="${ifDefined(this._supportingText)}" value-state="${ifDefined(this.fieldState)}"></udex-status-message><span id="${ifDefined(this._id)}-supportingTextDesc" class="hidden-text">${ifDefined(this._supportingText)}</span>`;}
function block4 (this: TextArea, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<span id="${ifDefined(this._id)}-valueStateDesc" class="hidden-text">${ifDefined(this.ariaValueStateHiddenText)}</span>`;}
function block5 (this: TextArea, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<span id="${ifDefined(this._id)}-readonlyDesc" class="hidden-text">${ifDefined(this.readOnlyText)}</span>`;}


export default block0;