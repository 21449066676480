/* eslint no-unused-vars: 0 */
import { html, svg, repeat, classMap, styleMap, ifDefined, unsafeHTML, scopeTag } from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import type UI5Element from "@ui5/webcomponents-base/dist/UI5Element.js";
	import type WizardTab from "../../WizardTab.js";
	import type { ClassMapValue } from "@ui5/webcomponents-base/dist/types.js";
	
function block0 (this: WizardTab, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<div class="ui5-wiz-step-root" role="listitem" tabindex="${ifDefined(this.effectiveTabIndex)}" aria-current="${ifDefined(this.accInfo.ariaCurrent)}" aria-setsize="${ifDefined(this.accInfo.ariaSetsize)}" aria-posinset="${ifDefined(this.accInfo.ariaPosinset)}" aria-disabled="${ifDefined(this.accInfo.ariaDisabled)}" aria-label="${ifDefined(this.accInfo.ariaLabel)}" @click="${this._onclick}" @keyup="${this._onkeyup}" @focusin="${this._onfocusin}"><div class="ui5-wiz-step-main"><div class="ui5-wiz-step-icon-circle">${ this.icon ? block1.call(this, context, tags, suffix) : block2.call(this, context, tags, suffix) }</div>${ this.hasTexts ? block3.call(this, context, tags, suffix) : undefined }</div>${ !this.hideSeparator ? block4.call(this, context, tags, suffix) : undefined }</div>`;}
function block1 (this: WizardTab, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<${scopeTag("ui5-icon", tags, suffix)} class="ui5-wiz-step-icon" name="${ifDefined(this.icon)}"></${scopeTag("ui5-icon", tags, suffix)}>` : html`<ui5-icon class="ui5-wiz-step-icon" name="${ifDefined(this.icon)}"></ui5-icon>`;}
function block2 (this: WizardTab, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<span class="ui5-wiz-step-number">${ifDefined(this.number)}</span>`;}
function block3 (this: WizardTab, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<div class="ui5-wiz-step-texts"><div class="ui5-wiz-step-title-text">${ifDefined(this.titleText)}</div><div class="ui5-wiz-step-subtitle-text">${ifDefined(this.subtitleText)}</div></div>`;}
function block4 (this: WizardTab, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<div class="ui5-wiz-step-hr"></div>`;}


export default block0;