/* eslint-disable @typescript-eslint/no-unsafe-return */
import UI5Element from "@ui5/webcomponents-base/dist/UI5Element.js";
import litRender from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import customElement from "@ui5/webcomponents-base/dist/decorators/customElement.js";
import property from "@ui5/webcomponents-base/dist/decorators/property.js";
import event from "@ui5/webcomponents-base/dist/decorators/event.js";
import slot from "@ui5/webcomponents-base/dist/decorators/slot.js";
import "@ui5/webcomponents-icons/dist/play.js";
import "@ui5/webcomponents-icons/dist/v5/play.svg";
import Player from "video.js/dist/types/player.js";
import type SeekBar from "video.js/dist/types/control-bar/progress-control/seek-bar";
import UDExMediaPlayerYoutube from "./MediaPlayerYoutube.js";
import UDExMediaPlayerVimeo, { VimeoPlayer } from "./MediaPlayerVimeo.js";

// Template
import MediaPlayerTemplate from "./generated/templates/MediaPlayerTemplate.lit.js";

// Styles
import MediaPlayerCss from "./generated/themes/MediaPlayer.css";

declare global {
  interface Window {
    videojs: any;
  }
}

export enum AspectRatio {
  "16:9" = "16:9",
  "9:16" = "9:16",
  "4:3" = "4:3",
  "1:1" = "1:1",
  "none" = "none",
}

export interface VideoJSPlayer extends Player{
  qualityLevels?: () => any;
  textTracks?: () => any;
  controlBar?: any;
  bigPlayButton?: any;
}

/**
 * @class
 *
 * <h3 class="comment-api-title">Overview</h3>
 *
 *
 * <h3>Usage</h3>
 *
 * For the <code>udex-media-player</code>
 * <h3>ES6 Module Import</h3>
 *
 * <code>import @udex/web-components/dist/MediaPlayer.js";</code>
 *
 * @constructor
 * @author SAP SE
 * @extends UI5Element
 * @tagname udex-media-player
 * @public
 */
@customElement({
  tag: "udex-media-player",
  renderer: litRender,
  styles: MediaPlayerCss,
  template: MediaPlayerTemplate,
  dependencies: [UDExMediaPlayerYoutube, UDExMediaPlayerVimeo],
})
/**
 * Fires when video is played
 *
 * @allowPreventDefault
 * @public
 */
@event("videoStart")
/**
 * Fires when video is paused
 *
 * @allowPreventDefault
 * @public
 */
@event("videoPause")
/**
 * Fires when video is watched
 *
 * @allowPreventDefault
 * @public
 */
@event("videoComplete")
/**
 * Fires when video is ready
 *
 * @allowPreventDefault
 * @public
 */
@event("videoReady")
/**
 * Fires when video watching progress changed
 *
 * @allowPreventDefault
 * @public
 */
@event("videoProgress")
/**
 * Fires when 25% of video is watched
 *
 * @allowPreventDefault
 * @public
 */
@event("video25")
/**
 * Fires when 50% of video is watched
 *
 * @allowPreventDefault
 * @public
 */
@event("video50")
/**
 * Fires when 75% of video is watched
 *
 * @allowPreventDefault
 * @public
 */
@event("video75")
class UDExMediaPlayer extends UI5Element {
  /**
   * Defines the options object of video.js element
   * accepts following properties: https://videojs.com/guides/options
   *
   * @public
   */
  @property({ type: String })
    options!: string;
  /**
   * Defines the source of the video  or audio
   *
   * @default ""
   * @public
   */
  @property({ type: String, defaultValue: "" })
    source!: string;
  /**
   * Defines the video/audio type (examples: "video/mp4" for mp4, "application/x-mpegURL" for m3u8, "audio/mp3" for mp3) more information https://developer.mozilla.org/en-US/docs/Web/HTML/Element/source
   *
   * @default "video/mp4"
   * @public
   */
  @property({ type: String, defaultValue: "video/mp4" })
    type!: string;
  /**
   * Defines a title for youtube video
   *
   * @default ""
   * @public
   */
  @property({ type: String, defaultValue: "" })
    title!: string;
  /**
   * Defines if only audio should be played
   *
   * @default false
   * @public
   */
  @property({ type: Boolean })
    audioOnly!: boolean;
  /**
   * Defines the poster url
   *
   * @default ""
   * @public
   */
  @property({ type: String, defaultValue: "" })
    poster!: string;
  /**
  * Placeholder for all html `track` elements to provide video subtitles.
  * Example: `<track kind='captions' src='./videoSubtitles.vtt' srclang='en' label='English' default />`
  * More information: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/track
  *
  * @slot
  * @public
  */
  @slot({ type: HTMLElement, "default": true, individualSlots: true })
    caption!: Array<HTMLElement>;
  /**
 * Defines video width. Used for YouTube and Vimeo videos only.
 * Regular video and audio sizes are control by CSS variables.
 *
 * @default ""
 * @public
 */
  @property({ type: String, defaultValue: "" })
    width!: string;
  /**
   * Defines video height. Used for YouTube and Vimeo videos only.
   * Regular video and audio sizes are control by CSS variables.
   *
   * @default ""
   * @public
   */
  @property({ type: String, defaultValue: "" })
    height!: string;
  /**
   * Defines id of html element that videojs will be initialized with
   *
   * @default "udex-media-player"
   * @public
   */
  @property({ type: String, defaultValue: "udex-media-player" })
    playerid!: string;
  /**
   * Defines aspect ratio of video. If set, video will be display in fluid mode.
   * Possible values: "16:9" , "9:16", "4:3", "1:1", "none"
  *
  * @default "16:9"
  * @public
  */
  @property({ type: AspectRatio, defaultValue: "16:9" })
    aspectRatio!: `${AspectRatio}`;
  /**
   * If this property is set, whenever user will focus on udex-media-player element, focus will be redirected into Big Play Button element.
   *
   * @default ""
   * @public
   */
  @property({ type: Boolean })
    playButtonInitialFocus!: boolean;
  /**
   * Enable videojs instance to be added to global window object
   *
   * @default ""
   * @public
   */
  @property({ type: Boolean })
    enableGlobalDependency!: boolean;
  /**
  * @private
  */
  @property({ type: Boolean })
    _allowIframeLoad!: boolean;

  player!: VideoJSPlayer;
  video25!: boolean;
  video50!: boolean;
  video75!: boolean;
  videoCompleted!: boolean;
  videoStart!: boolean;
  videoReady!: boolean;
  videoProgress!: number;

  getPlayedTime = (target: EventTarget): number => {
    const progressControl: SeekBar = (target as any)?.player.controlBar?.ProgressControl?.childNameIndex_.seekBar;
    if (!progressControl) {
      return 0;
    }

    return Math.round(progressControl.getPercent() * 100);
  };

  calculateVideoProgress(timeWatched: number) {
    if (timeWatched >= 25 && !this.video25) {
      this.fireEvent("video25");
      this.video25 = true;
    }

    if (timeWatched >= 50 && !this.video50) {
      this.fireEvent("video50");
      this.video50 = true;
    }

    if (timeWatched >= 75 && !this.video75) {
      this.fireEvent("video75");
      this.video75 = true;
    }

    this.fireEvent("videoProgress", { progress: timeWatched });
  }

  checkCaptionsAvailability(player: VideoJSPlayer) {
    player.on("loadedmetadata", () => {
      if (!player?.textTracks) { return; }

      const tracks = player?.textTracks();
      let captionsAvailable = false;
      for (let i = 0; i < tracks.length; i++) {
        if (tracks[i].kind === "captions" || tracks[i].kind === "subtitles") {
          captionsAvailable = true;
        }
      }

      if (!captionsAvailable && !this._tracks.length) {
        player.controlBar?.removeChild("SubsCapsButton");
      }
    });
  }

  applyScrollIntoTheView() {
    const bigPlayButton = this.player.bigPlayButton?.el();
    bigPlayButton.addEventListener("focus", () => {
      this.player.el().scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    });
  }

  attachListeners(player: Player) {
    player.on("timeupdate", (e: Event) => {
      if (!e.target) {
        return;
      }

      const timeWatched = this.getPlayedTime(e.target);
      if (timeWatched >= 0) {
        this.calculateVideoProgress(timeWatched);
      }
    });

    player.on("play", () => {
      this.fireEvent("videoStart");
    });

    player.on("ended", () => {
      this.fireEvent("videoComplete");
    });

    player.on("pause", () => {
      this.fireEvent("videoPause");
    });

    player.on("ready", () => {
      this.checkCaptionsAvailability(player);
      this.applyScrollIntoTheView();
      this.fireEvent("videoReady");
    });

    if (this.playButtonInitialFocus) {
      this.addEventListener("focus", () => {
        (async () => {
          await player.getChild("BigPlayButton")?.el_.focus();
        })();
      });
    }
  }

  /**
   * Returns video element
   * @public
   */
  getPlayer(): HTMLVideoElement | HTMLAudioElement | YT.Player | VimeoPlayer | null {
    if (this._youtubeSrc) {
      const player = this.shadowRoot?.querySelector("udex-media-player-youtube") as UDExMediaPlayerYoutube;
      return player?.getPlayer() || null;
    }
    if (this._vimeoSrc) {
      const player = this.shadowRoot?.querySelector("udex-media-player-vimeo") as UDExMediaPlayerVimeo;
      return player?.getPlayer() || null;
    }
    return this.shadowRoot?.querySelector(".video-js video") as HTMLVideoElement || this.shadowRoot?.querySelector(".video-js audio") || null;
  }

  get optionsValue() {
    const baseOptions = {
      playbackRates: [1, 1.5, 2],
      controls: true,
      audioOnly: this.audioOnly,
      poster: this.poster,
      controlBar: {
        children: [
          "PlayToggle",
          "VolumePanel",
          "ProgressControl",
          "RemainingTimeDisplay",
          "SubsCapsButton",
          "PlaybackRateMenuButton",
          "FullscreenToggle",
          "MediaLoader",
        ],
      },
      userActions: {
        hotkeys: true,
      },
    };

    if (typeof this.options === "string") {
      return this.options ? {
        ...baseOptions,
        ...JSON.parse(this.options),
      } : baseOptions;
    }

    return this.options;
  }

  get dataSetup() {
    return JSON.stringify(this.optionsValue);
  }

  async setupLanguage(language: string) {
    try {
      return await import(`video.js/dist/lang/${language}.json`);
    } catch (err) {
      console.warn("Cannot load language file", err);
    }
  }

  onAfterRendering(): void {
    if (this._youtubeSrc || this._vimeoSrc) {
      return;
    }

    import("video.js").then(async ({ "default": videojs }) => {
      if (this.enableGlobalDependency) {
        window.videojs = videojs;
      }
      const language: string = this.optionsValue?.language;
      if (language) {
        videojs.addLanguage(language, await this.setupLanguage(language));
      }

      this.player = videojs(this.shadowRoot?.querySelector(".video-js") || "");
      this.attachListeners(this.player);

      if (this.audioOnly) { return; }

      const backgroundOpacityElement = this.shadowRoot?.querySelector<HTMLOptionElement>(".vjs-bg-opacity > select > option[value=\"0.5\"]");
      if (backgroundOpacityElement) {
        backgroundOpacityElement.value = "0.7";
        backgroundOpacityElement.selected = true;
      }

      const fontSizeElement = this.shadowRoot?.querySelector<HTMLOptionElement>(".vjs-font-percent > select > option[value=\"1.00\"]");
      if (fontSizeElement) { fontSizeElement.value = "1.2245"; }

      const playbackSpeed = this.shadowRoot?.querySelector<HTMLOptionElement>(".vjs-playback-rate .vjs-playback-rate-value");
      playbackSpeed?.setAttribute("aria-hidden", "true");
    });
  }

  handleEmbedPlay() {
    this._allowIframeLoad = true;
  }

  handleEmbedVideoReady() {
    this.fireEvent("videoReady");
  }

  handleEmbedVideoPlaying = (embedEvent: CustomEvent) => {
    if (!this.videoStart) {
      this.fireEvent("videoStart");
      this.videoStart = true;
    }
    const playedTime = Math.round(embedEvent.detail.percentPlayed as number);
    this.calculateVideoProgress(playedTime);
  };

  handleEmbedVideoEnded() {
    this.fireEvent("videoComplete");
  }

  handleEmbedVideoPause() {
    this.fireEvent("videoPause");
  }

  get _iframePosterPreload() {
    const shouldBlockIframe = this._iframeLoad && this.poster;
    if (this._allowIframeLoad) {
      return false;
    }
    if (!shouldBlockIframe) {
      this._allowIframeLoad = true;
    }
    return shouldBlockIframe;
  }

  get _iframeLoad() {
    return this._youtubeSrc || this._vimeoSrc;
  }

  get _tracks() {
    return this.getSlottedNodes("caption");
  }

  get _vimeoSrc() {
    return this.source.includes("vimeo");
  }

  get _youtubeSrc() {
    return this.source.includes("youtube");
  }

  get aspectRatioClass() {
    switch (this.aspectRatio) {
    case AspectRatio["16:9"]:
      return "vjs-16-9 udex-media-player__fluid vjs-fluid";
    case AspectRatio["9:16"]:
      return "vjs-9-16 udex-media-player__fluid vjs-fluid";
    case AspectRatio["4:3"]:
      return "vjs-4-3 udex-media-player__fluid vjs-fluid";
    case AspectRatio["1:1"]:
      return "vjs-1-1 udex-media-player__fluid vjs-fluid";
    default:
      return "";
    }
  }

  get embedVideoFluidClass() {
    if (this.aspectRatio === AspectRatio["16:9"] || this.aspectRatio === AspectRatio["9:16"] || this.aspectRatio === AspectRatio["4:3"] || this.aspectRatio === AspectRatio["1:1"]) {
      return true;
    }
    return false;
  }

  get playerElementId(): string {
    return this.playerid || "udex-media-player";
  }

  onExitDOM(): void {
    if (this.player) {
      this.player.dispose();
    }
  }
}

UDExMediaPlayer.define();

export default UDExMediaPlayer;
