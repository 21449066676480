import type { StyleData } from "@ui5/webcomponents-base/dist/types.js";
import { registerThemePropertiesLoader } from "@ui5/webcomponents-base/dist/asset-registries/Themes.js";

import defaultThemeBase from "@ui5/webcomponents-theming/dist/generated/themes/sap_horizon/parameters-bundle.css.js";
import defaultTheme from "./sap_horizon/parameters-bundle.css.js";

registerThemePropertiesLoader("@ui5/webcomponents-theming", "sap_horizon", async () => defaultThemeBase);
registerThemePropertiesLoader("@sapudex/web-components", "sap_horizon", async () => defaultTheme);

const styleData: StyleData = {packageName:"@sapudex/web-components",fileName:"themes/Tooltip.css.ts",content:`:host{font-family:var(--sapFontFamily, sans-serif);font-size:var(--sapFontSize, 16px);color:var(--udexColorGrey9, #223548);--udex-tooltip-background-color: var(--udexColorNeutralWhite, #fff);--udex-tooltip-border-color: var(--udexColorGrey4, var(--udexColorGrey4));--udex-tooltip-box-shadow: var(--sapContent_Shadow0, 0px 2px 4px 0px rgba(34, 54, 73, .2))}.udex-tooltip__pane{border:var(--sapElement_BorderWidth, 1px) solid var(--udex-tooltip-border-color);box-shadow:var(--udex-tooltip-box-shadow);border-radius:var(--sapPopover_BorderCornerRadius, 8px);background:var(--udex-tooltip-background-color);max-width:360px}.udex-tooltip-title{font-size:var(--udexTypographyHeadingMediumXXXSFontSize, 18px);font-weight:var(--udexTypographyFontWeightMedium, 500);padding-bottom:var(--udexSpacer8, 8px)}
`};
export default styleData;
	