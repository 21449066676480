/* eslint no-unused-vars: 0 */
import { html, svg, repeat, classMap, styleMap, ifDefined, unsafeHTML, scopeTag } from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import type UI5Element from "@ui5/webcomponents-base/dist/UI5Element.js";
	import type DayPicker from "../../DayPicker.js";
	import type { ClassMapValue } from "@ui5/webcomponents-base/dist/types.js";
	
function block0 (this: DayPicker, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<div class="${classMap(this.classes.root as ClassMapValue)}" style="${styleMap(this.styles.wrapper)}" @keydown=${this._onkeydown} @keyup=${this._onkeyup} @click=${this._onclick} @mouseover=${this._onmouseover} @focusin=${this._onfocusin} @focusout=${this._onfocusout}><div id="${ifDefined(this._id)}-content" class="ui5-dp-content" role="grid" aria-roledescription="${ifDefined(this.ariaRoledescription)}"><div role="row" class="ui5-dp-days-names-container">${ repeat(this._dayNames, (item, index) => (item as typeof item & {_id?: any})._id || index, (item, index: number) => block1.call(this, context, tags, suffix, item, index)) }</div>${ repeat(this._weeks, (item, index) => (item as typeof item & {_id?: any})._id || index, (item, index: number) => block2.call(this, context, tags, suffix, item, index)) }</div></div>`;}
function block1 (this: DayPicker, context: UI5Element, tags: string[], suffix: string | undefined, item: any, index: number) { return html`<div role="columnheader" aria-label="${ifDefined(item.name)}" class="${ifDefined(item.classes)}">${ifDefined(item.ultraShortName)}</div>`;}
function block2 (this: DayPicker, context: UI5Element, tags: string[], suffix: string | undefined, item: any, index: number) { return html`${ item.length ? block3.call(this, context, tags, suffix, item, index) : block10.call(this, context, tags, suffix, item, index) }`;}
function block3 (this: DayPicker, context: UI5Element, tags: string[], suffix: string | undefined, item: any, index: number) { return html`<div class="ui5-dp-weeks-row" role="row">${ repeat(item, (item, index) => (item as typeof item & {_id?: any})._id || index, (item, index: number) => block4.call(this, context, tags, suffix, item, index)) }</div>`;}
function block4 (this: DayPicker, context: UI5Element, tags: string[], suffix: string | undefined, item: any, index: number) { return html`${ item.timestamp ? block5.call(this, context, tags, suffix, item, index) : block8.call(this, context, tags, suffix, item, index) }`;}
function block5 (this: DayPicker, context: UI5Element, tags: string[], suffix: string | undefined, item: any, index: number) { return html`<div ?special-day="${item.type}" tabindex="${ifDefined(item._tabIndex)}" ?data-sap-focus-ref="${item.focusRef}" data-sap-timestamp="${ifDefined(item.timestamp)}" role="gridcell" aria-selected="${ifDefined(item.ariaSelected)}" aria-label="${ifDefined(item.ariaLabel)}" aria-disabled="${ifDefined(item.ariaDisabled)}" class="${ifDefined(item.classes)}"><span class="ui5-dp-daytext" data-sap-timestamp="${ifDefined(item.timestamp)}">${ifDefined(item.day)}</span>${ item._isSecondaryCalendarType ? block6.call(this, context, tags, suffix, item, index) : undefined }${ item.type ? block7.call(this, context, tags, suffix, item, index) : undefined }</div>`;}
function block6 (this: DayPicker, context: UI5Element, tags: string[], suffix: string | undefined, item: any, index: number) { return html`<span class="ui5-dp-daytext ui5-dp-daysectext">${ifDefined(item.secondDay)}</span>`;}
function block7 (this: DayPicker, context: UI5Element, tags: string[], suffix: string | undefined, item: any, index: number) { return html`<div class="ui5-dp-specialday ${ifDefined(item.type)}"></div>`;}
function block8 (this: DayPicker, context: UI5Element, tags: string[], suffix: string | undefined, item: any, index: number) { return html`${ !item.isHidden ? block9.call(this, context, tags, suffix, item, index) : undefined }`;}
function block9 (this: DayPicker, context: UI5Element, tags: string[], suffix: string | undefined, item: any, index: number) { return html`<div class="ui5-dp-weekname-container" role="rowheader" aria-label="Calendar Week ${ifDefined(item.weekNum)}"><span class="ui5-dp-weekname">${ifDefined(item.weekNum)}</span></div>`;}
function block10 (this: DayPicker, context: UI5Element, tags: string[], suffix: string | undefined, item: any, index: number) { return html`<div class="sapWCEmptyWeek"></div>`;}


export default block0;