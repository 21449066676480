import UI5Element from "@ui5/webcomponents-base/dist/UI5Element.js";
import customElement from "@ui5/webcomponents-base/dist/decorators/customElement.js";
import property from "@ui5/webcomponents-base/dist/decorators/property.js";
import slot from "@ui5/webcomponents-base/dist/decorators/slot.js";
import event from "@ui5/webcomponents-base/dist/decorators/event.js";
import { getI18nBundle } from "@ui5/webcomponents-base/dist/i18nBundle.js";
import type I18nBundle from "@ui5/webcomponents-base/dist/i18nBundle.js";
import litRender from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import "@ui5/webcomponents-icons/dist/feeder-arrow.js";
import {
  isEnter,
  isSpace,
} from "@ui5/webcomponents-base/dist/Keys.js";
import AccordionTemplate from "./generated/templates/AccordionTemplate.lit.js";

// Styles
import AccordionCss from "./generated/themes/Accordion.css.js";
import { COLLAPSE_ALL, EXPAND_ALL } from "./generated/i18n/i18n-defaults.js";

/**
 * @class
 *
 * <h3 class="comment-api-title">Overview</h3>
 *
 *
 * <h3>Usage</h3>
 *
 * For the <code>udex-accordion</code>
 * <h3>ES6 Module Import</h3>
 *
 * <code>import @udex/web-components/dist/Accordion.js";</code>
 *
 * @constructor
 * @author SAP SE
 * @extends UI5Element
 * @tagname udex-accordion
 * @public
 */
@customElement({
  tag: "udex-accordion",
  renderer: litRender,
  styles: AccordionCss,
  template: AccordionTemplate,
  dependencies: [],
})

/**
 * Triggered when the user click on on Expand/Collapse All button.
 *
 * @event sap.ui.webc.web-components.UDExAccordion#toggleItems
 * @public
 */
@event("toggle-items", {
  detail:
    {
      isOpen:
        { type: Boolean },
    },
})
class UDExAccordion extends UI5Element {
  /**
   * Used internally to store expand/collapse button state.
   *
   * @private
   */
  @property({ type: Boolean, noAttribute: true }) _allExpanded!: boolean;

  /**
   * Defines direction of the accordion component.
   *
   * @private
   */
  @property({ type: String }) _direction?: string;

  /**
   * Defines the headline text of the accordion component.
   *
   * @default ""
   * @public
   */
  @property({ type: String }) headline!: string;

  /**
   * Slot for accordion items.
   *
   * @slot
   * @public
   */
  @slot({ type: HTMLElement, "default": true, individualSlots: true }) _items!: Array<HTMLElement>;

  _activeItemIndex: number;

  static i18nBundle: I18nBundle;

  constructor() {
    super();
    this._allExpanded = false;
    this._activeItemIndex = -1;
  }

  static async onDefine() {
    UDExAccordion.i18nBundle = await getI18nBundle("sap-ui-webcomponents-bundle");
  }

  get accordionClass(): string {
    let classes = "udex-accordion";
    if (this._direction === "rtl") {
      classes = `${classes} udex-accordion--${this._direction}`;
    }

    return classes;
  }

  get toggleAllText(): string {
    return this._allExpanded
      ? UDExAccordion.i18nBundle.getText(COLLAPSE_ALL)
      : UDExAccordion.i18nBundle.getText(EXPAND_ALL);
  }

  get isExpanded(): boolean {
    return this._allExpanded;
  }

  get items() {
    return this._items.map(el => {
      return {
        item: el,
      };
    });
  }

  get headlineText() {
    return this.headline ? this.headline : "Default headline";
  }

  toggleAllHandler(e: Event) {
    e.preventDefault();
    this.fireEvent("toggle-items", { isOpen: this._allExpanded });
    this._allExpanded = !this._allExpanded;
  }

  toggleKeyAllHandler(e: KeyboardEvent) {
    if (isEnter(e) || isSpace(e)) {
      this.fireEvent("toggle-items", { isOpen: this._allExpanded });
      this._allExpanded = !this._allExpanded;
    }
  }

  keydownHandler(e: KeyboardEvent) {
    if (e.key !== "Tab") {
      e.preventDefault();
    }
    const len = this._items.length;
    const focusOnItem = (itemIndex: number) => {
      const item = this._items[itemIndex];
      this._activeItemIndex = itemIndex;
      (item?.shadowRoot?.querySelector(".udex-accordion-item__header") as HTMLElement)?.focus();
    };

    switch (e.key) {
    case "Home":
      focusOnItem(0);
      break;
    case "End":
      focusOnItem(len - 1);
      break;
    case "ArrowUp":
      if (this._activeItemIndex > 0) {
        focusOnItem(this._activeItemIndex - 1);
      } else if (this._activeItemIndex === 0) {
        focusOnItem(len - 1);
      }
      break;
    case "ArrowDown":
      if (this._activeItemIndex < len - 1) {
        focusOnItem(this._activeItemIndex + 1);
      } else if (this._activeItemIndex === len - 1) {
        focusOnItem(0);
      }
      break;
    default:
      break;
    }
  }

  itemFocusHandler(e: CustomEvent) {
    const target = e.target as HTMLElement;
    if (target) {
      this._activeItemIndex = this._items?.indexOf(target) ?? -1;
    }
  }

  onAfterRendering() {
    const accordion = this.shadowRoot?.querySelector<HTMLElement>(
      ".udex-accordion",
    );
    if (accordion) {
      const directionStyles: string = window
        .getComputedStyle(accordion)
        .getPropertyValue("direction");
      if (!directionStyles) {
        this._direction = "ltr";
      } else {
        this._direction = directionStyles;
      }
    }
  }
}

UDExAccordion.define();

export default UDExAccordion;
