/* eslint no-unused-vars: 0 */
import { html, svg, repeat, classMap, styleMap, ifDefined, unsafeHTML, scopeTag } from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import type UI5Element from "@ui5/webcomponents-base/dist/UI5Element.js";
	import type Table from "../../Table.js";
	import type { ClassMapValue } from "@ui5/webcomponents-base/dist/types.js";
	
function block0 (this: Table, context: UI5Element, tags: string[], suffix: string | undefined) { return html`${ this._hasToolbar ? block1.call(this, context, tags, suffix) : undefined }<div id="table" class="udex-table" role="grid" aria-label="${ifDefined(this.accessibleName)}" @scroll="${this._onScroll}" @keydown="${this._handleKeyDown}" style="${styleMap(this._styles.table)}"><div class="udex-table__header-holder"><slot name="headerRow"></slot></div><slot></slot>${ !this.rows.length ? block2.call(this, context, tags, suffix) : undefined }${ this.loading ? block5.call(this, context, tags, suffix) : undefined }</div>${ this.footnotes.length ? block6.call(this, context, tags, suffix) : undefined } `;}
function block1 (this: Table, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<div class="udex-table-toolbar"><span class="udex-table-toolbar__title">${ifDefined(this.heading)}</span><div class="udex-table-toolbar__actions"><slot name="actions"></slot></div></div>`;}
function block2 (this: Table, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<${scopeTag("udex-table-row", tags, suffix)}><${scopeTag("udex-table-cell", tags, suffix)} class="udex-table__no-data">${ this.noData.length ? block3.call(this, context, tags, suffix) : block4.call(this, context, tags, suffix) }</${scopeTag("udex-table-cell", tags, suffix)}></${scopeTag("udex-table-row", tags, suffix)}>` : html`<udex-table-row><udex-table-cell class="udex-table__no-data">${ this.noData.length ? block3.call(this, context, tags, suffix) : block4.call(this, context, tags, suffix) }</udex-table-cell></udex-table-row>`;}
function block3 (this: Table, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<slot name="noData"></slot>`;}
function block4 (this: Table, context: UI5Element, tags: string[], suffix: string | undefined) { return html`${ifDefined(this._effectiveNoDataText)}`;}
function block5 (this: Table, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<${scopeTag("ui5-busy-indicator", tags, suffix)} class="udex-table__loading" delay="${ifDefined(this.loadingDelay)}" data-sap-focus-ref active></${scopeTag("ui5-busy-indicator", tags, suffix)}>` : html`<ui5-busy-indicator class="udex-table__loading" delay="${ifDefined(this.loadingDelay)}" data-sap-focus-ref active></ui5-busy-indicator>`;}
function block6 (this: Table, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<div class="udex-table-footnotes"><slot name="footnotes"></slot></div>`;}


export default block0;