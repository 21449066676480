/* eslint no-unused-vars: 0 */
import { html, svg, repeat, classMap, styleMap, ifDefined, unsafeHTML, scopeTag } from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import type UI5Element from "@ui5/webcomponents-base/dist/UI5Element.js";
	import type MastheadMobileMenu from "../../MastheadMobileMenu.js";
	import type { ClassMapValue } from "@ui5/webcomponents-base/dist/types.js";
	
function block0 (this: MastheadMobileMenu, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<nav class="udex-masthead-mobile-menu" @keydown="${this.handleKeyDown}"><div class="udex-masthead-mobile-menu__nav-holder">${ this.hasSearchSlot ? block1.call(this, context, tags, suffix) : undefined }<ul class="udex-masthead-mobile-menu__list" role="menu">${ this.showNavigation ? block2.call(this, context, tags, suffix) : undefined }${ this._showCategories ? block6.call(this, context, tags, suffix) : undefined }${ this._showCategoryItems ? block12.call(this, context, tags, suffix) : undefined }</ul></div>${ this.showExploreBtn ? block15.call(this, context, tags, suffix) : undefined }</nav> `;}
function block1 (this: MastheadMobileMenu, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<div class="udex-masthead-mobile-menu__list-search"><slot name="search"></slot></div>`;}
function block2 (this: MastheadMobileMenu, context: UI5Element, tags: string[], suffix: string | undefined) { return html`${ repeat(this.items, (item, index) => (item as typeof item & {_id?: any})._id || index, (item, index: number) => block3.call(this, context, tags, suffix, item, index)) }`;}
function block3 (this: MastheadMobileMenu, context: UI5Element, tags: string[], suffix: string | undefined, item: any, index: number) { return html`<li class="udex-masthead-mobile-menu__list-item" role="menuitem">${ item.subItems ? block4.call(this, context, tags, suffix, item, index) : block5.call(this, context, tags, suffix, item, index) }</li>`;}
function block4 (this: MastheadMobileMenu, context: UI5Element, tags: string[], suffix: string | undefined, item: any, index: number) { return suffix ? html`<${scopeTag("udex-masthead-menu-item", tags, suffix)} @click="${this.handleMenuItemClick}" label="${ifDefined(item.innerText)}" id="${ifDefined(item._id)}" type="Button" has-border icon-end first-level></${scopeTag("udex-masthead-menu-item", tags, suffix)}>` : html`<udex-masthead-menu-item @click="${this.handleMenuItemClick}" label="${ifDefined(item.innerText)}" id="${ifDefined(item._id)}" type="Button" has-border icon-end first-level></udex-masthead-menu-item>`;}
function block5 (this: MastheadMobileMenu, context: UI5Element, tags: string[], suffix: string | undefined, item: any, index: number) { return suffix ? html`<${scopeTag("udex-masthead-menu-item", tags, suffix)} url="${ifDefined(item.href)}" label="${ifDefined(item.innerText)}" has-border></${scopeTag("udex-masthead-menu-item", tags, suffix)}>` : html`<udex-masthead-menu-item url="${ifDefined(item.href)}" label="${ifDefined(item.innerText)}" has-border></udex-masthead-menu-item>`;}
function block6 (this: MastheadMobileMenu, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<li class="udex-masthead-mobile-menu__list-item" role="menuitem"><${scopeTag("udex-masthead-menu-item", tags, suffix)} class="udex-masthead-mobile-menu__back-item" @click="${this.handleBackToMenuItems}" label="${ifDefined(this._clickedItem.innerText)}" type="Button" has-border icon-start back-to-nav></${scopeTag("udex-masthead-menu-item", tags, suffix)}></li>${ this._clickedItem.href ? block7.call(this, context, tags, suffix) : undefined }${ repeat(this._categories, (item, index) => (item as typeof item & {_id?: any})._id || index, (item, index: number) => block8.call(this, context, tags, suffix, item, index)) }` : html`<li class="udex-masthead-mobile-menu__list-item" role="menuitem"><udex-masthead-menu-item class="udex-masthead-mobile-menu__back-item" @click="${this.handleBackToMenuItems}" label="${ifDefined(this._clickedItem.innerText)}" type="Button" has-border icon-start back-to-nav></udex-masthead-menu-item></li>${ this._clickedItem.href ? block7.call(this, context, tags, suffix) : undefined }${ repeat(this._categories, (item, index) => (item as typeof item & {_id?: any})._id || index, (item, index: number) => block8.call(this, context, tags, suffix, item, index)) }`;}
function block7 (this: MastheadMobileMenu, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<li class="udex-masthead-mobile-menu__list-item" role="menuitem"><${scopeTag("udex-masthead-menu-item", tags, suffix)} url="${ifDefined(this._clickedItem.href)}" label="${ifDefined(this.overviewLinkText)}" has-border></${scopeTag("udex-masthead-menu-item", tags, suffix)}></li>` : html`<li class="udex-masthead-mobile-menu__list-item" role="menuitem"><udex-masthead-menu-item url="${ifDefined(this._clickedItem.href)}" label="${ifDefined(this.overviewLinkText)}" has-border></udex-masthead-menu-item></li>`;}
function block8 (this: MastheadMobileMenu, context: UI5Element, tags: string[], suffix: string | undefined, item: any, index: number) { return html`${ item.category ? block9.call(this, context, tags, suffix, item, index) : block10.call(this, context, tags, suffix, item, index) }`;}
function block9 (this: MastheadMobileMenu, context: UI5Element, tags: string[], suffix: string | undefined, item: any, index: number) { return suffix ? html`<li class="udex-masthead-mobile-menu__list-item" role="menuitem"><${scopeTag("udex-masthead-menu-item", tags, suffix)} @click="${this.handleMenuCategoryClick}" url="${ifDefined(item.url)}" id="${index}" label="${ifDefined(item.category)}" type="Button" second-level has-border icon-end></${scopeTag("udex-masthead-menu-item", tags, suffix)}></li>` : html`<li class="udex-masthead-mobile-menu__list-item" role="menuitem"><udex-masthead-menu-item @click="${this.handleMenuCategoryClick}" url="${ifDefined(item.url)}" id="${index}" label="${ifDefined(item.category)}" type="Button" second-level has-border icon-end></udex-masthead-menu-item></li>`;}
function block10 (this: MastheadMobileMenu, context: UI5Element, tags: string[], suffix: string | undefined, item: any, index: number) { return html`${ repeat(item.items, (item, index) => (item as typeof item & {_id?: any})._id || index, (item, index: number) => block11.call(this, context, tags, suffix, item, index)) }`;}
function block11 (this: MastheadMobileMenu, context: UI5Element, tags: string[], suffix: string | undefined, item: any, index: number) { return suffix ? html`<li class="udex-masthead-mobile-menu__list-item" role="menuitem"><${scopeTag("udex-masthead-menu-item", tags, suffix)} url="${ifDefined(item.url)}" label="${ifDefined(item.title)}" has-border></${scopeTag("udex-masthead-menu-item", tags, suffix)}></li>` : html`<li class="udex-masthead-mobile-menu__list-item" role="menuitem"><udex-masthead-menu-item url="${ifDefined(item.url)}" label="${ifDefined(item.title)}" has-border></udex-masthead-menu-item></li>`;}
function block12 (this: MastheadMobileMenu, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<li class="udex-masthead-mobile-menu__list-item" role="menuitem"><${scopeTag("udex-masthead-menu-item", tags, suffix)} class="udex-masthead-mobile-menu__back-item" @click="${this.handleBackToCategories}" label="${ifDefined(this._category.category)}" type="Button" has-border icon-start back-to-categories></${scopeTag("udex-masthead-menu-item", tags, suffix)}></li>${ this._category.url ? block13.call(this, context, tags, suffix) : undefined }${ repeat(this._category.items, (item, index) => (item as typeof item & {_id?: any})._id || index, (item, index: number) => block14.call(this, context, tags, suffix, item, index)) }` : html`<li class="udex-masthead-mobile-menu__list-item" role="menuitem"><udex-masthead-menu-item class="udex-masthead-mobile-menu__back-item" @click="${this.handleBackToCategories}" label="${ifDefined(this._category.category)}" type="Button" has-border icon-start back-to-categories></udex-masthead-menu-item></li>${ this._category.url ? block13.call(this, context, tags, suffix) : undefined }${ repeat(this._category.items, (item, index) => (item as typeof item & {_id?: any})._id || index, (item, index: number) => block14.call(this, context, tags, suffix, item, index)) }`;}
function block13 (this: MastheadMobileMenu, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<li class="udex-masthead-mobile-menu__list-item" role="menuitem"><${scopeTag("udex-masthead-menu-item", tags, suffix)} url="${ifDefined(this._category.url)}" label="${ifDefined(this.overviewLinkText)}" has-border></${scopeTag("udex-masthead-menu-item", tags, suffix)}></li>` : html`<li class="udex-masthead-mobile-menu__list-item" role="menuitem"><udex-masthead-menu-item url="${ifDefined(this._category.url)}" label="${ifDefined(this.overviewLinkText)}" has-border></udex-masthead-menu-item></li>`;}
function block14 (this: MastheadMobileMenu, context: UI5Element, tags: string[], suffix: string | undefined, item: any, index: number) { return suffix ? html`<li class="udex-masthead-mobile-menu__list-item" role="menuitem"><${scopeTag("udex-masthead-menu-item", tags, suffix)} label="${ifDefined(item.title)}" url="${ifDefined(item.url)}" has-border></${scopeTag("udex-masthead-menu-item", tags, suffix)}></li>` : html`<li class="udex-masthead-mobile-menu__list-item" role="menuitem"><udex-masthead-menu-item label="${ifDefined(item.title)}" url="${ifDefined(item.url)}" has-border></udex-masthead-menu-item></li>`;}
function block15 (this: MastheadMobileMenu, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<${scopeTag("udex-masthead-menu-item", tags, suffix)} class="udex-masthead-mobile-menu__explore-btn" id="${ifDefined(this.exploreItem._id)}" @click="${this.handleExploreSapClick}" accessible-role="button" type="Button" first-level icon-end is-explore label=${ifDefined(this.exploreItem.innerText)}></${scopeTag("udex-masthead-menu-item", tags, suffix)}>` : html`<udex-masthead-menu-item class="udex-masthead-mobile-menu__explore-btn" id="${ifDefined(this.exploreItem._id)}" @click="${this.handleExploreSapClick}" accessible-role="button" type="Button" first-level icon-end is-explore label=${ifDefined(this.exploreItem.innerText)}></udex-masthead-menu-item>`;}


export default block0;