import type { StyleData } from "@ui5/webcomponents-base/dist/types.js";
import { registerThemePropertiesLoader } from "@ui5/webcomponents-base/dist/asset-registries/Themes.js";

import defaultThemeBase from "@ui5/webcomponents-theming/dist/generated/themes/sap_horizon/parameters-bundle.css.js";
import defaultTheme from "./sap_horizon/parameters-bundle.css.js";

registerThemePropertiesLoader("@ui5/webcomponents-theming", "sap_horizon", async () => defaultThemeBase);
registerThemePropertiesLoader("@sapudex/web-components", "sap_horizon", async () => defaultTheme);

const styleData: StyleData = {packageName:"@sapudex/web-components",fileName:"themes/Carousel.css.ts",content:`:host{--sapElement_BorderWidth: 0}.udex-carousel{position:relative}.udex-carousel__navigation--bottom{padding-bottom:6px}.udex-carousel__navigation--bottom ui5-carousel::part(content){padding-bottom:7px}:host([hide-page-indicator]) .udex-carousel__navigation--bottom,:host([hide-page-indicator]) .udex-carousel__navigation--bottom ui5-carousel::part(content){padding-bottom:0}.udex-carousel__navigation-wrapper{height:3.5rem;width:100%;display:flex;flex-wrap:nowrap;align-items:center;box-sizing:border-box}.udex-carousel__navigation--top .udex-carousel__navigation-wrapper{justify-content:end;margin-bottom:10px}.udex-carousel__navigation--bottom .udex-carousel__navigation-wrapper{justify-content:center;position:absolute;bottom:0;left:0}:host([hide-page-indicator]) .udex-carousel__navigation--bottom .udex-carousel__navigation-wrapper{position:static}.udex-carousel__navigation--top .udex-carousel__navigation-button{margin-inline-start:var(--udexSpacer16, 16px)}.udex-carousel__navigation--bottom .udex-carousel__navigation-button:first-child{margin-inline-end:4.7rem}.udex-carousel__navigation--bottom .udex-carousel__navigation-button:last-child{margin-inline-start:4.7rem}
`};
export default styleData;
	