import "@ui5/webcomponents-theming/dist/Assets.js"; // Theming

// own web-components package assets
import "./generated/json-imports/Themes.js";
import "./generated/json-imports/i18n.js";

import { registerCustomThemePropertiesLoader } from "@ui5/webcomponents-base/dist/AssetRegistry.js";

const customThemeLoader = async () => {
  return (
    await import("./generated/assets/themes/overrides/@ui5/webcomponents/sap_glow/parameters-bundle.css.json")
  ).default;
};

registerCustomThemePropertiesLoader("@ui5/webcomponents", "sap_glow", customThemeLoader);
