import customElement from "@ui5/webcomponents-base/dist/decorators/customElement.js";
import property from "@ui5/webcomponents-base/dist/decorators/property.js";
import slot from "@ui5/webcomponents-base/dist/decorators/slot.js";
import litRender from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import UDExListGroupHeaderBase from "./ListGroupHeaderBase.js";
import UDExSelectBoxItem from "./SelectBoxItem.js";

/**
 * @class
 *
 * <h3 class="comment-api-title">Overview</h3>
 *
 *
 * <h3>Usage</h3>
 *
 * For the <code>udex-select-box-group-header</code>
 * <h3>ES6 Module Import</h3>
 *
 * <code>import "@udex/web-components/dist/SelectBoxGroupHeader.js";</code>
 *
 * @constructor
 * @author SAP SE
 * @extends UDExListGroupHeaderBase
 * @tagname udex-select-box-group-header
 * @public
 */

@customElement({
  tag: "udex-select-box-group-header",
  renderer: litRender,
})

class UDExSelectBoxGroupHeader extends UDExListGroupHeaderBase {
  /**
   * Defines the <code>label</code> for the item.
   *
   * @public
   */
  @property()
    label!: string;

  /**
   * Slot for dropdown list items.
   *
   * @slot items
   * @public
   */
  @slot({ type: UDExSelectBoxItem, "default": true })
    items!: Array<UDExSelectBoxItem>;

  getItems(): Array<UDExSelectBoxItem> {
    return this.getSlottedNodes<UDExSelectBoxItem>("items");
  }

  get isGroup(): boolean {
    return true;
  }
}

UDExSelectBoxGroupHeader.define();

export default UDExSelectBoxGroupHeader;
