/* eslint no-unused-vars: 0 */
import { html, svg, repeat, classMap, styleMap, ifDefined, unsafeHTML, scopeTag } from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import type UI5Element from "@ui5/webcomponents-base/dist/UI5Element.js";
	import type ListGroupHeader from "../../ListGroupHeader.js";
	import type { ClassMapValue } from "@ui5/webcomponents-base/dist/types.js";
	
function block0 (this: ListGroupHeader, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<li part="item" class="udex-list__group" role="group" aria-labelledby="group-heading"><ul class="udex-list__group-list" @ui5-item-press="${ifDefined(this.getHandleGroupItemsClick)}">${ this.modeMultiSelectOrRadioGroup ? block1.call(this, context, tags, suffix) : block5.call(this, context, tags, suffix) }<slot></slot></ul></li> `;}
function block1 (this: ListGroupHeader, context: UI5Element, tags: string[], suffix: string | undefined) { return html`${ this.isNonActiveHeader ? block2.call(this, context, tags, suffix) : block4.call(this, context, tags, suffix) }`;}
function block2 (this: ListGroupHeader, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<header id="group-heading" class="udex-list__inactive-header"><span class="udex-list__header-title">${ifDefined(this.label)}</span>${ this.supportingText ? block3.call(this, context, tags, suffix) : undefined }</header>`;}
function block3 (this: ListGroupHeader, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<span class="udex-list__supporting-text ">${ifDefined(this.supportingText)}</span>`;}
function block4 (this: ListGroupHeader, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<${scopeTag("udex-list-item", tags, suffix)} id="group-heading" _additional-class-name="${ifDefined(this._additionalClassName)}" ?selected="${this.selected}" _tab-index="${ifDefined(this._effectiveTabIndex)}" ?indeterminate="${this.indeterminate}" part="group-item" role="none" class="${ifDefined(this.className)}" mode="${ifDefined(this.mode)}" ?controlled="${this.controlled}" supporting-text="${ifDefined(this.supportingText)}" @click="${this.handleHeaderPress}" @keydown="${this.handleKeyDownPress}" type="${ifDefined(this.type)}" label="${ifDefined(this.label)}" _icon="${ifDefined(this._icon)}" size="${ifDefined(this.size)}"></${scopeTag("udex-list-item", tags, suffix)}>` : html`<udex-list-item id="group-heading" _additional-class-name="${ifDefined(this._additionalClassName)}" ?selected="${this.selected}" _tab-index="${ifDefined(this._effectiveTabIndex)}" ?indeterminate="${this.indeterminate}" part="group-item" role="none" class="${ifDefined(this.className)}" mode="${ifDefined(this.mode)}" ?controlled="${this.controlled}" supporting-text="${ifDefined(this.supportingText)}" @click="${this.handleHeaderPress}" @keydown="${this.handleKeyDownPress}" type="${ifDefined(this.type)}" label="${ifDefined(this.label)}" _icon="${ifDefined(this._icon)}" size="${ifDefined(this.size)}"></udex-list-item>`;}
function block5 (this: ListGroupHeader, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<div class="udex-list__group-content ${ifDefined(this.sizeClass)}" part="group-item">${ this.typeNumber ? block6.call(this, context, tags, suffix) : undefined }${ this.typeCircle ? block7.call(this, context, tags, suffix) : undefined }${ this.typeIcon ? block8.call(this, context, tags, suffix) : undefined }<div class="udex-list__header"><span id="group-heading" class="udex-list__header-title">${ifDefined(this.label)}</span>${ this.supportingText ? block9.call(this, context, tags, suffix) : undefined }</div></div>`;}
function block6 (this: ListGroupHeader, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<span class="udex-list__number">${ifDefined(this.numberOfItem)}.</span>`;}
function block7 (this: ListGroupHeader, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<span class="udex-list__circle">&#x2022;</span>`;}
function block8 (this: ListGroupHeader, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<${scopeTag("ui5-icon", tags, suffix)} class="udex-list__icon" name="${ifDefined(this._icon)}" accessible-role="presentation" aria-hidden="true" part="icon"></${scopeTag("ui5-icon", tags, suffix)}>` : html`<ui5-icon class="udex-list__icon" name="${ifDefined(this._icon)}" accessible-role="presentation" aria-hidden="true" part="icon"></ui5-icon>`;}
function block9 (this: ListGroupHeader, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<span class="udex-list__supporting-text ${ifDefined(this.sizeClass)}">${ifDefined(this.supportingText)}</span>`;}


export default block0;