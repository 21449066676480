import UI5Element from "@ui5/webcomponents-base/dist/UI5Element.js";
import customElement from "@ui5/webcomponents-base/dist/decorators/customElement.js";
import property from "@ui5/webcomponents-base/dist/decorators/property.js";
import slot from "@ui5/webcomponents-base/dist/decorators/slot.js";
import litRender from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import "@ui5/webcomponents/dist/Badge.js";

import BadgeTemplate from "./generated/templates/BadgeTemplate.lit.js";

// Styles
import BadgeCss from "./generated/themes/Badge.css.js";

export enum BadgeType {
  text = "Text",
  number = "Number",
}

export enum BadgeSize {
  small = "Small",
  large = "Large",
}

export enum BadgeTextDesign {
  Info = "Info",
  Success = "Success",
  Error = "Error",
  Warning = "Warning",
}

export enum BadgeNumberDesign {
  Blue = "Blue",
  Red = "Red",
  White = "White",
}

/**
 * @class
 *
 * <h3 class="comment-api-title">Overview</h3>
 *
 *
 * <h3>Usage</h3>
 *
 * For the <code>udex-badge</code>
 * <h3>ES6 Module Import</h3>
 *
 * <code>import "@udex/web-components/dist/Badge.js";</code>
 *
 * @constructor
 * @extends UI5Element
 * @public
 */
@customElement({
  tag: "udex-badge",
  renderer: litRender,
  styles: BadgeCss,
  template: BadgeTemplate,
  dependencies: [],
})
class UDExBadge extends UI5Element {
  /**
   * Defines the type of the component.
   *
   * @default "Text"
   * @public
   */
  @property({ defaultValue: BadgeType.text })
    type!: `${BadgeType}`;

  /**
   * Defines the size of the component.
   *
   * @default "Large"
   * @public
   */
  @property({ defaultValue: BadgeSize.large })
    size!: `${BadgeSize}`;

  /**
   * Defines the design of text type component.
   *
   * @default "Info"
   * @public
   */
  @property({ defaultValue: BadgeTextDesign.Info })
    design: `${BadgeTextDesign}` = BadgeTextDesign.Info;
  /**
   * Defines the design of number type component.
   *
   * @default "Blue"
   * @public
   */
  @property({ defaultValue: BadgeNumberDesign.Blue })
    numberDesign!: `${BadgeNumberDesign}`;

  /**
   * Defines the content of the component.
   *
   * @public
   */
  @slot({ type: Node, "default": true })
    default!: Array<Node>;

  get textType(): boolean {
    return this.type === BadgeType.text;
  }

  get textClasses(): string {
    return `udex-badge udex-badge--text udex-badge--${this.design.toLowerCase()} udex-badge--${this.size.toLowerCase()}`;
  }

  get numberClasses(): string {
    return `udex-badge udex-badge--number udex-badge--${this.numberDesign.toLowerCase()} udex-badge--${this.size.toLowerCase()}`;
  }
}

UDExBadge.define();

export default UDExBadge;
