/* eslint no-unused-vars: 0 */
import { html, svg, repeat, classMap, styleMap, ifDefined, unsafeHTML, scopeTag } from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import type UI5Element from "@ui5/webcomponents-base/dist/UI5Element.js";
	import type MastheadMenuItem from "../../MastheadMenuItem.js";
	import type { ClassMapValue } from "@ui5/webcomponents-base/dist/types.js";
	
function block0 (this: MastheadMenuItem, context: UI5Element, tags: string[], suffix: string | undefined) { return html`${ this.isActive ? block1.call(this, context, tags, suffix) : block7.call(this, context, tags, suffix) } `;}
function block1 (this: MastheadMenuItem, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<a class="udex-masthead-menu-item ${ifDefined(this.rootClass)}" role="${ifDefined(this.accessibleRole)}" href="${ifDefined(this.url)}" part="menu-item-link" target="${ifDefined(this.target)}">${ this.iconStart ? block2.call(this, context, tags, suffix) : undefined }<span class=${ifDefined(this.labelClass)}>${ this.externalLink ? block3.call(this, context, tags, suffix) : block4.call(this, context, tags, suffix) }</span>${ this.iconEnd ? block5.call(this, context, tags, suffix) : undefined }</a>${ this.badge ? block6.call(this, context, tags, suffix) : undefined }`;}
function block2 (this: MastheadMenuItem, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<${scopeTag("ui5-icon", tags, suffix)} class="udex-masthead-menu-item__icon udex-masthead-menu-item_icon-left" name="slim-arrow-left" part="left"></${scopeTag("ui5-icon", tags, suffix)}>` : html`<ui5-icon class="udex-masthead-menu-item__icon udex-masthead-menu-item_icon-left" name="slim-arrow-left" part="left"></ui5-icon>`;}
function block3 (this: MastheadMenuItem, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`${ifDefined(this.leadingWordsOfLabel)}<span class="udex-masthead-menu-item__text-with-icon">${ifDefined(this.lastWordOfLabel)}<${scopeTag("ui5-icon", tags, suffix)} class="udex-masthead-menu-item__icon udex-masthead-menu-item_icon-action" name="action"></${scopeTag("ui5-icon", tags, suffix)}></span>` : html`${ifDefined(this.leadingWordsOfLabel)}<span class="udex-masthead-menu-item__text-with-icon">${ifDefined(this.lastWordOfLabel)}<ui5-icon class="udex-masthead-menu-item__icon udex-masthead-menu-item_icon-action" name="action"></ui5-icon></span>`;}
function block4 (this: MastheadMenuItem, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<span>${ifDefined(this.label)}</span>`;}
function block5 (this: MastheadMenuItem, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<${scopeTag("ui5-icon", tags, suffix)} class="udex-masthead-menu-item__icon udex-masthead-menu-item_icon-right" name="slim-arrow-right" part="right"></${scopeTag("ui5-icon", tags, suffix)}>` : html`<ui5-icon class="udex-masthead-menu-item__icon udex-masthead-menu-item_icon-right" name="slim-arrow-right" part="right"></ui5-icon>`;}
function block6 (this: MastheadMenuItem, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<${scopeTag("udex-badge", tags, suffix)} class="udex-masthead-menu-item-badge" type="Number" number-design="Blue" size="Small">${ifDefined(this.badge)}</${scopeTag("udex-badge", tags, suffix)}>` : html`<udex-badge class="udex-masthead-menu-item-badge" type="Number" number-design="Blue" size="Small">${ifDefined(this.badge)}</udex-badge>`;}
function block7 (this: MastheadMenuItem, context: UI5Element, tags: string[], suffix: string | undefined) { return html`${ this.isButtonType ? block8.call(this, context, tags, suffix) : block11.call(this, context, tags, suffix) }`;}
function block8 (this: MastheadMenuItem, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<button @click="${this.handleClick}" _id="${ifDefined(this.id)}" class="udex-masthead-menu-item ${ifDefined(this.rootClass)}" part="menu-item-button" role="${ifDefined(this.accessibleRole)}">${ this.iconStart ? block9.call(this, context, tags, suffix) : undefined }${ifDefined(this.label)}${ this.iconEnd ? block10.call(this, context, tags, suffix) : undefined }</button>`;}
function block9 (this: MastheadMenuItem, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<${scopeTag("ui5-icon", tags, suffix)} class="udex-masthead-menu-item__icon udex-masthead-menu-item_icon-left" name="slim-arrow-left" part="left"></${scopeTag("ui5-icon", tags, suffix)}>` : html`<ui5-icon class="udex-masthead-menu-item__icon udex-masthead-menu-item_icon-left" name="slim-arrow-left" part="left"></ui5-icon>`;}
function block10 (this: MastheadMenuItem, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<${scopeTag("ui5-icon", tags, suffix)} class="udex-masthead-menu-item__icon udex-masthead-menu-item_icon-right" name="slim-arrow-right" part="right"></${scopeTag("ui5-icon", tags, suffix)}>` : html`<ui5-icon class="udex-masthead-menu-item__icon udex-masthead-menu-item_icon-right" name="slim-arrow-right" part="right"></ui5-icon>`;}
function block11 (this: MastheadMenuItem, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<span class="udex-masthead-menu-item ${ifDefined(this.rootClass)}" part="menu-item">${ this.iconStart ? block12.call(this, context, tags, suffix) : undefined }${ifDefined(this.label)}${ this.iconEnd ? block13.call(this, context, tags, suffix) : undefined }</span>`;}
function block12 (this: MastheadMenuItem, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<${scopeTag("ui5-icon", tags, suffix)} class="udex-masthead-menu-item__icon udex-masthead-menu-item_icon-left" name="slim-arrow-left" part="left"></${scopeTag("ui5-icon", tags, suffix)}>` : html`<ui5-icon class="udex-masthead-menu-item__icon udex-masthead-menu-item_icon-left" name="slim-arrow-left" part="left"></ui5-icon>`;}
function block13 (this: MastheadMenuItem, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<${scopeTag("ui5-icon", tags, suffix)} class="udex-masthead-menu-item__icon udex-masthead-menu-item_icon-right" name="slim-arrow-right" part="right"></${scopeTag("ui5-icon", tags, suffix)}>` : html`<ui5-icon class="udex-masthead-menu-item__icon udex-masthead-menu-item_icon-right" name="slim-arrow-right" part="right"></ui5-icon>`;}


export default block0;