import UI5Element, { ChangeInfo } from "@ui5/webcomponents-base/dist/UI5Element.js";
import customElement from "@ui5/webcomponents-base/dist/decorators/customElement.js";
import property from "@ui5/webcomponents-base/dist/decorators/property.js";
import event from "@ui5/webcomponents-base/dist/decorators/event.js";
import slot from "@ui5/webcomponents-base/dist/decorators/slot.js";
import litRender from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import { isDesktop } from "@ui5/webcomponents-base/dist/Device.js";
import { getI18nBundle } from "@ui5/webcomponents-base/dist/i18nBundle.js";
import Integer from "@ui5/webcomponents-base/dist/types/Integer.js";
import {
  isLeft,
  isRight,
  isEscape,
} from "@ui5/webcomponents-base/dist/Keys.js";
import ResizeHandler from "@ui5/webcomponents-base/dist/delegate/ResizeHandler.js";
import UDExSecondaryNavigationItem from "./SecondaryNavigationItem.js";
import UDExSecondaryNavigationSubItem from "./SecondaryNavigationSubItem.js";
import UDExSecondaryNavigationItemBase, { NavItem } from "./SecondaryNavigationItemBase.js";
import { MORE } from "./generated/i18n/i18n-defaults.js";

import SecondaryNavigationTemplate from "./generated/templates/SecondaryNavigationTemplate.lit.js";

// Styles
import SecondaryNavigationCss from "./generated/themes/SecondaryNavigation.css.js";

const canvasContentFont: string = "16px / 17.6px '72 Brand Variable'";
const breakpointSizeS: number = 979;

export type SecondaryNavigationItemClickDetail = {
  originalEvent: Event,
  clickedItem: UDExSecondaryNavigationItem;
  clickedIndex: number;
};

export type SecondaryNavigationSubItemClickDetail = {
  originalEvent: Event,
  clickedItem: {
    items: Array<NavItem>
  };
}

/**
 * @class
 *
 * <h3 class="comment-api-title">Overview</h3>
 *
 *
 * <h3>Usage</h3>
 *
 * For the <code>udex-secondary-navigation</code>
 * <h3>ES6 Module Import</h3>
 *
 * <code>import "@udex/web-components/dist/SecondaryNavigation.js";</code>
 *
 * @constructor
 * @extends UI5Element
 * @public
 */
@customElement({
  tag: "udex-secondary-navigation",
  renderer: litRender,
  styles: SecondaryNavigationCss,
  template: SecondaryNavigationTemplate,
  dependencies: [UDExSecondaryNavigationItem],
})

/**
 * Fires when item is clicked
 *
 * @allowPreventDefault
 * @public
 */
@event<SecondaryNavigationItemClickDetail>("item-click", {
  detail: {
    originalEvent: {
      type: Event,
    },
    clickedItem: {
      type: HTMLElement,
    },
    clickedIndex: {
      type: Integer,
    },
  },
})

/**
 * Fires when sub item is clicked
 *
 * @allowPreventDefault
 * @public
 */
@event<SecondaryNavigationSubItemClickDetail>("sub-item-click", {
  detail: {
    originalEvent: {
      type: Event,
    },
    clickedItem: {
      type: HTMLElement,
    },
  },
})

class UDExSecondaryNavigation extends UI5Element {
  /**
   * Defines an aria label for the mobile menu opener
   *
   * @public
   */
  @property({ type: String })
    mobileMenuOpenerAriaLabel!: string;

  /**
   * List of the nav items
   *
   * @default "[]"
   * @public
   */
  @property({ type: String, defaultValue: "[]" })
    navItems!: string;

  /**
   * Slot for breadcrumbs.
   *
   * @slot breadcrumbs
   * @public
   */
  @slot({ type: HTMLElement })
    breadcrumbs!: Array<HTMLElement>;

  /**
   * Slot for action buttons.
   *
   * @slot buttons
   * @public
   */
  @slot({ type: HTMLElement })
    buttons!: Array<HTMLElement>;

  /**
   * Defines the title of the component.
   *
   * @default ""
   * @public
   */
  @property()
    title!: string;

  /**
   * Defines the href of the title.
   *
   * @default ""
   * @public
   */
  @property()
    titleHref!: string;

  @property({ type: Boolean, noAttribute: true })
    _selectedMore!: boolean;

  @property({ type: Object, multiple: true, noAttribute: true })
    _overflowItems!: Array<NavItem>;

  @property({ type: Object, multiple: true, noAttribute: true })
    _visibleItems!: Array<NavItem>;

  @property({ type: Boolean, noAttribute: true })
    _requestRender = false;

  @property({ type: Boolean, noAttribute: true })
    _mobileBtnExpanded = false;

  _navItems!: Array<NavItem>;
  _showMoreButton: boolean;
  _rootHolder!: HTMLElement | null | undefined;
  _clickedNavItem!: UDExSecondaryNavigationItem;
  _boundReflowItems: () => void;

  static i18nBundle: any;

  static async onDefine(): Promise<void> {
    UDExSecondaryNavigation.i18nBundle = await getI18nBundle("udex-secondary-navigation");
  }

  constructor() {
    super();
    this._showMoreButton = true;
    this._boundReflowItems = this.reflowItems.bind(this);
  }

  onEnterDOM(): void {
    if (isDesktop()) {
      this.setAttribute("desktop", "");
    }

    ResizeHandler.register(this, this._boundReflowItems);
  }

  onExitDOM(): void {
    ResizeHandler.deregister(this, this._boundReflowItems);
  }

  onInvalidation(changeInfo: ChangeInfo): void {
    if (changeInfo.name === "navItems") {
      this.reflowItems();
    }
  }

  onAfterRendering(): void {
    this._rootHolder = this.rootHolder;
    this.handleOutsideClick();
  }

  refreshRendering(): void {
    this._requestRender = !this._requestRender;
  }

  reflowItems(): void {
    const availableWidth = this.rootHolder.offsetWidth - this.heading?.offsetWidth - (this.optionalButtons?.offsetWidth || 0);
    const moreButtonWidth = this.getMoreButtonWidth();
    const itemsGap = 20;
    const widths = this.parsedItems.map(item => this.getItemWidth(item));
    const steps: Array<number> = [];

    widths?.forEach((itemWidth, index) => {
      steps.push((steps.at(-1) || 0) + itemWidth + (index && itemsGap));
    });

    if ((steps.at(-1) || 0) <= availableWidth) {
      this._visibleItems.splice(0, Infinity, ...this.parsedItems);
      this._showMoreButton = false;
      this.refreshRendering();
      return;
    }

    if (this.parsedItems) {
      // @ts-ignore
      const lastVisibleIndex: number = steps.findLastIndex((size: number) => size < (availableWidth - moreButtonWidth - itemsGap));
      this._visibleItems.splice(0, Infinity, ...this.parsedItems.slice(0, lastVisibleIndex + 1));
      this._overflowItems.splice(0, Infinity, ...this.parsedItems.slice(lastVisibleIndex + 1));
      this._showMoreButton = true;
      this.refreshRendering();
    }
  }

  handleKeyDown(e: KeyboardEvent): void {
    e.stopPropagation();
    if (isLeft(e)) {
      this.focusPrevNavItem(e);
    }
    if (isRight(e)) {
      this.focusNextNavItem(e);
    }
    if (isEscape(e)) {
      (e.target as UDExSecondaryNavigationItem).closeDropdown();
    }
  }

  focusPrevNavItem(e: KeyboardEvent): void {
    const item = e.target as UDExSecondaryNavigationItem;
    const currentSelectedIndex = this.getCurrentIndex(item);
    const lastItemIndex = this.domNavItems.length - 1;
    const previousSelectIndex = currentSelectedIndex - 1 < 0 ? lastItemIndex : currentSelectedIndex - 1;
    this.domNavItems[previousSelectIndex]?.focus();
  }

  focusNextNavItem(e: KeyboardEvent): void {
    const item = e.target as UDExSecondaryNavigationItem;
    const currentSelectedIndex = this.getCurrentIndex(item);
    const nextSelectIndex = currentSelectedIndex + 1 > this.domNavItems.length - 1 ? 0 : currentSelectedIndex + 1;
    this.domNavItems[nextSelectIndex].focus();
  }

  getItemWidth(item: NavItem): number {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d")!;
    context.font = canvasContentFont;

    return Math.ceil(context.measureText(item.title).width);
  }

  getMoreButtonWidth(): number {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d")!;
    context.font = canvasContentFont;

    return Math.ceil(context.measureText(this.moreButtonText).width);
  }

  getCurrentIndex(item: UDExSecondaryNavigationItem): number {
    return this.domNavItems.indexOf(item);
  }

  handleItemClick(e: CustomEvent): void {
    const clickedItem = e.detail.item as UDExSecondaryNavigationItem;
    const prevented = !this.fireEvent("item-click", {
      originalEvent: e,
      clickedItem,
      clickedIndex: this.getCurrentIndex(clickedItem),
    }, true);

    if (prevented) {
      e.preventDefault();
      return;
    }

    this._clickedNavItem = clickedItem;
    this.closePrevDropdown(clickedItem._id);

    if (!clickedItem.hasNestedItems) {
      this.setActiveItem(this.parsedItems, clickedItem);
    }
  }

  handleSubItemClick(e: CustomEvent): void {
    const item = e.detail.item as UDExSecondaryNavigationSubItem;
    const { items } = item as NavItem;
    const prevented = !this.fireEvent("sub-item-click", {
      originalEvent: e,
      clickedItem: item,
    }, true);

    if (prevented) {
      e.preventDefault();
      return;
    }

    if (this.isMobileScreen && !items) {
      this.mobileNav.closeDropdown();
    }

    if (!items) {
      this.setActiveItem(this.parsedItems, item);
      this._clickedNavItem?.closeDropdown();
    }
  }

  setActiveItem(navItems: Array<NavItem>, clickedItem: UDExSecondaryNavigationItemBase): void {
    const updateNav = (items: Array<NavItem>, { title, path }: UDExSecondaryNavigationItemBase) => {
      items.forEach(item => {
        if (item.title === title && item.path === path) {
          item.active = true;
          return;
        }
        delete item.active;

        if (item.items) {
          updateNav(item.items, clickedItem);
        }
      });
    };

    updateNav(navItems, clickedItem);
    this.navItems = JSON.stringify(navItems);
  }

  handleOutsideClick(): void {
    document.addEventListener("click", (e: MouseEvent) => {
      if (this.isMobileScreen && e.target !== this) {
        this.mobileNav?.closeDropdown();
        return;
      }

      const isClickedNavItem = e.composedPath().includes(this._clickedNavItem);
      if (this.isDesktopScreen && !isClickedNavItem && this._clickedNavItem) {
        this._clickedNavItem.closeDropdown();
      }
    });
  }

  closePrevDropdown(clickedItemId: string): void {
    this.domNavItems.forEach(item => {
      if (clickedItemId !== item._id) {
        item.closeDropdown();
      }
    });
  }

  parseArrayFromString<T>(string: string): T[] {
    return string ? JSON.parse(string) as T[] : [];
  }

  get domNavItems(): Array<UDExSecondaryNavigationItem> {
    const items = this.shadowRoot?.querySelectorAll<UDExSecondaryNavigationItem>("udex-secondary-navigation-item");
    return items ? Array.from(items) : [];
  }

  get rootHolder(): HTMLElement {
    return (this.shadowRoot ? this.shadowRoot.querySelector<HTMLElement>(".udex-secondary-navigation__root") : undefined)!;
  }

  get heading(): HTMLElement {
    return (this.shadowRoot ? this.shadowRoot.querySelector<HTMLElement>(".udex-secondary-navigation__heading") : undefined)!;
  }

  get mobileNav(): UDExSecondaryNavigationItem {
    return (this.shadowRoot ? this.shadowRoot.querySelector<UDExSecondaryNavigationItem>(".udex-secondary-navigation__mobile-nav") : undefined)!;
  }

  get optionalButtons(): HTMLElement | null | undefined {
    return this.shadowRoot?.querySelector<HTMLElement>(".udex-secondary-navigation__buttons");
  }

  get moreButtonText(): string {
    return UDExSecondaryNavigation.i18nBundle.getText(MORE) as string;
  }

  get parsedItems(): Array<NavItem> {
    return this.parseArrayFromString<NavItem>(this.navItems);
  }

  get visibleItems(): Array<NavItem> {
    return this.isDesktopScreen ? this._visibleItems : this.parsedItems;
  }

  get hasBreadcrumbsSlot(): boolean {
    return !!this.getSlottedNodes("breadcrumbs").length && this.isDesktopScreen;
  }

  get hasDesktopButtonsSlot(): boolean {
    return this.hasButtons && this.isDesktopScreen;
  }

  get hasMobileButtonsSlot(): boolean {
    return this.hasButtons && this.isMobileScreen;
  }

  get hasButtons(): boolean {
    return !!this.getSlottedNodes("buttons").length;
  }

  get isDesktopScreen(): boolean {
    return window.innerWidth > breakpointSizeS;
  }

  get isMobileScreen(): boolean {
    return window.innerWidth <= breakpointSizeS;
  }
}

UDExSecondaryNavigation.define();

export default UDExSecondaryNavigation;
