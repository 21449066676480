import type { StyleData } from "@ui5/webcomponents-base/dist/types.js";
import { registerThemePropertiesLoader } from "@ui5/webcomponents-base/dist/asset-registries/Themes.js";

import defaultThemeBase from "@ui5/webcomponents-theming/dist/generated/themes/sap_horizon/parameters-bundle.css.js";
import defaultTheme from "./sap_horizon/parameters-bundle.css.js";

registerThemePropertiesLoader("@ui5/webcomponents-theming", "sap_horizon", async () => defaultThemeBase);
registerThemePropertiesLoader("@sapudex/web-components", "sap_horizon", async () => defaultTheme);

const styleData: StyleData = {packageName:"@sapudex/web-components",fileName:"themes/TableCellBase.css.ts",content:`:host{--udex-table-cell-sticky-shadow: 0 4px 8px rgba(34,54,73,.3)}:host{display:flex;align-items:center;box-sizing:border-box;border-bottom-width:1px;border-bottom-style:solid;position:relative}:host([_sticky]){position:sticky;left:0;z-index:1}:host([_sticky]:dir(rtl)){right:0}:host([_horizontal-offset]){box-shadow:var(--udex-table-cell-sticky-shadow)}
`};
export default styleData;
	