import customElement from "@ui5/webcomponents-base/dist/decorators/customElement.js";
import property from "@ui5/webcomponents-base/dist/decorators/property.js";

import RatingIndicator from "@ui5/webcomponents/dist/RatingIndicator.js";
import litRender from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import Icon from "@ui5/webcomponents/dist/Icon.js";
import { getEffectiveAriaLabelText } from "@ui5/webcomponents-base/dist/util/AriaLabelHelper.js";
import RatingIndicatorTemplate from "./generated/templates/RatingIndicatorTemplate.lit.js";

// Styles
import RatingIndicatorCss from "./generated/themes/RatingIndicator.css.js";
import { TOTAL } from "./generated/i18n/i18n-defaults.js";

export enum DisplayType {
  Desktop = "Desktop",
  Mobile = "Mobile"
}

/**
 * @class
 *
 * ### Overview
 *
 * ### ES6 Module Import
 *
 * `import @udex/web-components/dist/RatingIndicator.js";`
 * @constructor
 * @extends RatingIndicator
 * @public
 */

@customElement({
  tag: "udex-rating-indicator",
  renderer: litRender,
  styles: [RatingIndicator.styles, RatingIndicatorCss],
  template: RatingIndicatorTemplate,
  dependencies: [Icon],
})

class UDExRatingIndicator extends RatingIndicator {
  /**
   * Defines the display type of the component.
   *
   * @default "Desktop"
   * @public
   */
  @property({ defaultValue: DisplayType.Desktop })
    display!: `${DisplayType}`;

  /**
    * The number of total votes.
    *
    * @public
    */
  @property()
    totalCount!: number;

  get classNames(): string {
    return `udex-rating-indicator--${this.display.toLowerCase()}`;
  }

  get _ariaLabel() {
    const ariaLabelText = getEffectiveAriaLabelText(this);
    if (this.totalCount) {
      return `${ariaLabelText ? `${ariaLabelText}, ` : ""}${RatingIndicator.i18nBundle.getText(TOTAL)} ${this.totalCount}`;
    }
    return ariaLabelText;
  }
}

UDExRatingIndicator.define();

export default UDExRatingIndicator;
