import customElement from "@ui5/webcomponents-base/dist/decorators/customElement.js";
import property from "@ui5/webcomponents-base/dist/decorators/property.js";
import litRender from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import TableHeaderCellTemplate from "./generated/templates/TableHeaderCellTemplate.lit.js";
import UDExTableCellBase from "./TableCellBase.js";

// Styles
import TableHeaderCellCss from "./generated/themes/TableHeaderCell.css.js";

/**
 * @class
 *
 * <h3 class="comment-api-title">Overview</h3>
 *
 *
 * <h3>Usage</h3>
 *
 * For the <code>udex-table-header-cell</code>
 * <h3>ES6 Module Import</h3>
 *
 * <code>import "@udex/web-components/dist/TableHeaderCell.js";</code>
 *
 * @constructor
 * @extends UDExTableCellBase
 * @public
 */
@customElement({
  tag: "udex-table-header-cell",
  renderer: litRender,
  styles: [UDExTableCellBase.styles, TableHeaderCellCss],
  template: TableHeaderCellTemplate,
  dependencies: [...UDExTableCellBase.dependencies],
})

class UDExTableHeaderCell extends UDExTableCellBase {
  /**
   * Defines highlighted the header cell.
   *
   * @default false
   * @public
   */
  @property({ type: Boolean })
    highlighted?: boolean;

  /**
   * Defines the name of the UI5 Icon, that will be displayed at the beginning of the content.
   *
   * @default ""
   * @public
   */
  @property({ type: String, defaultValue: "" })
    iconStart!: string;

  /**
   * Defines the name of the UI5 Icon, that will be displayed at the end of the content.
   *
   * @default ""
   * @public
   */
  @property({ type: String, defaultValue: "" })
    iconEnd!: string;

  protected ariaRole: string = "columnheader";

  onBeforeRendering() {
    super.onBeforeRendering();
    this.style.justifyContent = `var(--horizontal-align-${this._individualSlot})`;
  }

  get _hasIcons(): boolean {
    return this.iconStart.length > 0 || this.iconEnd.length > 0;
  }
}

UDExTableHeaderCell.define();

export default UDExTableHeaderCell;
