import customElement from "@ui5/webcomponents-base/dist/decorators/customElement.js";
import UDExListGroupHeaderBase from "./ListGroupHeaderBase.js";

/**
 * @class
 *
 * <h3 class="comment-api-title">Overview</h3>
 *
 *
 * <h3>Usage</h3>
 *
 * For the <code>udex-select-box-select-all</code>
 * <h3>ES6 Module Import</h3>
 *
 * <code>import "@udex/web-components/dist/SelectBoxSelectAll.js";</code>
 *
 * @constructor
 * @author SAP SE
 * @extends UDExListGroupHeaderBase
 * @tagname udex-select-box-select-all
 * @public
 */
@customElement({
  tag: "udex-select-box-select-all",
})

class UDExSelectBoxSelectAll extends UDExListGroupHeaderBase {
  get isSelectAllHeader() {
    return true;
  }
}

UDExSelectBoxSelectAll.define();

export default UDExSelectBoxSelectAll;
