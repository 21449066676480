import customElement from "@ui5/webcomponents-base/dist/decorators/customElement.js";
import litRender from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import Slider from "@ui5/webcomponents/dist/Slider.js";
import SliderBase from "@ui5/webcomponents/dist/SliderBase.js";
import { isDesktop } from "@ui5/webcomponents-base/dist/Device.js";

// Styles
import SliderCss from "./generated/themes/Slider.css.js";

/**
 * @class
 *
 * <h3 class="comment-api-title">Overview</h3>
 *
 *
 * <h3>Usage</h3>
 *
 * For the <code>udex-slider</code>
 * <h3>ES6 Module Import</h3>
 *
 * <code>import "@udex/web-components/dist/Slider.js";</code>
 *
 * @constructor
 * @extends Slider
 * @public
 */
@customElement({
  tag: "udex-slider",
  renderer: litRender,
  styles: [SliderBase.styles, SliderCss],
  dependencies: [],
})
class UDExSlider extends Slider {
  onEnterDOM(): void {
    if (isDesktop()) {
      this.setAttribute("desktop", "");
    }
  }
}

UDExSlider.define();

export default UDExSlider;
