/* eslint no-unused-vars: 0 */
import { html, svg, repeat, classMap, styleMap, ifDefined, unsafeHTML, scopeTag } from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import type UI5Element from "@ui5/webcomponents-base/dist/UI5Element.js";
	import type SearchSuggestions from "../../SearchSuggestions.js";
	import type { ClassMapValue } from "@ui5/webcomponents-base/dist/types.js";
	
function block0 (this: SearchSuggestions, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<${scopeTag("ui5-popover", tags, suffix)} class="udex-search-suggestions__root ${ifDefined(this.homeSearchPopoverClass)}" hide-arrow _disable-initial-focus placement-type="Bottom" horizontal-align="Right" style="${styleMap(this.popoverStyles)}" id="${ifDefined(this.getSuggestionsPopoverId)}" aria-orientation="vertical" accessible-name="${ifDefined(this.suggestionsAccessibleName)}">${ this.showNoResultMessage ? block1.call(this, context, tags, suffix) : block2.call(this, context, tags, suffix) }</${scopeTag("ui5-popover", tags, suffix)}>` : html`<ui5-popover class="udex-search-suggestions__root ${ifDefined(this.homeSearchPopoverClass)}" hide-arrow _disable-initial-focus placement-type="Bottom" horizontal-align="Right" style="${styleMap(this.popoverStyles)}" id="${ifDefined(this.getSuggestionsPopoverId)}" aria-orientation="vertical" accessible-name="${ifDefined(this.suggestionsAccessibleName)}">${ this.showNoResultMessage ? block1.call(this, context, tags, suffix) : block2.call(this, context, tags, suffix) }</ui5-popover>`;}
function block1 (this: SearchSuggestions, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<span class="udex-search-suggestions__message">${ifDefined(this.noResultMessage)}</span>`;}
function block2 (this: SearchSuggestions, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<div role="tree">${ this.isShowSuggestedList ? block3.call(this, context, tags, suffix) : undefined }${ this.isShowRecommendedList ? block6.call(this, context, tags, suffix) : undefined }</div>`;}
function block3 (this: SearchSuggestions, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<div class="udex-search-suggestions__block" role="treeitem">${ this.suggestedHeading ? block4.call(this, context, tags, suffix) : undefined }<div class="udex-search-suggestions__list" role="listbox" aria-labelledby="suggested-heading" tabindex="0" @keydown="${this.handleKeyDownPopover}">${ repeat(this.suggestedList, (item, index) => (item as typeof item & {_id?: any})._id || index, (item, index: number) => block5.call(this, context, tags, suffix, item, index)) }</div></div>`;}
function block4 (this: SearchSuggestions, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<span class="udex-search-suggestions__heading" id="suggested-heading">${ifDefined(this.suggestedHeading)}</span>`;}
function block5 (this: SearchSuggestions, context: UI5Element, tags: string[], suffix: string | undefined, item: any, index: number) { return suffix ? html`<${scopeTag("ui5-button", tags, suffix)} id="${ifDefined(item.id)}" icon="${ifDefined(item.icon)}" type="Button" design="Default" class="udex-search-suggestions__item-button" title="${ifDefined(item.title)}" tabindex="-1" role="option" @click="${item.handleSelectItem}">${unsafeHTML(item.text)}</${scopeTag("ui5-button", tags, suffix)}>` : html`<ui5-button id="${ifDefined(item.id)}" icon="${ifDefined(item.icon)}" type="Button" design="Default" class="udex-search-suggestions__item-button" title="${ifDefined(item.title)}" tabindex="-1" role="option" @click="${item.handleSelectItem}">${unsafeHTML(item.text)}</ui5-button>`;}
function block6 (this: SearchSuggestions, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<div class="udex-search-suggestions__block" role="treeitem">${ this.recommendedHeading ? block7.call(this, context, tags, suffix) : undefined }<div class="udex-search-suggestions__list" role="listbox" aria-labelledby="recommended-heading" tabindex="0" @keydown="${this.handleKeyDownPopover}">${ repeat(this.recommendedList, (item, index) => (item as typeof item & {_id?: any})._id || index, (item, index: number) => block8.call(this, context, tags, suffix, item, index)) }</div></div>`;}
function block7 (this: SearchSuggestions, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<span class="udex-search-suggestions__heading" id="recommended-heading">${ifDefined(this.recommendedHeading)}</span>`;}
function block8 (this: SearchSuggestions, context: UI5Element, tags: string[], suffix: string | undefined, item: any, index: number) { return html`${ item.hasCustomIcon ? block9.call(this, context, tags, suffix, item, index) : block10.call(this, context, tags, suffix, item, index) }`;}
function block9 (this: SearchSuggestions, context: UI5Element, tags: string[], suffix: string | undefined, item: any, index: number) { return suffix ? html`<${scopeTag("ui5-button", tags, suffix)} id="${ifDefined(item.id)}" icon="${ifDefined(item.icon)}" type="Button" design="Default" class="udex-search-suggestions__item-button" title="${ifDefined(item.title)}" tabindex="-1" role="option" @click="${item.handleSelectItem}">${unsafeHTML(item.text)}</${scopeTag("ui5-button", tags, suffix)}>` : html`<ui5-button id="${ifDefined(item.id)}" icon="${ifDefined(item.icon)}" type="Button" design="Default" class="udex-search-suggestions__item-button" title="${ifDefined(item.title)}" tabindex="-1" role="option" @click="${item.handleSelectItem}">${unsafeHTML(item.text)}</ui5-button>`;}
function block10 (this: SearchSuggestions, context: UI5Element, tags: string[], suffix: string | undefined, item: any, index: number) { return suffix ? html`<${scopeTag("ui5-button", tags, suffix)} id="${ifDefined(item.id)}" type="Button" design="Default" class="udex-search-suggestions__item-button" title="${ifDefined(item.title)}" tabindex="-1" role="option" @click="${item.handleSelectItem}">${unsafeHTML(item.text)}</${scopeTag("ui5-button", tags, suffix)}>` : html`<ui5-button id="${ifDefined(item.id)}" type="Button" design="Default" class="udex-search-suggestions__item-button" title="${ifDefined(item.title)}" tabindex="-1" role="option" @click="${item.handleSelectItem}">${unsafeHTML(item.text)}</ui5-button>`;}


export default block0;