import UI5Element from "@ui5/webcomponents-base/dist/UI5Element.js";
import customElement from "@ui5/webcomponents-base/dist/decorators/customElement.js";
import property from "@ui5/webcomponents-base/dist/decorators/property.js";
import slot from "@ui5/webcomponents-base/dist/decorators/slot.js";
import event from "@ui5/webcomponents-base/dist/decorators/event.js";
import UI5Icon from "@ui5/webcomponents/dist/Icon.js";
import litRender from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import ResizeHandler, { ResizeObserverCallback } from "@ui5/webcomponents-base/dist/delegate/ResizeHandler.js";
import { isLeft, isRight } from "@ui5/webcomponents-base/dist/Keys.js";
import "@ui5/webcomponents-icons/dist/decline.js";
import "@ui5/webcomponents-icons/dist/menu2.js";
import "@ui5/webcomponents-icons/dist/search.js";
import "@ui5/webcomponents-icons/dist/cart.js";
import UDExMastheadItem from "./MastheadItem.js";
import UDExMastheadMenu from "./MastheadMenu.js";
import UDExControlButton from "./ControlButton.js";
import UDExSearch from "./Search.js";
import UDExMastheadMobileMenu from "./MastheadMobileMenu.js";

import MastheadShellTemplate from "./generated/templates/MastheadShellTemplate.lit.js";

// Styles
import MastheadShellCss from "./generated/themes/MastheadShell.css.js";

const mobileMenuActiveClass: string = "udex-masthead-shell_mobile-menu-open";
/**
 * @class
 *
 * <h3 class="comment-api-title">Overview</h3>
 *
 *
 * <h3>Usage</h3>
 *
 * For the <code>udex-masthead-shell</code>
 * <h3>ES6 Module Import</h3>
 *
 * <code>import "@udex/web-components/dist/MastheadShell.js";</code>
 *
 * @constructor
 * @extends UI5Element
 * @public
 */
@customElement({
  tag: "udex-masthead-shell",
  renderer: litRender,
  styles: MastheadShellCss,
  template: MastheadShellTemplate,
  dependencies: [
    UDExMastheadMobileMenu,
    UDExControlButton,
    UDExMastheadItem,
    UDExMastheadMenu,
    UI5Icon,
  ],
})

/**
 * Fired event when the user clicks the search button trigger.
 *
 * @event sap.ui.webc.web-components.UDExMastheadShell#searchClick
 * @public
 */
@event("search-click")

/**
 * Fired event when the user clicks the cart button trigger.
 *
 * @event sap.ui.webc.web-components.UDExMastheadShell#cartClick
 * @public
 */
@event("cart-click")

/**
 * Fired event when the user clicks the profile button trigger.
 *
 * @event sap.ui.webc.web-components.UDExMastheadShell#profileClick
 * @public
 */
@event("profile-click")
class UDExMastheadShell extends UI5Element {
  /**
   * Slot for mobile menu
   *
   * @public
   */
  @slot({ type: HTMLElement })
    mobileMenu!: Array<Node>;

  /**
   * Slot for main menu items.
   *
   * @public
   */
  @slot({ type: HTMLElement, individualSlots: true, "default": true })
    menuItems!: Array<UDExMastheadItem>;

  /**
   * Slot for search component - will be visible on search icon click.
   * Note: search component should be hidden by default.
   * Note: search icon won't be visible if this slot would be empty
   *
   * @public
   */
  @slot({ type: Node })
    search!: Array<Node>;

  /**
   * Slot for 'Explore SAP' menu
   *
   * @public
   */
  @slot({ type: HTMLElement })
    exploreSAP!: Array<UDExMastheadItem>;

  /**
   * Slot for cart menu
   *
   * @public
   */
  @slot({ type: HTMLElement })
    cart!: Array<UDExMastheadItem>;

  /**
   * Slot for avatar
   *
   * @public
   */
  @slot({ type: HTMLElement })
    profile!: Array<UDExMastheadItem>;

  /**
   * Defines URL to homepage. It will be used as a link on a logo and a site label
   *
   * @default ""
   * @public
   */
  @property({ type: String, defaultValue: "" })
    homeUrl!: string;

  /**
   * Defines URL for site label. It will be used as a link on a logo and a site label
   *
   * @default ""
   * @public
   */
  @property({ type: String })
    siteLabelUrl!: string;

  /**
   * Defines overflow style for the body after clicking mobile menu opener button
   *
   * @default ""
   * @public
   */
  @property({ type: Boolean })
    bodyOverflow!: boolean;

  /**
   * Defines sticky position for component
   *
   * @default ""
   * @public
   */
  @property({ type: Boolean })
    stickyPosition!: boolean;

  /**
   * Defines the Logo image URL
   *
   * @default ""
   * @public
   */
  @property({ type: String, defaultValue: "" })
    logoUrl!: string;

  /**
   * Defines a site label. It will be displayed next to a logo.
   *
   * @default ""
   * @public
   */
  @property({ type: String, defaultValue: "" })
    siteLabel!: string;

  /**
   * Defines an alt text for logo
   *
   * @public
   */
  @property({ type: String })
    logoAlt!: string;

  /**
   * Defines an accessible name for close icon for the search bar
   *
   * default: ""
   * @public
   */
  @property({ type: String, defaultValue: "" })
    closeButtonAccessibleName?: string;

  /**
   * Defines an aria label for the homepage link
   *
   * @public
   */
  @property({ type: String })
    homepageAriaLabel!: string;

  /**
   * Defines an aria label for the site label
   *
   * @public
   */
  @property({ type: String })
    siteLabelAriaLabel!: string;

  /**
   * Defines if the search container is visible
   *
   * @private
   */
  @property({ type: Boolean })
    _isSearchVisible!: boolean;

  /**
   * Defines if the close button was clicked
   *
   * @private
   */
  @property({ type: Boolean })
    _closeBtnClicked!: boolean;

  _searchOpener!: UDExMastheadItem | null | undefined;
  _mobileMenu!: UDExMastheadMobileMenu | null | undefined;
  _handleResizeBound: ResizeObserverCallback;
  _rootHolder!: HTMLElement | null | undefined;
  _shouldHandleResize!: boolean;

  constructor() {
    super();
    this._handleResizeBound = this._handleResize.bind(this);
  }

  onAfterRendering() {
    this._rootHolder = this.mastheadHolder;
    this._searchOpener = this.searchOpener;
    this._mobileMenu = this.mastheadMobileMenu;

    this.handleOutsideClick();

    if (this._isSearchVisible) {
      this.focusSearchInput();
    }

    if (this._closeBtnClicked) {
      this.focusSearchBtn();
    }
  }

  onEnterDOM(): void {
    ResizeHandler.register(this, this._handleResizeBound);
  }

  onExitDOM(): void {
    ResizeHandler.deregister(this, this._handleResizeBound);
  }

  _handleResize(): void {
    if (this._shouldHandleResize) {
      this._mobileMenu?.showFirstLevelMenu();
      this.removeOverflowBody();
      return;
    }
    this._shouldHandleResize = true;
  }

  handleKeyDown(e: KeyboardEvent) {
    e.stopPropagation();
    this.handleLeftArrow(e);
    this.handleRightArrow(e);
  }

  handleLeftArrow(e: KeyboardEvent): void {
    if (isLeft(e)) {
      const currentItem = e.target as UDExMastheadItem;
      if (this.isNavigationItem(currentItem)) {
        this.focusPreviousMenuItem(currentItem);
      }
    }
  }

  handleRightArrow(e: KeyboardEvent): void {
    if (isRight(e)) {
      const currentItem = e.target as UDExMastheadItem;
      if (this.isNavigationItem(currentItem)) {
        this.focusNextMenuItem(currentItem);
      }
    }
  }

  handleSearchClick(e: CustomEvent): void {
    e.stopPropagation();
    this._isSearchVisible = true;
    this.resetSelectedItems();
    if (this._searchOpener) {
      this._searchOpener.selected = false;
    }
    this.fireEvent("search-click");
  }

  handleCartClick(e: CustomEvent) {
    e.stopPropagation();
    this.setSelectedItem(e);
    this.fireEvent("cart-click");
  }

  handleProfileClick(e: CustomEvent): void {
    e.stopPropagation();
    this.setSelectedItem(e);
    this.fireEvent("profile-click");
  }

  handleMobileMenuClick(e: CustomEvent) {
    e.stopPropagation();
    this.setSelectedItem(e);
    this.addActiveMobileClass();

    if (this.bodyOverflow) {
      this.setOverflowBody();
    }
  }

  handleMobileCloseClick(e: CustomEvent) {
    e.preventDefault();
    this.removeActiveMobileClass();
    this.setSelectedItem(e);
    this._mobileMenu?.showFirstLevelMenu();

    if (this.bodyOverflow) {
      this.removeOverflowBody();
    }
  }

  handleOutsideClick(): void {
    document.addEventListener("click", (e: MouseEvent) => {
      const el = e.target as UDExMastheadItem;
      const isMobile = window.innerWidth < 1280;
      const isDropdownSlot = el.getAttribute("slot") === "dropdown";
      if (!isDropdownSlot && !el.isMenuItem && !isMobile) {
        this.resetSelectedItems();
      }
    });
  }

  focusSearchInput(): void {
    const searchSlot = this.querySelector<UDExSearch>("udex-search");
    if (searchSlot) {
      const input = searchSlot.shadowRoot!.querySelector<HTMLElement>("input");
      input?.focus();
    }
  }

  focusPreviousMenuItem(item: UDExMastheadItem): void {
    const currentSelectedIndex = this.getCurrentIndex(item);
    const lastItemIndex = this.menuItems.length - 1;
    const previousSelectIndex = currentSelectedIndex - 1 < 0 ? lastItemIndex : currentSelectedIndex - 1;
    this.menuItems[previousSelectIndex].focus();
  }

  focusNextMenuItem(item: UDExMastheadItem): void {
    const currentSelectedIndex = this.getCurrentIndex(item);
    const nextSelectIndex = currentSelectedIndex + 1 > this.menuItems.length - 1 ? 0 : currentSelectedIndex + 1;
    this.menuItems[nextSelectIndex].focus();
  }

  resetSelectedItems(): void {
    this.getSelectedItems().forEach(item => {
      item.selected = false;
    });
  }

  addActiveMobileClass() {
    this._rootHolder?.classList.add(mobileMenuActiveClass);
  }

  removeActiveMobileClass() {
    this._rootHolder?.classList.remove(mobileMenuActiveClass);
  }

  setSelectedItem(e: CustomEvent): void {
    const clickedItem: UDExMastheadItem = e.detail.item;
    if (!clickedItem) {
      return;
    }

    this.getSelectedItems().forEach(item => {
      if (item._id !== clickedItem._id) {
        item.selected = false;
      }
    });
  }

  focusSearchBtn(): void {
    if (this._searchOpener) {
      this._searchOpener.focus();
    }
  }

  hideSearch(): void {
    this._isSearchVisible = false;
    this._closeBtnClicked = true;
  }

  getCurrentIndex(item: UDExMastheadItem): number {
    return this.menuItems.indexOf(item);
  }

  getSelectedItems(): Array<UDExMastheadItem> {
    const buttonsArray: Array<UDExMastheadItem> = [
      ...this.menuItems,
      ...this.exploreSAP,
      ...this.cart,
      ...this.profile,
      ...(this.mobileBtn && this.closeBtn ? [this.mobileBtn, this.closeBtn] : []),
    ];

    return buttonsArray.filter(({ selected }) => selected);
  }

  isNavigationItem(item: UDExMastheadItem): boolean {
    return this.menuItems.some(menuItem => menuItem === item);
  }

  setOverflowBody() {
    const { body: { style } } = document;
    this._shouldHandleResize = false;
    style.overflow = "hidden";
  }

  removeOverflowBody() {
    const { body: { style } } = document;
    style.removeProperty("overflow");
  }

  get searchOpener(): UDExMastheadItem | null | undefined {
    return this.shadowRoot?.querySelector(".udex-masthead-shell__search-btn");
  }

  get mastheadHolder(): HTMLElement | null | undefined {
    return this.shadowRoot?.querySelector(".udex-masthead-shell");
  }

  get mastheadMobileMenu(): UDExMastheadMobileMenu | null {
    return this.querySelector("[udex-masthead-mobile-menu]");
  }

  get mobileBtn(): UDExMastheadItem | null | undefined {
    return this.shadowRoot?.querySelector(".udex-masthead-shell__menu-btn");
  }

  get closeBtn(): UDExMastheadItem | null | undefined {
    return this.shadowRoot?.querySelector(".udex-masthead-shell__close-btn");
  }

  get hasProfile(): boolean {
    return !!this.profile.length;
  }

  get hasCart(): boolean {
    return !!this.cart.length;
  }

  get hasSearch(): boolean {
    return !!this.search.length;
  }

  get hasMobileMenuButtons(): boolean {
    return !!this.mobileMenu.length;
  }
}

UDExMastheadShell.define();

export default UDExMastheadShell;
