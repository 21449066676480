import UI5Element from "@ui5/webcomponents-base/dist/UI5Element.js";
import customElement from "@ui5/webcomponents-base/dist/decorators/customElement.js";
import property from "@ui5/webcomponents-base/dist/decorators/property.js";
import litRender from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import Icon from "@ui5/webcomponents/dist/Icon.js";
import "@ui5/webcomponents-icons/dist/slim-arrow-right.js";
import "@ui5/webcomponents-icons/dist/action.js";
import { isUp, isDown } from "@ui5/webcomponents-base/dist/Keys.js";
import UDExBadge from "./Badge.js";
import UDExMastheadMenuItem from "./MastheadMenuItem.js";
import MastheadMenuTemplate from "./generated/templates/MastheadMenuTemplate.lit.js";

// Styles
import MastheadMenuCss from "./generated/themes/MastheadMenu.css.js";

export type Item = {
  title: string;
  url: string;
  externalLink: boolean;
};

export type SubMenuLinkItem = {
  category: string;
  url: string;
  items: Item[];
};

export type MenuItem = {
  subMenuLinkItems: SubMenuLinkItem[];
};

/**
 * @class
 *
 * <h3 class="comment-api-title">Overview</h3>
 *
 *
 * <h3>Usage</h3>
 *
 * For the <code>udex-masthead-menu</code>
 * <h3>ES6 Module Import</h3>
 *
 * <code>import "@udex/web-components/dist/MastheadMenu.js";</code>
 *
 * @constructor
 * @extends UI5Element
 * @public
 */
@customElement({
  tag: "udex-masthead-menu",
  renderer: litRender,
  styles: MastheadMenuCss,
  template: MastheadMenuTemplate,
  dependencies: [Icon, UDExBadge, UDExMastheadMenuItem],
})

class UDExMastheadMenu extends UI5Element {
  /**
   * Defines a menu heading
   *
   * @default ""
   * @public
   */
  @property({ type: String, defaultValue: "" })
    menuTitle!: string;

  /**
   * List of the menu items
   *
   * @default "[]"
   * @public
   */
  @property({ type: String, defaultValue: "[]" })
    subItems!: string;

  handleKeyDown(e: KeyboardEvent) {
    this.handleUpArrow(e);
    this.handleDownArrow(e);
  }

  handleUpArrow(e: KeyboardEvent) {
    if (isUp(e)) {
      e.preventDefault();
      const currentItem = e.target as UDExMastheadMenuItem;
      this.focusPreviousItem(currentItem);
    }
  }

  handleDownArrow(e: KeyboardEvent) {
    if (isDown(e)) {
      e.preventDefault();
      const currentItem = e.target as UDExMastheadMenuItem;
      this.focusNextItem(currentItem);
    }
  }

  focusPreviousItem(item: UDExMastheadMenuItem): void {
    const currentSelectedIndex = this.getCurrentIndex(item);
    const lastItemIndex = this.links.length - 1;
    const previousSelectIndex = currentSelectedIndex - 1 < 0 ? lastItemIndex : currentSelectedIndex - 1;
    this.links[previousSelectIndex].focus();
  }

  focusNextItem(item: UDExMastheadMenuItem): void {
    const currentSelectedIndex = this.getCurrentIndex(item);
    const nextSelectIndex = currentSelectedIndex + 1 > this.links.length - 1 ? 0 : currentSelectedIndex + 1;
    this.links[nextSelectIndex].focus();
  }

  getCurrentIndex(item: UDExMastheadMenuItem): number {
    return this.links.indexOf(item);
  }

  parseArrayFromString<T>(string: string): T[] {
    return string ? JSON.parse(string) as T[] : [];
  }

  get links(): Array<UDExMastheadMenuItem> {
    const menuItems: Array<UDExMastheadMenuItem> = Array.from(this.shadowRoot!.querySelectorAll("udex-masthead-menu-item"));
    return menuItems.filter(({ url }) => url && url.length > 0);
  }

  get itemsList(): Array<MenuItem> {
    return this.parseArrayFromString(this.subItems);
  }

  get oneRowClass(): string {
    const hasOneColumn = this.itemsList.length === 1 && this.itemsList[0].subMenuLinkItems.length === 1;
    return hasOneColumn ? "udex-masthead-menu_with-one-column" : "";
  }
}

UDExMastheadMenu.define();

export default UDExMastheadMenu;
