import litRender from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import customElement from "@ui5/webcomponents-base/dist/decorators/customElement.js";
import property from "@ui5/webcomponents-base/dist/decorators/property.js";
import Button from "@ui5/webcomponents/dist/Button.js";
import "@ui5/webcomponents-icons/dist/AllIcons.js";
import I18nBundle, { I18nText, getI18nBundle } from "@ui5/webcomponents-base/dist/i18nBundle.js";
import {
  BUTTON_ARIA_ATTENTION, BUTTON_ARIA_DEFAULT, BUTTON_ARIA_EMPHASIZED, BUTTON_ARIA_TRANSPARENT, BUTTON_ARIA_TYPE_NEGATIVE, BUTTON_ARIA_TYPE_POSITIVE, BUTTON_ARIA_TYPE_PRIMARY, BUTTON_ARIA_TYPE_SECONDARY, BUTTON_ARIA_TYPE_TERTIARY, BUTTON_ARIA_TYPE_WARNING, TOGGLED,
} from "./generated/i18n/i18n-defaults.js";

// Styles
import ButtonCss from "./generated/themes/Button.css";

export enum UDExButtonDesign {
  Primary = "Primary",
  Emphasized = "Emphasized",
  Secondary = "Secondary",
  Default = "Default",
  Tertiary = "Tertiary",
  Transparent = "Transparent",
  Invisible = "Invisible",
  Warning = "Warning",
  Attention = "Attention",
  Positive = "Positive",
  Negative = "Negative",
}

/**
 * @class
 *
 * @constructor
 * @extends Button
 * @tagname udex-button
 * @public
 */
@customElement({
  tag: "udex-button",
  renderer: litRender,
  styles: [Button.styles, ButtonCss],
})
class UDExButton extends Button {
  /**
   * Defines the size of the component.
   * <br><br>
   * <b>Note:</b> There are three different sizes to choose from.
   *
   * @default "Medium"
   * @public
   */
  @property({ type: String, defaultValue: "Medium" })
    size!: string;
  /**
   * Defines if button is in toggled state
   *
   * @default false
   * @public
   */
  @property({ type: Boolean })
    toggled!: boolean;
  /**
   * Defines the component design.
   * @default "Primary"
   * @public
   */
  @property({ type: UDExButtonDesign, defaultValue: UDExButtonDesign.Primary })
  /** @ts-expect-error */
    design!: `${UDExButtonDesign}`;

  static i18nBundle: I18nBundle;

  static async onDefine() {
    UDExButton.i18nBundle = await getI18nBundle("@ui5/webcomponents");
  }

  static typeTextMappings(): Record<string, I18nText> {
    return {
      "Primary": BUTTON_ARIA_TYPE_PRIMARY,
      "Secondary": BUTTON_ARIA_TYPE_SECONDARY,
      "Tertiary": BUTTON_ARIA_TYPE_TERTIARY,
      "Positive": BUTTON_ARIA_TYPE_POSITIVE,
      "Warning": BUTTON_ARIA_TYPE_WARNING,
      "Negative": BUTTON_ARIA_TYPE_NEGATIVE,
      "Emphasized": BUTTON_ARIA_EMPHASIZED,
      "Default": BUTTON_ARIA_DEFAULT,
      "Transparent": BUTTON_ARIA_TRANSPARENT,
      "Attention": BUTTON_ARIA_ATTENTION,
    };
  }

  get hasButtonType() {
    return !!this.getAttribute("design") || this.toggled;
  }

  get buttonTypeText() {
    const designValue = this.getAttribute("design");
    const shouldReadDesign = designValue === UDExButtonDesign.Positive || designValue === UDExButtonDesign.Negative
    || designValue === UDExButtonDesign.Warning || designValue === UDExButtonDesign.Attention;

    let typeText;
    if (designValue) {
      typeText = shouldReadDesign ? UDExButton.i18nBundle.getText(UDExButton.typeTextMappings()[designValue]) : "";
    }
    return `${typeText} ${this.toggled ? UDExButton.i18nBundle.getText(TOGGLED) : ""}`.trim();
  }
}

UDExButton.define();

export default UDExButton;
