import customElement from "@ui5/webcomponents-base/dist/decorators/customElement.js";
import slot from "@ui5/webcomponents-base/dist/decorators/slot.js";
import property from "@ui5/webcomponents-base/dist/decorators/property.js";
import litRender from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import Wizard from "@ui5/webcomponents-fiori/dist/Wizard.js";
import WizardContentLayout from "@ui5/webcomponents-fiori/dist/types/WizardContentLayout.js";
import wizardCss from "@ui5/webcomponents-fiori/dist/generated/themes/Wizard.css.js";
import UDExWizardTab from "./WizardTab.js";
import UDExWizardStep from "./WizardStep.js";

import WizardTemplate from "./generated/templates/WizardTemplate.lit.js";

// Styles
import WizardCss from "./generated/themes/Wizard.css.js";

type ResponsiveBreakpoints = {
  [key: string]: string
}

const EXPANDED_STEP = "data-ui5-wizard-expanded-tab";
const RESPONSIVE_BREAKPOINTS: ResponsiveBreakpoints = {
  "360": "S",
  "640": "M",
  "980": "L",
  "1300": "XL",
};

/**
 * @class
 *
 * <h3 class="comment-api-title">Overview</h3>
 *
 *
 * <h3>Usage</h3>
 *
 * For the <code>udex-wizard</code>
 * <h3>ES6 Module Import</h3>
 *
 * <code>import "@udex/web-components/dist/Wizard.js";</code>
 *
 * @constructor
 * @author SAP SE
 * @extends Wizard
 * @tagname udex-wizard
 * @public
 */
@customElement({
  tag: "udex-wizard",
  renderer: litRender,
  template: WizardTemplate,
  styles: [wizardCss, WizardCss],
  dependencies: [Wizard, UDExWizardTab, UDExWizardStep],
})

class UDExWizard extends Wizard {
  @slot({
    "default": true,
    type: HTMLElement,
    individualSlots: true,
    invalidateOnChildChange: true,
  })
    steps!: UDExWizardStep[];

  /**
   * Defines whether the component has unknown number of steps.
   *
   * @default false
   * @public
   */
  @property({ type: Boolean })
    indeterminate!: boolean;

  constructor() {
    super();
  }

  onEnterDOM() {
    this.contentLayout = WizardContentLayout.SingleStep;

    this._calcCurrentBreakpoint = () => {
      const breakpointDimensions = Object.keys(RESPONSIVE_BREAKPOINTS).reverse();
      const breakpoint = breakpointDimensions.find(size => Number(size) < this.width!);
      this._breakpoint = breakpoint ? RESPONSIVE_BREAKPOINTS[breakpoint] : RESPONSIVE_BREAKPOINTS["360"];
    };

    // overriding ui5-wizard behavior for small screens - tabs are not clickable, unless expanded
    this._adjustHeaderOverflow = () => {
      const tabs = this.stepsInHeaderDOM;
      tabs.forEach(step => {
        step.setAttribute(EXPANDED_STEP, "true");
      });
    };

    this.switchSelectionFromOldToNewStep = (
      selectedStep: UDExWizardStep,
      stepToSelect: UDExWizardStep,
      stepToSelectIndex,
      changeWithClick,
    ) => {
      if (selectedStep && stepToSelect) {
        // keep the selection if next step is disabled
        if (!stepToSelect.disabled) {
          selectedStep.selected = false;
          selectedStep.completed = true;
          stepToSelect.selected = true;
        }
        this.fireEvent("step-change", {
          step: stepToSelect,
          previousStep: selectedStep,
          changeWithClick,
        });
        this.selectedStepIndex = stepToSelectIndex;
      }
    };

    this.getLastEnabledStepIndex = () => {
      let lastEnabledStepIndex = 0;
      this.slottedSteps.forEach((step, idx) => {
        if (step.selected || step.completed) {
          lastEnabledStepIndex = idx;
        }
      });
      return lastEnabledStepIndex;
    };

    this.getStepsInfo = () => {
      const lastEnabledStepIndex = this.getLastEnabledStepIndex();
      const stepsCount = this.stepsCount;
      const selectedStepIndex = this.getSelectedStepIndex();
      let initialZIndex = this.steps.length + 10;
      let accInfo;
      this._adjustHeaderOverflow();
      return this.steps.map((step, idx) => {
        const pos = idx + 1;
        const hideSeparator = idx === stepsCount - 1 && !this.indeterminate;
        const stepStateText = step.disabled
          ? this.inactiveStepText
          : this.activeStepText;
        const ariaLabel = (
          step.titleText
            ? `${pos} ${step.titleText} ${stepStateText}`
            : `${this.navStepDefaultHeading} ${pos} ${stepStateText}`
        ).trim();
        const isAfterCurrent = idx > selectedStepIndex;
        accInfo = {
          ariaSetsize: stepsCount,
          ariaPosinset: pos,
          ariaLabel: this.getStepAriaLabelText(step, ariaLabel),
        };
        const stepInfo = {
          icon: step.icon,
          titleText: step.titleText,
          subtitleText: step.subtitleText,
          number: pos,
          selected: step.selected,
          disabled: !step.selected && !step.completed,
          completed: step.completed,
          hideSeparator,
          activeSeparator: idx < lastEnabledStepIndex,
          branchingSeparator: step.branching,
          pos,
          accInfo,
          refStepId: step._id,
          tabIndex: this.selectedStepIndex === idx ? "0" : "-1",
          breakpoint: this._breakpoint,
          styles: {
            zIndex: isAfterCurrent ? --initialZIndex : 1,
          },
        };
        return stepInfo;
      });
    };
  }

  get slottedSteps(): UDExWizardStep[] {
    return this.getSlottedNodes("steps");
  }

  get stepsInHeaderDOM(): UDExWizardTab[] {
    return Array.from(this.shadowRoot!.querySelectorAll("[udex-wizard-tab]"));
  }

  get _steps(): UDExWizardStep[] {
    const stepsInfo = this.getStepsInfo();
    return this.steps.map((step, idx) => {
      step.stepContentAriaLabel = `${this.navStepDefaultHeading} ${stepsInfo[idx].number} ${stepsInfo[idx].titleText}`;
      return step;
    });
  }
}

UDExWizard.define();

export default UDExWizard;
