import customElement from "@ui5/webcomponents-base/dist/decorators/customElement.js";
import litRender from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import SideNavigationSubItem from "@ui5/webcomponents-fiori/dist/SideNavigationSubItem.js";

// Styles
import SideNavigationSubItemCss from "./generated/themes/SideNavigationSubItem.css.js";

/**
 * @class
 *
 * <h3 class="comment-api-title">Overview</h3>
 *
 *
 * <h3>Usage</h3>
 *
 * For the <code>udex-side-navigation-sub-item</code>
 * <h3>ES6 Module Import</h3>
 *
 * <code>import "@udex/web-components/dist/SideNavigationSubItem.js";</code>
 *
 * @constructor
 * @extends SideNavigationSubItem
 * @public
 */
@customElement({
  tag: "udex-side-navigation-sub-item",
  renderer: litRender,
  styles: [SideNavigationSubItem.styles, SideNavigationSubItemCss],
  dependencies: [],
})

class UDExSideNavigationSubItem extends SideNavigationSubItem {}

UDExSideNavigationSubItem.define();

export default UDExSideNavigationSubItem;
