/* eslint no-unused-vars: 0 */
import { html, svg, repeat, classMap, styleMap, ifDefined, unsafeHTML, scopeTag } from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import type UI5Element from "@ui5/webcomponents-base/dist/UI5Element.js";
	import type CheckBox from "../../CheckBox.js";
	import type { ClassMapValue } from "@ui5/webcomponents-base/dist/types.js";
	
function block0 (this: CheckBox, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<div class="ui5-checkbox-root ${classMap(this.classes.main as ClassMapValue)}" role="checkbox" part="root" aria-checked="${ifDefined(this.effectiveAriaChecked)}" aria-readonly="${ifDefined(this.ariaReadonly)}" aria-disabled="${ifDefined(this.effectiveAriaDisabled)}" aria-label="${ifDefined(this.ariaLabelText)}" aria-labelledby="${ifDefined(this.ariaLabelledBy)}" aria-describedby="${ifDefined(this.ariaDescribedBy)}" aria-required="${ifDefined(this.required)}" tabindex="${ifDefined(this.effectiveTabIndex)}" @mousedown="${this._onmousedown}" @mouseup="${this._onmouseup}" @keydown="${this._onkeydown}" @keyup="${this._onkeyup}" @click="${this._onclick}" @focusout="${this._onfocusout}">${ this.isDisplayOnly ? block1.call(this, context, tags, suffix) : block2.call(this, context, tags, suffix) }<input id="${ifDefined(this._id)}-CB" type='checkbox' ?checked="${this.checked}" ?readonly="${this.readonly}" ?disabled="${this.disabled}" tabindex="-1" aria-hidden="true" data-sap-no-tab-ref />${ this.text ? block4.call(this, context, tags, suffix) : undefined }${ this.hasValueState ? block5.call(this, context, tags, suffix) : undefined }<slot name="formSupport"></slot></div>`;}
function block1 (this: CheckBox, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<div class="ui5-checkbox-display-only-icon-inner"><${scopeTag("ui5-icon", tags, suffix)} aria-hidden="true" name=${ifDefined(this.displayOnlyIcon)} class="ui5-checkbox-display-only-icon" part="icon"></${scopeTag("ui5-icon", tags, suffix)}></div>` : html`<div class="ui5-checkbox-display-only-icon-inner"><ui5-icon aria-hidden="true" name=${ifDefined(this.displayOnlyIcon)} class="ui5-checkbox-display-only-icon" part="icon"></ui5-icon></div>`;}
function block2 (this: CheckBox, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<div id="${ifDefined(this._id)}-CbBg" class="ui5-checkbox-inner">${ this.isCompletelyChecked ? block3.call(this, context, tags, suffix) : undefined }</div>`;}
function block3 (this: CheckBox, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<${scopeTag("ui5-icon", tags, suffix)} aria-hidden="true" name="accept" class="ui5-checkbox-icon" part="icon"></${scopeTag("ui5-icon", tags, suffix)}>` : html`<ui5-icon aria-hidden="true" name="accept" class="ui5-checkbox-icon" part="icon"></ui5-icon>`;}
function block4 (this: CheckBox, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<${scopeTag("ui5-label", tags, suffix)} part="label" id="${ifDefined(this._id)}-label" class="ui5-checkbox-label" wrapping-type="${ifDefined(this.wrappingType)}">${ifDefined(this.text)}</${scopeTag("ui5-label", tags, suffix)}>` : html`<ui5-label part="label" id="${ifDefined(this._id)}-label" class="ui5-checkbox-label" wrapping-type="${ifDefined(this.wrappingType)}">${ifDefined(this.text)}</ui5-label>`;}
function block5 (this: CheckBox, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<span id="${ifDefined(this._id)}-descr" class="ui5-hidden-text">${ifDefined(this.valueStateText)}</span>`;}


export default block0;