import UI5Element from "@ui5/webcomponents-base/dist/UI5Element.js";
import customElement from "@ui5/webcomponents-base/dist/decorators/customElement.js";
import event from "@ui5/webcomponents-base/dist/decorators/event.js";
import property from "@ui5/webcomponents-base/dist/decorators/property.js";
import litRender from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import Icon from "@ui5/webcomponents/dist/Icon.js";
import { isDesktop } from "@ui5/webcomponents-base/dist/Device.js";
import "@ui5/webcomponents-icons/dist/slim-arrow-right.js";
import "@ui5/webcomponents-icons/dist/slim-arrow-left.js";

import MastheadMenuItemTemplate from "./generated/templates/MastheadMenuItemTemplate.lit.js";

// Styles
import MastheadMenuItemCss from "./generated/themes/MastheadMenuItem.css.js";

export enum ItemType {
  Heading = "Heading",
  Button = "Button",
  Link = "Link"
}
/**
 * @class
 *
 * <h3 class="comment-api-title">Overview</h3>
 *
 *
 * <h3>Usage</h3>
 *
 * For the <code>masthead-menu-item</code>
 * <h3>ES6 Module Import</h3>
 *
 * <code>import "@udex/web-components/dist/MastheadMenuItem.js";</code>
 *
 * @constructor
 * @extends UI5Element
 * @public
 */
@customElement({
  tag: "udex-masthead-menu-item",
  renderer: litRender,
  styles: MastheadMenuItemCss,
  template: MastheadMenuItemTemplate,
  dependencies: [Icon],
})

/**
 * Fires when item button is clicked
 *
 * @public
 */
@event("click", {
  detail: {
    item: { type: HTMLElement },
  },
})
class UDExMastheadMenuItem extends UI5Element {
  /**
   * Defines the type of the item.
   *
   * @default "Link"
   * @public
   */
  @property({ type: ItemType, defaultValue: ItemType.Link })
    type!: `${ItemType}`;

  /**
   * Controls the ARIA role of the item
   *
   * @default ""
   * @public
   */
  @property({ type: String })
    accessibleRole!: string;

  /**
   * Defines the label of the component.
   *
   * @default ""
   * @public
   */
  @property()
    label!: string;

  /**
   * Defines the url of the component.
   *
   * @default ""
   * @public
   */
  @property()
    url?: string;

  /**
   * Defines the badge of the component.
   *
   * @default ""
   * @public
   */
  @property()
    badge?: string;

  /**
   * Defines the externalLink of the component.
   *
   *
   * @public
   * @default false
   */
  @property({ type: Boolean })
    externalLink?: boolean;

  /**
   * Defines the border for component.
   *
   *
   * @public
   * @default false
   */
  @property({ type: Boolean })
    hasBorder?: boolean;

  /**
   * Defines the start position of icon arrow.
   *
   *
   * @public
   * @default false
   */
  @property({ type: Boolean })
    iconStart?: boolean;

  /**
   * Defines the end position of icon arrow.
   *
   *
   * @public
   * @default false
   */
  @property({ type: Boolean })
    iconEnd?: boolean;

  onEnterDOM() {
    if (isDesktop()) {
      this.setAttribute("desktop", "");
    }
  }
  handleClick() {
    this.fireEvent("click", { item: this });
  }

  get rootClass(): string {
    const badgeClass = this.badge ? "udex-masthead-menu-item_badge" : "";
    const rootClass = this.isHeadingType ? "udex-masthead-menu-item_is-header" : "udex-masthead-menu-item_is-list";
    return `${rootClass} ${badgeClass}`;
  }

  get labelClass(): string {
    const root = "udex-masthead-menu-item__label";
    return this.externalLink ? `${root} udex-masthead-menu-item__label_with-icon` : `${root}`;
  }

  get isHeadingType(): boolean {
    return this.type === ItemType.Heading;
  }

  get isButtonType(): boolean {
    return this.type === ItemType.Button;
  }

  get isActive(): boolean {
    return (this.type === ItemType.Link || !!this.url) && !this.isButtonType;
  }

  get target(): string {
    return this.externalLink ? "_blank" : "_self";
  }

  get lastWordOfLabel(): string | undefined {
    return this.label.split(" ").pop();
  }

  get leadingWordsOfLabel(): string {
    const leadingWords = this.label.split(" ").slice(0, -1).join(" ");
    return `${leadingWords} `;
  }
}

UDExMastheadMenuItem.define();

export default UDExMastheadMenuItem;
