import type { StyleData } from "@ui5/webcomponents-base/dist/types.js";
import { registerThemePropertiesLoader } from "@ui5/webcomponents-base/dist/asset-registries/Themes.js";

import defaultThemeBase from "@ui5/webcomponents-theming/dist/generated/themes/sap_horizon/parameters-bundle.css.js";
import defaultTheme from "./sap_horizon/parameters-bundle.css.js";

registerThemePropertiesLoader("@ui5/webcomponents-theming", "sap_horizon", async () => defaultThemeBase);
registerThemePropertiesLoader("@sapudex/web-components", "sap_horizon", async () => defaultTheme);

const styleData: StyleData = {packageName:"@sapudex/web-components",fileName:"themes/Slider.css.ts",content:`:host{--udex-slider-color-track-start-point: var( --sapSlider_Selected_Background, #0070f2 );--udex-slider-color-track-active: var( --sapSlider_Selected_Background, #0070f2 );--udex-slider-color-handle-focus-range: var( --sapSlider_Selected_BorderColor, #0070f2 );--udex-slider-color-handle-active-background: var( --sapSlider_Active_HandleBackground, #ffffff );--udex-slider-color-handle-default-icon: var( --sapContent_Selected_ForegroundColor, #0040b0 );--udex-slider-radius-range-size: var(---udexRadiusM, .5rem)}:host{font-family:var(--sapFontFamily);font-weight:var(--udexTypographyFontWeightRegular, 400);font-size:var(--udexTypographyNavigationRegularMFontSize, 14px);line-height:var(--udexTypographyHeadingLineHeight, 1.1);color:var(--sapContent_MeasureIndicatorColor, #5b738b);height:74px}.ui5-slider-progress-container:before{background-color:var(--udex-slider-color-track-start-point)}.ui5-slider-progress{background-color:var(--udex-slider-color-track-active)}.ui5-slider-progress:focus:after{border:2px solid var(--udex-slider-color-handle-focus-range);border-radius:var(--udex-slider-radius-range-size)}:host([desktop]) .ui5-slider-handle:focus,.ui5-slider-handle:focus-visible{background-color:var(--_ui5-v0-117-1_range_slider_handle_background_focus)}:host .ui5-slider-inner .ui5-slider-labels{top:34px}:host .ui5-slider-inner .ui5-slider-labels li{height:1.375rem;font-family:var(--sapFontFamily);font-size:var(--udexTypographyNavigationRegularMFontSize, 14px)}[slider-icon]{color:var(--udex-slider-color-handle-default-icon)}
`};
export default styleData;
	