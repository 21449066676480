import CallbackFunction from "../types/callback.js";

const retryUntilSuccessful = (checkFn: CallbackFunction<void>, delay: number, maxAttempts: number, errorMessage: string): Promise<void> => {
  return new Promise((resolve, reject) => {
    let currentAttempt = 0;

    const attempt = () => {
      currentAttempt++;
      const result = checkFn();
      if (result !== undefined) {
        resolve(result);
      } else if (currentAttempt < maxAttempts) {
        setTimeout(attempt, delay);
      } else {
        reject(new Error(errorMessage));
      }
    };

    attempt();
  });
};

export default retryUntilSuccessful;
