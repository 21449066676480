import UI5Element from "@ui5/webcomponents-base/dist/UI5Element.js";
import customElement from "@ui5/webcomponents-base/dist/decorators/customElement.js";
import property from "@ui5/webcomponents-base/dist/decorators/property.js";
import slot from "@ui5/webcomponents-base/dist/decorators/slot.js";
import event from "@ui5/webcomponents-base/dist/decorators/event.js";
import UI5Icon from "@ui5/webcomponents/dist/Icon.js";
import "@ui5/webcomponents-icons/dist/search.js";
import litRender from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import { isEscape } from "@ui5/webcomponents-base/dist/Keys.js";
import { isDesktop } from "@ui5/webcomponents-base/dist/Device.js";
import UDExMastheadMenu from "./MastheadMenu.js";
import UDExAvatar from "./Avatar.js";
import MastheadShell from "./MastheadShell.js";
import "@ui5/webcomponents-icons/dist/slim-arrow-down.js";

import MastheadItemTemplate from "./generated/templates/MastheadItemTemplate.lit.js";

// Styles
import MastheadItemCss from "./generated/themes/MastheadItem.css.js";

export interface IMastheadItem {
  text?: string;
  selected?: boolean;
  icon?: string;
  avatar?: Array<HTMLElement>;
  href?: string;
}

export type CategoryItem = {
  title: string;
  url: string;
  externalLink: boolean;
};

export type SubMenuLinkItem = {
  category: string;
  url?: string;
  items: CategoryItem[];
};

export type MenuItem = {
  subMenuLinkItems: SubMenuLinkItem[];
};

/**
 * @class
 *
 * <h3 class="comment-api-title">Overview</h3>
 *
 *
 * <h3>Usage</h3>
 *
 * For the <code>udex-masthead-item</code>
 * <h3>ES6 Module Import</h3>
 *
 * <code>import "@udex/web-components/dist/MastheadItem.js";</code>
 *
 * @constructor
 * @extends UI5Element
 * @public
 */
@customElement({
  tag: "udex-masthead-item",
  renderer: litRender,
  styles: MastheadItemCss,
  template: MastheadItemTemplate,
  dependencies: [
    UDExMastheadMenu,
    UI5Icon,
  ],
})

/**
 * Fires when item is clicked
 *
 * @allowPreventDefault
 * @public
 */
@event("click")
class UDExMastheadItem extends UI5Element implements IMastheadItem {
  /**
   * Defines sub items for item
   *
   * @default ""
   * @public
   */
  @property({ type: String, defaultValue: "" })
    subItems!: string;

  /**
   * Defines a title for sub menu items
   *
   * @default ""
   * @public
   */
  @property({ type: String, defaultValue: "" })
    menuTitle!: string;

  /**
   * Defines the text of item.
   *
   * @public
   * @default ""
   */
  @property({ type: String, defaultValue: "" })
    text!: string;

  /**
   * Defines the selected item.
   *
   * @public
   * @default false
   */
  @property({ type: Boolean })
    selected!: boolean;

  /**
   * Defines the icon in the item.
   *
   * @public
   * @default ""
   */
  @property({ type: String, defaultValue: "" })
    icon!: string;

  /**
   * Defines the href of the item.
   *
   * @public
   */
  @property({ type: String })
    href!: string;

  /**
   * Define the target of the item
   * <b>Note:</b> Standard hyperlink behavior is supported.
   * @public
   */
  @property({ type: String })
    target!: string;

  /**
   * Controls the ARIA name of the item
   *
   * @default ""
   * @public
   */
  @property({ type: String })
    accessibleName!: string;

  /**
   * Controls the ARIA role of the item
   *
   * @default ""
   * @public
   */
  @property({ type: String })
    accessibleRole!: string;

  /**
   * Defines an alt text for item icon
   *
   * @default ""
   * @public
   */
  @property({ type: String, defaultValue: "" })
    iconAlt?: string = "";

  /**
   * Hide active border for item
   *
   * @default ""
   * @public
   */
  @property({ type: Boolean })
    hideActiveBorder?: boolean;

  /**
   * Defines direction of the text.
   *
   * @private
   */
  @property({ type: String })
    _direction?: string;

  /**
   * Slot for the dropdown
   *
   * @slot
   * @public
   */
  @slot({ type: HTMLElement })
    dropdown?: Array<HTMLElement>;

  /**
   * Slot for any content
   *
   * @slot
   * @public
   */
  @slot({ type: HTMLElement, "default": true })
    content?: Array<HTMLElement>;

  _profileAvatar!: UDExAvatar;

  onEnterDOM() {
    if (isDesktop()) {
      this.setAttribute("desktop", "");
    }
  }

  onAfterRendering() {
    this._profileAvatar = this.profileAvatar;
    this.handleDirection();
    if (this.selected && this.hasDropdownSlot && !this.isMobile && !this.isExploreBtn) {
      this.setDropdownInsetStart();
    }
  }

  onClick(e: Event): void {
    e.stopPropagation();
    this.fireEvent("click", { item: this });
    this.selected = this.isMobile ? true : !this.selected;
  }

  handleKeyDown(e: KeyboardEvent): void {
    if (isEscape(e) && this.hasDropdownSlot) {
      if (this.isMobile) {
        this.handleKeyEscapeMobile();
      } else {
        this.handleKeyEscape();
      }
    }
  }

  handleKeyEscape() {
    this.selected = false;
    if (this.isProfileBtn) {
      this._profileAvatar.focus();
    } else {
      this.focus();
    }
  }

  handleKeyEscapeMobile() {
    const mastheadShell: MastheadShell | null = document.querySelector("udex-masthead-shell");
    const mobileMenu = mastheadShell?.mastheadMobileMenu;
    mastheadShell?.removeActiveMobileClass();
    mastheadShell?.mobileBtn?.focus();
    this.selected = false;

    if (mobileMenu) {
      mobileMenu.showFirstLevelMenu();
    }
  }

  setDropdownInsetStart(): void {
    const dropdown = this.getDomElement(".udex-masthead-item__dropdown");
    if (dropdown) {
      const rect = dropdown.getBoundingClientRect();
      const dropdownHolder = this.getDomElement(".udex-masthead-item__dropdown-holder");
      const marginInlineStart = this.calculateMarginInlineStart(rect);

      if (marginInlineStart !== null && dropdownHolder) {
        dropdownHolder.style.marginInlineStart = `${marginInlineStart - 3}px`;
      }
    }
  }

  calculateMarginInlineStart(rect: DOMRect): number | null {
    const { left, right } = rect;

    if (this.isLtrDirection && left < 0) {
      return left * -1 + 15;
    }

    if (this.isRtlDirection && left < 0) {
      return left - 15;
    }

    if (this.isRtlDirection && right > window.innerWidth) {
      return right - window.innerWidth + 15;
    }

    if (this.isLtrDirection && right > window.innerWidth) {
      return window.innerWidth - right - 15;
    }

    return null;
  }

  handleDirection(): void {
    const directionStyles = window
      .getComputedStyle(this)
      .getPropertyValue("direction");
    if (!directionStyles) {
      this._direction = "ltr";
    } else {
      this._direction = directionStyles;
    }
  }

  getDomElement(selector: string): HTMLElement | undefined | null {
    return this.shadowRoot?.querySelector(`${selector}`);
  }

  parseArrayFromString<T>(string: string): T[] {
    return string ? JSON.parse(string) as T[] : [];
  }

  getSubItems(): Array<MenuItem> {
    return this.parseArrayFromString(this.subItems);
  }

  get additionalClass(): string {
    const iconClass = this.icon ? "udex-masthead-item__button--icon" : "";
    const profileClass = this.isProfileBtn ? "udex-masthead-item__button--profile" : "";
    const exploreClass = this.isExploreBtn ? "udex-masthead-item__button--explore" : "";
    return `udex-masthead-item ${iconClass} ${profileClass} ${exploreClass}`;
  }

  get profileAvatar(): UDExAvatar {
    return this.querySelector("udex-avatar")!;
  }

  get isRtlDirection(): boolean {
    return this._direction === "rtl";
  }

  get isLtrDirection(): boolean {
    return this._direction === "ltr";
  }

  get hasHref(): boolean {
    return !!this.href.length;
  }

  get accessibleRoleName(): string {
    return this.accessibleRole ? this.accessibleRole : "button";
  }

  get selectedClass(): string {
    return this.selected ? " udex-masthead-item__button--active" : "";
  }

  get ariaLabelledBy(): string {
    const labels = [];

    if (this.text) {
      labels.push(`${this.__id}-text`);
    }

    if (this.icon) {
      labels.push(`${this.__id}-icon`);
    }

    return labels.join(" ");
  }

  get showDropdown(): boolean {
    return this.hasDropdownSlot && this.selected;
  }

  get hasDropdownSlot(): boolean {
    return !!this.getSlottedNodes("dropdown").length;
  }

  get hasArrowDown(): boolean {
    return this.hasDropdownSlot && !this.isProfileBtn && !this.icon.length;
  }

  get isProfileBtn():boolean {
    return this.getAttribute("slot") === "profile";
  }

  get isExploreBtn():boolean {
    return this.getAttribute("slot") === "exploreSAP";
  }

  get isMenuItem(): boolean {
    return true;
  }

  get isMobile(): boolean {
    return window.innerWidth < 1279;
  }
}

UDExMastheadItem.define();

export default UDExMastheadItem;
