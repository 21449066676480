import customElement from "@ui5/webcomponents-base/dist/decorators/customElement.js";
import litRender from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import property from "@ui5/webcomponents-base/dist/decorators/property.js";
import UDExTableCellBase from "./TableCellBase.js";
import TableCellTemplate from "./generated/templates/TableCellTemplate.lit.js";

// Styles
import TableCellCss from "./generated/themes/TableCell.css.js";

/**
 * @class
 *
 * <h3 class="comment-api-title">Overview</h3>
 *
 *
 * <h3>Usage</h3>
 *
 * For the <code>udex-table-cell</code>
 * <h3>ES6 Module Import</h3>
 *
 * <code>import "@udex/web-components/dist/TableCell.js";</code>
 *
 * @constructor
 * @extends UDExTableCellBase
 * @public
 */
@customElement({
  tag: "udex-table-cell",
  renderer: litRender,
  styles: [UDExTableCellBase.styles, TableCellCss],
  template: TableCellTemplate,
  dependencies: [],
})

class UDExTableCell extends UDExTableCellBase {
  /**
   * Defines the name of the UI5 Icon, that will be displayed at the beginning of the content.
   *
   * @default ""
   * @public
   */
  @property({ type: String, defaultValue: "" })
    icon!: string;

  /**
   * Defines highlighted of the table cell.
   *
   * @private
   */
  @property({ type: Boolean })
    _highlighted?: boolean;

  onBeforeRendering() {
    super.onBeforeRendering();
    this.setJustifyContent();
  }

  setJustifyContent(): void {
    if (this.horizontalAlign) {
      this.style.justifyContent = this.horizontalAlign;
    } else {
      this.style.justifyContent = `var(--horizontal-align-${(this)._individualSlot})`;
    }
  }
}

UDExTableCell.define();

export default UDExTableCell;
