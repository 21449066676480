import UI5Element from "@ui5/webcomponents-base/dist/UI5Element.js";
import customElement from "@ui5/webcomponents-base/dist/decorators/customElement.js";
import property from "@ui5/webcomponents-base/dist/decorators/property.js";
import litRender from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import Integer from "@ui5/webcomponents-base/dist/types/Integer.js";
import I18nBundle, { getI18nBundle } from "@ui5/webcomponents-base/dist/i18nBundle.js";
import { READ_LESS, READ_MORE } from "./generated/i18n/i18n-defaults.js";

// Template
import TruncatedTextTemplate from "./generated/templates/TruncatedTextTemplate.lit.js";

// Styles
import TruncatedTextCss from "./generated/themes/TruncatedText.css.js";
import UDExButton from "./Button.js";

export enum ErrorVariableName {
  charLimit = "char-limit"
}

const ellipsisSymbols = "...";
const lineEndRegExp = new RegExp(/($)|([,.]+)$|([,.]+)?\s+(\S*)$/);

/**
 * @class
 *
 * <h3 class="comment-api-title">Overview</h3>
 *
 * The <code> udex-truncated-text </code> component is a component used to limit and toggle text visibility.
 *
 * @constructor
 * @extends UI5Element
 * @tagname udex-truncated-text
 * @public
 */
@customElement({
  tag: "udex-truncated-text",
  renderer: litRender,
  styles: TruncatedTextCss,
  template: TruncatedTextTemplate,
  dependencies: [UDExButton],
})

class UDExTruncatedText extends UI5Element {
  /**
  * Defines if the text is initially expanded.
  *
  * @public
  */
  @property({ type: Boolean })
    initiallyExpanded!: boolean;

  /**
   * Defines direction of the text.
   *
   * @private
   */
  @property({ type: String })
    _direction?: string;

  /**
  * Indicates the element text
  *
  * @public
  */
  @property({ type: String })
    text!: string;

  /**
  * Indicates the limit for chars
  *
  * @public
  * @default 150
  */
  @property({ defaultValue: 150, type: Integer })
    charLimit!: Integer;

  static i18nBundle: I18nBundle;

  constructor() {
    super();
  }

  static async onDefine() {
    UDExTruncatedText.i18nBundle = await getI18nBundle("sap-ui-webcomponents-bundle");
  }

  get visibleText() {
    return this.initiallyExpanded ? `${this.text} ` : this.truncatedText;
  }

  get truncatedText() {
    return this.text.substring(0, this.cropAfter()).replace(lineEndRegExp, ellipsisSymbols);
  }

  get buttonLabel() {
    return this.initiallyExpanded
      ? UDExTruncatedText.i18nBundle.getText(READ_LESS)
      : UDExTruncatedText.i18nBundle.getText(READ_MORE);
  }

  get validatedCharLimit() {
    return (Number(this.charLimit) < 0) ? 0 : this.charLimit;
  }

  onBeforeRendering(): void {
    this.validateVariables(ErrorVariableName.charLimit, Number(this.charLimit));
  }

  cropAfter(): number {
    return this.text.substring(0, Number(this.validatedCharLimit)).trim().length;
  }

  validateVariables(name: string, value: number): void {
    if (value < 0) {
      console.warn(`The variable ${name} cannot take a negative value`);
    }
  }

  onToggleClick(): void {
    this.initiallyExpanded = !this.initiallyExpanded;
  }
}

UDExTruncatedText.define();

export default UDExTruncatedText;
