/* eslint no-unused-vars: 0 */
import { html, svg, repeat, classMap, styleMap, ifDefined, unsafeHTML, scopeTag } from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import type UI5Element from "@ui5/webcomponents-base/dist/UI5Element.js";
	import type DatePicker from "../../DatePicker.js";
	import type { ClassMapValue } from "@ui5/webcomponents-base/dist/types.js";
	
function block0 (this: DatePicker, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<div class="udex-date-picker__container"><${scopeTag("udex-text-field", tags, suffix)} @click="${this.handleInputClick}" @mousedown="${this.handleInputMouseDown}" @keydown="${this.handleInputKeyDown}" class="udex-date-picker__input udex-date-picker__trigger" label="${ifDefined(this.label)}" accessible-name="${ifDefined(this.accessibleName)}" accessible-name-ref="${ifDefined(this.accessibleNameRef)}" accessible-icon-name="${ifDefined(this.accessibleIconName)}" aria-haspopup="grid" ?disabled="${this.disabled}" ?required="${this.required}" ?readonly="${this.readonly}" value="${ifDefined(this.value)}" icon="appointment-2" tabindex="0" value-state="${ifDefined(this.valueState)}"></${scopeTag("udex-text-field", tags, suffix)}>${ this.supportingText ? block1.call(this, context, tags, suffix) : undefined }</div>` : html`<div class="udex-date-picker__container"><udex-text-field @click="${this.handleInputClick}" @mousedown="${this.handleInputMouseDown}" @keydown="${this.handleInputKeyDown}" class="udex-date-picker__input udex-date-picker__trigger" label="${ifDefined(this.label)}" accessible-name="${ifDefined(this.accessibleName)}" accessible-name-ref="${ifDefined(this.accessibleNameRef)}" accessible-icon-name="${ifDefined(this.accessibleIconName)}" aria-haspopup="grid" ?disabled="${this.disabled}" ?required="${this.required}" ?readonly="${this.readonly}" value="${ifDefined(this.value)}" icon="appointment-2" tabindex="0" value-state="${ifDefined(this.valueState)}"></udex-text-field>${ this.supportingText ? block1.call(this, context, tags, suffix) : undefined }</div>`;}
function block1 (this: DatePicker, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<${scopeTag("udex-status-message", tags, suffix)} ?disabled="${this.disabled}" supporting-text="${ifDefined(this.supportingText)}" value-state="${ifDefined(this.valueState)}"></${scopeTag("udex-status-message", tags, suffix)}>` : html`<udex-status-message ?disabled="${this.disabled}" supporting-text="${ifDefined(this.supportingText)}" value-state="${ifDefined(this.valueState)}"></udex-status-message>`;}


export default block0;