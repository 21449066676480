/* eslint no-unused-vars: 0 */
import { html, svg, repeat, classMap, styleMap, ifDefined, unsafeHTML, scopeTag } from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import type UI5Element from "@ui5/webcomponents-base/dist/UI5Element.js";
	import type CalendarLegend from "../../CalendarLegend.js";
	import type { ClassMapValue } from "@ui5/webcomponents-base/dist/types.js";
	
function block0 (this: CalendarLegend, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<div class="ui5-calendar-legend-root" @focusout="${this._onFocusOut}" @focusin="${this._onFocusIn}" @keydown="${this._onItemKeyDown}" @mousedown="${this._onMouseDown}">${ repeat(this.defaultItemsMapping, (item, index) => (item as typeof item & {_id?: any})._id || index, (item, index: number) => block1.call(this, context, tags, suffix, item, index)) }${ repeat(this.legendItems, (item, index) => (item as typeof item & {_id?: any})._id || index, (item, index: number) => block3.call(this, context, tags, suffix, item, index)) }</div>`;}
function block1 (this: CalendarLegend, context: UI5Element, tags: string[], suffix: string | undefined, item: any, index: number) { return html`${ !item.hide ? block2.call(this, context, tags, suffix, item, index) : undefined }`;}
function block2 (this: CalendarLegend, context: UI5Element, tags: string[], suffix: string | undefined, item: any, index: number) { return suffix ? html`<${scopeTag("ui5-calendar-legend-item", tags, suffix)} type=${ifDefined(item.type)}></${scopeTag("ui5-calendar-legend-item", tags, suffix)}>` : html`<ui5-calendar-legend-item type=${ifDefined(item.type)}></ui5-calendar-legend-item>`;}
function block3 (this: CalendarLegend, context: UI5Element, tags: string[], suffix: string | undefined, item: any, index: number) { return html`<slot name="${ifDefined(item._individualSlot)}"></slot>`;}


export default block0;