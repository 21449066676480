import customElement from "@ui5/webcomponents-base/dist/decorators/customElement.js";
import litRender from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import SideNavigationItem from "@ui5/webcomponents-fiori/dist/SideNavigationItem.js";

// Styles
import SideNavigationItemCss from "./generated/themes/SideNavigationItem.css.js";

/**
 * @class
 *
 * <h3 class="comment-api-title">Overview</h3>
 *
 *
 * <h3>Usage</h3>
 *
 * For the <code>udex-side-navigation-item</code>
 * <h3>ES6 Module Import</h3>
 *
 * <code>import "@udex/web-components/dist/SideNavigationItem.js";</code>
 *
 * @constructor
 * @extends SideNavigationItem
 * @public
 */
@customElement({
  tag: "udex-side-navigation-item",
  renderer: litRender,
  styles: [SideNavigationItem.styles, SideNavigationItemCss],
  dependencies: [],
})

class UDExSideNavigationItem extends SideNavigationItem {
  onEnterDOM() {
    this.setAttribute("id", this._id);
    this.items.map(item => {
      if (this.icon) {
        item.setAttribute("with-icon", "true");
      }
      return item;
    });
  }
}

UDExSideNavigationItem.define();

export default UDExSideNavigationItem;
