import UI5Element from "@ui5/webcomponents-base/dist/UI5Element.js";
import customElement from "@ui5/webcomponents-base/dist/decorators/customElement.js";
import property from "@ui5/webcomponents-base/dist/decorators/property.js";
import slot from "@ui5/webcomponents-base/dist/decorators/slot.js";
import litRender from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import { getI18nBundle } from "@ui5/webcomponents-base/dist/i18nBundle.js";
import type I18nBundle from "@ui5/webcomponents-base/dist/i18nBundle.js";
import {
  isUp,
  isDown,
  isRight,
  isLeft,
} from "@ui5/webcomponents-base/dist/Keys.js";
import UI5Carousel from "@ui5/webcomponents/dist/Carousel.js";
import {
  CAROUSEL_NEXT_ARROW_TEXT,
  CAROUSEL_PREVIOUS_ARROW_TEXT,
} from "./generated/i18n/i18n-defaults.js";

import redirectAttributes from "./utils/redirectAttributes.js";

import CarouselTemplate from "./generated/templates/CarouselTemplate.lit.js";

// Styles
import CarouselCss from "./generated/themes/Carousel.css.js";
import UDExButton from "./Button.js";

export enum CarouselNavigationPosition {
  top = "top",
  bottom = "bottom",
}

/**
 * @class
 *
 * @constructor
 * @extends UI5Element
 * @tagname udex-carousel
 * @public
 */
@customElement({
  tag: "udex-carousel",
  renderer: litRender,
  styles: CarouselCss,
  template: CarouselTemplate,
  dependencies: [UI5Carousel, UDExButton],
})
class UDExCarousel extends UI5Element {
  /**
   * Defines the position of the navigation.
   * <br><br>
   * Available options are:
   * <ul>
   * <li><code>top</code> - the navigation button are placed above the component.</li>
   * <li><code>bottom</code> - the navigation button arrows are placed below the component.</li>
   * </ul>
   *
   * @public
   */
  @property({
    type: CarouselNavigationPosition,
    defaultValue: CarouselNavigationPosition.top,
  })
    navigationPlacement!: `${CarouselNavigationPosition}`;

  /**
   * Defines the content of the component.
   *
   * @slot
   * @public
   */
  @slot({ "default": true, type: HTMLElement, individualSlots: true })
    items!: Array<HTMLElement>;

  /**
   * Defines the prev button is disabled
   *
   * @default false
   * @private
   */
  @property({ type: Boolean, noAttribute: true })
    isPrevButtonDisabled!: boolean;

  /**
   * Defines the next button is disabled
   *
   * @default false
   * @private
   */
  @property({ type: Boolean, noAttribute: true })
    isNextButtonDisabled!: boolean;

  /**
   * Defines the accessible ARIA name of the component.
   *
   * @default ''
   * @public
   */
  @property({ type: String, defaultValue: "" })
    accessibleName!: string;

  static i18nBundle: I18nBundle;

  static async onDefine() {
    UDExCarousel.i18nBundle = await getI18nBundle("sap-ui-webcomponents-bundle");
  }

  get nextPageText() {
    return UDExCarousel.i18nBundle.getText(CAROUSEL_NEXT_ARROW_TEXT);
  }

  get previousPageText() {
    return UDExCarousel.i18nBundle.getText(CAROUSEL_PREVIOUS_ARROW_TEXT);
  }

  get carouselClass(): string {
    const classes = "udex-carousel";

    return `${classes} udex-carousel__navigation--${this.navigationPlacement}`;
  }

  get buttonDesign() {
    return this.navigationPlacement === CarouselNavigationPosition.top
      ? "Secondary"
      : "Tertiary";
  }

  get showNavigationButtons() {
    return {
      top: this.navigationPlacement === CarouselNavigationPosition.top,
      bottom: this.navigationPlacement === CarouselNavigationPosition.bottom,
    };
  }

  get _hidePageIndicator() {
    return this.navigationPlacement === CarouselNavigationPosition.top;
  }

  get _carousel() {
    return this.shadowRoot!.querySelector<UI5Carousel>("[ui5-carousel]");
  }

  get _ui5CarouselRoot(): HTMLElement | null | undefined {
    return this._carousel?.shadowRoot?.querySelector(".ui5-carousel-root");
  }

  _calculateDisabledButtonsState() {
    this.isPrevButtonDisabled = !this._carousel?.hasPrev;
    this.isNextButtonDisabled = !this._carousel?.hasNext;
  }

  _prevButtonClick() {
    if (this.isPrevButtonDisabled) {
      return;
    }

    this._carousel?.navigateLeft();
    this._ui5CarouselRoot?.focus();
    this._calculateDisabledButtonsState();
  }

  _nextButtonClick() {
    if (this.isNextButtonDisabled) {
      return;
    }

    this._carousel?.navigateRight();
    this._ui5CarouselRoot?.focus();
    this._calculateDisabledButtonsState();
  }

  _onkeydown(e: KeyboardEvent) {
    if (isLeft(e) || isDown(e) || isRight(e) || isUp(e)) {
      this._calculateDisabledButtonsState();
    }
  }

  onAfterRendering() {
    redirectAttributes(this, "carousel");

    this._calculateDisabledButtonsState();
  }
}

UDExCarousel.define();

export default UDExCarousel;
