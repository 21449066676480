/* eslint no-unused-vars: 0 */
import { html, svg, repeat, classMap, styleMap, ifDefined, unsafeHTML, scopeTag } from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import type UI5Element from "@ui5/webcomponents-base/dist/UI5Element.js";
	import type HeroBanner from "../../HeroBanner.js";
	import type { ClassMapValue } from "@ui5/webcomponents-base/dist/types.js";
	
function block0 (this: HeroBanner, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<div class="${ifDefined(this.heroBannerClasses)}" part="wrapper" style="background-color: ${ifDefined(this.wrapperBackgroundColor)}"><div class="udex-hero-banner__container">${ this.hasCustomBackgroundPicture ? block1.call(this, context, tags, suffix) : block2.call(this, context, tags, suffix) }${ this.hasBackgroundVideo ? block4.call(this, context, tags, suffix) : block6.call(this, context, tags, suffix) }</div></div> `;}
function block1 (this: HeroBanner, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<slot name="backgroundPicture"></slot>`;}
function block2 (this: HeroBanner, context: UI5Element, tags: string[], suffix: string | undefined) { return html`${ this.hasBackgroundImage ? block3.call(this, context, tags, suffix) : undefined }`;}
function block3 (this: HeroBanner, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<picture><img loading="${ifDefined(this.backgroundImageLoadingStrategy)}" src="${ifDefined(this.backgroundImage)}" part="background-image" class="udex-hero-banner__background-image" alt="${ifDefined(this.backgroundImageLabel)}" /></picture>`;}
function block4 (this: HeroBanner, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<div class="udex-hero-banner__background-video-wrapper" tabindex="0" @keydown=${this._handleKeydown}><video ?autoplay="${this.shouldVideoAutoplay}" muted loop playsinline part="background-video" class="udex-hero-banner__background-video" alt="${ifDefined(this.backgroundVideoLabel)}"><source src="${ifDefined(this.backgroundVideo)}" type="video/mp4" /></video><div class="udex-hero-banner__background-video-overlay"></div><slot id="udex-hero-banner__content" name="content" part="content"></slot>${ this.hasAdditionalContent ? block5.call(this, context, tags, suffix) : undefined }<button class="udex-hero-banner__media-button" aria-label="Play / Pause button" role="button" @click=${this._toggleMediaButton}><${scopeTag("ui5-icon", tags, suffix)} name=${ifDefined(this.mediaButtonIconName)}></${scopeTag("ui5-icon", tags, suffix)}></button></div>` : html`<div class="udex-hero-banner__background-video-wrapper" tabindex="0" @keydown=${this._handleKeydown}><video ?autoplay="${this.shouldVideoAutoplay}" muted loop playsinline part="background-video" class="udex-hero-banner__background-video" alt="${ifDefined(this.backgroundVideoLabel)}"><source src="${ifDefined(this.backgroundVideo)}" type="video/mp4" /></video><div class="udex-hero-banner__background-video-overlay"></div><slot id="udex-hero-banner__content" name="content" part="content"></slot>${ this.hasAdditionalContent ? block5.call(this, context, tags, suffix) : undefined }<button class="udex-hero-banner__media-button" aria-label="Play / Pause button" role="button" @click=${this._toggleMediaButton}><ui5-icon name=${ifDefined(this.mediaButtonIconName)}></ui5-icon></button></div>`;}
function block5 (this: HeroBanner, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<slot id="udex-hero-banner__additionalContent" name="additionalContent" part="additionalContent"></slot>`;}
function block6 (this: HeroBanner, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<div class="udex-hero-banner__content-container"><slot id="udex-hero-banner__content" name="content" part="content"></slot>${ this.hasAdditionalContent ? block7.call(this, context, tags, suffix) : undefined }</div>`;}
function block7 (this: HeroBanner, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<slot id="udex-hero-banner__additionalContent" name="additionalContent" part="additionalContent"></slot>`;}


export default block0;