import type { StyleData } from "@ui5/webcomponents-base/dist/types.js";
import { registerThemePropertiesLoader } from "@ui5/webcomponents-base/dist/asset-registries/Themes.js";

import defaultThemeBase from "@ui5/webcomponents-theming/dist/generated/themes/sap_horizon/parameters-bundle.css.js";
import defaultTheme from "./sap_horizon/parameters-bundle.css.js";

registerThemePropertiesLoader("@ui5/webcomponents-theming", "sap_horizon", async () => defaultThemeBase);
registerThemePropertiesLoader("@sapudex/web-components", "sap_horizon", async () => defaultTheme);

const styleData: StyleData = {packageName:"@sapudex/web-components",fileName:"themes/InputGroup.css.ts",content:`.udex-input-group__content{display:flex;flex-direction:column;gap:var(--udexSpacer4)}:host([orientation="Horizontal"]) .udex-input-group__content{flex-direction:row;flex-wrap:wrap;gap:var(--udexSpacer12)}.udex-input-group__content{padding-bottom:var(--udexSpacer4)}.hidden-text{display:none}
`};
export default styleData;
	