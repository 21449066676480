/* eslint no-unused-vars: 0 */
import { html, svg, repeat, classMap, styleMap, ifDefined, unsafeHTML, scopeTag } from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import type UI5Element from "@ui5/webcomponents-base/dist/UI5Element.js";
	type SideNavigationMobileDropdown = any;
	import type { ClassMapValue } from "@ui5/webcomponents-base/dist/types.js";
	
function block0 (this: SideNavigationMobileDropdown, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<${scopeTag("udex-dropdown", tags, suffix)} class="udex-side-navigation-mobile__popover" id="udex-${ifDefined(this._id)}-side-navigation-mobile__popover" placement-type="Bottom" horizontal-align="Stretch" disable-target-overlap="true" height="none" @click-trigger="${this.handleTriggerClick}" @after-open="${this._afterDropdownOpen}" @after-close="${this._afterDropdownClose}"><${scopeTag("udex-side-navigation", tags, suffix)} @ui5-selection-change="${ifDefined(this._handleSelectionChanged)}">${ repeat(this._navItems, (item, index) => (item as typeof item & {_id?: any})._id || index, (item, index: number) => block1.call(this, context, tags, suffix, item, index)) }</${scopeTag("udex-side-navigation", tags, suffix)}></${scopeTag("udex-dropdown", tags, suffix)}>` : html`<udex-dropdown class="udex-side-navigation-mobile__popover" id="udex-${ifDefined(this._id)}-side-navigation-mobile__popover" placement-type="Bottom" horizontal-align="Stretch" disable-target-overlap="true" height="none" @click-trigger="${this.handleTriggerClick}" @after-open="${this._afterDropdownOpen}" @after-close="${this._afterDropdownClose}"><udex-side-navigation @ui5-selection-change="${ifDefined(this._handleSelectionChanged)}">${ repeat(this._navItems, (item, index) => (item as typeof item & {_id?: any})._id || index, (item, index: number) => block1.call(this, context, tags, suffix, item, index)) }</udex-side-navigation></udex-dropdown>`;}
function block1 (this: SideNavigationMobileDropdown, context: UI5Element, tags: string[], suffix: string | undefined, item: any, index: number) { return html`${ifDefined(item)}`;}


export default block0;