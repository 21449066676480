import UI5Element from "@ui5/webcomponents-base/dist/UI5Element.js";
import customElement from "@ui5/webcomponents-base/dist/decorators/customElement.js";
import property from "@ui5/webcomponents-base/dist/decorators/property.js";
import event from "@ui5/webcomponents-base/dist/decorators/event.js";
import litRender from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import Integer from "@ui5/webcomponents-base/dist/types/Integer.js";
import { getI18nBundle } from "@ui5/webcomponents-base/dist/i18nBundle.js";
import type I18nBundle from "@ui5/webcomponents-base/dist/i18nBundle.js";
import {
  OF,
  PAGINATION_PAGE,
  SELECTED,
  ACTIVATE_PAGE,
  FIRST_PAGE,
  LAST_PAGE,
  PREVIOUS_PAGE,
  NEXT_PAGE,
} from "./generated/i18n/i18n-defaults.js";
import Button from "./Button.js";
import "@ui5/webcomponents/dist/Icon.js";
import "@ui5/webcomponents-icons/dist/nav-back.js";
import "@ui5/webcomponents-icons/dist/navigation-right-arrow.js";
import "@ui5/webcomponents-icons/dist/open-command-field.js";
import "@ui5/webcomponents-icons/dist/close-command-field.js";

// Template
import PaginationTemplate from "./generated/templates/PaginationTemplate.lit.js";

// Styles
import PaginationCss from "./generated/themes/Pagination.css";

interface Page {
  value: number;
  active: boolean;
}

export enum PaginationType {
  full = "full",
  short = "short",
}

export enum DisplayType {
  desktop = "desktop",
  mobile = "mobile"
}

/**
 * @class
 *
 * <h3 class="comment-api-title">Overview</h3>
 *
 * The <code>udex-pagination</code> component is a pagination component used to navigate thought the pages.
 *
 * @constructor
 * @extends UI5Element
 * @tagname udex-pagination
 * @public
 */
@customElement({
  tag: "udex-pagination",
  renderer: litRender,
  styles: PaginationCss,
  template: PaginationTemplate,
  dependencies: [Button],
})
/**
 * Fires when pagination item was clicked.
 *
 * @allowPreventDefault
 * @param {Number}
 * @public
 */
@event("change", {
  detail: {
    currentPage: { type: Number },
  },
})
class UDExPagination extends UI5Element {
  /**
   * Defines number of pages.
   *
   * @public
   */
  @property({ validator: Integer })
    pages?: number;

  /**
   * Defines type of pagination.
   *
   * @public
   * @default "full"
   */
  @property({ defaultValue: PaginationType.full, type: PaginationType })
    type?: `${PaginationType}`;

  /**
   * Define display purpose.
   *
   * @public
   * @default "desktop"
   */
  @property({ defaultValue: DisplayType.desktop, type: DisplayType })
    display?: `${DisplayType}`;

  /**
   * Define if arrow icons for last and first page are visible.
   *
   * @public
   */
  @property({ type: Boolean })
    absoluteNavigation!: boolean;

  /**
   * Current index of pagination page.
   * @public
   * @default 0
   */
  @property({ validator: Integer, defaultValue: 0 })
    currentPageIndex: number;

  /**
   * Defines direction of the input.
   *
   * @private
   */
  @property({ type: String })
    _direction?: string;

  _pages: Page[];

  _previousPageElement?: HTMLElement;

  _mobileDisplayNumber: number;

  static i18nBundle: I18nBundle;

  constructor() {
    super();
    this._pages = [];
    this.currentPageIndex = 0;
    this._mobileDisplayNumber = 5;
  }

  get nextButtonDisbled(): boolean {
    return this.currentPageIndex + 1 === this.pages;
  }

  get prevButtonDisbled(): boolean {
    return this.currentPageIndex === 0;
  }

  get typeFull(): boolean {
    return (
      this.type === PaginationType.full || this.display === DisplayType.mobile
    );
  }

  get typeShort(): boolean {
    return (
      this.type === PaginationType.short && this.display !== DisplayType.mobile
    );
  }

  get shortTextStart(): string {
    if (!this.pages) {
      return "";
    }
    return `${this.currentPageIndex + 1}`;
  }

  get shortTextEnd(): string {
    if (!this.pages) {
      return "";
    }
    return `${this.pages}`;
  }

  get shortTextSeparator(): string {
    if (!this.pages) {
      return "";
    }
    return ` ${UDExPagination.i18nBundle.getText(OF)} `;
  }

  get pageAccessibleName(): string {
    return UDExPagination.i18nBundle.getText(PAGINATION_PAGE);
  }

  get paginationShortAccessibleName(): string {
    return `${this.pageAccessibleName} ${this.shortTextStart} ${this.shortTextSeparator} ${this.shortTextEnd}`;
  }

  get activatePageAccessibleText() {
    return UDExPagination.i18nBundle.getText(ACTIVATE_PAGE);
  }

  get firstPageAccessibleName(): string {
    if (this.currentPageIndex === 0) { return UDExPagination.i18nBundle.getText(FIRST_PAGE); }
    return `${UDExPagination.i18nBundle.getText(FIRST_PAGE)}. ${this.activatePageAccessibleText} 1`;
  }

  get lastPageAccessibleName(): string {
    if (this.pages && this.currentPageIndex === (this.pages - 1)) { return UDExPagination.i18nBundle.getText(LAST_PAGE); }
    return `${UDExPagination.i18nBundle.getText(LAST_PAGE)}. ${this.activatePageAccessibleText} ${this.pages}`;
  }

  get previousPageAccessibleName(): string {
    if (this.currentPageIndex === 0) { return UDExPagination.i18nBundle.getText(PREVIOUS_PAGE); }
    return `${UDExPagination.i18nBundle.getText(PREVIOUS_PAGE)}. ${this.activatePageAccessibleText} ${this.currentPageIndex}`;
  }

  get nextPageAccessibleName(): string {
    if (this.pages && this.currentPageIndex === (this.pages - 1)) { return UDExPagination.i18nBundle.getText(NEXT_PAGE); }
    return `${UDExPagination.i18nBundle.getText(NEXT_PAGE)}. ${this.activatePageAccessibleText} ${this.currentPageIndex + 2}`;
  }

  get pagination(): Page[] {
    return this.setupPages(this.pages);
  }

  get paginationClass(): string {
    let classes = "udex-pagination";
    if (this._direction === "rtl") {
      classes = `${classes} udex-pagination--${this._direction}`;
    }
    if (!this.display) {
      return classes;
    }
    return `${classes} udex-pagination--${this.display} udex-pagination--${this.type}`;
  }

  static async onDefine() {
    UDExPagination.i18nBundle = await getI18nBundle("sap-ui-webcomponents-bundle");
  }

  setActive(element: HTMLElement): void {
    const pageIndex = element.getAttribute("index")
      ? parseInt(element.getAttribute("index") || "")
      : null;
    if (pageIndex === null) {
      return;
    }

    element.setAttribute("aria-current", "page");
    this.currentPageIndex = pageIndex;
    this._previousPageElement?.removeAttribute("aria-current");
    this._previousPageElement = element;
  }

  setNextPage(e: Event): void {
    e.preventDefault();
    const currentPage = this.currentPageIndex ?? 1;
    if (this.pages && currentPage >= this.pages - 1) {
      return;
    }
    this.currentPageIndex = currentPage + 1;
    this.emitChangeEvent();
  }

  setPrevPage(e: Event): void {
    e.preventDefault();
    const currentPage = this.currentPageIndex ?? 1;
    if (this.pages && currentPage <= 0) {
      return;
    }
    this.currentPageIndex -= 1;
    this.emitChangeEvent();
  }

  setFirstPage(e: Event): void {
    e.preventDefault();
    const currentPage = this.currentPageIndex ?? 1;
    if (currentPage === 0) {
      return;
    }
    this.currentPageIndex = 0;
    this.emitChangeEvent();
  }

  setLastPage(e: Event): void {
    e.preventDefault();
    const currentPage = this.currentPageIndex ?? 1;
    if (!this.pages || currentPage >= this.pages - 1) {
      return;
    }
    this.currentPageIndex = this.pages - 1;
    this.emitChangeEvent();
  }

  setupMobilePages(pages: number): Page[] {
    const pagesCollection = [];
    let startIndex;
    let endIndex;
    const offset = Math.floor(this._mobileDisplayNumber / 2);

    if (pages <= this._mobileDisplayNumber) {
      startIndex = 0;
      endIndex = pages;
    } else {
      const displayStartNumber = this.currentPageIndex < offset ? 0 : this.currentPageIndex - offset;

      startIndex = this.currentPageIndex <= pages - offset - 1
        ? displayStartNumber
        : pages - this._mobileDisplayNumber;

      const displayEndNumber = this.currentPageIndex < offset
        ? this._mobileDisplayNumber
        : this.currentPageIndex + this._mobileDisplayNumber - offset;

      endIndex = this.currentPageIndex <= pages - offset ? displayEndNumber : pages;
    }

    for (let index = startIndex; index < endIndex && index < pages; index++) {
      const isCurrent = index === this.currentPageIndex;
      const accessibleName = isCurrent ? ` 
      ${this.pageAccessibleName} ${index + 1} ${UDExPagination.i18nBundle.getText(OF)} ${this.pages}, ${UDExPagination.i18nBundle.getText(SELECTED)}` : ` 
      ${this.pageAccessibleName} ${index + 1} ${UDExPagination.i18nBundle.getText(OF)} ${this.pages}`;
      pagesCollection.push({
        index,
        value: index + 1,
        active: isCurrent,
        accessibleName,
      });
    }

    return pagesCollection;
  }

  setupDesktopPages(pages: number): Page[] {
    const pagesCollection = [];
    for (let index = 0; index < pages; index++) {
      const isCurrent = index === this.currentPageIndex;
      const accessibleName = isCurrent ? ` 
      ${this.pageAccessibleName} ${index + 1} ${UDExPagination.i18nBundle.getText(OF)} ${this.pages}, ${UDExPagination.i18nBundle.getText(SELECTED)}` : ` 
      ${this.pageAccessibleName} ${index + 1} ${UDExPagination.i18nBundle.getText(OF)} ${this.pages}`;
      pagesCollection.push({
        index,
        value: index + 1,
        active: isCurrent,
        accessibleName,
      });
    }

    return pagesCollection;
  }

  setupPages(pages?: number): Page[] {
    if (!pages) {
      return [];
    }
    if (this.display === DisplayType.mobile) {
      return this.setupMobilePages(pages);
    }

    return this.setupDesktopPages(pages);
  }

  onPageClick(e: Event) {
    e.preventDefault();
    const target = e.target as HTMLElement;
    const element = target !== e.currentTarget ? (target.parentNode as HTMLElement) : target;
    if (element.getAttribute("active") !== null) {
      return;
    }

    this.setActive(element);
    this.emitChangeEvent();
  }

  emitChangeEvent() {
    this.fireEvent("change", { currentPage: this.currentPageIndex + 1 });
  }

  onAfterRendering() {
    if (this.type === PaginationType.full) {
      return;
    }

    const shortPaginationElement = this.shadowRoot?.querySelector<HTMLElement>(
      ".udex-pagination__item--short",
    );

    if (!shortPaginationElement) {
      return;
    }

    const directionStyles: string = window
      .getComputedStyle(shortPaginationElement)
      .getPropertyValue("direction");
    if (!directionStyles) {
      this._direction = "ltr";
    } else {
      this._direction = directionStyles;
    }
  }
}

UDExPagination.define();

export default UDExPagination;
