import customElement from "@ui5/webcomponents-base/dist/decorators/customElement.js";
import litRender from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import Button from "@ui5/webcomponents/dist/Button.js";

// Styles
import property from "@ui5/webcomponents-base/dist/decorators/property.js";
import ControlButtonCss from "./generated/themes/ControlButton.css.js";

export enum ControlButtonSize {
  Small = "Small",
  Medium = "Medium",
  Large = "Large",
}

/**
 * @class
 *
 * <h3 class="comment-api-title">Overview</h3>
 *
 *
 * <h3>Usage</h3>
 *
 * For the <code>udex-control-button</code>
 * <h3>ES6 Module Import</h3>
 *
 * <code>import "@udex/web-components/dist/ControlButton.js";</code>
 *
 * @constructor
 * @extends Button
 * @public
 */
@customElement({
  tag: "udex-control-button",
  renderer: litRender,
  styles: [Button.styles, ControlButtonCss],
})
class UDExControlButton extends Button {
  /**
   * Defines the size of the button.
   *
   * @default "Medium"
   * @public
   */
  @property({ type: ControlButtonSize, defaultValue: ControlButtonSize.Medium })
    size!: `${ControlButtonSize}`;

  /**
   * Determines whether the component is displayed as read-only.
   * @default false
   * @public
   */
  @property({ type: Boolean })
    readonly!: boolean;
}

UDExControlButton.define();

export default UDExControlButton;
