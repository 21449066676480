/* eslint no-unused-vars: 0 */
import { html, svg, repeat, classMap, styleMap, ifDefined, unsafeHTML, scopeTag } from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import type UI5Element from "@ui5/webcomponents-base/dist/UI5Element.js";
	import type Quote from "../../Quote.js";
	import type { ClassMapValue } from "@ui5/webcomponents-base/dist/types.js";
	
function block0 (this: Quote, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<div class="udex-quote__container"><div class="udex-quote__image-container">${ this.image ? block1.call(this, context, tags, suffix) : undefined }</div><div class="udex-quote__content-container"><div class="content-container__text-container"><p class="content-container__text"><slot></slot></p></div>${ this.author ? block2.call(this, context, tags, suffix) : undefined }${ this.link ? block3.call(this, context, tags, suffix) : undefined }</div></div>`;}
function block1 (this: Quote, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<slot name="image" slot="image"></slot>`;}
function block2 (this: Quote, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<slot slot="author" name="author"></slot>`;}
function block3 (this: Quote, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<slot slot="link" name="link"></slot>`;}


export default block0;