import UDExListItemBase from "../ListItemBase.js";
import UDExListGroupHeaderBase from "../ListGroupHeaderBase.js";

export interface ProcessedItem {
  item: UDExListItemBase | UDExListGroupHeaderBase,
  groupItems: UDExListItemBase[],
  isGroup: boolean,
}

export const SHOW_SEARCH_AFTER_COUNT = 13;
