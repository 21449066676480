import type { StyleData } from "@ui5/webcomponents-base/dist/types.js";
import { registerThemePropertiesLoader } from "@ui5/webcomponents-base/dist/asset-registries/Themes.js";

import defaultThemeBase from "@ui5/webcomponents-theming/dist/generated/themes/sap_horizon/parameters-bundle.css.js";
import defaultTheme from "./sap_horizon/parameters-bundle.css.js";

registerThemePropertiesLoader("@ui5/webcomponents-theming", "sap_horizon", async () => defaultThemeBase);
registerThemePropertiesLoader("@sapudex/web-components", "sap_horizon", async () => defaultTheme);

const styleData: StyleData = {packageName:"@sapudex/web-components",fileName:"themes/MediaPlayerYoutube.css.ts",content:`.udex-media-player__video-embed.udex-media-player__fluid{position:absolute;top:0;left:0;width:100%;height:100%}
`};
export default styleData;
	