import type { StyleData } from "@ui5/webcomponents-base/dist/types.js";
import { registerThemePropertiesLoader } from "@ui5/webcomponents-base/dist/asset-registries/Themes.js";

import defaultThemeBase from "@ui5/webcomponents-theming/dist/generated/themes/sap_horizon/parameters-bundle.css.js";
import defaultTheme from "./sap_horizon/parameters-bundle.css.js";

registerThemePropertiesLoader("@ui5/webcomponents-theming", "sap_horizon", async () => defaultThemeBase);
registerThemePropertiesLoader("@sapudex/web-components", "sap_horizon", async () => defaultTheme);

const styleData: StyleData = {packageName:"@sapudex/web-components",fileName:"themes/SideNavigationMobileDropdown.css.ts",content:`:host{--udex-side-navigation-item-height: 2.5rem;--udex-side-navigation-item-bottom-margin: var(--udexSpacer8);--udex-side-navigation-icon-font-size: 1.25rem;--udex-side-navigation-item-padding-left: var(--udexSpacer16);--udex-side-navigation-mobile-trigger-shadow: var(--sapContent_Shadow1, 0 2px 8px 0 #2236494D);--udex-side-navigation-mobile-trigger-padding-horizontal: var(--udexSpacer16);--udex-side-navigation-mobile-active-trigger-border-color: var(--udexColorBlue7, #0070F2);--udex-side-navigation-mobile-active-arrow-color: var(--udexColorBlue7, #0070F2);--udex-side-navigation-padding-vertical: var(--udexSpacer16);width:100%;font-size:var(--udexTypographyNavigationMediumMFontSize);font-family:var(--sapFontFamily)}.udex-side-navigation-mobile__popover{--udex-dropdown-vertical-distance: 0;--udex-dropdown-box-shadow: var(--sapContent_Shadow1, 0 2px 8px 0 #2236494D);--udex-dropdown-border-radius: 0;--udex-dropdown-border-width: 0;--udex-dropdown-pane-max-width: 100%;--udex-dropdown-pane-overflow: auto}udex-side-navigation{--udex-side-navigation-max-width: 100%;--udex-side-navigation-padding: var(--udex-side-navigation-padding-vertical) 0}
`};
export default styleData;
	