export type Country = {
    locKey: string;
    iso: string;
    phoneFmt: string;
    countryName: string,
    localName?: string,
    phonePrefix: string;
    flagX: string;
    flagY: string;
}

export const CountryListDB = [
  {
    "locKey": "COUNTRY_AF",
    "iso": "AF",
    "phoneFmt": " XXX XXX XXXX",
    "countryName": "Afghanistan",
    "localName": "Afghanistan",
    "phonePrefix": "+93",
    "flagX": "-60px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_AL",
    "iso": "AL",
    "phoneFmt": " XXX XXX XXX",
    "countryName": "Albania",
    "localName": "Albania",
    "phonePrefix": "+355",
    "flagX": "-150px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_DZ",
    "iso": "DZ",
    "phoneFmt": " XXX XX XX XX",
    "countryName": "Algeria",
    "localName": "\u0f68\u0f71\u0f63\u0f0b\u0f47\u0f72\u0f0b\u0f62\u0f72\u0f0b\u0f61\u0f0d",
    "phonePrefix": "+213",
    "flagX": "-1800px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_AS",
    "iso": "AS",
    "phoneFmt": " (XXX) XXX-XXXX",
    "countryName": "American Samoa",
    "localName": "\u0986\u09ae\u09c7\u09f0\u09bf\u0995\u09be\u09a8 \u099b\u09be\u09ae\u09cb\u0986",
    "phonePrefix": "+1",
    "flagX": "-270px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_AD",
    "iso": "AD",
    "phoneFmt": " XXX XXX",
    "countryName": "Andorra",
    "localName": "Andorra",
    "phonePrefix": "+376",
    "flagX": "0px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_AO",
    "iso": "AO",
    "phoneFmt": " XXX XXX XXX",
    "countryName": "Angola",
    "localName": "Angola",
    "phonePrefix": "+244",
    "flagX": "-210px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_AI",
    "iso": "AI",
    "phoneFmt": " (XXX) XXX-XXXX",
    "countryName": "Anguilla",
    "localName": "Anguilla",
    "phonePrefix": "+1",
    "flagX": "-120px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_AG",
    "iso": "AG",
    "phoneFmt": " (XXX) XXX-XXXX",
    "countryName": "Antigua and Barbuda",
    "localName": "Antigua and Barbuda",
    "phonePrefix": "+1",
    "flagX": "-90px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_AR",
    "iso": "AR",
    "phoneFmt": " XXX XXXX-XXXX",
    "countryName": "Argentina",
    "localName": "\u0627\u0644\u0623\u0631\u062c\u0646\u062a\u064a\u0646",
    "phonePrefix": "+54",
    "flagX": "-240px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_AM",
    "iso": "AM",
    "phoneFmt": " (XXX) XXXXXX",
    "countryName": "Armenia",
    "localName": "\u1310\u1234\u1223\u1292\u1362",
    "phonePrefix": "+374",
    "flagX": "-180px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_AW",
    "iso": "AW",
    "phoneFmt": " XXX XXXX",
    "countryName": "Aruba",
    "localName": "Aruba",
    "phonePrefix": "+297",
    "flagX": "-360px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_AU",
    "iso": "AU",
    "phoneFmt": " (XX) XXXX XXXX",
    "countryName": "Australia",
    "localName": "Australia",
    "phonePrefix": "+61",
    "flagX": "-330px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_AT",
    "iso": "AT",
    "phoneFmt": " XX XXXXXXXXX",
    "countryName": "Austria",
    "localName": "Austria",
    "phonePrefix": "+43",
    "flagX": "-300px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_AZ",
    "iso": "AZ",
    "phoneFmt": " (XXX) XXX XX XX",
    "countryName": "Azerbaijan",
    "localName": "Az\u0259rbaycan",
    "phonePrefix": "+994",
    "flagX": "-420px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_BS",
    "iso": "BS",
    "phoneFmt": " (XXX) XXX-XXXX",
    "countryName": "Bahamas",
    "localName": "Bahami",
    "phonePrefix": "+1",
    "flagX": "-900px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_BH",
    "iso": "BH",
    "phoneFmt": " XXXX XXXX",
    "countryName": "Bahrain",
    "localName": "Bahrain",
    "phonePrefix": "+973",
    "flagX": "-630px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_BD",
    "iso": "BD",
    "phoneFmt": " XX-XXXXXXX",
    "countryName": "Bangladesh",
    "localName": "Bangladesh",
    "phonePrefix": "+880",
    "flagX": "-510px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_BB",
    "iso": "BB",
    "phoneFmt": " (XXX) XXX-XXXX",
    "countryName": "Barbados",
    "localName": "Barbados",
    "phonePrefix": "+1",
    "flagX": "-480px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_BY",
    "iso": "BY",
    "phoneFmt": " X XXXX XX-XX-XX",
    "countryName": "Belarus",
    "localName": "Belarus",
    "phonePrefix": "+375",
    "flagX": "-1020px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_BE",
    "iso": "BE",
    "phoneFmt": " XXX XX XX XX",
    "countryName": "Belgium",
    "localName": "\u0411\u0435\u043b\u044c\u0433\u0456\u044f",
    "phonePrefix": "+32",
    "flagX": "-540px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_BZ",
    "iso": "BZ",
    "phoneFmt": " XXX-XXXX",
    "countryName": "Belize",
    "localName": "Belize",
    "phonePrefix": "+501",
    "flagX": "-1050px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_BJ",
    "iso": "BJ",
    "phoneFmt": " XX XX XX XX",
    "countryName": "Benin",
    "localName": "Benin",
    "phonePrefix": "+229",
    "flagX": "-690px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_BM",
    "iso": "BM",
    "phoneFmt": " (XXX) XXX-XXXX",
    "countryName": "Bermuda",
    "localName": "Bermuda",
    "phonePrefix": "+1",
    "flagX": "-750px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_BT",
    "iso": "BT",
    "phoneFmt": " X XXX XXX",
    "countryName": "Bhutan",
    "localName": "Bhutan",
    "phonePrefix": "+975",
    "flagX": "-930px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_BO",
    "iso": "BO",
    "phoneFmt": " X XXXXXXX",
    "countryName": "Bolivia, Plurinational State of",
    "localName": "Bolivia, Plurinational State of",
    "phonePrefix": "+591",
    "flagX": "-810px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_BQ",
    "iso": "BQ",
    "phoneFmt": " XXX XXXX",
    "countryName": "Bonaire, Sint Eustatius and Saba",
    "localName": "Bonaire, Sint Eustatius and Saba",
    "phonePrefix": "+599",
    "flagX": "-840px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_BA",
    "iso": "BA",
    "phoneFmt": " XXX XXX-XXX",
    "countryName": "Bosnia and Herzegovina",
    "localName": "Bosnia and Herzegovina",
    "phonePrefix": "+387",
    "flagX": "-450px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_BW",
    "iso": "BW",
    "phoneFmt": " XXX XXXX",
    "countryName": "Botswana",
    "localName": "Botswana",
    "phonePrefix": "+267",
    "flagX": "-990px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_BV",
    "iso": "BV",
    "phoneFmt": " XXXXXXXXX",
    "countryName": "Bouvet Island",
    "localName": "Bouvet Island",
    "phonePrefix": "+0",
    "flagX": "-960px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_BR",
    "iso": "BR",
    "phoneFmt": " (XX) XXXX-XXXX",
    "countryName": "Brazil",
    "localName": "Brazil",
    "phonePrefix": "+55",
    "flagX": "-870px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_IO",
    "iso": "IO",
    "phoneFmt": " XXX XXXX",
    "countryName": "British Indian Ocean Territory",
    "localName": "Britaniana teritorio en Indiana Oceano",
    "phonePrefix": "+246",
    "flagX": "-3120px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_BN",
    "iso": "BN",
    "phoneFmt": " XXX XXXX",
    "countryName": "Brunei Darussalam",
    "localName": "\u09ac\u09cd\u09b0\u09c1\u09a8\u09c7\u0987 \u09a6\u09be\u09b0\u09c1\u09b8\u09b2\u09be\u09ae",
    "phonePrefix": "+673",
    "flagX": "-780px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_BG",
    "iso": "BG",
    "phoneFmt": " XX XXX XXX",
    "countryName": "Bulgaria",
    "localName": "\u0411\u044a\u043b\u0433\u0430\u0440\u0438\u044f",
    "phonePrefix": "+359",
    "flagX": "-600px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_BF",
    "iso": "BF",
    "phoneFmt": " XX XX XX XX",
    "countryName": "Burkina Faso",
    "localName": "Burkina Faso",
    "phonePrefix": "+226",
    "flagX": "-570px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_BI",
    "iso": "BI",
    "phoneFmt": " XX XX XX XX",
    "countryName": "Burundi",
    "localName": "Burundi",
    "phonePrefix": "+257",
    "flagX": "-660px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_CV",
    "iso": "CV",
    "phoneFmt": " XXX XX XX",
    "countryName": "Cabo Verde",
    "localName": "Cabo Verde",
    "phonePrefix": "+238",
    "flagX": "-1500px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_KH",
    "iso": "KH",
    "phoneFmt": " XXX XXX XXX",
    "countryName": "Cambodia",
    "localName": "Cambodia",
    "phonePrefix": "+855",
    "flagX": "-3450px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_CM",
    "iso": "CM",
    "phoneFmt": " X XX XX XX XX",
    "countryName": "Cameroon",
    "localName": "Cameroon",
    "phonePrefix": "+237",
    "flagX": "-1350px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_CA",
    "iso": "CA",
    "phoneFmt": " (XXX) XXX-XXXX",
    "countryName": "Canada",
    "localName": "Canad\u00e0",
    "phonePrefix": "+1",
    "flagX": "-1080px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_KY",
    "iso": "KY",
    "phoneFmt": " (XXX) XXX-XXXX",
    "countryName": "Cayman Islands",
    "localName": "\u041a\u0430\u0439\u043c\u0430\u043d \u0430\u0440\u0430\u043b\u0434\u0430\u0440\u044b",
    "phonePrefix": "+1",
    "flagX": "-3660px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_CF",
    "iso": "CF",
    "phoneFmt": " XX XX XX XX",
    "countryName": "Central African Republic",
    "localName": "Central African Republic",
    "phonePrefix": "+236",
    "flagX": "-1170px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_TD",
    "iso": "TD",
    "phoneFmt": " XX XX XX XX",
    "countryName": "Chad",
    "localName": "Chad",
    "phonePrefix": "+235",
    "flagX": "-6390px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_CL",
    "iso": "CL",
    "phoneFmt": " (X) XXXX XXXX",
    "countryName": "Chile",
    "localName": "Chile",
    "phonePrefix": "+56",
    "flagX": "-1320px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_CN",
    "iso": "CN",
    "phoneFmt": " XXX XXXX XXXX",
    "countryName": "China",
    "localName": "China",
    "phonePrefix": "+86",
    "flagX": "-1380px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_CX",
    "iso": "CX",
    "phoneFmt": " (XX) XXXX XXXX",
    "countryName": "Christmas Island",
    "localName": "Christmas Island",
    "phonePrefix": "+61",
    "flagX": "-1560px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_CC",
    "iso": "CC",
    "phoneFmt": " (XX) XXXX XXXX",
    "countryName": "Cocos (Keeling) Islands",
    "localName": "Cocos (Keeling) Islands",
    "phonePrefix": "+61",
    "flagX": "-1110px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_CO",
    "iso": "CO",
    "phoneFmt": " (XXX) XXXXXXX",
    "countryName": "Colombia",
    "localName": "Colombia",
    "phonePrefix": "+57",
    "flagX": "-1410px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_KM",
    "iso": "KM",
    "phoneFmt": " XXX XX XX",
    "countryName": "Comoros",
    "localName": "\u1780\u17bb\u17c6\u1798\u17c9\u17bc\u179a\u17c9\u17bc\u179f",
    "phonePrefix": "+269",
    "flagX": "-3510px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_CG",
    "iso": "CG",
    "phoneFmt": " XX XXX XXXX",
    "countryName": "Congo",
    "localName": "Congo",
    "phonePrefix": "+242",
    "flagX": "-1200px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_CD",
    "iso": "CD",
    "phoneFmt": " XXX XXXXX",
    "countryName": "Congo, The Democratic Republic of the",
    "localName": "Congo, The Democratic Republic of the",
    "phonePrefix": "+243",
    "flagX": "-1140px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_CK",
    "iso": "CK",
    "phoneFmt": " XX XXX",
    "countryName": "Cook Islands",
    "localName": "Cook Islands",
    "phonePrefix": "+682",
    "flagX": "-1290px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_CR",
    "iso": "CR",
    "phoneFmt": " XXXX XXXX",
    "countryName": "Costa Rica",
    "localName": "Costa Rica",
    "phonePrefix": "+506",
    "flagX": "-1440px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_HR",
    "iso": "HR",
    "phoneFmt": " XX XXXX XXX",
    "countryName": "Croatia",
    "localName": "Hrvatska",
    "phonePrefix": "+385",
    "flagX": "-2880px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_CU",
    "iso": "CU",
    "phoneFmt": " (XX) XXXXXXX",
    "countryName": "Cuba",
    "localName": "Cuba",
    "phonePrefix": "+53",
    "flagX": "-1470px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_CW",
    "iso": "CW",
    "phoneFmt": " X XXX XXXX",
    "countryName": "Cura\u00e7ao",
    "localName": "Cura\u00e7ao",
    "phonePrefix": "+599",
    "flagX": "-1530px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_CY",
    "iso": "CY",
    "phoneFmt": " XX XXXXXX",
    "countryName": "Cyprus",
    "localName": "Cyprus",
    "phonePrefix": "+357",
    "flagX": "-1590px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_CZ",
    "iso": "CZ",
    "phoneFmt": " XXX XXX XXX",
    "countryName": "Czechia",
    "localName": "\u010cesko",
    "phonePrefix": "+420",
    "flagX": "-1620px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_CI",
    "iso": "CI",
    "phoneFmt": " XX XX X XXXXX",
    "countryName": "C\u00f4te d'Ivoire",
    "localName": "C\u00f4te d'Ivoire",
    "phonePrefix": "+225",
    "flagX": "-1260px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_DK",
    "iso": "DK",
    "phoneFmt": " XX XX XX XX",
    "countryName": "Denmark",
    "localName": "Denmark",
    "phonePrefix": "+45",
    "flagX": "-1710px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_DJ",
    "iso": "DJ",
    "phoneFmt": " XX XX XX XX",
    "countryName": "Djibouti",
    "localName": "Djibouti",
    "phonePrefix": "+253",
    "flagX": "-1680px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_DM",
    "iso": "DM",
    "phoneFmt": " (XXX) XXX-XXXX",
    "countryName": "Dominica",
    "localName": "Dominica",
    "phonePrefix": "+1",
    "flagX": "-1740px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_DO",
    "iso": "DO",
    "phoneFmt": " (XXX) XXX-XXXX",
    "countryName": "Dominican Republic",
    "localName": "Dominican Republic",
    "phonePrefix": "+1",
    "flagX": "-1770px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_EC",
    "iso": "EC",
    "phoneFmt": " (XX) XXX-XXXX",
    "countryName": "Ecuador",
    "localName": "Ecuador",
    "phonePrefix": "+593",
    "flagX": "-1830px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_EG",
    "iso": "EG",
    "phoneFmt": " XX XXXXXXXX",
    "countryName": "Egypt",
    "localName": "Egypt",
    "phonePrefix": "+20",
    "flagX": "-1890px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_SV",
    "iso": "SV",
    "phoneFmt": " XXXX XXXX",
    "countryName": "El Salvador",
    "localName": "El Salvador",
    "phonePrefix": "+503",
    "flagX": "-6240px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_GQ",
    "iso": "GQ",
    "phoneFmt": " XXX XXX XXX",
    "countryName": "Equatorial Guinea",
    "localName": "Equatorial Guinea",
    "phonePrefix": "+240",
    "flagX": "-2580px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_ER",
    "iso": "ER",
    "phoneFmt": " XX XXX XXX",
    "countryName": "Eritrea",
    "localName": "Eritrea",
    "phonePrefix": "+291",
    "flagX": "-1950px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_EE",
    "iso": "EE",
    "phoneFmt": " XXX XXXX",
    "countryName": "Estonia",
    "localName": "Estonia",
    "phonePrefix": "+372",
    "flagX": "-1860px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_SZ",
    "iso": "SZ",
    "phoneFmt": " XXXX XXXX",
    "countryName": "Eswatini",
    "localName": "Eswatini",
    "phonePrefix": "+268",
    "flagX": "-6330px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_ET",
    "iso": "ET",
    "phoneFmt": " XXX XXX XXXX",
    "countryName": "Ethiopia",
    "localName": "Etioopia",
    "phonePrefix": "+251",
    "flagX": "-2010px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_FK",
    "iso": "FK",
    "phoneFmt": " XXXXX",
    "countryName": "Falkland Islands (Malvinas)",
    "localName": "Falkland Islands (Malvinas)",
    "phonePrefix": "+500",
    "flagX": "-2100px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_FO",
    "iso": "FO",
    "phoneFmt": " XXXXXX",
    "countryName": "Faroe Islands",
    "localName": "F\u00f8royar",
    "phonePrefix": "+298",
    "flagX": "-2160px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_FJ",
    "iso": "FJ",
    "phoneFmt": " XXX XXXX",
    "countryName": "Fiji",
    "localName": "Fiji",
    "phonePrefix": "+679",
    "flagX": "-2070px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_FI",
    "iso": "FI",
    "phoneFmt": " XXX XXXXXXX",
    "countryName": "Finland",
    "localName": "Suomi",
    "phonePrefix": "+358",
    "flagX": "-2040px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_FR",
    "iso": "FR",
    "phoneFmt": " XX XX XX XX XX",
    "countryName": "France",
    "localName": "France",
    "phonePrefix": "+33",
    "flagX": "-2190px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_GF",
    "iso": "GF",
    "phoneFmt": " XXXX XX XX XX",
    "countryName": "French Guiana",
    "localName": "French Guiana",
    "phonePrefix": "+594",
    "flagX": "-2340px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_PF",
    "iso": "PF",
    "phoneFmt": " XX XX XX XX",
    "countryName": "French Polynesia",
    "localName": "French Polynesia",
    "phonePrefix": "+689",
    "flagX": "-5190px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_TF",
    "iso": "TF",
    "phoneFmt": " XXXXXXXXX",
    "countryName": "French Southern Territories",
    "localName": "French Southern Territories",
    "phonePrefix": "+0",
    "flagX": "-6420px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_GA",
    "iso": "GA",
    "phoneFmt": " XX XX XX XX",
    "countryName": "Gabon",
    "localName": "An Ghab\u00fain",
    "phonePrefix": "+241",
    "flagX": "-2220px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_GM",
    "iso": "GM",
    "phoneFmt": " XXX XXXX",
    "countryName": "Gambia",
    "localName": "Gambia",
    "phonePrefix": "+220",
    "flagX": "-2490px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_GE",
    "iso": "GE",
    "phoneFmt": " XXX XXX XX XX",
    "countryName": "Georgia",
    "localName": "Georgia",
    "phonePrefix": "+995",
    "flagX": "-2310px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_DE",
    "iso": "DE",
    "phoneFmt": " XXX XXXXXX",
    "countryName": "Germany",
    "localName": "Deutschland",
    "phonePrefix": "+49",
    "flagX": "-1650px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_GH",
    "iso": "GH",
    "phoneFmt": " XXX XXX XXXX",
    "countryName": "Ghana",
    "localName": "Ghana",
    "phonePrefix": "+233",
    "flagX": "-2400px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_GI",
    "iso": "GI",
    "phoneFmt": " XXX XXXXX",
    "countryName": "Gibraltar",
    "localName": "Gibraltar",
    "phonePrefix": "+350",
    "flagX": "-2430px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_GR",
    "iso": "GR",
    "phoneFmt": " XX XXXX XXXX",
    "countryName": "Greece",
    "localName": "Greece",
    "phonePrefix": "+30",
    "flagX": "-2610px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_GL",
    "iso": "GL",
    "phoneFmt": " XX XX XX",
    "countryName": "Greenland",
    "localName": "Grenlandia",
    "phonePrefix": "+299",
    "flagX": "-2460px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_GD",
    "iso": "GD",
    "phoneFmt": " (XXX) XXX-XXXX",
    "countryName": "Grenada",
    "localName": "Grenada",
    "phonePrefix": "+1",
    "flagX": "-2280px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_GP",
    "iso": "GP",
    "phoneFmt": " XXXX XX XX XX",
    "countryName": "Guadeloupe",
    "localName": "Guadeloupe",
    "phonePrefix": "+590",
    "flagX": "-2550px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_GU",
    "iso": "GU",
    "phoneFmt": " (XXX) XXX-XXXX",
    "countryName": "Guam",
    "localName": "\u0a97\u0ac1\u0a86\u0aae",
    "phonePrefix": "+1",
    "flagX": "-2700px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_GT",
    "iso": "GT",
    "phoneFmt": " XXXX XXXX",
    "countryName": "Guatemala",
    "localName": "Guatemala",
    "phonePrefix": "+502",
    "flagX": "-2670px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_GG",
    "iso": "GG",
    "phoneFmt": " XXXXX XXXXXX",
    "countryName": "Guernsey",
    "localName": "Guernsey",
    "phonePrefix": "+44",
    "flagX": "-2370px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_GN",
    "iso": "GN",
    "phoneFmt": " XX XX XX XX",
    "countryName": "Guinea",
    "localName": "Guinea",
    "phonePrefix": "+224",
    "flagX": "-2520px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_GW",
    "iso": "GW",
    "phoneFmt": " XXX XXX XXX",
    "countryName": "Guinea-Bissau",
    "localName": "Guinea-Bissau",
    "phonePrefix": "+245",
    "flagX": "-2730px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_GY",
    "iso": "GY",
    "phoneFmt": " XXX XXXX",
    "countryName": "Guyana",
    "localName": "Guyana",
    "phonePrefix": "+592",
    "flagX": "-2760px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_HT",
    "iso": "HT",
    "phoneFmt": " XX XX XXXX",
    "countryName": "Haiti",
    "localName": "Ayiti",
    "phonePrefix": "+509",
    "flagX": "-2910px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_HM",
    "iso": "HM",
    "phoneFmt": " XXXXXXXXX",
    "countryName": "Heard Island and McDonald Islands",
    "localName": "Heard Island and McDonald Islands",
    "phonePrefix": "+0",
    "flagX": "-2820px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_VA",
    "iso": "VA",
    "phoneFmt": " XX XXXX XXXX",
    "countryName": "Holy See (Vatican City State)",
    "localName": "Holy See (Vatican City State)",
    "phonePrefix": "+39",
    "flagX": "-7020px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_HN",
    "iso": "HN",
    "phoneFmt": " XXXX-XXXX",
    "countryName": "Honduras",
    "localName": "Honduras",
    "phonePrefix": "+504",
    "flagX": "-2850px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_HK",
    "iso": "HK",
    "phoneFmt": " XXXX XXXX",
    "countryName": "Hong Kong",
    "localName": "Hong Kong",
    "phonePrefix": "+852",
    "flagX": "-2790px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_HU",
    "iso": "HU",
    "phoneFmt": " (XX X) XXX XXXX",
    "countryName": "Hungary",
    "localName": "Magyarorsz\u00e1g",
    "phonePrefix": "+36",
    "flagX": "-2940px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_IS",
    "iso": "IS",
    "phoneFmt": " XXX XXXX",
    "countryName": "Iceland",
    "localName": "\u00cdsland",
    "phonePrefix": "+354",
    "flagX": "-3210px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_IN",
    "iso": "IN",
    "phoneFmt": " XXXXXX XXXXX",
    "countryName": "India",
    "localName": "India",
    "phonePrefix": "+91",
    "flagX": "-3090px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_ID",
    "iso": "ID",
    "phoneFmt": " (XXX) XXXXXXX",
    "countryName": "Indonesia",
    "localName": "Indonesia",
    "phonePrefix": "+62",
    "flagX": "-2970px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_IR",
    "iso": "IR",
    "phoneFmt": " XXX XXXX XXXX",
    "countryName": "Iran, Islamic Republic of",
    "localName": "Iran, Islamic Republic of",
    "phonePrefix": "+98",
    "flagX": "-3180px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_IQ",
    "iso": "IQ",
    "phoneFmt": " XX XXX XXXX",
    "countryName": "Iraq",
    "localName": "Iraq",
    "phonePrefix": "+964",
    "flagX": "-3150px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_IE",
    "iso": "IE",
    "phoneFmt": " (XXX) XXXXX",
    "countryName": "Ireland",
    "localName": "Ireland",
    "phonePrefix": "+353",
    "flagX": "-3000px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_IM",
    "iso": "IM",
    "phoneFmt": " XXXXX XXXXXX",
    "countryName": "Isle of Man",
    "localName": "Isle of Man",
    "phonePrefix": "+44",
    "flagX": "-3060px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_IL",
    "iso": "IL",
    "phoneFmt": " XX-XXX-XXXX",
    "countryName": "Israel",
    "localName": "Israel",
    "phonePrefix": "+972",
    "flagX": "-3030px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_IT",
    "iso": "IT",
    "phoneFmt": " XX XXXX XXXX",
    "countryName": "Italy",
    "localName": "Italia",
    "phonePrefix": "+39",
    "flagX": "-3240px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_JM",
    "iso": "JM",
    "phoneFmt": " (XXX) XXX-XXXX",
    "countryName": "Jamaica",
    "localName": "Jamaica",
    "phonePrefix": "+1",
    "flagX": "-3300px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_JP",
    "iso": "JP",
    "phoneFmt": " XX-XXXX-XXXX",
    "countryName": "Japan",
    "localName": "Japan",
    "phonePrefix": "+81",
    "flagX": "-3360px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_JE",
    "iso": "JE",
    "phoneFmt": " XXXXX XXXXXX",
    "countryName": "Jersey",
    "localName": "Jersey",
    "phonePrefix": "+44",
    "flagX": "-3270px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_JO",
    "iso": "JO",
    "phoneFmt": " (XX) XXX XXXX",
    "countryName": "Jordan",
    "localName": "Jordan",
    "phonePrefix": "+962",
    "flagX": "-3330px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_KZ",
    "iso": "KZ",
    "phoneFmt": " X (XXXXX) X XX XX",
    "countryName": "Kazakhstan",
    "localName": "Kazakhstan",
    "phonePrefix": "+7",
    "flagX": "-3690px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_KE",
    "iso": "KE",
    "phoneFmt": " XXX XXXXXXX",
    "countryName": "Kenya",
    "localName": "Kenya",
    "phonePrefix": "+254",
    "flagX": "-3390px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_KI",
    "iso": "KI",
    "phoneFmt": " XXXXX",
    "countryName": "Kiribati",
    "localName": "Kiribati",
    "phonePrefix": "+686",
    "flagX": "-3480px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_KP",
    "iso": "KP",
    "phoneFmt": " XX XXX XXXX",
    "countryName": "Korea, Democratic People's Republic of",
    "localName": "Korea, Democratic People's Republic of",
    "phonePrefix": "+850",
    "flagX": "-3570px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_KR",
    "iso": "KR",
    "phoneFmt": " XX-XXX-XXXX",
    "countryName": "Korea, Republic of",
    "localName": "Korea, Republic of",
    "phonePrefix": "+82",
    "flagX": "-3600px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_KW",
    "iso": "KW",
    "phoneFmt": " XXXX XXXX",
    "countryName": "Kuwait",
    "localName": "Koweyt",
    "phonePrefix": "+965",
    "flagX": "-3630px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_KG",
    "iso": "KG",
    "phoneFmt": " XXXX XXX XXX",
    "countryName": "Kyrgyzstan",
    "localName": "Kyrgyzstan",
    "phonePrefix": "+996",
    "flagX": "-3420px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_LA",
    "iso": "LA",
    "phoneFmt": " XXX XXX XXX",
    "countryName": "Lao People's Democratic Republic",
    "localName": "Lao People's Democratic Republic",
    "phonePrefix": "+856",
    "flagX": "-3720px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_LV",
    "iso": "LV",
    "phoneFmt": " XX XXX XXX",
    "countryName": "Latvia",
    "localName": "Latvija",
    "phonePrefix": "+371",
    "flagX": "-3990px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_LB",
    "iso": "LB",
    "phoneFmt": " XX XXX XXX",
    "countryName": "Lebanon",
    "localName": "Lebanon",
    "phonePrefix": "+961",
    "flagX": "-3750px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_LS",
    "iso": "LS",
    "phoneFmt": " XXXX XXXX",
    "countryName": "Lesotho",
    "localName": "Lesotho",
    "phonePrefix": "+266",
    "flagX": "-3900px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_LR",
    "iso": "LR",
    "phoneFmt": " XXX XXX XXX",
    "countryName": "Liberia",
    "localName": "Liberia",
    "phonePrefix": "+231",
    "flagX": "-3870px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_LY",
    "iso": "LY",
    "phoneFmt": " XXX-XXXXXXX",
    "countryName": "Libya",
    "localName": "Libya",
    "phonePrefix": "+218",
    "flagX": "-4020px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_LI",
    "iso": "LI",
    "phoneFmt": " XXX XX XX",
    "countryName": "Liechtenstein",
    "localName": "Liechtenstein",
    "phonePrefix": "+423",
    "flagX": "-3810px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_LT",
    "iso": "LT",
    "phoneFmt": " (X-XXX) XXXXX",
    "countryName": "Lithuania",
    "localName": "Lietuva",
    "phonePrefix": "+370",
    "flagX": "-3930px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_LU",
    "iso": "LU",
    "phoneFmt": " XX XX XX XX",
    "countryName": "Luxembourg",
    "localName": "Luxembourg",
    "phonePrefix": "+352",
    "flagX": "-3960px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_MO",
    "iso": "MO",
    "phoneFmt": " XXXX XXXX",
    "countryName": "Macao",
    "localName": "Macao",
    "phonePrefix": "+853",
    "flagX": "-4380px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_MG",
    "iso": "MG",
    "phoneFmt": " XXX XX XXX XX",
    "countryName": "Madagascar",
    "localName": "Madagascar",
    "phonePrefix": "+261",
    "flagX": "-4200px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_MW",
    "iso": "MW",
    "phoneFmt": " XX XXX XXX",
    "countryName": "Malawi",
    "localName": "Malawi",
    "phonePrefix": "+265",
    "flagX": "-4620px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_MY",
    "iso": "MY",
    "phoneFmt": " XX-XXXX XXXX",
    "countryName": "Malaysia",
    "localName": "\u1019\u101c\u1031\u1038\u101b\u103e\u102c\u1038\u1014\u102d\u102f\u1004\u103a\u1004\u1036",
    "phonePrefix": "+60",
    "flagX": "-4680px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_MV",
    "iso": "MV",
    "phoneFmt": " XXX-XXXX",
    "countryName": "Maldives",
    "localName": "Maldives",
    "phonePrefix": "+960",
    "flagX": "-4590px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_ML",
    "iso": "ML",
    "phoneFmt": " XX XX XX XX",
    "countryName": "Mali",
    "localName": "\u0d2e\u0d3e\u0d32\u0d3f",
    "phonePrefix": "+223",
    "flagX": "-4290px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_MT",
    "iso": "MT",
    "phoneFmt": " XXXX XXXX",
    "countryName": "Malta",
    "localName": "Malta",
    "phonePrefix": "+356",
    "flagX": "-4530px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_MH",
    "iso": "MH",
    "phoneFmt": " XXX-XXXX",
    "countryName": "Marshall Islands",
    "localName": "Marshall Islands",
    "phonePrefix": "+692",
    "flagX": "-4230px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_MQ",
    "iso": "MQ",
    "phoneFmt": " XXXX XX XX XX",
    "countryName": "Martinique",
    "localName": "Martinique",
    "phonePrefix": "+596",
    "flagX": "-4440px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_MR",
    "iso": "MR",
    "phoneFmt": " XX XX XX XX",
    "countryName": "Mauritania",
    "localName": "\u092e\u0949\u0930\u093f\u091f\u093e\u0928\u093f\u092f\u093e",
    "phonePrefix": "+222",
    "flagX": "-4470px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_MU",
    "iso": "MU",
    "phoneFmt": " XXXX XXXX",
    "countryName": "Mauritius",
    "localName": "Mauritius",
    "phonePrefix": "+230",
    "flagX": "-4560px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_YT",
    "iso": "YT",
    "phoneFmt": " XXXX XX XX XX",
    "countryName": "Mayotte",
    "localName": "Mayotte",
    "phonePrefix": "+262",
    "flagX": "-7320px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_MX",
    "iso": "MX",
    "phoneFmt": " XXX XXX XXXX",
    "countryName": "Mexico",
    "localName": "Mexico",
    "phonePrefix": "+52",
    "flagX": "-4650px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_FM",
    "iso": "FM",
    "phoneFmt": " XXX XXXX",
    "countryName": "Micronesia, Federated States of",
    "localName": "Micronesia, Federated States of",
    "phonePrefix": "+691",
    "flagX": "-2130px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_MD",
    "iso": "MD",
    "phoneFmt": " XXX XXX XXX",
    "countryName": "Moldova, Republic of",
    "localName": "Moldova, Republic of",
    "phonePrefix": "+373",
    "flagX": "-4110px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_MC",
    "iso": "MC",
    "phoneFmt": " XX XX XX XX",
    "countryName": "Monaco",
    "localName": "Monaco",
    "phonePrefix": "+377",
    "flagX": "-4080px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_MN",
    "iso": "MN",
    "phoneFmt": " XXXX XXXX",
    "countryName": "Mongolia",
    "localName": "\u041c\u043e\u043d\u0433\u043e\u043b",
    "phonePrefix": "+976",
    "flagX": "-4350px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_ME",
    "iso": "ME",
    "phoneFmt": " XXX XXX XXX",
    "countryName": "Montenegro",
    "localName": "Montenegro",
    "phonePrefix": "+382",
    "flagX": "-4140px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_MS",
    "iso": "MS",
    "phoneFmt": " (XXX) XXX-XXXX",
    "countryName": "Montserrat",
    "localName": "Montserrat",
    "phonePrefix": "+1",
    "flagX": "-4500px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_MA",
    "iso": "MA",
    "phoneFmt": " XXXX-XXXXXX",
    "countryName": "Morocco",
    "localName": "Morocco",
    "phonePrefix": "+212",
    "flagX": "-4050px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_MZ",
    "iso": "MZ",
    "phoneFmt": " XX XXX XXX",
    "countryName": "Mozambique",
    "localName": "Mozambique",
    "phonePrefix": "+258",
    "flagX": "-4710px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_MM",
    "iso": "MM",
    "phoneFmt": " XX XXX XXX",
    "countryName": "Myanmar",
    "localName": "Myanmar",
    "phonePrefix": "+95",
    "flagX": "-4320px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_NA",
    "iso": "NA",
    "phoneFmt": " XXX XXX XXX",
    "countryName": "Namibia",
    "localName": "Namibiya",
    "phonePrefix": "+264",
    "flagX": "-4740px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_NR",
    "iso": "NR",
    "phoneFmt": " XXX XXXX",
    "countryName": "Nauru",
    "localName": "Nauru",
    "phonePrefix": "+674",
    "flagX": "-5010px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_NP",
    "iso": "NP",
    "phoneFmt": " XX-XXXXXXX",
    "countryName": "Nepal",
    "localName": "Nepal",
    "phonePrefix": "+977",
    "flagX": "-4980px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_NL",
    "iso": "NL",
    "phoneFmt": " XXX XXX XXXX",
    "countryName": "Netherlands",
    "localName": "Nederland",
    "phonePrefix": "+31",
    "flagX": "-4920px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_NC",
    "iso": "NC",
    "phoneFmt": " XX.XX.XX",
    "countryName": "New Caledonia",
    "localName": "New Caledonia",
    "phonePrefix": "+687",
    "flagX": "-4770px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_NZ",
    "iso": "NZ",
    "phoneFmt": " XX XXX XXXX",
    "countryName": "New Zealand",
    "localName": "New Zealand",
    "phonePrefix": "+64",
    "flagX": "-5070px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_NI",
    "iso": "NI",
    "phoneFmt": " XXXX XXXX",
    "countryName": "Nicaragua",
    "localName": "Nicaragua",
    "phonePrefix": "+505",
    "flagX": "-4890px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_NE",
    "iso": "NE",
    "phoneFmt": " XX XX XX XX",
    "countryName": "Niger",
    "localName": "\u0928\u093f\u0917\u0930",
    "phonePrefix": "+227",
    "flagX": "-4800px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_NG",
    "iso": "NG",
    "phoneFmt": " XX XXX XXXX",
    "countryName": "Nigeria",
    "localName": "Nigeria",
    "phonePrefix": "+234",
    "flagX": "-4860px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_NU",
    "iso": "NU",
    "phoneFmt": " XXXX",
    "countryName": "Niue",
    "localName": "Niue",
    "phonePrefix": "+683",
    "flagX": "-5040px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_NF",
    "iso": "NF",
    "phoneFmt": " XX XXXX",
    "countryName": "Norfolk Island",
    "localName": "Norfolk Island",
    "phonePrefix": "+672",
    "flagX": "-4830px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_MK",
    "iso": "MK",
    "phoneFmt": " XX XXX XXXX",
    "countryName": "North Macedonia",
    "localName": "North Macedonia",
    "phonePrefix": "+389",
    "flagX": "-4260px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_MP",
    "iso": "MP",
    "phoneFmt": " (XXX) XXX-XXXX",
    "countryName": "Northern Mariana Islands",
    "localName": "Northern Mariana Islands",
    "phonePrefix": "+1",
    "flagX": "-4410px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_NO",
    "iso": "NO",
    "phoneFmt": " XX XX XX XX",
    "countryName": "Norway",
    "localName": "Norway",
    "phonePrefix": "+47",
    "flagX": "-4950px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_OM",
    "iso": "OM",
    "phoneFmt": " XX XXXXXX",
    "countryName": "Oman",
    "localName": "Oman",
    "phonePrefix": "+968",
    "flagX": "-5100px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_PK",
    "iso": "PK",
    "phoneFmt": " (XXX) XXXXXXXX",
    "countryName": "Pakistan",
    "localName": "Pakistan",
    "phonePrefix": "+92",
    "flagX": "-5280px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_PW",
    "iso": "PW",
    "phoneFmt": " XXX XXXX",
    "countryName": "Palau",
    "localName": "Palau",
    "phonePrefix": "+680",
    "flagX": "-5490px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_PS",
    "iso": "PS",
    "phoneFmt": " XX XXX XXXX",
    "countryName": "Palestine, State of",
    "localName": "Palestine, State of",
    "phonePrefix": "+970",
    "flagX": "-5430px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_PA",
    "iso": "PA",
    "phoneFmt": " XXX-XXXX",
    "countryName": "Panama",
    "localName": "\u0a2a\u0a48\u0a28\u0a3e\u0a2e\u0a3e",
    "phonePrefix": "+507",
    "flagX": "-5130px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_PG",
    "iso": "PG",
    "phoneFmt": " XXX XXXX",
    "countryName": "Papua New Guinea",
    "localName": "Papua New Guinea",
    "phonePrefix": "+675",
    "flagX": "-5220px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_PY",
    "iso": "PY",
    "phoneFmt": " (XXX) XXX XXXX",
    "countryName": "Paraguay",
    "localName": "Paraguay",
    "phonePrefix": "+595",
    "flagX": "-5520px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_PE",
    "iso": "PE",
    "phoneFmt": " (XX) XXXXXXX",
    "countryName": "Peru",
    "localName": "Peru",
    "phonePrefix": "+51",
    "flagX": "-5160px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_PH",
    "iso": "PH",
    "phoneFmt": " (XX) XXXX XXXX",
    "countryName": "Philippines",
    "localName": "Philippines",
    "phonePrefix": "+63",
    "flagX": "-5250px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_PN",
    "iso": "PN",
    "phoneFmt": " XXXXXXXXX",
    "countryName": "Pitcairn",
    "localName": "Pitcairn",
    "phonePrefix": "+0",
    "flagX": "-5370px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_PL",
    "iso": "PL",
    "phoneFmt": " XX XXX XX XX",
    "countryName": "Poland",
    "localName": "Polska",
    "phonePrefix": "+48",
    "flagX": "-5310px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_PT",
    "iso": "PT",
    "phoneFmt": " XX XXX XXXX",
    "countryName": "Portugal",
    "localName": "Portugal",
    "phonePrefix": "+351",
    "flagX": "-5460px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_PR",
    "iso": "PR",
    "phoneFmt": " (XXX) XXX-XXXX",
    "countryName": "Puerto Rico",
    "localName": "Puerto Rico",
    "phonePrefix": "+1",
    "flagX": "-5400px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_QA",
    "iso": "QA",
    "phoneFmt": " XXXX XXXX",
    "countryName": "Qatar",
    "localName": "Qatar",
    "phonePrefix": "+974",
    "flagX": "-5550px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_RO",
    "iso": "RO",
    "phoneFmt": " XXX XXX XXXX",
    "countryName": "Romania",
    "localName": "Rom\u00e2nia",
    "phonePrefix": "+40",
    "flagX": "-5610px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_RU",
    "iso": "RU",
    "phoneFmt": " X (XXX) XXX-XX-XX",
    "countryName": "Russian Federation",
    "localName": "\u0420\u043e\u0441\u0441\u0438\u0439\u0441\u043a\u0430\u044f \u0424\u0435\u0434\u0435\u0440\u0430\u0446\u0438\u044f",
    "phonePrefix": "+7",
    "flagX": "-5670px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_RW",
    "iso": "RW",
    "phoneFmt": " XXX XXX XXX",
    "countryName": "Rwanda",
    "localName": "Rwanda",
    "phonePrefix": "+250",
    "flagX": "-5700px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_RE",
    "iso": "RE",
    "phoneFmt": " XXXX XX XX XX",
    "countryName": "R\u00e9union",
    "localName": "R\u00e9union",
    "phonePrefix": "+262",
    "flagX": "-5580px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_BL",
    "iso": "BL",
    "phoneFmt": " XXXX XX XX XX",
    "countryName": "Saint Barth\u00e9lemy",
    "localName": "Saint Barth\u00e9lemy",
    "phonePrefix": "+590",
    "flagX": "-720px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_SH",
    "iso": "SH",
    "phoneFmt": " XXXXX",
    "countryName": "Saint Helena, Ascension and Tristan da Cunha",
    "localName": "Sveta Jelena, Asansion i Tristan da Kuna",
    "phonePrefix": "+290",
    "flagX": "-5910px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_KN",
    "iso": "KN",
    "phoneFmt": " (XXX) XXX-XXXX",
    "countryName": "Saint Kitts and Nevis",
    "localName": "\u0cb8\u0cc7\u0c82\u0c9f\u0ccd \u0c95\u0cbf\u0c9f\u0ccd\u0cb8\u0ccd \u0cae\u0ca4\u0ccd\u0ca4\u0cc1 \u0ca8\u0cc7\u0cb5\u0cbf\u0cb8\u0ccd",
    "phonePrefix": "+1",
    "flagX": "-3540px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_LC",
    "iso": "LC",
    "phoneFmt": " (XXX) XXX-XXXX",
    "countryName": "Saint Lucia",
    "localName": "Saint Lucia",
    "phonePrefix": "+1",
    "flagX": "-3780px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_MF",
    "iso": "MF",
    "phoneFmt": " XXXX XX XX XX",
    "countryName": "Saint Martin (French part)",
    "localName": "Saint Martin (French part)",
    "phonePrefix": "+590",
    "flagX": "-4170px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_PM",
    "iso": "PM",
    "phoneFmt": " XXX XX XX",
    "countryName": "Saint Pierre and Miquelon",
    "localName": "Saint Pierre and Miquelon",
    "phonePrefix": "+508",
    "flagX": "-5340px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_VC",
    "iso": "VC",
    "phoneFmt": " (XXX) XXX-XXXX",
    "countryName": "Saint Vincent and the Grenadines",
    "localName": "Saint Vincent and the Grenadines",
    "phonePrefix": "+1",
    "flagX": "-7050px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_WS",
    "iso": "WS",
    "phoneFmt": " XXXXX",
    "countryName": "Samoa",
    "localName": "Samoa",
    "phonePrefix": "+685",
    "flagX": "-7260px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_SM",
    "iso": "SM",
    "phoneFmt": " XXXX XXXXXX",
    "countryName": "San Marino",
    "localName": "San Marino",
    "phonePrefix": "+378",
    "flagX": "-6060px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_ST",
    "iso": "ST",
    "phoneFmt": " XXX XXXX",
    "countryName": "Sao Tome and Principe",
    "localName": "Sao Tome and Principe",
    "phonePrefix": "+239",
    "flagX": "-6210px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_SA",
    "iso": "SA",
    "phoneFmt": " XXX XXX XXXX",
    "countryName": "Saudi Arabia",
    "localName": "Saudi Arabia",
    "phonePrefix": "+966",
    "flagX": "-5730px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_SN",
    "iso": "SN",
    "phoneFmt": " XX XXX XX XX",
    "countryName": "Senegal",
    "localName": "Senegal",
    "phonePrefix": "+221",
    "flagX": "-6090px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_RS",
    "iso": "RS",
    "phoneFmt": " XXX XXXXXX",
    "countryName": "Serbia",
    "localName": "Serbia",
    "phonePrefix": "+381",
    "flagX": "-5640px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_SC",
    "iso": "SC",
    "phoneFmt": " X XXX XXX",
    "countryName": "Seychelles",
    "localName": "Seychelles",
    "phonePrefix": "+248",
    "flagX": "-5790px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_SL",
    "iso": "SL",
    "phoneFmt": " (XXX) XXXXXX",
    "countryName": "Sierra Leone",
    "localName": "Sierra Leone",
    "phonePrefix": "+232",
    "flagX": "-6030px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_SG",
    "iso": "SG",
    "phoneFmt": " XXXX XXXX",
    "countryName": "Singapore",
    "localName": "Singapore",
    "phonePrefix": "+65",
    "flagX": "-5880px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_SX",
    "iso": "SX",
    "phoneFmt": " (XXX) XXX-XXXX",
    "countryName": "Sint Maarten (Dutch part)",
    "localName": "Sint Maarten (Dutch part)",
    "phonePrefix": "+1",
    "flagX": "-6270px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_SK",
    "iso": "SK",
    "phoneFmt": " XX/XXX XXX XX",
    "countryName": "Slovakia",
    "localName": "Slovensko",
    "phonePrefix": "+421",
    "flagX": "-6000px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_SI",
    "iso": "SI",
    "phoneFmt": " (XX) XXX XX XX",
    "countryName": "Slovenia",
    "localName": "\u0dc3\u0dca\u0dbd\u0ddd\u0dc0\u0dd3\u0db1\u0dd2\u0dba\u0dcf\u0dc0",
    "phonePrefix": "+386",
    "flagX": "-5940px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_SB",
    "iso": "SB",
    "phoneFmt": " XXXXX",
    "countryName": "Solomon Islands",
    "localName": "Solomon Islands",
    "phonePrefix": "+677",
    "flagX": "-5760px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_SO",
    "iso": "SO",
    "phoneFmt": " X XXXXXX",
    "countryName": "Somalia",
    "localName": "Soomaaliya",
    "phonePrefix": "+252",
    "flagX": "-6120px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_ZA",
    "iso": "ZA",
    "phoneFmt": " XXX XXX XXXX",
    "countryName": "South Africa",
    "localName": "South Africa",
    "phonePrefix": "+27",
    "flagX": "-7350px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_GS",
    "iso": "GS",
    "phoneFmt": " XXXXXXXXX",
    "countryName": "South Georgia and the South Sandwich Islands",
    "localName": "South Georgia and the South Sandwich Islands",
    "phonePrefix": "+0",
    "flagX": "-2640px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_SS",
    "iso": "SS",
    "phoneFmt": " XXXX XXX XXX",
    "countryName": "South Sudan",
    "localName": "South Sudan",
    "phonePrefix": "+211",
    "flagX": "-6180px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_ES",
    "iso": "ES",
    "phoneFmt": " XXX XX XX XX",
    "countryName": "Spain",
    "localName": "Espa\u00f1a",
    "phonePrefix": "+34",
    "flagX": "-1980px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_LK",
    "iso": "LK",
    "phoneFmt": " XXXX XXX XXX",
    "countryName": "Sri Lanka",
    "localName": "Sri Lanka",
    "phonePrefix": "+94",
    "flagX": "-3840px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_SD",
    "iso": "SD",
    "phoneFmt": " XXX XXX XXXX",
    "countryName": "Sudan",
    "localName": "Sudan",
    "phonePrefix": "+249",
    "flagX": "-5820px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_SR",
    "iso": "SR",
    "phoneFmt": " XXX-XXX",
    "countryName": "Suriname",
    "localName": "\u0421\u0443\u0440\u0438\u043d\u0430\u043c",
    "phonePrefix": "+597",
    "flagX": "-6150px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_SJ",
    "iso": "SJ",
    "phoneFmt": " XX XX XX XX",
    "countryName": "Svalbard and Jan Mayen",
    "localName": "Svalbard and Jan Mayen",
    "phonePrefix": "+47",
    "flagX": "-5970px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_SE",
    "iso": "SE",
    "phoneFmt": " XX-XX XX XX",
    "countryName": "Sweden",
    "localName": "Sweden",
    "phonePrefix": "+46",
    "flagX": "-5850px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_CH",
    "iso": "CH",
    "phoneFmt": " XXX XXX XX XX",
    "countryName": "Switzerland",
    "localName": "Switzerland",
    "phonePrefix": "+41",
    "flagX": "-1230px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_SY",
    "iso": "SY",
    "phoneFmt": " XXX XXX XXXX",
    "countryName": "Syrian Arab Republic",
    "localName": "Syrian Arab Republic",
    "phonePrefix": "+963",
    "flagX": "-6300px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_TW",
    "iso": "TW",
    "phoneFmt": " XX XXXX XXXX",
    "countryName": "Taiwan, Province of China",
    "localName": "Taiwan, Province of China",
    "phonePrefix": "+886",
    "flagX": "-6780px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_TJ",
    "iso": "TJ",
    "phoneFmt": " XXX XX XXXX",
    "countryName": "Tajikistan",
    "localName": "Tajikistan",
    "phonePrefix": "+992",
    "flagX": "-6510px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_TZ",
    "iso": "TZ",
    "phoneFmt": " XXX XXX XXXX",
    "countryName": "Tanzania, United Republic of",
    "localName": "Tanzania, United Republic of",
    "phonePrefix": "+255",
    "flagX": "-6810px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_TH",
    "iso": "TH",
    "phoneFmt": " XX XXX XXXX",
    "countryName": "Thailand",
    "localName": "\u0e44\u0e17\u0e22",
    "phonePrefix": "+66",
    "flagX": "-6480px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_TL",
    "iso": "TL",
    "phoneFmt": " XXX XXXX",
    "countryName": "Timor-Leste",
    "localName": "Timor-Leste",
    "phonePrefix": "+670",
    "flagX": "-6570px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_TG",
    "iso": "TG",
    "phoneFmt": " XX XX XX XX",
    "countryName": "Togo",
    "localName": "\u0422\u043e\u0433\u043e",
    "phonePrefix": "+228",
    "flagX": "-6450px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_TK",
    "iso": "TK",
    "phoneFmt": " XXXX",
    "countryName": "Tokelau",
    "localName": "Tokelau",
    "phonePrefix": "+690",
    "flagX": "-6540px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_TO",
    "iso": "TO",
    "phoneFmt": " XX-XXX",
    "countryName": "Tonga",
    "localName": "Tonga",
    "phonePrefix": "+676",
    "flagX": "-6660px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_TT",
    "iso": "TT",
    "phoneFmt": " (XXX) XXX-XXXX",
    "countryName": "Trinidad and Tobago",
    "localName": "\u0422\u0440\u0438\u043d\u0438\u0434\u0430\u0434 \u0431\u0435\u043b\u04d9\u043d \u0422\u043e\u0431\u0430g\u043e",
    "phonePrefix": "+1",
    "flagX": "-6720px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_TN",
    "iso": "TN",
    "phoneFmt": " XX XXX XXX",
    "countryName": "Tunisia",
    "localName": "Tunisia",
    "phonePrefix": "+216",
    "flagX": "-6630px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_TM",
    "iso": "TM",
    "phoneFmt": " (X XX) XX-XX-XX",
    "countryName": "Turkmenistan",
    "localName": "Turkmenistan",
    "phonePrefix": "+993",
    "flagX": "-6600px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_TC",
    "iso": "TC",
    "phoneFmt": " (XXX) XXX-XXXX",
    "countryName": "Turks and Caicos Islands",
    "localName": "Turks and Caicos Islands",
    "phonePrefix": "+1",
    "flagX": "-6360px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_TV",
    "iso": "TV",
    "phoneFmt": " XX XXX",
    "countryName": "Tuvalu",
    "localName": "Tuvalu",
    "phonePrefix": "+688",
    "flagX": "-6750px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_TR",
    "iso": "TR",
    "phoneFmt": " (XXXX) XXX XX XX",
    "countryName": "T\u00fcrkiye",
    "localName": "T\u00fcrkiye",
    "phonePrefix": "+90",
    "flagX": "-6690px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_UG",
    "iso": "UG",
    "phoneFmt": " XXX XXXXXXX",
    "countryName": "Uganda",
    "localName": "\u0626\u06c7\u06af\u0627\u0646\u062f\u0627",
    "phonePrefix": "+256",
    "flagX": "-6870px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_UA",
    "iso": "UA",
    "phoneFmt": " XXXXX XXXXX",
    "countryName": "Ukraine",
    "localName": "Ukraine",
    "phonePrefix": "+380",
    "flagX": "-6840px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_AE",
    "iso": "AE",
    "phoneFmt": " XX XXX XXXX",
    "countryName": "United Arab Emirates",
    "localName": "United Arab Emirates",
    "phonePrefix": "+971",
    "flagX": "-30px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_GB",
    "iso": "GB",
    "phoneFmt": " XXXX XXX XXXX",
    "countryName": "United Kingdom",
    "localName": "United Kingdom",
    "phonePrefix": "+44",
    "flagX": "-2250px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_US",
    "iso": "US",
    "phoneFmt": " (XXX) XXX-XXXX",
    "countryName": "United States",
    "localName": "United States",
    "phonePrefix": "+1",
    "flagX": "-6930px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_UM",
    "iso": "UM",
    "phoneFmt": " XXXXXXXXX",
    "countryName": "United States Minor Outlying Islands",
    "localName": "United States Minor Outlying Islands",
    "phonePrefix": "+0",
    "flagX": "-6900px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_UY",
    "iso": "UY",
    "phoneFmt": " XXXX XXXX",
    "countryName": "Uruguay",
    "localName": "Uruguay",
    "phonePrefix": "+598",
    "flagX": "-6960px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_UZ",
    "iso": "UZ",
    "phoneFmt": " X XX XXX XX XX",
    "countryName": "Uzbekistan",
    "localName": "O\u02bbzbekiston",
    "phonePrefix": "+998",
    "flagX": "-6990px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_VU",
    "iso": "VU",
    "phoneFmt": " XXXXX",
    "countryName": "Vanuatu",
    "localName": "Vanuatu",
    "phonePrefix": "+678",
    "flagX": "-7200px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_VE",
    "iso": "VE",
    "phoneFmt": " XXXX-XXXXXXX",
    "countryName": "Venezuela, Bolivarian Republic of",
    "localName": "Venezuela, Bolivarian Republic of",
    "phonePrefix": "+58",
    "flagX": "-7080px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_VN",
    "iso": "VN",
    "phoneFmt": " XXXX XXXX XXX",
    "countryName": "Viet Nam",
    "localName": "Viet Nam",
    "phonePrefix": "+84",
    "flagX": "-7170px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_VG",
    "iso": "VG",
    "phoneFmt": " (XXX) XXX-XXXX",
    "countryName": "Virgin Islands, British",
    "localName": "Virgin Islands, British",
    "phonePrefix": "+1",
    "flagX": "-7110px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_VI",
    "iso": "VI",
    "phoneFmt": " (XXX) XXX-XXXX",
    "countryName": "Virgin Islands, U.S.",
    "localName": "Qu\u1ea7n \u0110\u1ea3o V\u01a1-chin M\u1ef9",
    "phonePrefix": "+1",
    "flagX": "-7140px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_WF",
    "iso": "WF",
    "phoneFmt": " XX XX XX",
    "countryName": "Wallis and Futuna",
    "localName": "Wallis and Futuna",
    "phonePrefix": "+681",
    "flagX": "-7230px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_EH",
    "iso": "EH",
    "phoneFmt": " XXXXX-XXXXX",
    "countryName": "Western Sahara",
    "localName": "Western Sahara",
    "phonePrefix": "+212",
    "flagX": "-1920px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_YE",
    "iso": "YE",
    "phoneFmt": " XX XXX XXX",
    "countryName": "Yemen",
    "localName": "Yemen",
    "phonePrefix": "+967",
    "flagX": "-7290px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_ZM",
    "iso": "ZM",
    "phoneFmt": " XXXX XXX XXX",
    "countryName": "Zambia",
    "localName": "Zambia",
    "phonePrefix": "+260",
    "flagX": "-7380px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_ZW",
    "iso": "ZW",
    "phoneFmt": " XXX XXXXX",
    "countryName": "Zimbabwe",
    "localName": "Zimbabwe",
    "phonePrefix": "+263",
    "flagX": "-7410px",
    "flagY": "0px",
  },
  {
    "locKey": "COUNTRY_AX",
    "iso": "AX",
    "phoneFmt": " XXX XXXXXXX",
    "countryName": "\u00c5land Islands",
    "localName": "\u00c5land Islands",
    "phonePrefix": "+358",
    "flagX": "-390px",
    "flagY": "0px",
  },
];
