import customElement from "@ui5/webcomponents-base/dist/decorators/customElement.js";
import litRender from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import SideNavigationGroup from "@ui5/webcomponents-fiori/dist/SideNavigationGroup.js";

// Styles
import SideNavigationGroupCss from "./generated/themes/SideNavigationGroup.css.js";

/**
 * @class
 *
 * <h3 class="comment-api-title">Overview</h3>
 *
 *
 * <h3>Usage</h3>
 *
 * For the <code>udex-side-navigation-group</code>
 * <h3>ES6 Module Import</h3>
 *
 * <code>import "@udex/web-components/dist/SideNavigationGroup.js";</code>
 *
 * @constructor
 * @extends SideNavigationGroup
 * @public
 */
@customElement({
  tag: "udex-side-navigation-group",
  renderer: litRender,
  styles: [SideNavigationGroup.styles, SideNavigationGroupCss],
  dependencies: [],
})

class UDExSideNavigationGroup extends SideNavigationGroup {}

UDExSideNavigationGroup.define();

export default UDExSideNavigationGroup;
