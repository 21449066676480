import type { StyleData } from "@ui5/webcomponents-base/dist/types.js";
import { registerThemePropertiesLoader } from "@ui5/webcomponents-base/dist/asset-registries/Themes.js";

import defaultThemeBase from "@ui5/webcomponents-theming/dist/generated/themes/sap_horizon/parameters-bundle.css.js";
import defaultTheme from "./sap_horizon/parameters-bundle.css.js";

registerThemePropertiesLoader("@ui5/webcomponents-theming", "sap_horizon", async () => defaultThemeBase);
registerThemePropertiesLoader("@sapudex/web-components", "sap_horizon", async () => defaultTheme);

const styleData: StyleData = {packageName:"@sapudex/web-components",fileName:"themes/PasswordField.css.ts",content:`.udex-password-field{position:relative}[udex-control-button]:hover+[udex-text-field]{--udex-input-color-standard-default-border: var(--sapField_Hover_BorderColor, var(--udexColorBlue6))}[udex-control-button][active]+[udex-text-field]{--udex-input-color-standard-default-border: var(--sapField_Active_BorderColor, var(--udexColorBlue9))}[udex-control-button].udex-password-field__button{--sapButton_BorderWidth: 0 0 0 1px;--udex-control-button-large-height: 40px;--udex-control-button-large-width: 40px;position:absolute;overflow:visible;top:1px;z-index:1}[udex-control-button].udex-password-field__button:dir(ltr){right:1px}[udex-control-button].udex-password-field__button:dir(rtl){left:1px}
`};
export default styleData;
	