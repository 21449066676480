import UI5Element from "@ui5/webcomponents-base/dist/UI5Element.js";
import customElement from "@ui5/webcomponents-base/dist/decorators/customElement.js";
import property from "@ui5/webcomponents-base/dist/decorators/property.js";
import event from "@ui5/webcomponents-base/dist/decorators/event.js";
import litRender from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import Player from "@vimeo/player";
import MediaPlayerVimeoTemplate from "./generated/templates/MediaPlayerVimeoTemplate.lit.js";

// Styles
import MediaPlayerCss from "./generated/themes/MediaPlayer.css.js";

export type VimeoPlayer = Player;

/**
 * @class
 *
 * <h3 class="comment-api-title">Overview</h3>
 *
 *
 * <h3>Usage</h3>
 *
 * For the <code>udex-media-player-vimeo</code>
 * <h3>ES6 Module Import</h3>
 *
 * <code>import "@udex/web-components/dist/MediaPlayerVimeo.js";</code>
 *
 * @constructor
 * @extends UI5Element
 * @public
 */
@customElement({
  tag: "udex-media-player-vimeo",
  renderer: litRender,
  styles: [MediaPlayerCss],
  template: MediaPlayerVimeoTemplate,
  dependencies: [],
})
/**
 * Fires when video is ready
 *
 * @allowPreventDefault
 * @public
 */
@event("ready")
/**
 * Fires when video play ended
 *
 * @allowPreventDefault
 * @public
 */
@event("ended")
/**
 * Fires when video is paused
 *
 * @allowPreventDefault
 * @public
 */
@event("pause")
/**
 * Fires when video watching progress changed
 *
 * @allowPreventDefault
 * @public
 */
@event("playing", {
  detail: {
    percentPlayed: { type: Number },
  },
})
class UDExMediaPlayerVimeo extends UI5Element {
  /**
 * Defines the source of the video  or audio
 *
 * @default ""
 * @public
 */
  @property({ type: String, defaultValue: "" })
    source!: string;
  /**
   * Defines a title for youtube video
   *
   * @default ""
   * @public
   */
  @property({ type: String, defaultValue: "" })
    title!: string;
  /**
 * Defines video width. Used for YouTube and Vimeo videos only.
 * Regular video and audio sizes are control by CSS variables.
 *
 * @default ""
 * @public
 */
  @property({ type: String, defaultValue: "" })
    width!: string;
  /**
   * Defines video height. Used for YouTube and Vimeo videos only.
   * Regular video and audio sizes are control by CSS variables.
   *
   * @default ""
   * @public
   */
  @property({ type: String, defaultValue: "" })
    height!: string;
  /**
   * CSS class name for iframe fluid styles
   *
   * @default ""
   * @public
   */
  @property({ type: String, defaultValue: "" })
    aspectRatioClass!: string;
  /**
  * Defines if video should be displayed in fluid mode.
  * In fluid mode, width and height will be ignored and size will be adjusted to the container.
  *
  * @default false
  * @public
  */
  @property({ type: Boolean })
    fluidMode!: boolean;

  player!: Player;

  setupPlayer = () => {
    const iframe = this.shadowRoot?.querySelector("iframe");
    if (!iframe) {
      return;
    }
    this.player = new Player(iframe);

    this.player.on("timeupdate", e => {
      this.fireEvent("playing", { percentPlayed: e.percent * 100 });
    });

    this.player.on("pause", () => {
      this.fireEvent("pause");
    });

    this.player.on("ended", () => {
      this.fireEvent("ended");
    });

    this.player.on("loaded", () => {
      this.fireEvent("ready");
    });
  };

  onEnterDOM(): void {
    this.setupPlayer();
  }

  /**
   * Returns video element
   * @public
   */
  getPlayer(): VimeoPlayer {
    return this.player;
  }

  get fluidModeClass() {
    if (this.fluidMode) {
      return "video-js udex-media-player__fluid";
    }
    return "";
  }
}

UDExMediaPlayerVimeo.define();

export default UDExMediaPlayerVimeo;
