import customElement from "@ui5/webcomponents-base/dist/decorators/customElement.js";
import property from "@ui5/webcomponents-base/dist/decorators/property.js";
import litRender from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import BreadcrumbsItem from "@ui5/webcomponents/dist/BreadcrumbsItem.js";

/**
 * @class
 *
 * <h3 class="comment-api-title">Overview</h3>
 *
 *
 * <h3>Usage</h3>
 *
 * For the <code>udex-breadcrumbs-item</code>
 * <h3>ES6 Module Import</h3>
 *
 * <code>import "@udex/web-components/dist/BreadcrumbsItem.js";</code>
 *
 * @constructor
 * @extends BreadcrumbsItem
 * @public
 */
@customElement({
  tag: "udex-breadcrumbs-item",
  renderer: litRender,
  dependencies: [],
})
class UDExBreadcrumbsItem extends BreadcrumbsItem {
  /**
   * Defines item icon to be displayed before the item name.
   *
   * @default ""
   * @public
   */
  @property()
    icon?: string;
}

UDExBreadcrumbsItem.define();

export default UDExBreadcrumbsItem;
