import UI5Element from "@ui5/webcomponents-base/dist/UI5Element.js";
import customElement from "@ui5/webcomponents-base/dist/decorators/customElement.js";
import property from "@ui5/webcomponents-base/dist/decorators/property.js";
import slot from "@ui5/webcomponents-base/dist/decorators/slot.js";
import event from "@ui5/webcomponents-base/dist/decorators/event.js";
import litRender from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import type I18nBundle from "@ui5/webcomponents-base/dist/i18nBundle.js";
import { getI18nBundle } from "@ui5/webcomponents-base/dist/i18nBundle.js";
import { LOGOUT } from "./generated/i18n/i18n-defaults.js";
import UDExMastheadMenu from "./MastheadMenu.js";
import MastheadProfileMenuTemplate from "./generated/templates/MastheadProfileMenuTemplate.lit.js";

// Styles
import MastheadProfileMenuCss from "./generated/themes/MastheadProfileMenu.css.js";

interface UserData {
    name?: string,
    username?: string,
    email?: string,
    avatarUrl?: string,
    avatarInitials?: string
}

/**
 * @class
 *
 * <h3 class="comment-api-title">Overview</h3>
 *
 *
 * <h3>Usage</h3>
 *
 * For the <code>udex-masthead-profile-menu</code>
 * <h3>ES6 Module Import</h3>
 *
 * <code>import "@udex/web-components/dist/MastheadProfileMenu.js";</code>
 *
 * @constructor
 * @extends UI5Element
 * @public
 */
@customElement({
  tag: "udex-masthead-profile-menu",
  renderer: litRender,
  styles: [UDExMastheadMenu.styles, MastheadProfileMenuCss],
  template: MastheadProfileMenuTemplate,
  dependencies: [UDExMastheadMenu],
})

/**
 * Fired when use click Logout link
 * @public
 */
@event("logout")
class UDExMastheadProfileMenu extends UI5Element {
  /**
   * Defines the object of link values
   *
   * @default ""
   * @public
   */
  @property()
    links!: string;
  /**
   * Defines the object of user profile data
   *
   * @default ""
   * @public
   */
  @property()
    loggedInUser!: string;
  /**
   * Defines the link for logout
   *
   * @default ""
   * @public
   */
  @property()
    logoutUrl!: string;

  /**
   * Placeholder for message strip.
   *
   * @public
   */
  @slot({ type: Node, "default": false })
    message!: Array<Node>;
  static i18nBundle: I18nBundle;

  static async onDefine() {
    UDExMastheadProfileMenu.i18nBundle = await getI18nBundle("sap-ui-webcomponents-bundle");
  }

  get linksList(): string {
    return this.links ? JSON.stringify([{
      subMenuLinkItems: JSON.parse(this.links),
    }]) : "";
  }

  get userData(): UserData {
    return this.parseArrayFromString(this.loggedInUser) as UserData;
  }

  parseArrayFromString<T>(string: string): T[] {
    return string ? JSON.parse(string) as T[] : [];
  }

  get messageAvailable() {
    return this.message.length;
  }

  get logoutLink() {
    return this.logoutUrl.length;
  }

  get logoutText() {
    return UDExMastheadProfileMenu.i18nBundle.getText(LOGOUT);
  }

  userLogout() {
    this.fireEvent("logout");
  }
}

UDExMastheadProfileMenu.define();

export default UDExMastheadProfileMenu;
