import UI5Element from "@ui5/webcomponents-base/dist/UI5Element.js";
import customElement from "@ui5/webcomponents-base/dist/decorators/customElement.js";
import property from "@ui5/webcomponents-base/dist/decorators/property.js";
import litRender from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import slot from "@ui5/webcomponents-base/dist/decorators/slot.js";
import { getI18nBundle } from "@ui5/webcomponents-base/dist/i18nBundle.js";
import type I18nBundle from "@ui5/webcomponents-base/dist/i18nBundle.js";
import {
  isUp, isDown, isRight, isLeft, isEnter,
} from "@ui5/webcomponents-base/dist/Keys.js";
import UDExMastheadItem, { MenuItem, SubMenuLinkItem } from "./MastheadItem.js";
import UDExMastheadMenuItem from "./MastheadMenuItem.js";
import { OVERVIEW } from "./generated/i18n/i18n-defaults.js";

import MastheadMobileMenuTemplate from "./generated/templates/MastheadMobileMenuTemplate.lit.js";
// Styles
import MastheadMobileMenuCss from "./generated/themes/MastheadMobileMenu.css.js";

/**
 * @class
 *
 * <h3 class="comment-api-title">Overview</h3>
 *
 *
 * <h3>Usage</h3>
 *
 * For the <code>udex-masthead-mobile-menu</code>
 * <h3>ES6 Module Import</h3>
 *
 * <code>import "@udex/web-components/dist/MastheadMobileMenu.js";</code>
 *
 * @constructor
 * @extends UI5Element
 * @public
 */
@customElement({
  tag: "udex-masthead-mobile-menu",
  renderer: litRender,
  styles: MastheadMobileMenuCss,
  template: MastheadMobileMenuTemplate,
  dependencies: [UDExMastheadMenuItem],
})

class UDExMastheadMobileMenu extends UI5Element {
  /**
   * Slot for search
   *
   * @public
   */
  @slot({ type: Node })
    search!: Array<Node>;

  /**
   * List of the menu items
   *
   * @default "[]"
   * @public
   */
  @slot({ type: HTMLElement, "default": true })
    items!: Array<UDExMastheadItem>;

  /**
   * Slot for explore Sap mobile button
   *
   * @slot
   * @public
   */
  @slot({ type: HTMLElement })
    exploreSapMobileButton?: UDExMastheadItem;

  @property({ type: Boolean })
    _showCategories!: boolean;

  @property({ type: Boolean })
    _hideExploreSapBtn!: boolean;

  @property({ type: Boolean })
    _showCategoryItems!: boolean;

  @property({ type: String })
    _direction?: string;

  _clickedItem!: UDExMastheadItem;
  _categories!: Array<SubMenuLinkItem>;
  _category!: SubMenuLinkItem;

  static i18nBundle: I18nBundle;

  static async onDefine(): Promise<void> {
    UDExMastheadMobileMenu.i18nBundle = await getI18nBundle("sap-ui-webcomponents-bundle");
  }

  onAfterRendering() {
    if (this.hasAttribute("slot")) {
      this.focusFirstItem();
    }
  }

  handleMenuItemClick(e: Event) {
    e.preventDefault();
    this.openCategories(e);
  }

  handleMenuCategoryClick(e: Event) {
    e.preventDefault();
    this.openCategoryItems(e);
  }

  handleExploreSapClick(e: Event) {
    e.preventDefault();
    this._hideExploreSapBtn = true;
    this._showCategoryItems = false;
    this.openCategories(e);
  }

  handleBackToMenuItems() {
    this.showFirstLevelMenu();
  }

  handleBackToCategories() {
    this.showCategories();
  }

  handleKeyDown(e: KeyboardEvent) {
    this.handleUpArrow(e);
    this.handleDownArrow(e);
    this.handleRightArrow(e);
    this.handleLeftArrow(e);
  }

  handleRightArrow(e: KeyboardEvent) {
    this.handleDirection();
    if (isRight(e) || isEnter(e)) {
      if (this.isRtlDirection) {
        this.moveToBackWithKeyboard(e);
      } else {
        this.moveToNextLevelWithKeyboard(e);
      }
    }
  }

  handleLeftArrow(e: KeyboardEvent) {
    this.handleDirection();
    if (isLeft(e) || isEnter(e)) {
      if (this.isRtlDirection) {
        this.moveToNextLevelWithKeyboard(e);
      } else {
        this.moveToBackWithKeyboard(e);
      }
    }
  }

  handleUpArrow(e: KeyboardEvent) {
    if (isUp(e)) {
      e.preventDefault();
      this.focusPreviousItem(e);
    }
  }

  handleDownArrow(e: KeyboardEvent) {
    if (isDown(e)) {
      e.preventDefault();
      this.focusNextItem(e);
    }
  }

  handleDirection() {
    const directionStyles: string = window
      .getComputedStyle(this)
      .getPropertyValue("direction");
    if (!directionStyles) {
      this._direction = "ltr";
    } else {
      this._direction = directionStyles;
    }
  }

  moveToBackWithKeyboard(e: KeyboardEvent) {
    const target = e.target as UDExMastheadItem;
    if (target.hasAttribute("back-to-nav")) {
      this.showFirstLevelMenu();
    }

    if (target.hasAttribute("back-to-categories")) {
      this.showCategories();
    }
  }

  moveToNextLevelWithKeyboard(e: KeyboardEvent) {
    const target = e.target as UDExMastheadItem;

    if (target.hasAttribute("is-explore")) {
      this._hideExploreSapBtn = true;
      this._showCategoryItems = false;
    }

    if (target.hasAttribute("first-level")) {
      this.openCategories(e);
    }

    if (target.hasAttribute("second-level")) {
      this.openCategoryItems(e);
    }
  }

  focusFirstItem(): void {
    this.getListItemByIndex(0)?.focus();
  }

  focusPreviousItem(e: KeyboardEvent): void {
    const target = e.target as HTMLElement;
    const currentItem = target.parentElement as HTMLElement;
    const currentSelectedIndex = this.getCurrentIndex(currentItem);
    const lastItemIndex = this.navigationItems.length - 1;
    const previousSelectIndex = currentSelectedIndex - 1 < 0 ? lastItemIndex : currentSelectedIndex - 1;
    const listItem = this.getListItemByIndex(previousSelectIndex);
    listItem?.focus();
  }

  focusNextItem(e: KeyboardEvent): void {
    const target = e.target as HTMLElement;
    const currentItem = target.parentElement as HTMLElement;
    const currentSelectedIndex = this.getCurrentIndex(currentItem);
    const nextSelectIndex = currentSelectedIndex + 1 > this.navigationItems.length - 1 ? 0 : currentSelectedIndex + 1;
    const listItem = this.getListItemByIndex(nextSelectIndex);
    listItem?.focus();
  }

  openCategories(e: Event) {
    const item = this.getItemByList(e.target as UDExMastheadItem);
    this._clickedItem = item;
    this._categories = this.getCategories(item.getSubItems());
    this.setCategoryByItem(item);
    this._showCategories = true;
  }

  openCategoryItems(e: Event) {
    const item = e.target as UDExMastheadMenuItem;
    this.setCategoryByItem(item);
    this._showCategories = false;
    this._showCategoryItems = true;
  }

  showFirstLevelMenu() {
    this._showCategories = false;
    this._showCategoryItems = false;
    this._hideExploreSapBtn = false;
  }

  showCategories() {
    this._showCategories = true;
    this._showCategoryItems = false;
  }

  setCategoryByItem(item: UDExMastheadMenuItem | UDExMastheadItem) {
    const index = Number(item.getAttribute("id"));
    this._category = this._categories[index];
  }

  getItemByList(el: UDExMastheadItem): UDExMastheadItem {
    const items = [
      ...this.items,
      ...[this.hasExploreBtnSlot ? this.exploreItem : []],
    ] as Array<UDExMastheadItem>;
    return items.find(item => item._id === el.getAttribute("id"))!;
  }

  getCategories(items: Array<MenuItem>): Array<SubMenuLinkItem> {
    const categories: Array<SubMenuLinkItem> = [];
    items?.forEach(item => {
      item.subMenuLinkItems.forEach(category => {
        categories.push(category);
      });
    });

    return categories;
  }

  getCurrentIndex(item: HTMLElement): number {
    return this.navigationItems.indexOf(item);
  }

  getListItemByIndex(index: number): HTMLElement | null {
    return this.navigationItems[index]?.querySelector("udex-masthead-menu-item");
  }

  get isRtlDirection(): boolean {
    return this._direction === "rtl";
  }

  get navigationItems(): Array<HTMLElement> {
    return Array.from(this.shadowRoot!.querySelectorAll(".udex-masthead-mobile-menu__list-item"));
  }

  get showNavigation(): boolean {
    return !this._showCategories && !this._showCategoryItems;
  }

  get overviewLinkText(): string {
    return UDExMastheadMobileMenu.i18nBundle.getText(OVERVIEW);
  }

  get exploreItem(): UDExMastheadItem {
    return this.getSlottedNodes<UDExMastheadItem>("exploreSapMobileButton")[0];
  }

  get showExploreBtn(): boolean {
    return this.hasExploreBtnSlot && !this._hideExploreSapBtn;
  }

  get hasExploreBtnSlot(): boolean {
    return !!this.getSlottedNodes("exploreSapMobileButton").length;
  }

  get hasSearchSlot(): boolean {
    return !!this.search.length;
  }
}

UDExMastheadMobileMenu.define();

export default UDExMastheadMobileMenu;
