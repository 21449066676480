import UI5Element from "@ui5/webcomponents-base/dist/UI5Element.js";
import customElement from "@ui5/webcomponents-base/dist/decorators/customElement.js";
import property from "@ui5/webcomponents-base/dist/decorators/property.js";
import Integer from "@ui5/webcomponents-base/dist/types/Integer.js";
import slot from "@ui5/webcomponents-base/dist/decorators/slot.js";
import UI5Icon from "@ui5/webcomponents/dist/Icon.js";
import litRender from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import { isEscape } from "@ui5/webcomponents-base/dist/Keys.js";
import UDExTableRow from "./TableRow.js";

import TableGroupRowTemplate from "./generated/templates/TableGroupRowTemplate.lit.js";

// Styles
import TableGroupRowCss from "./generated/themes/TableGroupRow.css.js";

/**
 * @class
 *
 * <h3 class="comment-api-title">Overview</h3>
 *
 *
 * <h3>Usage</h3>
 *
 * For the <code>udex-table-group-row</code>
 * <h3>ES6 Module Import</h3>
 *
 * <code>import "@udex/web-components/dist/TableGroupRow.js";</code>
 *
 * @constructor
 * @extends UI5Element
 * @public
 */
@customElement({
  tag: "udex-table-group-row",
  renderer: litRender,
  styles: TableGroupRowCss,
  template: TableGroupRowTemplate,
  dependencies: [UDExTableRow, UI5Icon],
})

class UDExTableGroupRow extends UI5Element {
  /**
   * Defines collapsible type the group row.
   *
   * @default false
   * @public
   */
  @property({ type: Boolean })
    collapsible?: boolean;

  /**
   * Defines the title of the group row.
   *
   * @default ""
   * @public
   */
  @property({ type: String })
    groupTitle!: string;

  /**
   * Defines the rows of the group row.
   *
   * @public
   */
  @slot({ type: HTMLElement, "default": true })
    rows!: Array<UDExTableRow>;

  /**
   * Defines if the row is expanded
   *
   * @default false
   * @public
   */
  @property({ type: Boolean })
    expanded?: boolean;

  /**
   * Defines the index of the component.
   *
   * @private
   */
  @property({ validator: Integer })
    _index!: number;

  /**
   * Defines if the collapsible row is open.
   *
   * @private
   */
  @property({ type: Boolean })
    _isOpen?: boolean = true;

  onAfterRendering() {
    this.setIndexToRows();

    if (this.collapsible && !this.expanded) {
      this._isOpen = false;
    }
  }

  onEnterDOM() {
    this.setAttribute("role", "rowgroup");
  }

  toggleExpanded(): void {
    if (this.collapsible) {
      this.expanded = !this.expanded;
      this._isOpen = !this._isOpen;
    }
  }

  handleEscapeKey(e: KeyboardEvent) {
    if (isEscape(e)) {
      const btn = this.shadowRoot?.querySelector(".udex-table-grow-btn") as HTMLElement;
      this.toggleExpanded();
      btn.focus();
    }
  }

  setIndexToRows(): void {
    this.rows.forEach((row, index) => {
      row._index = index;
    });
  }

  get isGroup() {
    return true;
  }
}

UDExTableGroupRow.define();

export default UDExTableGroupRow;
