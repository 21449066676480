const redirectAttributes = (sourceElement: HTMLElement, targetElementId: string) => {
  const targetElement = sourceElement.shadowRoot?.getElementById(targetElementId);
  Array.from(sourceElement.attributes).forEach(attribute => {
    if (attribute.name !== "id" && attribute.name !== "class") {
      targetElement?.setAttribute(attribute.name, attribute.value);
    }
  });
};

export default redirectAttributes;
