/* eslint no-unused-vars: 0 */
import { html, svg, repeat, classMap, styleMap, ifDefined, unsafeHTML, scopeTag } from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import type UI5Element from "@ui5/webcomponents-base/dist/UI5Element.js";
	type TabHeader = any;
	import type { ClassMapValue } from "@ui5/webcomponents-base/dist/types.js";
	
function block0 (this: TabHeader, context: UI5Element, tags: string[], suffix: string | undefined) { return html`${ this.hasNotHref ? block1.call(this, context, tags, suffix) : block6.call(this, context, tags, suffix) } `;}
function block1 (this: TabHeader, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<button id="${ifDefined(this._id)}" class="udex-tab-navigation__button-tab ${ifDefined(this.selectedClass)}${ifDefined(this.classWithIconWithoutBackground)}${ifDefined(this.buttonClassWithIcon)}" tabindex="${ifDefined(this._tabIndex)}" type="button" role="tab" aria-describedby="udex-tab-navigation__${ifDefined(this._id)}__accessible-name" aria-posinset="${ifDefined(this._posinset)}" aria-setsize="${ifDefined(this._setsize)}" aria-controls="${ifDefined(this._contentId)}" aria-selected="${ifDefined(this.effectiveSelected)}" aria-labelledby="${ifDefined(this.ariaLabelledBy)}" ._realTab="${ifDefined(this)}" style="width: ${ifDefined(this._width)};">${ this.hasIcon ? block2.call(this, context, tags, suffix) : undefined }<span class="udex-tab-navigation__button-text ${ifDefined(this.classWithIcon)}">${ifDefined(this.text)}${ this.numberBadge ? block4.call(this, context, tags, suffix) : undefined }</span>${ this.hasNotIcon ? block5.call(this, context, tags, suffix) : undefined }<span aria-hidden="true" class="hidden" id="udex-tab-navigation__${ifDefined(this._id)}__accessible-name">${ifDefined(this.accessibleNameText)}</span></button>`;}
function block2 (this: TabHeader, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<span class="udex-tab-navigation__button-icon-outer"><${scopeTag("ui5-icon", tags, suffix)} class="udex-tab-navigation__button-icon" name="${ifDefined(this.icon)}"></${scopeTag("ui5-icon", tags, suffix)}>${ this.showAttentionBadge ? block3.call(this, context, tags, suffix) : undefined }</span>` : html`<span class="udex-tab-navigation__button-icon-outer"><ui5-icon class="udex-tab-navigation__button-icon" name="${ifDefined(this.icon)}"></ui5-icon>${ this.showAttentionBadge ? block3.call(this, context, tags, suffix) : undefined }</span>`;}
function block3 (this: TabHeader, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<span class="udex-tab-navigation__button-badge"></span>`;}
function block4 (this: TabHeader, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<${scopeTag("udex-badge", tags, suffix)} class="udex-tab-navigation__badge" type="Number" number-design="Blue" size="Large">${ifDefined(this.numberBadge)}</${scopeTag("udex-badge", tags, suffix)}>` : html`<udex-badge class="udex-tab-navigation__badge" type="Number" number-design="Blue" size="Large">${ifDefined(this.numberBadge)}</udex-badge>`;}
function block5 (this: TabHeader, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<span class="udex-tab-navigation__button-badge"></span>`;}
function block6 (this: TabHeader, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<a id="${ifDefined(this._id)}" class="udex-tab-navigation__button-tab ${ifDefined(this.selectedClass)}${ifDefined(this.classWithIconWithoutBackground)}${ifDefined(this.buttonClassWithIcon)}" tabindex="-1" role="tab" aria-posinset="${ifDefined(this._posinset)}" aria-setsize="${ifDefined(this._setsize)}" href="${ifDefined(this.href)}" target="${ifDefined(this.target)}" style="max-width: ${ifDefined(this._maxWidth)}px; width: ${ifDefined(this._width)};" ._realTab="${ifDefined(this)}">${ this.hasIcon ? block7.call(this, context, tags, suffix) : undefined }<span class="udex-tab-navigation__button-text ${ifDefined(this.classWithIcon)}">${ifDefined(this.text)}${ this.numberBadge ? block9.call(this, context, tags, suffix) : undefined }</span>${ this.hasNotIcon ? block10.call(this, context, tags, suffix) : undefined }<span aria-hidden="true" class="hidden" id="udex-tab-navigation__${ifDefined(this._id)}__accessible-name">${ifDefined(this.accessibleNameText)}</span></a>`;}
function block7 (this: TabHeader, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<span class="udex-tab-navigation__button-icon-outer"><${scopeTag("ui5-icon", tags, suffix)} class="udex-tab-navigation__button-icon" name="${ifDefined(this.icon)}"></${scopeTag("ui5-icon", tags, suffix)}>${ this.showAttentionBadge ? block8.call(this, context, tags, suffix) : undefined }</span>` : html`<span class="udex-tab-navigation__button-icon-outer"><ui5-icon class="udex-tab-navigation__button-icon" name="${ifDefined(this.icon)}"></ui5-icon>${ this.showAttentionBadge ? block8.call(this, context, tags, suffix) : undefined }</span>`;}
function block8 (this: TabHeader, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<span class="udex-tab-navigation__button-badge"></span>`;}
function block9 (this: TabHeader, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<${scopeTag("udex-badge", tags, suffix)} class="udex-tab-navigation__badge" type="Number" number-design="Blue" size="Large">${ifDefined(this.numberBadge)}</${scopeTag("udex-badge", tags, suffix)}>` : html`<udex-badge class="udex-tab-navigation__badge" type="Number" number-design="Blue" size="Large">${ifDefined(this.numberBadge)}</udex-badge>`;}
function block10 (this: TabHeader, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<span class="udex-tab-navigation__button-badge"></span>`;}


export default block0;