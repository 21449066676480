/* eslint no-unused-vars: 0 */
import { html, svg, repeat, classMap, styleMap, ifDefined, unsafeHTML, scopeTag } from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import type UI5Element from "@ui5/webcomponents-base/dist/UI5Element.js";
	import type MastheadProfileMenu from "../../MastheadProfileMenu.js";
	import type { ClassMapValue } from "@ui5/webcomponents-base/dist/types.js";
	
function block0 (this: MastheadProfileMenu, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<div class="udex-masthead-profile-menu"><div class="udex-masthead-profile-menu__header">${ this.userData ? block1.call(this, context, tags, suffix) : undefined }</div>${ this.messageAvailable ? block3.call(this, context, tags, suffix) : undefined }<div class="udex-masthead-profile-menu__links"><${scopeTag("udex-masthead-menu", tags, suffix)} sub-items='${ifDefined(this.linksList)}'></${scopeTag("udex-masthead-menu", tags, suffix)}>${ this.logoutLink ? block4.call(this, context, tags, suffix) : undefined }</div></div>` : html`<div class="udex-masthead-profile-menu"><div class="udex-masthead-profile-menu__header">${ this.userData ? block1.call(this, context, tags, suffix) : undefined }</div>${ this.messageAvailable ? block3.call(this, context, tags, suffix) : undefined }<div class="udex-masthead-profile-menu__links"><udex-masthead-menu sub-items='${ifDefined(this.linksList)}'></udex-masthead-menu>${ this.logoutLink ? block4.call(this, context, tags, suffix) : undefined }</div></div>`;}
function block1 (this: MastheadProfileMenu, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<${scopeTag("udex-avatar", tags, suffix)} size="L" initials="${ifDefined(this.userData.avatarInitials)}">${ this.userData.avatarUrl ? block2.call(this, context, tags, suffix) : undefined }</${scopeTag("udex-avatar", tags, suffix)}><div><p class="udex-profile-menu__account-name">${ifDefined(this.userData.name)}</p><p class="udex-profile-menu__account-email">${ifDefined(this.userData.email)}</p></div>` : html`<udex-avatar size="L" initials="${ifDefined(this.userData.avatarInitials)}">${ this.userData.avatarUrl ? block2.call(this, context, tags, suffix) : undefined }</udex-avatar><div><p class="udex-profile-menu__account-name">${ifDefined(this.userData.name)}</p><p class="udex-profile-menu__account-email">${ifDefined(this.userData.email)}</p></div>`;}
function block2 (this: MastheadProfileMenu, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<img src="${ifDefined(this.userData.avatarUrl)}" alt="avatar"/>`;}
function block3 (this: MastheadProfileMenu, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<div class="udex-masthead-profile-menu__message"><slot name="message"></slot></div>`;}
function block4 (this: MastheadProfileMenu, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<div class="udex-masthead-profile-menu__actions" @click=${this.userLogout}><${scopeTag("udex-masthead-menu-item", tags, suffix)} url="${ifDefined(this.logoutUrl)}" label="${ifDefined(this.logoutText)}"></${scopeTag("udex-masthead-menu-item", tags, suffix)}></div>` : html`<div class="udex-masthead-profile-menu__actions" @click=${this.userLogout}><udex-masthead-menu-item url="${ifDefined(this.logoutUrl)}" label="${ifDefined(this.logoutText)}"></udex-masthead-menu-item></div>`;}


export default block0;