/* eslint no-unused-vars: 0 */
import { html, svg, repeat, classMap, styleMap, ifDefined, unsafeHTML, scopeTag } from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import type UI5Element from "@ui5/webcomponents-base/dist/UI5Element.js";
	import type SegmentedButtonItem from "../../SegmentedButtonItem.js";
	import type { ClassMapValue } from "@ui5/webcomponents-base/dist/types.js";
	
function block0 (this: SegmentedButtonItem, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<li role="option" aria-roledescription="${ifDefined(this.ariaDescription)}" aria-posinset="${ifDefined(this.posInSet)}" aria-setsize="${ifDefined(this.sizeOfSet)}" aria-selected="${ifDefined(this.pressed)}" class="ui5-button-root" aria-disabled="${ifDefined(this.disabled)}" data-sap-focus-ref  @focusout=${this._onfocusout} @focusin=${this._onfocusin} @click=${this._onclick} @mousedown=${this._onmousedown} @mouseup=${this._onmouseup} @keydown=${this._onkeydown} @keyup=${this._onkeyup} @touchstart="${this._ontouchstart}" @touchend="${this._ontouchend}" tabindex=${ifDefined(this.tabIndexValue)} aria-label="${ifDefined(this.ariaLabelText)}" title="${ifDefined(this.tooltip)}">${ this.icon ? block1.call(this, context, tags, suffix) : undefined }<span id="${ifDefined(this._id)}-content" class="ui5-button-text"><bdi><slot></slot></bdi></span></li> `;}
function block1 (this: SegmentedButtonItem, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<${scopeTag("ui5-icon", tags, suffix)} class="ui5-button-icon" name="${ifDefined(this.icon)}" part="icon" ?show-tooltip=${this.showIconTooltip}></${scopeTag("ui5-icon", tags, suffix)}>` : html`<ui5-icon class="ui5-button-icon" name="${ifDefined(this.icon)}" part="icon" ?show-tooltip=${this.showIconTooltip}></ui5-icon>`;}


export default block0;