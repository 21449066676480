import UI5Element from "@ui5/webcomponents-base/dist/UI5Element.js";
import customElement from "@ui5/webcomponents-base/dist/decorators/customElement.js";
import property from "@ui5/webcomponents-base/dist/decorators/property.js";
import litRender from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import Icon from "@ui5/webcomponents/dist/Icon.js";
import "@ui5/webcomponents-icons/dist/error.js";
import "@ui5/webcomponents-icons/dist/alert.js";
import "@ui5/webcomponents-icons/dist/sys-enter-2.js";
import "@ui5/webcomponents-icons/dist/edit.js";
import "@ui5/webcomponents-icons/dist/information.js";
import I18nBundle, { getI18nBundle } from "@ui5/webcomponents-base/dist/i18nBundle.js";
import {
  STATUS_BADGE_TEXT,
  STATUS_BADGE_EDIT_TITLE,
  STATUS_BADGE_INFORMATION_TITLE,
  STATUS_BADGE_ERROR_TITLE,
  STATUS_BADGE_SUCCESS_TITLE,
  STATUS_BADGE_WARNING_TITLE,
} from "./generated/i18n/i18n-defaults.js";

import StatusBadgeTemplate from "./generated/templates/StatusBadgeTemplate.lit.js";

// Styles
import StatusBadgeCss from "./generated/themes/StatusBadge.css.js";

export enum IconMapping {
  Success = "sys-enter-2",
  Error = "error",
  Warning = "alert",
  Edit = "edit",
  Information = "information",
}

export enum BadgeSize {
  XS = "XS",
  S = "S",
  M = "M",
  L = "L",
  XL = "XL",
}

/**
 * @class
 *
 * <h3 class="comment-api-title">Overview</h3>
 *
 *
 * <h3>Usage</h3>
 *
 * For the <code>udex-status-badge</code>
 * <h3>ES6 Module Import</h3>
 *
 * <code>import "@udex/web-components/dist/StatusBadge.js";</code>
 *
 * @constructor
 * @extends UI5Element
 * @public
 */
@customElement({
  tag: "udex-status-badge",
  renderer: litRender,
  styles: StatusBadgeCss,
  template: StatusBadgeTemplate,
  dependencies: [Icon],
})
class UDExStatusBadge extends UI5Element {
  /**
   * Defines the value state of the component.
   *
   * @default "Information"
   * @public
   */
  @property({ type: IconMapping, defaultValue: IconMapping.Information })
    valueState!: "Information" | "Warning" | "Error" | "Success" | "Edit";

  /**
   * Defines predefined size of the component.
   *
   * @default "S"
   * @public
   */
  @property({ type: BadgeSize, defaultValue: BadgeSize.S })
    size!: `${BadgeSize}`;

    /**
   * Defines the text alternative of the component.
   *
   * @default ""
   * @public
   */
    @property({ type: String, defaultValue: "" })
    accessibleName!: string;

  static i18nBundle: I18nBundle;

  static async onDefine(): Promise<void> {
    UDExStatusBadge.i18nBundle = await getI18nBundle("sap-ui-webcomponents-bundle");
  }

  get iconFieldName(): string {
    if (!IconMapping[this.valueState]) {
      return IconMapping.Information;
    }
    return IconMapping[this.valueState];
  }

  get badgeAccessibleName(): string {
    return `${this.accessibleName ? this.accessibleName : UDExStatusBadge.i18nBundle.getText(STATUS_BADGE_TEXT)}`;
  }

  get ariaTitle() {
    switch (IconMapping[this.valueState]) {
    case IconMapping.Edit:
      return UDExStatusBadge.i18nBundle.getText(STATUS_BADGE_EDIT_TITLE);
    case IconMapping.Information:
      return UDExStatusBadge.i18nBundle.getText(STATUS_BADGE_INFORMATION_TITLE);
    case IconMapping.Success:
      return UDExStatusBadge.i18nBundle.getText(STATUS_BADGE_SUCCESS_TITLE);
    case IconMapping.Error:
      return UDExStatusBadge.i18nBundle.getText(STATUS_BADGE_ERROR_TITLE);
    case IconMapping.Warning:
      return UDExStatusBadge.i18nBundle.getText(STATUS_BADGE_WARNING_TITLE);
    }
  }
}

UDExStatusBadge.define();

export default UDExStatusBadge;
