import UI5Element from "@ui5/webcomponents-base/dist/UI5Element.js";
import customElement from "@ui5/webcomponents-base/dist/decorators/customElement.js";
import property from "@ui5/webcomponents-base/dist/decorators/property.js";
import event from "@ui5/webcomponents-base/dist/decorators/event.js";
import litRender from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";

import MediaPlayerYoutubeTemplate from "./generated/templates/MediaPlayerYoutubeTemplate.lit.js";

// Styles
import MediaPlayerYoutubeCss from "./generated/themes/MediaPlayerYoutube.css.js";
import MediaPlayerCss from "./generated/themes/MediaPlayer.css";
import { AspectRatio } from "./MediaPlayer.js";

const MATCH_NOCOOKIE = /youtube-nocookie\.com/;
const MATCH_URL_YOUTUBE = /(?:youtu\.be\/|youtube(?:-nocookie|education)?\.com\/(?:embed\/|v\/|watch\/|watch\?v=|watch\?.+&v=|shorts\/|live\/))((\w|-){11})|youtube\.com\/playlist\?list=|youtube\.com\/user\//;
const NOCOOKIE_HOST = "https://www.youtube-nocookie.com";
const SDK_URL = "https://www.youtube.com/iframe_api";
const SDK_GLOBAL_READY = "onYouTubeIframeAPIReady";
const CHECK_INTERVAL_TIME = 1000;
const MATCH_START_QUERY = /[?&#](?:start|t)=([0-9hms]+)/;
const MATCH_END_QUERY = /[?&#]end=([0-9hms]+)/;
const MATCH_START_STAMP = /(\d+)(h|m|s)/g;
const MATCH_NUMERIC = /^\d+$/;

declare global {
  interface Window {
    onYouTubeIframeAPIReady: () => void;
  }
}

/**
 * @class
 *
 * <h3 class="comment-api-title">Overview</h3>
 *
 *
 * <h3>Usage</h3>
 *
 * For the <code>udex-media-player-youtube</code>
 * <h3>ES6 Module Import</h3>
 *
 * <code>import "@udex/web-components/dist/MediaPlayerYoutube.js";</code>
 *
 * @constructor
 * @extends UI5Element
 * @public
 */
@customElement({
  tag: "udex-media-player-youtube",
  renderer: litRender,
  styles: [MediaPlayerCss, MediaPlayerYoutubeCss],
  template: MediaPlayerYoutubeTemplate,
})
/**
 * Fires when video is ready
 *
 * @allowPreventDefault
 * @public
 */
@event("ready")
/**
 * Fires when video play ended
 *
 * @allowPreventDefault
 * @public
 */
@event("ended")
/**
 * Fires when video is paused
 *
 * @allowPreventDefault
 * @public
 */
@event("pause")
/**
 * Fires when video watching progress changed
 *
 * @allowPreventDefault
 * @public
 */
@event("playing", {
  detail: {
    percentPlayed: { type: Number },
  },
})
class UDExMediaPlayerYoutube extends UI5Element {
  /**
 * Defines the source of the video  or audio
 *
 * @default ""
 * @public
 */
  @property({ type: String, defaultValue: "" })
    source!: string;
  /**
 * Defines video width. Used for YouTube and Vimeo videos only.
 * Regular video and audio sizes are control by CSS variables.
 *
 * @default ""
 * @public
 */
  @property({ type: String, defaultValue: "" })
    width!: string;
  /**
   * Defines video height. Used for YouTube and Vimeo videos only.
   * Regular video and audio sizes are control by CSS variables.
   *
   * @default ""
   * @public
   */
  @property({ type: String, defaultValue: "" })
    height!: string;
  /**
   * Defines aspect ratio of video. If set, video will be display in fluid mode.
   * Possible values: "16:9" , "9:16", "4:3", "1:1", "none"
  *
  * @default "16:9"
  * @public
  */
  @property({ type: AspectRatio, defaultValue: "16:9" })
    aspectRatio!: `${AspectRatio}`;
  /**
  * Defines if video should be displayed in fluid mode.
  * In fluid mode, width and height will be ignored and size will be adjusted to the container.
  *
  * @default false
  * @public
  */
  @property({ type: Boolean })
    fluidMode!: boolean;

  private youtubePlayer!: YT.Player;
  private checkInterval?: number;
  private videoDuration: number = 0;

  get videoId(): string {
    if (!this.source) {
      return "";
    }
    const urlMatch = this.source.match(MATCH_URL_YOUTUBE);
    return urlMatch ? urlMatch[1] : "";
  }

  get url(): URL {
    return new URL(this.source);
  }

  get autoplay(): number | undefined {
    const param = this.url.searchParams.get("autoplay");
    return param ? parseInt(param) : undefined;
  }

  get muted(): number | undefined {
    const param = this.url.searchParams.get("mute");
    return param ? parseInt(param) : undefined;
  }

  get playsinline(): number | undefined {
    const param = this.url.searchParams.get("playsinline");
    return param ? parseInt(param) : undefined;
  }

  setupYoutubeAPI = () => {
    if (!window.YT) { // Only load script if YT is not already loaded
      const tag = document.createElement("script");
      tag.src = SDK_URL;
      const firstScriptTag = document.getElementsByTagName("script")[0];
      firstScriptTag.parentNode!.insertBefore(tag, firstScriptTag);
    }

    window[SDK_GLOBAL_READY] = () => this.onYouTubeIframeAPIReady();

    // If YouTube IFrame API is already loaded, directly initialize the player
    if (window.YT && window.YT.Player) {
      this.onYouTubeIframeAPIReady();
    }
  };

  onYouTubeIframeAPIReady = (): void => {
    this.youtubePlayer = new YT.Player(this.shadowRoot?.querySelector("#player") as HTMLElement, {
      height: this.height,
      width: this.width,
      videoId: this.videoId,
      playerVars: {
        autoplay: this.autoplay ? 1 : 0,
        mute: this.muted ? 1 : 0,
        playsinline: this.playsinline ? 1 : 0,
        origin: window.location.origin,
        start: this.parseStartTime(this.source),
        end: this.parseEndTime(this.source),
      },
      host: MATCH_NOCOOKIE.test(this.source) ? NOCOOKIE_HOST : undefined,
      events: {
        "onReady": this.onPlayerReady,
        "onStateChange": this.onPlayerStateChange,
      },
    } as unknown as YT.PlayerOptions);
  };

  private onPlayerReady = (ytevent: YT.PlayerEvent): void => {
    this.videoDuration = ytevent.target.getDuration();
    this.fireEvent("ready");
  };

  private onPlayerStateChange = (ytevent: YT.OnStateChangeEvent): void => {
    const { data } = ytevent;
    const { PLAYING, PAUSED, ENDED } = window.YT.PlayerState;

    if (data === PLAYING && !this.checkInterval) {
      this.checkInterval = window.setInterval(() => {
        this.checkVideoProgress();
      }, CHECK_INTERVAL_TIME);
    } else if (data !== PLAYING && this.checkInterval) {
      clearInterval(this.checkInterval);
      this.checkInterval = undefined;
    }

    if (data === ENDED) {
      this.fireEvent("ended", ytevent);
    }

    if (data === PAUSED) {
      this.fireEvent("pause", ytevent);
    }
  };

  private checkVideoProgress(): void {
    const currentTime = this.youtubePlayer?.getCurrentTime() || 0;
    const percentageWatched = (currentTime / this.videoDuration) * 100;
    this.fireEvent("playing", { percentPlayed: percentageWatched });
  }

  parseTimeParam(url: any, pattern: RegExp) {
    if (url instanceof Array) {
      return undefined;
    }
    const match = url.match(pattern);
    if (match) {
      const stamp: string = match[1];
      if (stamp.match(MATCH_START_STAMP)) {
        return this.parseTimeString(stamp);
      }
      if (MATCH_NUMERIC.test(stamp)) {
        return parseInt(stamp);
      }
    }
    return undefined;
  }

  parseTimeString(stamp: string) {
    let seconds = 0;
    let array = MATCH_START_STAMP.exec(stamp);
    while (array !== null) {
      const [, count, period] = array;
      if (period === "h") { seconds += parseInt(count) * 60 * 60; }
      if (period === "m") { seconds += parseInt(count) * 60; }
      if (period === "s") { seconds += parseInt(count); }
      array = MATCH_START_STAMP.exec(stamp);
    }
    return seconds;
  }

  parseStartTime(url: string) {
    return this.parseTimeParam(url, MATCH_START_QUERY);
  }

  parseEndTime(url: string) {
    return this.parseTimeParam(url, MATCH_END_QUERY);
  }

  onEnterDOM(): void {
    this.setupYoutubeAPI();
  }

  onExitDOM() {
    if (this.checkInterval) {
      clearInterval(this.checkInterval);
      this.checkInterval = undefined;
    }
  }

  /**
   * Returns video element
   * @public
   */
  getPlayer(): YT.Player | null {
    return this.youtubePlayer || null;
  }

  get fluidModeClass() {
    if (this.fluidMode) {
      return "udex-media-player__fluid";
    }
    return "";
  }
}

UDExMediaPlayerYoutube.define();

export default UDExMediaPlayerYoutube;
