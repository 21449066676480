import type { StyleData } from "@ui5/webcomponents-base/dist/types.js";
import { registerThemePropertiesLoader } from "@ui5/webcomponents-base/dist/asset-registries/Themes.js";

import defaultThemeBase from "@ui5/webcomponents-theming/dist/generated/themes/sap_horizon/parameters-bundle.css.js";
import defaultTheme from "./sap_horizon/parameters-bundle.css.js";

registerThemePropertiesLoader("@ui5/webcomponents-theming", "sap_horizon", async () => defaultThemeBase);
registerThemePropertiesLoader("@sapudex/web-components", "sap_horizon", async () => defaultTheme);

const styleData: StyleData = {packageName:"@sapudex/web-components",fileName:"themes/SearchableList.css.ts",content:`:host::part(checkbox){pointer-events:none}.udex-searchable-list__header{display:block;margin-top:var(--udexSpacer16, 16px)}.udex-searchable-list__header:only-child,.udex-searchable-list__header.udex-searchable-list__header_no-margin{margin-top:0}
`};
export default styleData;
	