import UI5Element from "@ui5/webcomponents-base/dist/UI5Element.js";
import customElement from "@ui5/webcomponents-base/dist/decorators/customElement.js";
import property from "@ui5/webcomponents-base/dist/decorators/property.js";
import litRender from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import getEffectiveScrollbarStyle from "@ui5/webcomponents-base/dist/util/getEffectiveScrollbarStyle.js";
import Integer from "@ui5/webcomponents-base/dist/types/Integer.js";
import { getI18nBundle } from "@ui5/webcomponents-base/dist/i18nBundle.js";
import type I18nBundle from "@ui5/webcomponents-base/dist/i18nBundle.js";
import {
  READ_ONLY, TEXTAREA_CHARACTERS_LEFT, VALUE_STATE_TYPE_ERROR, VALUE_STATE_TYPE_STANDARD, VALUE_STATE_TYPE_SUCCESS, VALUE_STATE_TYPE_WARNING,
} from "./generated/i18n/i18n-defaults.js";

import "@ui5/webcomponents/dist/Icon.js";
import "@ui5/webcomponents-icons/dist/accept.js";
import "@ui5/webcomponents-icons/dist/error.js";
import "@ui5/webcomponents-icons/dist/alert.js";

import TextAreaTemplate from "./generated/templates/TextAreaTemplate.lit.js";

// Styles
import TextAreaCss from "./generated/themes/TextArea.css.js";
import UDExStatusMessage from "./StatusMessage.js";

export enum FieldState {
  Standard = "Standard",
  Warning = "Warning",
  Error = "Error",
  Success = "Success",
}

/**
 * @class
 *
 * @constructor
 * @extends UI5Element
 * @tagname udex-text-area
 * @public
 */
@customElement({
  tag: "udex-textarea",
  renderer: litRender,
  styles: [TextAreaCss, getEffectiveScrollbarStyle()],
  template: TextAreaTemplate,
  dependencies: [UDExStatusMessage],
})

class UDExTextArea extends UI5Element {
  _labelActiveClassName = "udex-textarea__label--active";

  /**
   * Defines label text for the textarea field.
   *
   * @default ""
   * @public
   */
  @property({ type: String, defaultValue: "" })
    label!: string;

  /**
   * Defines the value of the component.
   * <br><br>
   * <b>Note:</b> The property is updated upon typing.
   *
   * @default ""
   * @public
   */
  @property({ type: String, defaultValue: "" })
    value!: string;

  /**
   * Defines the number of visible text lines for the component.
   * <br><br>
   * <b>Notes:</b>
   * <ul>
   * <li>If the <code>growing</code> property is enabled, this property defines the minimum rows to be displayed
   * in the textarea.</li>
   * <li>The CSS <code>height</code> property wins over the <code>rows</code> property, if both are set.</li>
   * </ul>
   *
   * @public
   */
  @property({ validator: Integer })
    rows!: number;

  /**
   * Enables the component to automatically grow and shrink dynamically with its content.
   * <br><br>
   *
   * @default false
   * @public
   */
  @property({ type: Boolean })
    growing!: boolean;

  /**
   * Defines the maximum number of lines that the component can grow.
   *
   * @default 0
   * @public
   */
  @property({ validator: Integer, defaultValue: 0 })
    growingMaxLines!: number;

  /**
   * Defines supporting text under the input field.
   *
   * @default ""
   * @public
   */
  @property({ type: String, defaultValue: "" })
    supportingText!: string;

  /**
   * Sets the maximum number of characters available in the textarea.
   *
   * @public
   */
  @property({ validator: Integer })
    maxlength?: number;

  /**
   * Defines the value state of the component.
   *
   * @default "Standard"
   * @public
   */
  @property({ type: FieldState, defaultValue: FieldState.Standard })
    fieldState!: `${FieldState}`;

  /**
   * Defines whether the component is required.
   *
   * @default false
   * @public
   */
  @property({ type: Boolean })
    required!: boolean;

  /**
   * Defines whether the component is in disabled state.
   * <br><br>
   * <b>Note:</b> A disabled component is completely noninteractive.
   *
   * @default false
   * @public
   */
  @property({ type: Boolean })
    disabled!: boolean;

  /**
   * Defines whether the component is read-only.
   * <br><br>
   * <b>Note:</b> A read-only component is not editable,
   * but still provides visual feedback upon user interaction.
   *
   * @default false
   * @public
   */
  @property({ type: Boolean })
    readonly!: boolean;

  /**
   * Defines whether the component is display-only.
   * <br><br>
   * <b>Note:</b> A display-only component is not editable,
   * but still provides visual feedback upon user interaction, input border is not visible.
   *
   * @default false
   * @public
   */
  @property({ type: Boolean })
    displayonly!: boolean;

  /**
   * Defines the inner stored value of the readonly attribute of the input.
   *
   * @default false
   * @private
   */
  @property({ type: Boolean, noAttribute: true })
    isInputReadonly!: boolean;

  /**
   * Defines the inner stored value of the classes of the label.
   *
   * @default ""
   * @private
   */
  @property({ type: String, noAttribute: true, defaultValue: "" })
    labelClasses!: string;

  /**
   * Defines the inner stored class of the label.
   *
   * @default ""
   * @private
   */
  @property({ type: String, noAttribute: true, defaultValue: "" })
    labelActiveClass!: string;

  /**
   * Defines the accessible ARIA name of the component.
   * @default ""
   * @public
   */
  @property()
    accessibleName!: string;

  /**
   * Receives id (or many ids) of the elements that label the component.
   * @default ""
   * @public
   */
  @property({ defaultValue: "" })
    accessibleNameRef!: string;

  static i18nBundle: I18nBundle;

  static async onDefine() {
    UDExTextArea.i18nBundle = await getI18nBundle("sap-ui-webcomponents-bundle");
  }

  getInputDomRef() {
    return this.getDomRef()!.querySelector<HTMLTextAreaElement>("textarea")!;
  }

  get hasValueState() {
    return this.fieldState === FieldState.Error || this.fieldState === FieldState.Warning || this.fieldState === FieldState.Success;
  }

  get fieldStateTypeMappings() {
    return {
      "Success": UDExTextArea.i18nBundle.getText(VALUE_STATE_TYPE_SUCCESS),
      "Standard": UDExTextArea.i18nBundle.getText(VALUE_STATE_TYPE_STANDARD),
      "Error": UDExTextArea.i18nBundle.getText(VALUE_STATE_TYPE_ERROR),
      "Warning": UDExTextArea.i18nBundle.getText(VALUE_STATE_TYPE_WARNING),
    };
  }

  get ariaValueStateHiddenText() {
    if (!this.hasValueState) {
      return;
    }

    if (this.fieldState === FieldState.Standard) {
      return;
    }

    return `${this.fieldStateTypeMappings[this.fieldState]}`;
  }

  get readOnlyText() {
    return UDExTextArea.i18nBundle.getText(READ_ONLY);
  }

  get ariaDescribedBy() {
    const valueStateTextDescId = this.hasValueState ? `${this._id}-valueStateDesc` : "";
    const readonlyTextDescId = this.readonly ? `${this._id}-readonlyDesc` : "";
    const supportingTextDescId = this._supportingText ? `${this._id}-supportingTextDesc` : "";
    return `${valueStateTextDescId} ${supportingTextDescId} ${readonlyTextDescId}`;
  }

  get _supportingText() {
    if (this.supportingText.length) {
      return this.supportingText;
    }

    const maxLength = this.maxlength;

    if (maxLength !== null && maxLength !== undefined) {
      const leftCharactersCount = maxLength - this.value.length;

      if (leftCharactersCount >= 0) {
        return UDExTextArea.i18nBundle.getText(TEXTAREA_CHARACTERS_LEFT, leftCharactersCount);
      }
    }
  }

  handleInput = (event: InputEvent) => {
    this.value = (event.target as HTMLInputElement)?.value;
  };

  handleChange = () => {
    this.fireEvent("change");
  };

  handleGrowing = () => {
    if (this.growing) {
      const nativeTextArea = this.getInputDomRef();

      if (!this.rows) {
        this.rows = 1;
      }
      nativeTextArea.style.height = "auto";
      // 2px - to avoid an unnecessary scrollbar, the border needs to be taken into account
      nativeTextArea.style.height = `${nativeTextArea.scrollHeight + 2}px`;

      if (this.growingMaxLines) {
        const style = getComputedStyle(nativeTextArea);

        const lineHeight = parseInt(style.lineHeight);
        const paddingTop = parseInt(style.paddingTop);
        const paddingBottom = parseInt(style.paddingBottom);

        const heightRatio = this.growingMaxLines > this.rows ? this.growingMaxLines : this.rows;

        const growingMaxHeight = lineHeight * heightRatio + paddingTop + paddingBottom;

        // 2px - to avoid an unnecessary scrollbar, the border needs to be taken into account
        nativeTextArea.style.maxHeight = `${growingMaxHeight + 2}px`;
      }
    }
  };

  handleFocus = () => {
    if (!this.disabled && !this.readonly && !this.displayonly) {
      this.labelActiveClass = this._labelActiveClassName;
    }
  };

  handleBlur = () => {
    if (this.value) {
      this.labelActiveClass = this._labelActiveClassName;
    } else {
      this.labelActiveClass = "";
    }
  };

  get ariaInvalid() {
    return this.fieldState === FieldState.Error ? "true" : null;
  }

  onBeforeRendering() {
    if (this.value && this.labelActiveClass === "") {
      this.labelActiveClass = this._labelActiveClassName;
    }

    this.isInputReadonly = this.readonly || this.displayonly;

    this.labelClasses = `udex-textarea__label ${this.labelActiveClass}`;
  }

  onAfterRendering() {
    this.handleGrowing();
  }
}

UDExTextArea.define();

export default UDExTextArea;
