/* eslint no-unused-vars: 0 */
import { html, svg, repeat, classMap, styleMap, ifDefined, unsafeHTML, scopeTag } from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import type UI5Element from "@ui5/webcomponents-base/dist/UI5Element.js";
	import type Badge from "../../Badge.js";
	import type { ClassMapValue } from "@ui5/webcomponents-base/dist/types.js";
	
function block0 (this: Badge, context: UI5Element, tags: string[], suffix: string | undefined) { return html`${ this.textType ? block1.call(this, context, tags, suffix) : block2.call(this, context, tags, suffix) }`;}
function block1 (this: Badge, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<div class="${ifDefined(this.textClasses)}"><slot></slot></div>`;}
function block2 (this: Badge, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<div class="${ifDefined(this.numberClasses)}"><slot></slot></div>`;}


export default block0;