import UI5Element from "@ui5/webcomponents-base/dist/UI5Element.js";
import customElement from "@ui5/webcomponents-base/dist/decorators/customElement.js";
import litRender from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import property from "@ui5/webcomponents-base/dist/decorators/property.js";
import slot from "@ui5/webcomponents-base/dist/decorators/slot.js";
import event from "@ui5/webcomponents-base/dist/decorators/event.js";

import Icon from "@ui5/webcomponents/dist/Icon.js";
import { LinkClickEventDetail } from "@ui5/webcomponents/dist/Link.js";
import Integer from "@ui5/webcomponents-base/dist/types/Integer.js";
import NavigationMode from "@ui5/webcomponents-base/dist/types/NavigationMode.js";
import ItemNavigation, { ITabbable } from "@ui5/webcomponents-base/dist/delegate/ItemNavigation.js";
import type I18nBundle from "@ui5/webcomponents-base/dist/i18nBundle.js";
import { getI18nBundle } from "@ui5/webcomponents-base/dist/i18nBundle.js";
import breadcrumbsCss from "@ui5/webcomponents/dist/generated/themes/Breadcrumbs.css.js";
import BreadcrumbsTemplate from "./generated/templates/BreadcrumbsTemplate.lit.js";
import BreadcrumbsPopover from "./generated/templates/BreadcrumbsPopoverTemplate.lit.js";
import UDExBreadcrumbsItem from "./BreadcrumbsItem.js";
import Link from "./Link.js";
import Dropdown from "./Dropdown.js";
import {
  BREADCRUMBS_ARIA_LABEL,
  BREADCRUMBS_OVERFLOW_ARIA_LABEL,
} from "./generated/i18n/i18n-defaults.js";
import "@ui5/webcomponents-icons/dist/slim-arrow-down.js";
import "@ui5/webcomponents-icons/dist/home.js";

// Styles
import BreadcrumbsCss from "./generated/themes/Breadcrumbs.css.js";
import BreadcrumbsPopoverCss from "./generated/themes/BreadcrumbsPopover.css.js";

export type UDExBreadcrumbsItemClickEventDetail = {
  item: UDExBreadcrumbsItem;
  altKey: boolean;
  ctrlKey: boolean;
  metaKey: boolean;
  shiftKey: boolean;
}

/**
 * @class
 *
 * <h3 class="comment-api-title">Overview</h3>
 *
 *
 * <h3>Usage</h3>
 *
 * For the <code>udex-breadcrumbs</code>
 * <h3>ES6 Module Import</h3>
 *
 * <code>import "@udex/web-components/dist/Breadcrumbs.js";</code>
 *
 * @constructor
 * @extends UI5Element
 * @public
 */
@customElement({
  tag: "udex-breadcrumbs",
  renderer: litRender,
  styles: [breadcrumbsCss, BreadcrumbsCss],
  template: BreadcrumbsTemplate,
  staticAreaTemplate: BreadcrumbsPopover,
  staticAreaStyles: [BreadcrumbsPopoverCss],
  dependencies: [UDExBreadcrumbsItem, Link, Dropdown, Icon],
})
/**
 * Fires when a `BreadcrumbsItem` is clicked.
 *
 * **Note:** You can prevent browser location change by calling `event.preventDefault()`.
 * @allowPreventDefault
 * @param {HTMLElement} item The clicked item.
 * @param {Boolean} altKey Returns whether the "ALT" key was pressed when the event was triggered.
 * @param {Boolean} ctrlKey Returns whether the "CTRL" key was pressed when the event was triggered.
 * @param {Boolean} metaKey Returns whether the "META" key was pressed when the event was triggered.
 * @param {Boolean} shiftKey Returns whether the "SHIFT" key was pressed when the event was triggered.
 * @public
 */
@event<UDExBreadcrumbsItemClickEventDetail>("item-click", {
  detail: {
    /**
     * @public
     */
    item: { type: HTMLElement },
    /**
     * @public
     */
    altKey: { type: Boolean },
    /**
     * @public
     */
    ctrlKey: { type: Boolean },
    /**
     * @public
     */
    metaKey: { type: Boolean },
    /**
     * @public
     */
    shiftKey: { type: Boolean },
  },
})
class UDExBreadcrumbs extends UI5Element {
  /**
   * Indicates if dropdown is opened
   *
   * @default false
   * @private
   */
  @property({ noAttribute: true, type: Boolean })
    _dropdownOpened!: boolean;

  /**
   * Defines how many breadcrumb items should be visible
   *
   * @default 3
   * @public
   */
  @property({ type: Number, defaultValue: 3, validator: Integer })
    showItems?: number;

  /**
   * Defines the component items.
   *
   * **Note:** Use the `udex-breadcrumbs-item` component to define the desired items.
   * @public
   */
  @slot({ type: HTMLElement, invalidateOnChildChange: true, "default": true })
    items!: Array<UDExBreadcrumbsItem>;

  responsivePopover?: Dropdown;
  _itemNavigation?: ItemNavigation;
  static i18nBundle: I18nBundle;

  onBeforeRendering() {
    this._preprocessItems();
  }

  _preprocessItems() {
    this.items.forEach(item => {
      item._getRealDomRef = () => this.getDomRef()!.querySelector(`[data-ui5-stable*=${item.stableDomRef}]`)!;
    });
  }

  async _getResponsePopover() {
    const staticAreaItem = await this.getStaticAreaItemDomRef();
    return staticAreaItem!.querySelector<Dropdown>(`#udex-${this.__id!}-breadcrumbs__popover`)!;
  }

  /**
   * Getter for the interactive element that opens the overflow
   * @private
   */
  get _dropdownArrowLink() {
    return this.shadowRoot!.querySelector<Link>(".udex-breadcrumbs__trigger")!;
  }

  /**
   * Getter for the list of links corresponding to the abstract breadcrumb items
   */
  get _links() {
    return Array.from(this.shadowRoot!.querySelectorAll<Link>(".udex-breadcrumbs__link-wrapper [ui5-link]"));
  }

  _getFocusableItems() {
    const items: Array<ITabbable> = this._links;
    items.unshift(this._dropdownArrowLink);

    return items;
  }

  _initItemNavigation() {
    if (!this._itemNavigation) {
      this._itemNavigation = new ItemNavigation(this, {
        navigationMode: NavigationMode.Horizontal,
        getItemsCallback: () => this._getFocusableItems(),
      });
    }
  }

  onAfterRendering(): void {
    this._initItemNavigation();
  }

  _getItems() {
    return this.getSlottedNodes<UDExBreadcrumbsItem>("items");
  }

  _closeRespPopover() {
    this.responsivePopover && this.responsivePopover.onClose();
  }

  async _openRespPopover() {
    this.responsivePopover = await this._getResponsePopover();
    this.responsivePopover.onOpen(this._dropdownArrowLink);
  }

  _onDropdownLinkPress(e: CustomEvent<LinkClickEventDetail>) {
    const listItem = e.target as HTMLElement,
      items = this._getItems(),
      item = items.find(x => `${x._id}-li` === listItem.id)!;

    if (item._isCurrentPageItem) {
      return;
    }

    if (this.fireEvent("item-click", { item }, true)) {
      window.open(item.href, item.target || "_self", "noopener,noreferrer");
      this.responsivePopover!.onClose();
    }
  }

  _onLinkPress(e: CustomEvent<LinkClickEventDetail>) {
    const link = e.target as Link,
      items = this._getItems(),
      item = items.find(x => `${x._id}-link` === link.id)!,
      {
        altKey,
        ctrlKey,
        metaKey,
        shiftKey,
      } = e.detail;

    if (!this.fireEvent<UDExBreadcrumbsItemClickEventDetail>("item-click", {
      item,
      altKey,
      ctrlKey,
      metaKey,
      shiftKey,
    }, true)) {
      e.preventDefault();
    }
  }

  _isItemVisible(item: UDExBreadcrumbsItem) {
    return !item.hidden;
  }

  _afterDropdownOpen() {
    this._dropdownOpened = true;
  }

  _afterDropdownClose() {
    this._dropdownOpened = false;
  }

  get _visibleItems() {
    const items = this._getItems();
    const itemsLength = this.showItems ?? 0;

    return this._getItems()
      .slice(Math.max(items.length - itemsLength - 1, 0))
      .filter(i => this._isItemVisible(i));
  }

  /**
   * Getter for the list of abstract breadcrumb items to be rendered as links outside the overflow
   */
  get _linksData() {
    const items = this._visibleItems;
    const itemsCount = items.length;

    return items
      .map((item, index) => {
        item._isCurrentPageItem = index === (itemsCount - 1);
        return item;
      });
  }

  get _isOverflowEmpty() {
    return this._dropdownItems.length === 0;
  }

  _toggleDropdown() {
    if (this._dropdownOpened) {
      this._closeRespPopover();
    } else {
      this._openRespPopover();
    }
  }

  get _dropdownItems() {
    return this._getItems().filter(i => this._isItemVisible(i));
  }

  get _triggerClass() {
    return this._dropdownOpened ? "udex-breadcrumbs__trigger--opened" : "";
  }

  get _accessibleNameText() {
    return UDExBreadcrumbs.i18nBundle.getText(BREADCRUMBS_ARIA_LABEL);
  }

  get _dropdownArrowAccessibleNameText() {
    return UDExBreadcrumbs.i18nBundle.getText(BREADCRUMBS_OVERFLOW_ARIA_LABEL);
  }

  _onfocusin(e: FocusEvent) {
    const currentItem = e.target as Link;

    this._itemNavigation?.setCurrentItem(currentItem);
  }

  static async onDefine() {
    UDExBreadcrumbs.i18nBundle = await getI18nBundle("@ui5/webcomponents");
  }
}

UDExBreadcrumbs.define();

export default UDExBreadcrumbs;
