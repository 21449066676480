import customElement from "@ui5/webcomponents-base/dist/decorators/customElement.js";
import litRender from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import property from "@ui5/webcomponents-base/dist/decorators/property.js";
import UDExTableRowBase from "./TableRowBase.js";
import TableRowTemplate from "./generated/templates/TableRowTemplate.lit.js";

/**
 * @class
 *
 * <h3 class="comment-api-title">Overview</h3>
 *
 *
 * <h3>Usage</h3>
 *
 * For the <code>udex-table-row</code>
 * <h3>ES6 Module Import</h3>
 *
 * <code>import "@udex/web-components/dist/TableRow.js";</code>
 *
 * @constructor
 * @extends UDExTableRowBase
 * @public
 */
@customElement({
  tag: "udex-table-row",
  renderer: litRender,
  styles: [UDExTableRowBase.styles],
  template: TableRowTemplate,
  dependencies: [...UDExTableRowBase.dependencies],
})

class UDExTableRow extends UDExTableRowBase {
  /**
   * Defines the interactive state of the row.
   *
   * @default false
   * @public
   */
  @property({ type: Boolean })
    interactive?: boolean;

  get isGroup() {
    return false;
  }
}

UDExTableRow.define();

export default UDExTableRow;
