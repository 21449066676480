import customElement from "@ui5/webcomponents-base/dist/decorators/customElement.js";
import property from "@ui5/webcomponents-base/dist/decorators/property.js";
import event from "@ui5/webcomponents-base/dist/decorators/event.js";
import litRender from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import Toast from "@ui5/webcomponents/dist/Toast.js";
import "@ui5/webcomponents/dist/Icon.js";
import "@ui5/webcomponents-icons/dist/sys-enter-2.js";
import "@ui5/webcomponents-icons/dist/error.js";
import "@ui5/webcomponents-icons/dist/alert.js";
import { isDesktop } from "@ui5/webcomponents-base/dist/Device.js";

import ToastTemplate from "./generated/templates/ToastTemplate.lit.js";

// Styles
import ToastCss from "./generated/themes/Toast.css.js";

export enum ToastValueState {
  Action = "Action",
  Success = "Success",
  Warning = "Warning",
  Error = "Error"
}

/**
 * @class
 *
 * <h3 class="comment-api-title">Overview</h3>
 *
 *
 * <h3>Usage</h3>
 *
 * For the <code>udex-toast</code>
 * <h3>ES6 Module Import</h3>
 *
 * <code>import "@udex/web-components/dist/Toast.js";</code>
 *
 * @constructor
 * @extends Toast
 * @public
 */
@customElement({
  tag: "udex-toast",
  renderer: litRender,
  styles: [Toast.styles, ToastCss],
  template: ToastTemplate,
  dependencies: [Toast],
})

/**
 * Emit when close icon was clicked
 *
 * @public
 */
@event("close")
class UDExToast extends Toast {
  /**
  * Defines design state of component
  *
  * @public
  * @default "Action"
  */
  @property({ type: ToastValueState, defaultValue: ToastValueState.Action })
    valueState!: `${ToastValueState}`;

  /**
  * Defines if close icon should be visible
  *
  * @public
  * @default false
  */
  @property()
    showCloseIcon!: boolean;

  /**
  * Indicates whether the component is open (visible).
  * @default false
  * @public
  * @since 2.0.0
  */
  @property({ type: Boolean })
    open = false;

  onEnterDOM(): void {
    if (isDesktop()) {
      this.setAttribute("desktop", "");
    }
  }

  get withIcon(): boolean {
    return this.valueState !== ToastValueState.Action;
  }

  get iconName(): string {
    switch (this.valueState) {
    case ToastValueState.Success:
      return "sys-enter-2";
    case ToastValueState.Error:
      return "error";
    case ToastValueState.Warning:
      return "alert";
    default:
      return "";
    }
  }

  get iconDesign(): string {
    switch (this.valueState) {
    case ToastValueState.Success:
      return "Positive";
    case ToastValueState.Error:
      return "Negative";
    case ToastValueState.Warning:
      return "Critical";
    default:
      return "Default";
    }
  }

  closeToast() {
    this.open = false;
    this.fireEvent("close");
  }
}

UDExToast.define();

export default UDExToast;
