import type { StyleData } from "@ui5/webcomponents-base/dist/types.js";
import { registerThemePropertiesLoader } from "@ui5/webcomponents-base/dist/asset-registries/Themes.js";

import defaultThemeBase from "@ui5/webcomponents-theming/dist/generated/themes/sap_horizon/parameters-bundle.css.js";
import defaultTheme from "./sap_horizon/parameters-bundle.css.js";

registerThemePropertiesLoader("@ui5/webcomponents-theming", "sap_horizon", async () => defaultThemeBase);
registerThemePropertiesLoader("@sapudex/web-components", "sap_horizon", async () => defaultTheme);

const styleData: StyleData = {packageName:"@sapudex/web-components",fileName:"themes/Checkbox.css.ts",content:`:host([readonly]){--sapField_TextColor: var(--sapField_ReadOnly_BorderColor)}:host([display-only])::part(icon){height:24px;width:24px}:host([readonly]) .ui5-checkbox-inner,:host([readonly][value-state="Warning"][value-state="Error"][value-state="Success"]) .ui5-checkbox-inner{background:var(--sapField_ReadOnly_Background);border:var(--sapElement_BorderWidth) var(--sapField_ReadOnly_BorderColor) dashed;color:var(--sapField_TextColor)}:host([required]) .ui5-checkbox-label:after{padding-left:var(--udexSpacer8);padding-right:var(--udexSpacer2);content:"*"}:host([required]) .ui5-checkbox-label:dir(rtl):after{padding-left:var(--udexSpacer2);padding-right:var(--udexSpacer8);content:"*"}
`};
export default styleData;
	