/* eslint no-unused-vars: 0 */
import { html, svg, repeat, classMap, styleMap, ifDefined, unsafeHTML, scopeTag } from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import type UI5Element from "@ui5/webcomponents-base/dist/UI5Element.js";
	import type Tag from "../../Tag.js";
	import type { ClassMapValue } from "@ui5/webcomponents-base/dist/types.js";
	
function block0 (this: Tag, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<div tabindex="${ifDefined(this._tabIndex)}" class="${ifDefined(this.tagClasses)}" @click="${this.tagClickHandler}" @focusin="${this._focusin}" @focusout="${this._focusout}" @keydown="${this.tagKeyDown}" role="${ifDefined(this.accessibleRole)}" aria-label="${ifDefined(this.accessibleName)}" aria-pressed="${ifDefined(this.selected)}">${ this.startIcon ? block1.call(this, context, tags, suffix) : undefined }<span class="udex-tag__text">${unsafeHTML(this.text)}</span>${ this.endIcon ? block2.call(this, context, tags, suffix) : undefined }</div>`;}
function block1 (this: Tag, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<${scopeTag("ui5-icon", tags, suffix)} class="udex-tag__icon udex-tag__icon--start" name="${ifDefined(this.icon)}" @click="${this.iconClickHandler}"></${scopeTag("ui5-icon", tags, suffix)}>` : html`<ui5-icon class="udex-tag__icon udex-tag__icon--start" name="${ifDefined(this.icon)}" @click="${this.iconClickHandler}"></ui5-icon>`;}
function block2 (this: Tag, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<${scopeTag("ui5-icon", tags, suffix)} class="udex-tag__icon udex-tag__icon--end" name="${ifDefined(this.icon)}" @click="${this.iconClickHandler}"></${scopeTag("ui5-icon", tags, suffix)}>` : html`<ui5-icon class="udex-tag__icon udex-tag__icon--end" name="${ifDefined(this.icon)}" @click="${this.iconClickHandler}"></ui5-icon>`;}


export default block0;