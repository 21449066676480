/* eslint no-unused-vars: 0 */
import { html, svg, repeat, classMap, styleMap, ifDefined, unsafeHTML, scopeTag } from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import type UI5Element from "@ui5/webcomponents-base/dist/UI5Element.js";
	type WizardPopover = any;
	import type { ClassMapValue } from "@ui5/webcomponents-base/dist/types.js";
	
function block0 (this: WizardPopover, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<${scopeTag("ui5-responsive-popover", tags, suffix)} horizontal-align="Center" placement-type="Bottom" aria-label="${ifDefined(this.actionSheetStepsText)}" class="${classMap(this.classes.popover as ClassMapValue)}" @ui5-after-close=${ifDefined(this._afterClosePopover)} content-only-on-desktop prevent-focus-restore _hide-header><ul class="ui5-wizard-responsive-popover-list">${ repeat(this._groupedTabs, (item, index) => (item as typeof item & {_id?: any})._id || index, (item, index: number) => block1.call(this, context, tags, suffix, item, index)) }</ul><div slot="footer" class="ui5-responsive-popover-footer"><${scopeTag("ui5-button", tags, suffix)} design="Transparent" @click="${this._closeRespPopover}">Cancel</${scopeTag("ui5-button", tags, suffix)}></div></${scopeTag("ui5-responsive-popover", tags, suffix)}>` : html`<ui5-responsive-popover horizontal-align="Center" placement-type="Bottom" aria-label="${ifDefined(this.actionSheetStepsText)}" class="${classMap(this.classes.popover as ClassMapValue)}" @ui5-after-close=${ifDefined(this._afterClosePopover)} content-only-on-desktop prevent-focus-restore _hide-header><ul class="ui5-wizard-responsive-popover-list">${ repeat(this._groupedTabs, (item, index) => (item as typeof item & {_id?: any})._id || index, (item, index: number) => block1.call(this, context, tags, suffix, item, index)) }</ul><div slot="footer" class="ui5-responsive-popover-footer"><ui5-button design="Transparent" @click="${this._closeRespPopover}">Cancel</ui5-button></div></ui5-responsive-popover>`;}
function block1 (this: WizardPopover, context: UI5Element, tags: string[], suffix: string | undefined, item: any, index: number) { return suffix ? html`<li><${scopeTag("ui5-button", tags, suffix)} icon="${ifDefined(item.icon)}" ?disabled="${item.disabled}" design="Transparent" data-ui5-header-tab-ref-id="${ifDefined(item.accInfo.ariaPosinset)}" @click="${this._onOverflowStepButtonClick}">${ifDefined(item.titleText)}</${scopeTag("ui5-button", tags, suffix)}></li>` : html`<li><ui5-button icon="${ifDefined(item.icon)}" ?disabled="${item.disabled}" design="Transparent" data-ui5-header-tab-ref-id="${ifDefined(item.accInfo.ariaPosinset)}" @click="${this._onOverflowStepButtonClick}">${ifDefined(item.titleText)}</ui5-button></li>`;}


export default block0;