import UI5Element from "@ui5/webcomponents-base/dist/UI5Element.js";
import customElement from "@ui5/webcomponents-base/dist/decorators/customElement.js";
import property from "@ui5/webcomponents-base/dist/decorators/property.js";
import event from "@ui5/webcomponents-base/dist/decorators/event.js";
import litRender from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import Float from "@ui5/webcomponents-base/dist/types/Float.js";
import Integer from "@ui5/webcomponents-base/dist/types/Integer.js";
import { Timeout } from "@ui5/webcomponents-base/dist/types.js";
import Icon from "@ui5/webcomponents/dist/Icon.js";
import "@ui5/webcomponents-icons/dist/less.js";
import "@ui5/webcomponents-icons/dist/add.js";
import {
  isDown,
  isDownCtrl,
  isDownShift,
  isDownShiftCtrl,
  isEnter,
  isEscape,
  isPageDownShift,
  isPageUpShift,
  isUp,
  isUpCtrl,
  isUpShift,
  isUpShiftCtrl,
} from "@ui5/webcomponents-base/dist/Keys.js";
import I18nBundle, { getI18nBundle } from "@ui5/webcomponents-base/dist/i18nBundle.js";
import { STEPINPUT_DEC_ICON_TITLE, STEPINPUT_INC_ICON_TITLE } from "./generated/i18n/i18n-defaults.js";
import UDExTextField from "./TextField.js";
import ValueState from "./types/common.js";

import StepInputTemplate from "./generated/templates/StepInputTemplate.lit.js";

// Styles
import StepInputCss from "./generated/themes/StepInput.css.js";
import UDExControlButton from "./ControlButton.js";

// Spin variables
const INITIAL_WAIT_TIMEOUT = 500; // milliseconds
const ACCELERATION = 0.8;
const MIN_WAIT_TIMEOUT = 50; // milliseconds
const INITIAL_SPEED = 120; // milliseconds

export type StepInputValueStateChangeEventDetail = {
  valueState: `${ValueState}`,
  valid: boolean,
}

/**
 * @class
 *
 * <h3 class="comment-api-title">Overview</h3>
 *
 *
 * <h3>Usage</h3>
 *
 * For the <code>udex-step-input</code>
 * <h3>ES6 Module Import</h3>
 *
 * <code>import "@udex/web-components/dist/StepInput.js";</code>
 *
 * @constructor
 * @extends UI5Element
 * @public
 */
@customElement({
  tag: "udex-step-input",
  renderer: litRender,
  styles: StepInputCss,
  template: StepInputTemplate,
  dependencies: [Icon, UDExTextField, UDExControlButton],
})

/**
 * Fired when the input operation has finished by pressing Enter or on focusout.
 * @public
 */
@event("change")
/**
 * Fired before the value state of the component is updated internally.
 * The event is preventable, meaning that if it's default action is
 * prevented, the component will not update the value state.
 * @allowPreventDefault
 * @public
 * @param {string} valueState The new `valueState` that will be set.
 * @param {boolean} valid Indicator if the value is in between the min and max value.
 */
@event<StepInputValueStateChangeEventDetail>("value-state-change", {
  detail: {
    /**
     * @public
     */
    valueState: {
      type: String,
    },
    /**
     * @public
     */
    valid: {
      type: Boolean,
    },
  },
})
class UDExStepInput extends UI5Element {
  /**
   * Defines a value of the component.
   * @default 0
   * @public
   */
  @property({ validator: Float, defaultValue: 0 })
    value!: number;

  /**
   * Defines a minimum value of the component.
   * @default undefined
   * @public
   */
  @property({ validator: Float })
    min?: number;

  /**
   * Defines a maximum value of the component.
   * @default undefined
   * @public
   */
  @property({ validator: Float })
    max?: number;

  /**
   * Defines a step of increasing/decreasing the value of the component.
   * @default 1
   * @public
   */
  @property({ validator: Float, defaultValue: 1 })
    step!: number;

  /**
   * Defines the value state of the component.
   * @default "None"
   * @public
   */
  @property({ type: ValueState, defaultValue: ValueState.Standard })
    valueState!: `${ValueState}`;

  /**
   * Defines whether the component is required.
   * @default false
   * @public
   */
  @property({ type: Boolean })
    required!: boolean;

  /**
   * Determines whether the component is displayed as disabled.
   * @default false
   * @public
   */
  @property({ type: Boolean })
    disabled!: boolean;

  /**
   * Determines whether the component is displayed as read-only.
   * @default false
   * @public
   */
  @property({ type: Boolean })
    readonly!: boolean;

  /**
   * Defines whether the component is display-only.
   * <br><br>
   * <b>Note:</b> A display-only component is not editable,
   * but still provides visual feedback upon user interaction, input border is not visible.
   *
   * @defaultvalue false
   * @public
   */
  @property({ type: Boolean })
    displayonly!: boolean;

  /**
   * Determines the number of digits after the decimal point of the component.
   * @default 0
   * @public
   */
  @property({ validator: Integer, defaultValue: 0 })
    valuePrecision!: number;

  /**
   * Defines label text for the input field.
   *
   * @defaultvalue ""
   * @public
   */
  @property({ type: String, defaultValue: "" })
    label!: string;

  /**
   * Defines supporting text under the input field.
   *
   * @defaultvalue ""
   * @public
   */
  @property({ type: String, defaultValue: "" })
    supportingText!: string;

  /**
   * Defines the accessible ARIA name of the component.
   * @default ""
   * @public
   */
  @property()
    accessibleName!: string;

  /**
   * Receives id (or many ids) of the elements that label the component.
   * @default ""
   * @public
   */
  @property({ defaultValue: "" })
    accessibleNameRef!: string;

  @property({ type: String, noAttribute: true, defaultValue: "Number" })
    type!: string;

  @property({ type: Boolean, noAttribute: true })
    _decIconDisabled!: boolean;

  @property({ type: Boolean, noAttribute: true })
    _incIconDisabled!: boolean;

  @property({ type: Boolean, noAttribute: true })
    incIconActive!: boolean;

  @property({ type: Boolean, noAttribute: true })
    decIconActive!: boolean;

  @property({ validator: Float, noAttribute: true })
    _initialValue!: number;

  @property({ validator: Float, noAttribute: true })
    _previousValue!: number;

  @property({ validator: Float, noAttribute: true })
    _waitTimeout!: number;

  @property({ validator: Float, noAttribute: true })
    _speed!: number;

  @property({ type: Boolean, noAttribute: true })
    _btnDown!: boolean;

  @property({ validator: Integer, noAttribute: true })
    _spinTimeoutId!: Timeout;

  @property({ type: Boolean, noAttribute: true })
    _spinStarted!: boolean;

  @property({ type: ValueState, noAttribute: true })
    _initialValueState?: `${ValueState}`;

  static i18nBundle: I18nBundle;

  _keyDownHandlerBound: (e: KeyboardEvent) => void;

  static async onDefine() {
    UDExStepInput.i18nBundle = await getI18nBundle("@ui5/webcomponents");
  }

  constructor() {
    super();
    this._keyDownHandlerBound = this._onkeydown.bind(this);
  }

  get decIconTitle() {
    return [
      UDExStepInput.i18nBundle.getText(STEPINPUT_DEC_ICON_TITLE),
      this.step.toFixed(this._getNumberPrecision(this.step)),
    ].join(" ");
  }

  get incIconTitle() {
    return [
      UDExStepInput.i18nBundle.getText(STEPINPUT_INC_ICON_TITLE),
      this.step.toFixed(this._getNumberPrecision(this.step)),
    ].join(" ");
  }

  get _decIconInactive() {
    return this._decIconDisabled || this.readonly || this.disabled;
  }

  get _incIconInactive() {
    return this._incIconDisabled || this.readonly || this.disabled;
  }

  get _valuePrecisioned() {
    return this.value.toFixed(this.valuePrecision);
  }

  onBeforeRendering() {
    this._setButtonState();
  }

  get input() {
    return this.inputOuter.shadowRoot!.querySelector("input")!;
  }

  get inputOuter() {
    return this.shadowRoot!.querySelector("udex-text-field")!;
  }

  _onInputFocusIn() {
    if (this.value !== this._previousValue) {
      this._previousValue = this.value;
    }
  }

  _onInputFocusOut() {
    this._onInputChange();
  }

  _setButtonState() {
    this._decIconDisabled = this.min !== undefined && this.value <= this.min;
    this._incIconDisabled = this.max !== undefined && this.value >= this.max;
  }

  setIncButtonActiveState() {
    this.incIconActive = true;
  }

  setDecButtonActiveState() {
    this.decIconActive = true;
  }

  unsetButtonsActiveState() {
    this.decIconActive = false;
    this.incIconActive = false;
  }

  _validate() {
    if (this._initialValueState === undefined) {
      this._initialValueState = this.valueState;
    }

    this._updateValueState();
  }

  _updateValueState() {
    const valid = !((this.min !== undefined && this.value < this.min) || (this.max !== undefined && this.value > this.max));
    const previousValueState = this.valueState;

    this.valueState = valid ? ValueState.Standard : ValueState.Error;

    const eventPrevented = !this.fireEvent<StepInputValueStateChangeEventDetail>("value-state-change", { valueState: this.valueState, valid }, true);

    if (eventPrevented) {
      this.valueState = previousValueState;
    }
  }

  _getNumberPrecision(value: number) {
    if (!Number.isFinite(value)) {
      return 0;
    }
    let exponent = 1;
    let precision = 0;
    while (Math.round(value * exponent) / exponent !== value) { exponent *= 10; precision++; }
    return precision;
  }

  _preciseValue(value: number) {
    const pow = 10 ** this.valuePrecision;
    return Math.round(value * pow) / pow;
  }

  _fireChangeEvent() {
    if (this._previousValue !== this.value) {
      this._previousValue = this.value;
      this.fireEvent("change", { value: this.value });
    }
  }

  /**
   * Value modifier - modifies the value of the component, validates the new value and enables/disables increment and
   * decrement buttons according to the value and min/max values (if set). Fires `change` event when requested
   * @private
   * @param modifier modifies the value of the component with the given modifier (positive or negative)
   * @param fireChangeEvent if `true`, fires `change` event when the value is changed
   */
  _modifyValue(modifier: number, fireChangeEvent = false) {
    let value;
    this.value = this._preciseValue(parseFloat(this.input.value));
    value = this.value + modifier;
    if (this.min !== undefined && value < this.min) {
      value = this.min;
    }
    if (this.max !== undefined && value > this.max) {
      value = this.max;
    }
    value = this._preciseValue(value);
    if (value !== this.value) {
      this.value = value;
      this._validate();
      this._setButtonState();
      if (fireChangeEvent) {
        this._fireChangeEvent();
      }
    }
  }

  _incValue(e: CustomEvent) {
    if (!this._incIconInactive && e.isTrusted) {
      this._modifyValue(this.step, true);
      this._previousValue = this.value;
    }
  }

  _decValue(e: CustomEvent) {
    if (!this._decIconInactive && e.isTrusted) {
      this._modifyValue(-this.step, true);
      this._previousValue = this.value;
    }
  }

  _onInputChange() {
    if (Number.isNaN(Number(this.input.value)) || this.input.value.trim() === "") {
      const fallbackValuePrecisioned = this._initialValue.toFixed(this.valuePrecision);
      this.inputOuter.setAttribute("value", fallbackValuePrecisioned); // necessary for correct label rendering
      this.input.value = fallbackValuePrecisioned; // necessary for correct value rendering
    }
    const inputValue = this._preciseValue(parseFloat(this.input.value));
    if (this.value !== this._previousValue || this.value !== inputValue) {
      this.value = inputValue;
      this._validate();
      this._setButtonState();
      this._fireChangeEvent();
    }
  }

  _onkeydown(e: KeyboardEvent) {
    let preventDefault = true;
    if (this.disabled || this.readonly) {
      return;
    }

    if (isEnter(e)) {
      this._onInputChange();
      return;
    }

    if (isUp(e)) {
      // step up
      this._modifyValue(this.step);
    } else if (isDown(e)) {
      // step down
      this._modifyValue(-this.step);
    } else if (isEscape(e)) {
      // return previous value
      this.value = this._previousValue;
      this.input.value = this.value.toFixed(this.valuePrecision);
    } else if (this.max !== undefined && (isPageUpShift(e) || isUpShiftCtrl(e))) {
      // step to max
      this._modifyValue(this.max - this.value, true);
    } else if (this.min !== undefined && (isPageDownShift(e) || isDownShiftCtrl(e))) {
      // step to min
      this._modifyValue(this.min - this.value, true);
    } else if (!isUpCtrl(e) && !isDownCtrl(e) && !isUpShift(e) && !isDownShift(e)) {
      preventDefault = false;
    }
    if (preventDefault) {
      e.preventDefault();
    }
  }

  _decSpin() {
    if (!this._decIconDisabled) {
      this._spinValue(false, true);
      this.setDecButtonActiveState();
    }
  }

  _incSpin() {
    if (!this._incIconDisabled) {
      this._spinValue(true, true);
      this.setIncButtonActiveState();
    }
  }

  /**
   * Calculates the time which should be waited until _spinValue function is called.
   */
  _calcWaitTimeout() {
    this._speed *= ACCELERATION;
    this._waitTimeout = ((this._waitTimeout - this._speed) < MIN_WAIT_TIMEOUT ? MIN_WAIT_TIMEOUT : (this._waitTimeout - this._speed));
    return this._waitTimeout;
  }

  /**
   * Called when the increment or decrement button is pressed and held to set new value.
   * @private
   * @param increment - is this the increment button or not so the values should be spin accordingly up or down
   * @param resetVariables - whether to reset the spin-related variables or not
   */
  _spinValue(increment: boolean, resetVariables = false) {
    if (resetVariables) {
      this._waitTimeout = INITIAL_WAIT_TIMEOUT;
      this._speed = INITIAL_SPEED;
      this._btnDown = true;
    }
    this._spinTimeoutId = setTimeout(() => {
      if (this._btnDown) {
        this._spinStarted = true;
        this._modifyValue(increment ? this.step : -this.step);
        this._setButtonState();
        if ((!this._incIconDisabled && increment) || (!this._decIconDisabled && !increment)) {
          this._spinValue(increment);
        } else {
          this._resetSpin();
          this._fireChangeEvent();
        }
      }
    }, this._calcWaitTimeout());
  }

  /**
   * Resets spin process
   */
  _resetSpin() {
    clearTimeout(this._spinTimeoutId);
    this._btnDown = false;
    this._spinStarted = false;
    this.unsetButtonsActiveState();
  }

  /**
   * Resets spin process when mouse outs + or - buttons
   */
  _resetSpinOut() {
    if (this._btnDown) {
      this._resetSpin();
      this._fireChangeEvent();
      this.unsetButtonsActiveState();
    }
  }

  onEnterDOM(): void {
    // swap values if the min value is larger than the max value
    if (this.min !== undefined && this.max !== undefined && this.min > this.max) {
      const temp = this.min;
      this.min = this.max;
      this.max = temp;
    }

    // detect necessarry value precision as the highest presicion of the step, min, max and initial value
    // the valuePrecision attribute can be used to increase precision, but not to lower it
    this.valuePrecision = Math.max(
      this.valuePrecision ?? 0,
      this._getNumberPrecision(this.step),
      this._getNumberPrecision(this.min ?? 0),
      this._getNumberPrecision(this.max ?? 0),
      this._getNumberPrecision(this.value),
    );

    this._initialValue = Math.min(Math.max(this.value, this.min ?? 0), this.max ?? Infinity);

    this.value = this._initialValue;

    this._previousValue = this.value;

    this.input.addEventListener("keydown", this._keyDownHandlerBound);
  }

  onExitDOM(): void {
    this.input.removeEventListener("keydown", this._keyDownHandlerBound);
  }
}

UDExStepInput.define();

export default UDExStepInput;
