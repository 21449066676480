import UI5Element from "@ui5/webcomponents-base/dist/UI5Element.js";
import customElement from "@ui5/webcomponents-base/dist/decorators/customElement.js";
import slot from "@ui5/webcomponents-base/dist/decorators/slot.js";
import property from "@ui5/webcomponents-base/dist/decorators/property.js";
import litRender from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import ResponsivePopover from "@ui5/webcomponents/dist/ResponsivePopover.js";
import PopoverVerticalAlign from "@ui5/webcomponents/dist/types/PopoverVerticalAlign.js";
import PopoverHorizontalAlign from "@ui5/webcomponents/dist/types/PopoverHorizontalAlign.js";
import PopupAccessibleRole from "@ui5/webcomponents/dist/types/PopupAccessibleRole.js";

// Template
import TooltipTemplate from "./generated/templates/TooltipTemplate.lit.js";

// Styles
import TooltipCss from "./generated/themes/Tooltip.css";

export enum PlacementType {
  Left = "Left",
  Right = "Right",
  Top = "Top",
  Bottom = "Bottom",
}

/**
 * @class
 *
 * <h3 class="comment-api-title">Overview</h3>
 *
 * <h3>Usage</h3>
 *
 * For the <code>udex-tooltip</code>
 * <h3>ES6 Module Import</h3>
 *
 * <code>import @udex/web-components/dist/Tooltip.js";</code>
 *
 * @constructor
 * @author SAP SE
 * @extends UI5Element
 * @tagname udex-tooltip
 * @public
 */
@customElement({
  tag: "udex-tooltip",
  renderer: litRender,
  styles: TooltipCss,
  template: TooltipTemplate,
  dependencies: [ResponsivePopover],
})
class UDExTooltip extends UI5Element {
  /**
   * Defines the content of the component.
   *
   * @slot
   * @public
   */
  @slot({ type: Node }) default!: Node;

  /**
   * Defines the Tooltip title.
   *
   * @default ""
   * @public
   */
  @property({ type: String, defaultValue: "" }) title!: string;

  /**
   * Defines if Tooltip has an arrow or not.
   *
   * @default false
   * @public
   */
  @property({ type: Boolean })
    hideArrow!: boolean;

  /**
   * Determines on which side of the component the Tooltip is displayed.
   *
   * @default "Right"
   * @public
   */
  @property({ type: PlacementType, defaultValue: PlacementType.Right })
    placement!: `${PlacementType}`;
  /**
   * Determines the horizontal alignment of the component.
   * @default "Center"
   * @public
   */
  @property({ type: PopoverHorizontalAlign, defaultValue: PopoverHorizontalAlign.Center })
    horizontalAlign!: `${PopoverHorizontalAlign}`;

  /**
   * Determines the vertical alignment of the component.
   * @default "Center"
   * @public
   */
  @property({ type: PopoverVerticalAlign, defaultValue: PopoverVerticalAlign.Center })
    verticalAlign!: `${PopoverVerticalAlign}`;

  /**
   * Defines the accessible name of the component.
   * @default undefined
   * @public
   */
  @property({ defaultValue: undefined })
    accessibleName?: string;

  /**
   * Defines the IDs of the elements that label the component.
   * @default ""
   * @public
   */
  @property({ defaultValue: "" })
    accessibleNameRef!: string;

  /**
   * Allows setting a custom role.
   * @default "Dialog"
   * @public
   */
  @property({ type: PopupAccessibleRole, defaultValue: PopupAccessibleRole.Dialog })
    accessibleRole!: `${PopupAccessibleRole}`;

  getPopover() {
    return this.shadowRoot!.querySelector<ResponsivePopover>(
      "[ui5-responsive-popover]",
    )!;
  }

  /** Returns whether the tooltip is currently open.
   * @public
   */
  isOpen(): boolean {
    const popover = this.getPopover();
    return popover.isOpen();
  }

  /**
   * Used to open tooltip
   * @param opener element at which the popover is shown
   * @public
   */
  onOpen(opener: HTMLElement): void {
    const popover = this.getPopover();
    popover.showAt(opener);
  }

  /**
   * Used to close tooltip
   * @public
   */
  onClose(): void {
    const popover = this.getPopover();
    popover.close(true);
  }
}

UDExTooltip.define();

export default UDExTooltip;
