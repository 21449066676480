import UI5Element from "@ui5/webcomponents-base/dist/UI5Element.js";
import litRender from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import customElement from "@ui5/webcomponents-base/dist/decorators/customElement.js";
import property from "@ui5/webcomponents-base/dist/decorators/property.js";
import event from "@ui5/webcomponents-base/dist/decorators/event.js";
// eslint-disable-next-line
import Calendar, { CalendarSelectedDatesChangeEventDetail } from "@ui5/webcomponents/dist/Calendar.js";
import CalendarDate from "@ui5/webcomponents/dist/CalendarDate.js";
import "@ui5/webcomponents/dist/Icon.js";
import "@ui5/webcomponents-icons/dist/appointment-2.js";
import type I18nBundle from "@ui5/webcomponents-base/dist/i18nBundle.js";
import { getI18nBundle } from "@ui5/webcomponents-base/dist/i18nBundle.js";
import { CALENDAR_ICON } from "./generated/i18n/i18n-defaults.js";

// Template
import DatePickerTemplate from "./generated/templates/DatePickerTemplate.lit.js";
import DatePickerPopover from "./generated/templates/DatePickerPopoverTemplate.lit.js";

// Styles
import DatePickerCss from "./generated/themes/DatePicker.css";
import UDExTextField from "./TextField.js";
import UDExDropdown from "./Dropdown.js";
import DatePickerPopoverCss from "./generated/themes/DatePickerPopover.css.js";
import UDExStatusMessage from "./StatusMessage.js";
import ValueState from "./types/common.js";

export enum UDExDatePickerSelectionMode {
  Single = "Single",
  Range = "Range",
}

export type DatePickerSelectionChangeEventDetail = {
  selectedValues: Array<string>;
  selectedDates: Array<number>;
  timestamp: number | undefined;
};

/**
 * @class
 *
 * <h3 class="comment-api-title">Overview</h3>
 *
 *
 * <h3>Usage</h3>
 *
 * For the <code>udex-date-picker</code>
 * <h3>ES6 Module Import</h3>
 *
 * <code>import "@udex/web-components/dist/DatePicker.js";</code>
 *
 * @constructor
 * @author SAP SE
 * @extends UI5Element
 * @tagname udex-date-picker
 * @public
 */
@customElement({
  tag: "udex-date-picker",
  renderer: litRender,
  styles: DatePickerCss,
  template: DatePickerTemplate,
  staticAreaTemplate: DatePickerPopover,
  staticAreaStyles: [DatePickerPopoverCss],
  dependencies: [CalendarDate, Calendar, UDExTextField, UDExDropdown, UDExStatusMessage],
})
/**
 * Fired when the selected dates change.
 *
 * @allowPreventDefault
 * @param {Array<string>} selectedValues The selected dates
 * @param {Array<number>} selectedDates The selected dates as UTC timestamps
 * @public
 */
@event<DatePickerSelectionChangeEventDetail>("selection-change", {
  detail: {
    /**
     * @public
     */
    selectedDates: { type: Array },
    /**
     * @public
     */
    selectedValues: { type: Array },

    timestamp: { type: Number },
  },
})
class UDExDatePicker extends UI5Element {
  /**
   * Defines selection mode of the calendar component.
   *
   * @default "Single"
   * @public
   */
  @property({ type: UDExDatePickerSelectionMode, defaultValue: "Single" })
    selectionMode?: `${UDExDatePickerSelectionMode}`;

  /**
   * Defines whether the component is read-only.
   * <br><br>
   * <b>Note:</b> A read-only component is not editable,
   * but still provides visual feedback upon user interaction.
   *
   * @default false
   * @public
   */
  @property({ type: Boolean })
    readonly!: boolean;

  /**
   * Defines whether the component is required.
   *
   * @default false
   * @public
   */
  @property({ type: Boolean })
    required!: boolean;

  /**
   * Defines whether the component is in disabled state.
   * <br><br>
   * <b>Note:</b> A disabled component is completely noninteractive.
   *
   * @default false
   * @public
   */
  @property({ type: Boolean })
    disabled!: boolean;

  /**
   * Defines supporting text under the input field.
   *
   * @default ""
   * @public
   */
  @property({ type: String })
    supportingText!: string;

  /**
   * Defines the value of the input.
   *
   * @default ""
   * @public
   */
  @property({ type: String, defaultValue: "" })
    value!: string;

  /**
   * Sets the accessible ARIA name of the component.
   *
   * @default ""
   * @public
   */
  @property({ type: String, defaultValue: "" })
    accessibleName!: string;

  /**
   * Receives id (or many ids) of the elements that label the component.
   * @default ""
   * @public
   */
  @property({ defaultValue: "" })
    accessibleNameRef!: string;

  /**
   * Defines label text for the input field.
   *
   * @default ""
   * @public
   */
  @property({ type: String, defaultValue: "" })
    label!: string;

  /**
   * Determines the minimum date available for selection.
   * <br/><br/>
   * <b>Note:</b> The minDate value must be provided in the ISO date format (YYYY-MM-dd).
   *
   * @default ""
   * @public
   */
  @property({ type: String, defaultValue: "" })
    minDate!: string;

  /**
   * Determines the maximum date available for selection.
   * <br/><br/>
   * <b>Note:</b> The maxDate value must be provided in the ISO date format (YYYY-MM-dd).
   *
   * @default ""
   * @public
   */
  @property({ type: String, defaultValue: "" })
    maxDate!: string;

  /**
   * Defines selected dates values.
   *
   * @default ""
   * @private
   */
  @property({ type: String, noAttribute: true, defaultValue: "" })
    selectedDates!: string;

  /**
   * Defines the value state of the component.
   *
   * @default "Standard"
   * @public
   */
  @property({ type: ValueState, defaultValue: "Standard" })
    valueState!: "Standard" | "Warning" | "Error" | "Success";

  /**
   * Defines the date format pattern (e.g., \"MMM dd, yyyy\" or \"MM/dd/yyyy\").
   * @default "MMM dd, yyyy"
   * @public
   */
  @property({ type: String, defaultValue: "MMM dd, yyyy" })
    formatPattern!: string;

  responsivePopover?: UDExDropdown;

  static i18nBundle: I18nBundle;

  static async onDefine() {
    UDExDatePicker.i18nBundle = await getI18nBundle("sap-ui-webcomponents-bundle");
  }

  async _getCalendar() {
    const staticAreaItem = await this.getStaticAreaItemDomRef();
    return staticAreaItem!.querySelector<Calendar>("#ui5-calendar")!;
  }

  async _getResponsePopover() {
    const staticAreaItem = await this.getStaticAreaItemDomRef();
    return staticAreaItem!.querySelector<UDExDropdown>(`#udex-${this.__id!}-calendar__popover`)!;
  }

  /**
   * Getter for the interactive element that opens the overflow
   * @private
   */
  get _dropdownTrigger() {
    return this.shadowRoot!.querySelector<UDExTextField>(".udex-date-picker__trigger")!;
  }

  _afterDropdownClose() {
    this._dropdownTrigger.active = false;
    this._dropdownTrigger.shadowRoot?.querySelector("input")?.focus();
  }

  _afterDropdownOpen() {
    this._dropdownTrigger.active = true;
  }

  _closeRespPopover() {
    this.responsivePopover && this.responsivePopover.onClose();
  }

  async _openRespPopover() {
    this._dropdownTrigger.active = true;
    this.responsivePopover = await this._getResponsePopover();
    this.responsivePopover?.onOpen(this._dropdownTrigger);
  }

  hideCalendar(): void {
    this._closeRespPopover();
  }

  showCalendar(): void {
    this._openRespPopover();
  }

  handleCalendarChange(e: CustomEvent<CalendarSelectedDatesChangeEventDetail>): void {
    const { dates, timestamp, values } = e.detail;

    const formattedValues = values;

    if (this.selectionMode === UDExDatePickerSelectionMode.Single) {
      this.value = formattedValues[0];
      this.selectedDates = formattedValues[0];
    }

    if (this.selectionMode === UDExDatePickerSelectionMode.Range) {
      if (dates.length < 2) {
        return;
      }

      this.value = `${formattedValues[0]} - ${formattedValues[1]}`;
      this.selectedDates = `${formattedValues[0]}, ${formattedValues[1]}`;
    }

    this.fireEvent(
      "selection-change",
      {
        timestamp,
        selectedDates: [...dates],
        selectedValues: formattedValues,
      },
      true,
    );

    this.hideCalendar();
  }

  handleInputClick(e: MouseEvent | KeyboardEvent): void {
    e.preventDefault();

    if (this.disabled || this.readonly) {
      return;
    }

    this.showCalendar();
  }

  handleInputMouseDown(e: MouseEvent): void {
    e.preventDefault();
  }

  handleInputKeyDown(e: KeyboardEvent): void {
    if (e.key === "Enter") {
      this.handleInputClick(e);
    }
  }

  /**
   * Used to provide selectedDates to the calendar based on the component's state
   * Override in derivatives to provide different rules for setting the calendar's selected dates
   * @protected
   */
  get _calendarSelectedDates(): Array<string> {
    if (this.value) {
      return this.value.split(" - ");
    }

    return [];
  }

  get accessibleIconName() {
    return UDExDatePicker.i18nBundle.getText(CALENDAR_ICON);
  }
}

UDExDatePicker.define();

export default UDExDatePicker;
