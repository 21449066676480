import type { StyleData } from "@ui5/webcomponents-base/dist/types.js";
import { registerThemePropertiesLoader } from "@ui5/webcomponents-base/dist/asset-registries/Themes.js";

import defaultThemeBase from "@ui5/webcomponents-theming/dist/generated/themes/sap_horizon/parameters-bundle.css.js";
import defaultTheme from "./sap_horizon/parameters-bundle.css.js";

registerThemePropertiesLoader("@ui5/webcomponents-theming", "sap_horizon", async () => defaultThemeBase);
registerThemePropertiesLoader("@sapudex/web-components", "sap_horizon", async () => defaultTheme);

const styleData: StyleData = {packageName:"@sapudex/web-components",fileName:"themes/StatusMessage.css.ts",content:`:host{--udex-status-message-supporting-text-paddings: var(--udexSpacer4, 4px) 0;--udex-status-message-supporting-text-font-family: var(--sapFontFamily, "sans-serif");--udex-status-message-supporting-text-font-size: var(--sapFontSmallSize, 12px);--udex-status-message-color-standard-default-supporting-text: var(--sapField_PlaceholderTextColor, var(--udexColorGrey7));--udex-status-message-color-error-default-supporting-text: var(--sapField_InvalidColor, #d20a0a);--udex-status-message-color-success-default-supporting-text: var(--sapField_SuccessColor, #188918);--udex-status-message-color-warning-default-supporting-text: var(--sapField_WarningColor, var(--udexCoreSemanticWarning7))}.udex-status-message__supporting-text{font-family:var(--udex-status-message-supporting-text-font-family);font-size:var(--udex-status-message-supporting-text-font-size);line-height:1.5;padding:var(--udex-status-message-supporting-text-paddings);color:var(--udex-status-message-color-standard-default-supporting-text);text-align:start;padding-inline-start:var(--udexSpacer12, 12px)}:host([supporting-text-alignment="Right"]:not([value-state="Error"]):not([value-state="Success"]):not([value-state="Warning"])) .udex-status-message__supporting-text{text-align:end}.udex-status-message__icon{color:inherit;vertical-align:top;margin-inline-end:var(--udexSpacer4, 4px)}:host([value-state="Error"]) .udex-status-message__supporting-text{color:var(--udex-status-message-color-error-default-supporting-text)}:host([value-state="Success"]) .udex-status-message__supporting-text{color:var(--udex-status-message-color-success-default-supporting-text)}:host([value-state="Warning"]) .udex-status-message__supporting-text{color:var(--udex-status-message-color-warning-default-supporting-text)}:host([disabled]) .udex-status-message__supporting-text{opacity:.4}
`};
export default styleData;
	