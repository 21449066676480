/* eslint no-unused-vars: 0 */
import { html, svg, repeat, classMap, styleMap, ifDefined, unsafeHTML, scopeTag } from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import type UI5Element from "@ui5/webcomponents-base/dist/UI5Element.js";
	import type MediaPlayer from "../../MediaPlayer.js";
	import type { ClassMapValue } from "@ui5/webcomponents-base/dist/types.js";
	
function block0 (this: MediaPlayer, context: UI5Element, tags: string[], suffix: string | undefined) { return html`${ this.audioOnly ? block1.call(this, context, tags, suffix) : block2.call(this, context, tags, suffix) } `;}
function block1 (this: MediaPlayer, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<audio id="${ifDefined(this.playerElementId)}" class="udex-media-player__video  video-js ${ifDefined(this.aspectRatioClass)}" preload="auto" data-setup="${ifDefined(this.dataSetup)}"><source src="${ifDefined(this.source)}" type="${ifDefined(this.type)}" /></audio>`;}
function block2 (this: MediaPlayer, context: UI5Element, tags: string[], suffix: string | undefined) { return html`${ this._iframeLoad ? block3.call(this, context, tags, suffix) : block8.call(this, context, tags, suffix) }`;}
function block3 (this: MediaPlayer, context: UI5Element, tags: string[], suffix: string | undefined) { return html`${ this._iframePosterPreload ? block4.call(this, context, tags, suffix) : block5.call(this, context, tags, suffix) }`;}
function block4 (this: MediaPlayer, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<div class="video-js udex-media-player__iframe-poster ${ifDefined(this.aspectRatioClass)}"><div class="udex-media-player__vjs-poster" aria-disabled="false"><picture tabindex="-1"><img loading="lazy" alt="" src="${ifDefined(this.poster)}" width="${ifDefined(this.width)}" height="${ifDefined(this.height)}"></picture><button class="vjs-big-play-button" type="button" title="Play Video" aria-disabled="false" @click=${this.handleEmbedPlay}><${scopeTag("ui5-icon", tags, suffix)} aria-label="Play Video" name="play" ui5-icon=""></${scopeTag("ui5-icon", tags, suffix)}></button></div></div>` : html`<div class="video-js udex-media-player__iframe-poster ${ifDefined(this.aspectRatioClass)}"><div class="udex-media-player__vjs-poster" aria-disabled="false"><picture tabindex="-1"><img loading="lazy" alt="" src="${ifDefined(this.poster)}" width="${ifDefined(this.width)}" height="${ifDefined(this.height)}"></picture><button class="vjs-big-play-button" type="button" title="Play Video" aria-disabled="false" @click=${this.handleEmbedPlay}><ui5-icon aria-label="Play Video" name="play" ui5-icon=""></ui5-icon></button></div></div>`;}
function block5 (this: MediaPlayer, context: UI5Element, tags: string[], suffix: string | undefined) { return html`${ this._youtubeSrc ? block6.call(this, context, tags, suffix) : undefined }${ this._vimeoSrc ? block7.call(this, context, tags, suffix) : undefined }`;}
function block6 (this: MediaPlayer, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<${scopeTag("udex-media-player-youtube", tags, suffix)} source="${ifDefined(this.source)}" width="${ifDefined(this.width)}" height="${ifDefined(this.height)}" class="video-js ${ifDefined(this.aspectRatioClass)}" ?fluid-mode="${this.embedVideoFluidClass}" @playing="${this.handleEmbedVideoPlaying}" @ended="${this.handleEmbedVideoEnded}" @ready="${this.handleEmbedVideoReady}" @pause="${this.handleEmbedVideoPause}"></${scopeTag("udex-media-player-youtube", tags, suffix)}>` : html`<udex-media-player-youtube source="${ifDefined(this.source)}" width="${ifDefined(this.width)}" height="${ifDefined(this.height)}" class="video-js ${ifDefined(this.aspectRatioClass)}" ?fluid-mode="${this.embedVideoFluidClass}" @playing="${this.handleEmbedVideoPlaying}" @ended="${this.handleEmbedVideoEnded}" @ready="${this.handleEmbedVideoReady}" @pause="${this.handleEmbedVideoPause}"></udex-media-player-youtube>`;}
function block7 (this: MediaPlayer, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<${scopeTag("udex-media-player-vimeo", tags, suffix)} source="${ifDefined(this.source)}" width="${ifDefined(this.width)}" height="${ifDefined(this.height)}" title="${ifDefined(this.title)}" aspect-ratio-class="${ifDefined(this.aspectRatioClass)}" ?fluid-mode="${this.embedVideoFluidClass}" @playing="${this.handleEmbedVideoPlaying}" @ended="${this.handleEmbedVideoEnded}" @ready="${this.handleEmbedVideoReady}" @pause="${this.handleEmbedVideoPause}"></${scopeTag("udex-media-player-vimeo", tags, suffix)}>` : html`<udex-media-player-vimeo source="${ifDefined(this.source)}" width="${ifDefined(this.width)}" height="${ifDefined(this.height)}" title="${ifDefined(this.title)}" aspect-ratio-class="${ifDefined(this.aspectRatioClass)}" ?fluid-mode="${this.embedVideoFluidClass}" @playing="${this.handleEmbedVideoPlaying}" @ended="${this.handleEmbedVideoEnded}" @ready="${this.handleEmbedVideoReady}" @pause="${this.handleEmbedVideoPause}"></udex-media-player-vimeo>`;}
function block8 (this: MediaPlayer, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<video id="${ifDefined(this.playerElementId)}" class="udex-media-player__video video-js ${ifDefined(this.aspectRatioClass)}" data-setup="${ifDefined(this.dataSetup)}"><source src="${ifDefined(this.source)}" type="${ifDefined(this.type)}">${ repeat(this._tracks, (item, index) => (item as typeof item & {_id?: any})._id || index, (item, index: number) => block9.call(this, context, tags, suffix, item, index)) }</video>`;}
function block9 (this: MediaPlayer, context: UI5Element, tags: string[], suffix: string | undefined, item: any, index: number) { return html`<track kind=${ifDefined(item.kind)} src='${ifDefined(item.src)}' srclang='${ifDefined(item.srclang)}' label='${ifDefined(item.label)}' ?default="${item.default}"/>`;}


export default block0;