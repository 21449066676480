import UI5Element from "@ui5/webcomponents-base/dist/UI5Element.js";
import customElement from "@ui5/webcomponents-base/dist/decorators/customElement.js";
import property from "@ui5/webcomponents-base/dist/decorators/property.js";
import litRender from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import Integer from "@ui5/webcomponents-base/dist/types/Integer.js";
import { getI18nBundle } from "@ui5/webcomponents-base/dist/i18nBundle.js";
import type I18nBundle from "@ui5/webcomponents-base/dist/i18nBundle.js";
import UDExTextField from "./TextField.js";
import UDExControlButton from "./ControlButton.js";
import ValueState from "./types/common.js";

import PasswordFieldTemplate from "./generated/templates/PasswordFieldTemplate.lit.js";

// Styles

import PasswordFieldCss from "./generated/themes/PasswordField.css.js";

/**
 * @class
 *
 * <h3 class="comment-api-title">Overview</h3>
 *
 *
 * <h3>Usage</h3>
 *
 * For the <code>udex-password-field</code>
 * <h3>ES6 Module Import</h3>
 *
 * <code>import "@udex/web-components/dist/UDExPasswordField.js";</code>
 *
 * @constructor
 * @extends UI5Element
 * @tagname udex-password-field
 * @public
 */
@customElement({
  tag: "udex-password-field",
  renderer: litRender,
  styles: PasswordFieldCss,
  template: PasswordFieldTemplate,
  dependencies: [UDExTextField, UDExControlButton],
})
class UDExPasswordField extends UI5Element {
  /**
   * Defines supporting text under the input field.
   *
   * @default ""
   * @public
   */
  @property({ type: String, noAttribute: true, defaultValue: "password" })
    type!: string;

  /**
   * Defines whether the component is in disabled state.
   * <br><br>
   * <b>Note:</b> A disabled component is completely noninteractive.
   *
   * @default false
   * @public
   */
  @property({ type: Boolean })
    disabled!: boolean;

  /**
   * Defines the value of the component.
   * <br><br>
   * <b>Note:</b> The property is updated upon typing.
   *
   * @default ""
   * @formEvents input
   * @formProperty
   * @public
   */
  @property({ type: String })
    value!: string;

  /**
   * Sets the maximum number of characters available in the input field.
   * <br><br>
   * <b>Note:</b> This property is not compatible with the ui5-input type InputType.Number. If the ui5-input type is set to Number, the maxlength value is ignored.
   *
   * @public
   */
  @property({ validator: Integer })
    maxlength?: number;

  /**
   * Defines label text for the input field.
   *
   * @default ""
   * @public
   */
  @property({ type: String })
    label!: string;

  /**
   * Defines asterisk for the input field's label text.
   *
   * @default false
   * @public
   */
  @property({ type: Boolean })
    showAsterisk!: boolean;

  /**
   * Defines the value state of the component.
   *
   * @default "Standard"
   * @public
   */

  @property({ type: ValueState, defaultValue: "Standard" })
    valueState!: "Standard" | "Warning" | "Error" | "Success";

  /**
   * Defines supporting text under the input field.
   *
   * @default ""
   * @public
   */

  @property({ type: String })
    supportingText!: string;

  /**
   * Defines the inner stored show password button visibility state.
   *
   * @default false
   * @public
   */

  @property({ type: Boolean })
    showButtonEnabled!: boolean;

  /**
   * Defines the inner stored show password button icon.
   *
   * @default "show"
   * @private
   */

  @property({ type: String, noAttribute: true, defaultValue: "show" })
    icon!: string;

  /**
   * Defines the inner stored show password button tooltip.
   *
   * @default ""
   * @public
   */

  @property({ type: String, defaultValue: "" })
    showButtonTooltip!: string;

  /**
   * Defines the inner stored readonly state.
   *
   * @default false
   * @public
   */
  @property({ type: Boolean })
    readonly!: boolean;

  /**
   * Defines the inner stored displayonly state.
   *
   * @default false
   * @public
   */
  @property({ type: Boolean })
    displayonly!: boolean;

  /**
   * Defines the accessible ARIA name of the component.
   *
   * @default ""
   * @public
   */
  @property()
    accessibleName!: string;

  /**
   * Receives id (or many ids) of the elements that label the component.
   * @default ""
   * @public
   */
  @property({ defaultValue: "" })
    accessibleNameRef!: string;

  static i18nBundle: I18nBundle;

  static async onDefine() {
    UDExPasswordField.i18nBundle = await getI18nBundle("sap-ui-webcomponents-bundle");
  }

  handleShowPassword() {
    const isPassword = this.type === "password";

    this.icon = isPassword ? "hide" : "show";
    this.type = isPassword ? "text" : "password";
  }

  get passwordLabel() {
    return `${this.label} ${this.showAsterisk && this.showAsterisk ? "*" : ""}`;
  }

  get renderShowButton() {
    return !this.disabled && !this.readonly && !this.displayonly && this.showButtonEnabled;
  }
}

UDExPasswordField.define();

export default UDExPasswordField;
