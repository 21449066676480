/* eslint no-unused-vars: 0 */
import { html, svg, repeat, classMap, styleMap, ifDefined, unsafeHTML, scopeTag } from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import type UI5Element from "@ui5/webcomponents-base/dist/UI5Element.js";
	import type Menu from "../../Menu.js";
	import type { ClassMapValue } from "@ui5/webcomponents-base/dist/types.js";
	
function block0 (this: Menu, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<${scopeTag("ui5-responsive-popover", tags, suffix)} id="${ifDefined(this._id)}-menu-rp" class="ui5-menu-rp" horizontal-align="Left" placement-type=${ifDefined(this.placementType)} vertical-align=${ifDefined(this.verticalAlign)} hide-arrow allow-target-overlap ?sub-menu=${this._isSubMenu} @ui5-before-open=${ifDefined(this._beforePopoverOpen)} @ui5-after-open=${ifDefined(this._afterPopoverOpen)} @ui5-before-close=${ifDefined(this._beforePopoverClose)} @ui5-after-close=${ifDefined(this._afterPopoverClose)}>${ this.isPhone ? block1.call(this, context, tags, suffix) : undefined }<div id="${ifDefined(this._id)}-menu-main">${ this._currentItems.length ? block3.call(this, context, tags, suffix) : block5.call(this, context, tags, suffix) }</div>${ this.menuHasSubMenus ? block7.call(this, context, tags, suffix) : undefined }</${scopeTag("ui5-responsive-popover", tags, suffix)}>` : html`<ui5-responsive-popover id="${ifDefined(this._id)}-menu-rp" class="ui5-menu-rp" horizontal-align="Left" placement-type=${ifDefined(this.placementType)} vertical-align=${ifDefined(this.verticalAlign)} hide-arrow allow-target-overlap ?sub-menu=${this._isSubMenu} @ui5-before-open=${ifDefined(this._beforePopoverOpen)} @ui5-after-open=${ifDefined(this._afterPopoverOpen)} @ui5-before-close=${ifDefined(this._beforePopoverClose)} @ui5-after-close=${ifDefined(this._afterPopoverClose)}>${ this.isPhone ? block1.call(this, context, tags, suffix) : undefined }<div id="${ifDefined(this._id)}-menu-main">${ this._currentItems.length ? block3.call(this, context, tags, suffix) : block5.call(this, context, tags, suffix) }</div>${ this.menuHasSubMenus ? block7.call(this, context, tags, suffix) : undefined }</ui5-responsive-popover>`;}
function block1 (this: Menu, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<div slot="header" class="ui5-menu-dialog-header">${ this._isSubMenu ? block2.call(this, context, tags, suffix) : undefined }<div class="ui5-menu-dialog-title"><div>${ifDefined(this.menuHeaderTextPhone)}</div></div><${scopeTag("ui5-button", tags, suffix)} icon="decline" design="Transparent" aria-label="${ifDefined(this.labelClose)}" @click=${this._closeAll}></${scopeTag("ui5-button", tags, suffix)}></div>` : html`<div slot="header" class="ui5-menu-dialog-header">${ this._isSubMenu ? block2.call(this, context, tags, suffix) : undefined }<div class="ui5-menu-dialog-title"><div>${ifDefined(this.menuHeaderTextPhone)}</div></div><ui5-button icon="decline" design="Transparent" aria-label="${ifDefined(this.labelClose)}" @click=${this._closeAll}></ui5-button></div>`;}
function block2 (this: Menu, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<${scopeTag("ui5-button", tags, suffix)} icon="nav-back" class="ui5-menu-back-button" design="Transparent" aria-label="${ifDefined(this.labelBack)}" @click=${this._navigateBack}></${scopeTag("ui5-button", tags, suffix)}>` : html`<ui5-button icon="nav-back" class="ui5-menu-back-button" design="Transparent" aria-label="${ifDefined(this.labelBack)}" @click=${this._navigateBack}></ui5-button>`;}
function block3 (this: Menu, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<${scopeTag("ui5-list", tags, suffix)} id="${ifDefined(this._id)}-menu-list" mode="None" ?busy="${this.busy}" busy-delay="${ifDefined(this.busyDelay)}" separators="None" accessible-role="menu" @ui5-item-click=${ifDefined(this._itemClick)} @mouseover="${this._busyMouseOver}">${ repeat(this._currentItems, (item, index) => (item as typeof item & {_id?: any})._id || index, (item, index: number) => block4.call(this, context, tags, suffix, item, index)) }</${scopeTag("ui5-list", tags, suffix)}>` : html`<ui5-list id="${ifDefined(this._id)}-menu-list" mode="None" ?busy="${this.busy}" busy-delay="${ifDefined(this.busyDelay)}" separators="None" accessible-role="menu" @ui5-item-click=${ifDefined(this._itemClick)} @mouseover="${this._busyMouseOver}">${ repeat(this._currentItems, (item, index) => (item as typeof item & {_id?: any})._id || index, (item, index: number) => block4.call(this, context, tags, suffix, item, index)) }</ui5-list>`;}
function block4 (this: Menu, context: UI5Element, tags: string[], suffix: string | undefined, item: any, index: number) { return suffix ? html`<${scopeTag("ui5-menu-li", tags, suffix)} .associatedItem="${ifDefined(item.item)}" class="ui5-menu-item" id="${ifDefined(this._id)}-menu-item-${index}" .icon="${ifDefined(item.item.icon)}" accessible-name=${ifDefined(item.item.ariaLabelledByText)} accessible-role="menuitem" .additionalText="${ifDefined(item.item._additionalText)}" tooltip="${ifDefined(item.item.tooltip)}" selected="${ifDefined(item.item.subMenuOpened)}" ?disabled=${item.item.disabled} ?starts-section=${item.item.startsSection} ?selected=${item.item.subMenuOpened} ?is-phone=${this.isPhone} @mouseover=${this._itemMouseOver} @mouseout=${this._itemMouseOut} @keydown=${this._itemKeyDown} @_focused=${ifDefined(this._onfocusin)}></${scopeTag("ui5-menu-li", tags, suffix)}>` : html`<ui5-menu-li .associatedItem="${ifDefined(item.item)}" class="ui5-menu-item" id="${ifDefined(this._id)}-menu-item-${index}" .icon="${ifDefined(item.item.icon)}" accessible-name=${ifDefined(item.item.ariaLabelledByText)} accessible-role="menuitem" .additionalText="${ifDefined(item.item._additionalText)}" tooltip="${ifDefined(item.item.tooltip)}" selected="${ifDefined(item.item.subMenuOpened)}" ?disabled=${item.item.disabled} ?starts-section=${item.item.startsSection} ?selected=${item.item.subMenuOpened} ?is-phone=${this.isPhone} @mouseover=${this._itemMouseOver} @mouseout=${this._itemMouseOut} @keydown=${this._itemKeyDown} @_focused=${ifDefined(this._onfocusin)}></ui5-menu-li>`;}
function block5 (this: Menu, context: UI5Element, tags: string[], suffix: string | undefined) { return html`${ this.busy ? block6.call(this, context, tags, suffix) : undefined }`;}
function block6 (this: Menu, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<${scopeTag("ui5-busy-indicator", tags, suffix)} id="${ifDefined(this._id)}-menu-busy-indicator" delay="${ifDefined(this.busyDelay)}" class="ui5-menu-busy-indicator" @mouseover="${this._busyMouseOver}" active></${scopeTag("ui5-busy-indicator", tags, suffix)}>` : html`<ui5-busy-indicator id="${ifDefined(this._id)}-menu-busy-indicator" delay="${ifDefined(this.busyDelay)}" class="ui5-menu-busy-indicator" @mouseover="${this._busyMouseOver}" active></ui5-busy-indicator>`;}
function block7 (this: Menu, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<div class="ui5-menu-submenus"></div>`;}


export default block0;