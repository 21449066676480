import customElement from "@ui5/webcomponents-base/dist/decorators/customElement.js";
import litRender from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import property from "@ui5/webcomponents-base/dist/decorators/property.js";
import UDExListItemBase from "./ListItemBase.js";
/**
 * @class
 *
 * <h3 class="comment-api-title">Overview</h3>
 *
 *
 * <h3>Usage</h3>
 *
 * For the <code>udex-filter-menu-item</code>
 * <h3>ES6 Module Import</h3>
 *
 * <code>import "@udex/web-components/dist/FilterMenuItem.js";</code>
 *
 * @constructor
 * @extends UDExListItemBase
 * @public
 */
@customElement({
  tag: "udex-filter-menu-item",
  renderer: litRender,
})

class UDExFilterMenuItem extends UDExListItemBase {
  /**
   *
   * @default false
   * @public
   */
  @property({ type: Boolean })
    _isFilterSelectAllItem?: boolean;
}

UDExFilterMenuItem.define();

export default UDExFilterMenuItem;
