import type { StyleData } from "@ui5/webcomponents-base/dist/types.js";
import { registerThemePropertiesLoader } from "@ui5/webcomponents-base/dist/asset-registries/Themes.js";

import defaultThemeBase from "@ui5/webcomponents-theming/dist/generated/themes/sap_horizon/parameters-bundle.css.js";
import defaultTheme from "./sap_horizon/parameters-bundle.css.js";

registerThemePropertiesLoader("@ui5/webcomponents-theming", "sap_horizon", async () => defaultThemeBase);
registerThemePropertiesLoader("@sapudex/web-components", "sap_horizon", async () => defaultTheme);

const styleData: StyleData = {packageName:"@sapudex/web-components",fileName:"themes/Wizard.css.ts",content:`:host{--udex-wizard-background-color: var(--sapBackgroundColor, #FFFFFF);--udex-wizard-nav-shadow: var(--sapContent_HeaderShadow, none)}:host .ui5-wiz-nav{box-shadow:var(--udex-wizard-nav-shadow);position:relative;z-index:1}:host([_breakpoint="S"])::part(navigator),:host([_breakpoint="M"])::part(navigator),:host([_breakpoint="L"])::part(navigator),:host([_breakpoint="XL"])::part(navigator),:host([_breakpoint="S"])::part(step-content),:host([_breakpoint="M"])::part(step-content),:host([_breakpoint="L"])::part(step-content),:host([_breakpoint="XL"])::part(step-content){padding-left:.375rem;padding-right:.375rem}:host .ui5-wiz-nav-list{display:flex}:host .ui5-wiz-content{background:var(--udex-wizard-background-color)}[udex-wizard-tab]{display:block;flex-grow:1;position:relative}:host(:not([indeterminate])) [udex-wizard-tab]:last-child{flex-grow:0}[udex-wizard-tab][data-ui5-wizard-expanded-tab-prev=true]{width:2rem;padding-right:.75rem}
`};
export default styleData;
	