/* eslint no-unused-vars: 0 */
import { html, svg, repeat, classMap, styleMap, ifDefined, unsafeHTML, scopeTag } from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import type UI5Element from "@ui5/webcomponents-base/dist/UI5Element.js";
	import type Accordion from "../../Accordion.js";
	import type { ClassMapValue } from "@ui5/webcomponents-base/dist/types.js";
	
function block0 (this: Accordion, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<div class="${ifDefined(this.accordionClass)}" @keydown=${this.keydownHandler} @ui5-accordion-item-focus=${ifDefined(this.itemFocusHandler)}><div class="udex-accordion__head" part="head"><h3 class="udex-accordion__headline">${ifDefined(this.headlineText)}</h3><${scopeTag("udex-button", tags, suffix)} class="udex-accordion__btn" size="Large" design="Tertiary" @click=${this.toggleAllHandler} @keydown=${this.toggleKeyAllHandler} accessible-name=${ifDefined(this.toggleAllText)}>${ifDefined(this.toggleAllText)}</${scopeTag("udex-button", tags, suffix)}></div><div class="udex-accordion__content"><ul class="udex-accordion__list" role="list">${ repeat(this.items, (item, index) => (item as typeof item & {_id?: any})._id || index, (item, index: number) => block1.call(this, context, tags, suffix, item, index)) }</ul></div></div>` : html`<div class="${ifDefined(this.accordionClass)}" @keydown=${this.keydownHandler} @ui5-accordion-item-focus=${ifDefined(this.itemFocusHandler)}><div class="udex-accordion__head" part="head"><h3 class="udex-accordion__headline">${ifDefined(this.headlineText)}</h3><udex-button class="udex-accordion__btn" size="Large" design="Tertiary" @click=${this.toggleAllHandler} @keydown=${this.toggleKeyAllHandler} accessible-name=${ifDefined(this.toggleAllText)}>${ifDefined(this.toggleAllText)}</udex-button></div><div class="udex-accordion__content"><ul class="udex-accordion__list" role="list">${ repeat(this.items, (item, index) => (item as typeof item & {_id?: any})._id || index, (item, index: number) => block1.call(this, context, tags, suffix, item, index)) }</ul></div></div>`;}
function block1 (this: Accordion, context: UI5Element, tags: string[], suffix: string | undefined, item: any, index: number) { return html`<slot name="${ifDefined(item.item._individualSlot)}"></slot>`;}


export default block0;