/* eslint no-unused-vars: 0 */
import { html, svg, repeat, classMap, styleMap, ifDefined, unsafeHTML, scopeTag } from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import type UI5Element from "@ui5/webcomponents-base/dist/UI5Element.js";
	import type MastheadMenu from "../../MastheadMenu.js";
	import type { ClassMapValue } from "@ui5/webcomponents-base/dist/types.js";
	
function block0 (this: MastheadMenu, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<div class="udex-masthead-menu ${ifDefined(this.oneRowClass)}" part="menu" @keydown="${this.handleKeyDown}">${ this.menuTitle ? block1.call(this, context, tags, suffix) : undefined }${ repeat(this.itemsList, (item, index) => (item as typeof item & {_id?: any})._id || index, (item, index: number) => block2.call(this, context, tags, suffix, item, index)) }</div> `;}
function block1 (this: MastheadMenu, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<div class="udex-masthead-menu__title">${ifDefined(this.menuTitle)}</div>`;}
function block2 (this: MastheadMenu, context: UI5Element, tags: string[], suffix: string | undefined, item: any, index: number) { return html`<div class="udex-masthead-menu__row">${ repeat(item.subMenuLinkItems, (item, index) => (item as typeof item & {_id?: any})._id || index, (item, index: number) => block3.call(this, context, tags, suffix, item, index)) }</div>`;}
function block3 (this: MastheadMenu, context: UI5Element, tags: string[], suffix: string | undefined, item: any, index: number) { return html`<div class="udex-masthead-menu__column" part="column">${ item.category ? block4.call(this, context, tags, suffix, item, index) : undefined }<ul class="udex-masthead-menu__list" role="menu">${ repeat(item.items, (item, index) => (item as typeof item & {_id?: any})._id || index, (item, index: number) => block5.call(this, context, tags, suffix, item, index)) }</ul></div>`;}
function block4 (this: MastheadMenu, context: UI5Element, tags: string[], suffix: string | undefined, item: any, index: number) { return suffix ? html`<div class="udex-masthead-menu__header"><${scopeTag("udex-masthead-menu-item", tags, suffix)} url="${ifDefined(item.url)}" label="${ifDefined(item.category)}" ?icon-end="${item.url}" type="Heading" has-border></${scopeTag("udex-masthead-menu-item", tags, suffix)}></div>` : html`<div class="udex-masthead-menu__header"><udex-masthead-menu-item url="${ifDefined(item.url)}" label="${ifDefined(item.category)}" ?icon-end="${item.url}" type="Heading" has-border></udex-masthead-menu-item></div>`;}
function block5 (this: MastheadMenu, context: UI5Element, tags: string[], suffix: string | undefined, item: any, index: number) { return suffix ? html`<li role="none"><${scopeTag("udex-masthead-menu-item", tags, suffix)} role="menuitem" badge="${ifDefined(item.badge)}" ?external-link="${item.externalLink}" url="${ifDefined(item.url)}" label="${ifDefined(item.title)}"></${scopeTag("udex-masthead-menu-item", tags, suffix)}></li>` : html`<li role="none"><udex-masthead-menu-item role="menuitem" badge="${ifDefined(item.badge)}" ?external-link="${item.externalLink}" url="${ifDefined(item.url)}" label="${ifDefined(item.title)}"></udex-masthead-menu-item></li>`;}


export default block0;