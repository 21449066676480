import customElement from "@ui5/webcomponents-base/dist/decorators/customElement.js";
import litRender from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import property from "@ui5/webcomponents-base/dist/decorators/property.js";
import SideNavigation from "@ui5/webcomponents-fiori/dist/SideNavigation.js";
import UDExSideNavigationItem from "./SideNavigationItem.js";
import UDExSideNavigationGroup from "./SideNavigationGroup.js";
import UDExSideNavigationSubItem from "./SideNavigationSubItem.js";

// Styles
import SideNavigationCss from "./generated/themes/SideNavigation.css.js";

/**
 * @class
 *
 * <h3 class="comment-api-title">Overview</h3>
 *
 *
 * <h3>Usage</h3>
 *
 * For the <code>udex-side-navigation</code>
 * <h3>ES6 Module Import</h3>
 *
 * <code>import "@udex/web-components/dist/SideNavigation.js";</code>
 *
 * @constructor
 * @extends SideNavigation
 * @public
 */
@customElement({
  tag: "udex-side-navigation",
  renderer: litRender,
  styles: [SideNavigation.styles, SideNavigationCss],
  dependencies: [UDExSideNavigationItem, UDExSideNavigationGroup, UDExSideNavigationSubItem],
})

class UDExSideNavigation extends SideNavigation {
  /**
   * Defines if Side Navigation is fixed in place or scrollable with the content.
   *
   * @default false
   * @public
   */
  @property({ type: Boolean })
    isFixedInPlace!: boolean;
}

UDExSideNavigation.define();

export default UDExSideNavigation;
