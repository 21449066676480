import UI5Element from "@ui5/webcomponents-base/dist/UI5Element.js";
import customElement from "@ui5/webcomponents-base/dist/decorators/customElement.js";
import property from "@ui5/webcomponents-base/dist/decorators/property.js";
import slot from "@ui5/webcomponents-base/dist/decorators/slot.js";
import litRender from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import Integer from "@ui5/webcomponents-base/dist/types/Integer.js";
import UDExTableCell from "./TableCell.js";
import UDExTableHeaderCell from "./TableHeaderCell.js";

// Styles
import TableRowBase from "./generated/themes/TableRowBase.css.js";

/**
 * @class
 * A class to serve as a foundation for the `UDExTableRow` and `UDExTableHeaderRow` classes.
 * @constructor
 * @abstract
 * @extends UI5Element
 * @public
 */

@customElement({
  renderer: litRender,
  styles: TableRowBase,
  dependencies: [UDExTableCell],
})

abstract class UDExTableRowBase extends UI5Element {
  /**
   * Defines the cells of the component.
   *
   * @public
   */
  @slot({ type: HTMLElement, "default": true, individualSlots: true })
    cells!: Array<UDExTableCell | UDExTableHeaderCell>;

  /**
   * Defines the index of the component.
   *
   * @private
   */
  @property({ validator: Integer })
    _index!: number;

  onEnterDOM() {
    this.setAttribute("role", "row");
  }
}

export default UDExTableRowBase;
