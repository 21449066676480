/* eslint no-unused-vars: 0 */
import { html, svg, repeat, classMap, styleMap, ifDefined, unsafeHTML, scopeTag } from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import type UI5Element from "@ui5/webcomponents-base/dist/UI5Element.js";
	type TabNavigationPopover = any;
	import type { ClassMapValue } from "@ui5/webcomponents-base/dist/types.js";
	
function block0 (this: TabNavigationPopover, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<${scopeTag("ui5-popover", tags, suffix)} id="${ifDefined(this._id)}-overflow-popover" horizontal-align="Right" placement-type="Bottom" aria-orientation="vertical" hide-arrow _hideHeader><div class="tab-navigation-popover" @click="${this.handleOverflowItemClick}" @keydown="${this.handleFocusPopover}" role="tablist">${ repeat(this._overflowItems, (item, index) => (item as typeof item & {_id?: any})._id || index, (item, index: number) => block1.call(this, context, tags, suffix, item, index)) }</div></${scopeTag("ui5-popover", tags, suffix)}>` : html`<ui5-popover id="${ifDefined(this._id)}-overflow-popover" horizontal-align="Right" placement-type="Bottom" aria-orientation="vertical" hide-arrow _hideHeader><div class="tab-navigation-popover" @click="${this.handleOverflowItemClick}" @keydown="${this.handleFocusPopover}" role="tablist">${ repeat(this._overflowItems, (item, index) => (item as typeof item & {_id?: any})._id || index, (item, index: number) => block1.call(this, context, tags, suffix, item, index)) }</div></ui5-popover>`;}
function block1 (this: TabNavigationPopover, context: UI5Element, tags: string[], suffix: string | undefined, item: any, index: number) { return html`${ifDefined(item.tabInOverflow)}`;}


export default block0;