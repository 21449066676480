import UI5Element from "@ui5/webcomponents-base/dist/UI5Element.js";
import customElement from "@ui5/webcomponents-base/dist/decorators/customElement.js";
import litRender from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import property from "@ui5/webcomponents-base/dist/decorators/property.js";

// Template
import DividerTemplate from "./generated/templates/DividerTemplate.lit.js";

// Styles
import DividerCss from "./generated/themes/Divider.css";

/**
 * @class
 *
 * <h3 class="comment-api-title">Overview</h3>
 *
 * The <code>udex-divider</code> component is a divider component used to separate content in a page.
 *
 * @constructor
 * @extends UI5Element
 * @tagname udex-divider
 * @public
 */
@customElement({
  tag: "udex-divider",
  renderer: litRender,
  styles: DividerCss,
  template: DividerTemplate,
})
class UDExDivider extends UI5Element {
  /**
   * Defines the ARIA role of the component.
   *
   * @default "separator"
   * @public
   */
  @property({ type: String, defaultValue: "separator" })
    accessibleRole!: string;

  get effectiveAccRole() {
    return this.accessibleRole.toLowerCase();
  }
}

UDExDivider.define();

export default UDExDivider;
