import customElement from "@ui5/webcomponents-base/dist/decorators/customElement.js";
import slot from "@ui5/webcomponents-base/dist/decorators/slot.js";
import property from "@ui5/webcomponents-base/dist/decorators/property.js";
import litRender from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import UDExListItemBase from "./ListItemBase.js";

/**
 * @class
 *
 * A class to serve as a foundation
 * for the <code>UDExListGroupHeader</code> and <code>UDExFilterMenuGroupHeader</code> classes.
 *
 * @constructor
 * @author SAP SE
 * @extends UDExListItemBase
 * @public
 */

@customElement({
  languageAware: true,
  renderer: litRender,
})

class UDExListGroupHeaderBase extends UDExListItemBase {
  /**
   * Defines non-interactive the group header.
   *
   * @default false
   * @public
   */
  @property({ type: Boolean })
    nonInteractive!: boolean;

  @property({ type: String })
    _additionalClassName!: string;

  /**
   * Slot for group header list items.
   *
   * @slot items
   * @public
   */
  @slot({ type: UDExListItemBase, "default": true })
    items!: Array<UDExListItemBase>;

  handleSelectionGroupState() {
    const isAnyEnabledOption = this.isAnyEnabledOption();

    if (!isAnyEnabledOption) {
      return;
    }

    const isAllSelectedGroupState = this.isAllSelected();
    const isIndeterminateGroupState = this.indeterminate;

    if (isAllSelectedGroupState) {
      this.processGroupDeselectAll();
    } else if (isIndeterminateGroupState) {
      this.toggleIndeterminateGroupState();
    } else {
      this.processGroupSelectAll();
    }
  }

  processGroupDeselectAll() {
    const isAnySelectedOptionDisabled = this.isAnySelectedOptionDisabled();

    this.setAllItems(false);

    if (isAnySelectedOptionDisabled) {
      this.activateHeaderIndeterminate();
    } else {
      this.deactivateHeader();
    }
  }

  toggleIndeterminateGroupState() {
    const isEachEnabledOptionSelected = this.isEachEnabledOptionSelected();

    if (isEachEnabledOptionSelected) {
      this.processGroupDeselectAll();
    } else {
      this.processGroupSelectAll();
    }
  }

  processGroupSelectAll() {
    const isAnyNotSelectedOptionDisabled = this.isAnyNotSelectedOptionDisabled();

    this.setAllItems(true);

    if (isAnyNotSelectedOptionDisabled) {
      this.activateHeaderIndeterminate();
    } else {
      this.activateHeader();
    }
  }

  handleHeaderState() {
    const isAllSelected = this.isAllSelected();
    const isAllDeselected = this.isAllDeselected();

    if (isAllDeselected) {
      this.deactivateHeader();
      return;
    }

    if (isAllSelected) {
      this.activateHeader();
      return;
    }

    this.activateHeaderIndeterminate();
  }

  activateHeaderIndeterminate() {
    this.selected = true;
    this.indeterminate = true;
  }

  activateHeader() {
    this.indeterminate = false;
    this.selected = true;
  }

  deactivateHeader() {
    this.selected = false;
    this.indeterminate = false;
  }

  setAllItems(value: boolean) {
    this.getItems().forEach(item => {
      if (!item.disabled) {
        item.selected = value;
      }
    });
  }

  isAllSelected() {
    return this.getItems().every(({ selected }) => selected);
  }

  isAllDeselected() {
    return this.getItems().every(({ selected }) => !selected);
  }

  isEachEnabledOptionSelected() {
    return this.getItems().every(item => item.disabled || item.selected);
  }

  isAnyNotSelectedOptionDisabled() {
    return this.getItems().some(item => !item.selected && item.disabled);
  }

  isAnySelected() {
    return this.getItems().some(item => item.selected);
  }

  isAnySelectedOptionDisabled() {
    return this.getItems().some(({ disabled, selected }) => disabled && selected);
  }

  isAnyEnabledOption() {
    return this.getItems().some(({ disabled }) => !disabled);
  }

  getItems(): Array<UDExListItemBase> {
    return this.getSlottedNodes<UDExListItemBase>("items");
  }

  get isGroup(): boolean {
    return true;
  }

  get isSelectAllHeader(): boolean {
    return false;
  }

  get isFilterMenuSelectAllHeader(): boolean {
    return false;
  }

  get isNonActiveHeader() {
    return this.nonInteractive || this.modeRadioGroup;
  }

  get className(): string {
    const className = "udex-list__group-header";
    const controlledClassName = this.controlled ? `${className}_controlled` : "";
    return `${className} ${this._additionalClassName} ${controlledClassName}`;
  }
}

export default UDExListGroupHeaderBase;
