/* eslint no-unused-vars: 0 */
import { html, svg, repeat, classMap, styleMap, ifDefined, unsafeHTML, scopeTag } from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import type UI5Element from "@ui5/webcomponents-base/dist/UI5Element.js";
	import type Dropdown from "../../Dropdown.js";
	import type { ClassMapValue } from "@ui5/webcomponents-base/dist/types.js";
	
function block0 (this: Dropdown, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<div class="udex-dropdown">${ this.hasTriggerSlot ? block1.call(this, context, tags, suffix) : undefined }<${scopeTag("ui5-popover", tags, suffix)} id="udex-dropdown" class="udex-dropdown__pane" ?allow-target-overlap="${this.disableAllowTargetOverlap}" hide-arrow placement-type="${ifDefined(this.placementType)}" horizontal-align="${ifDefined(this.horizontalAlign)}" accessible-name="${ifDefined(this.accessibleName)}" accessible-role="${ifDefined(this.accessibleRole)}" @ui5-after-open=${ifDefined(this.afterDropdownOpen)} @ui5-before-open=${ifDefined(this.beforeDropdownOpen)} @ui5-after-close=${ifDefined(this.afterDropdownClose)} @ui5-before-close=${ifDefined(this.beforeDropdownClose)}>${ this.hasHeaderSlot ? block2.call(this, context, tags, suffix) : undefined }<slot></slot>${ this.hasFooterSlot ? block3.call(this, context, tags, suffix) : undefined }</${scopeTag("ui5-popover", tags, suffix)}></div>` : html`<div class="udex-dropdown">${ this.hasTriggerSlot ? block1.call(this, context, tags, suffix) : undefined }<ui5-popover id="udex-dropdown" class="udex-dropdown__pane" ?allow-target-overlap="${this.disableAllowTargetOverlap}" hide-arrow placement-type="${ifDefined(this.placementType)}" horizontal-align="${ifDefined(this.horizontalAlign)}" accessible-name="${ifDefined(this.accessibleName)}" accessible-role="${ifDefined(this.accessibleRole)}" @ui5-after-open=${ifDefined(this.afterDropdownOpen)} @ui5-before-open=${ifDefined(this.beforeDropdownOpen)} @ui5-after-close=${ifDefined(this.afterDropdownClose)} @ui5-before-close=${ifDefined(this.beforeDropdownClose)}>${ this.hasHeaderSlot ? block2.call(this, context, tags, suffix) : undefined }<slot></slot>${ this.hasFooterSlot ? block3.call(this, context, tags, suffix) : undefined }</ui5-popover></div>`;}
function block1 (this: Dropdown, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<div class="udex-dropdown__opener" aria-haspopup="true" aria-controls="udex-dropdown" @click="${this.onTriggerClick}"><slot slot="trigger" name="trigger"></slot></div>`;}
function block2 (this: Dropdown, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<slot slot="header" name="header"></slot>`;}
function block3 (this: Dropdown, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<slot slot="footer" name="footer"></slot>`;}


export default block0;