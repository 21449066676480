import type { StyleData } from "@ui5/webcomponents-base/dist/types.js";
import { registerThemePropertiesLoader } from "@ui5/webcomponents-base/dist/asset-registries/Themes.js";

import defaultThemeBase from "@ui5/webcomponents-theming/dist/generated/themes/sap_horizon/parameters-bundle.css.js";
import defaultTheme from "./sap_horizon/parameters-bundle.css.js";

registerThemePropertiesLoader("@ui5/webcomponents-theming", "sap_horizon", async () => defaultThemeBase);
registerThemePropertiesLoader("@sapudex/web-components", "sap_horizon", async () => defaultTheme);

const styleData: StyleData = {packageName:"@sapudex/web-components",fileName:"themes/Accordion.css.ts",content:`:host{--udex-accordion-heading-color: var(--sapTitleColor, var(--udexCoreHeadingDark));font-family:var(--sapFontFamily, sans-serif);font-size:var(--sapFontSize, 16px)}.udex-accordion__head{display:flex;justify-content:space-between;margin-bottom:var(--udexSpacer16, 16px)}.udex-accordion__headline{font-size:var(--sapFontHeader3Size,var(--udexTypographyHeadingRegularXSFontSize, 24px));font-weight:var(--udexTypographyFontWeightRegular, 400);line-height:var(--udexTypographyHeadingLineHeight, 26px);margin:var(--udexSpacer8, 8px);color:var(--udex-accordion-heading-color)}.udex-accordion__btn{height:42px}.udex-accordion__btn::part(button){padding-inline:var(--udexSpacer12,12px)}.udex-accordion__list{list-style:none;padding:0;margin-inline:0}
`};
export default styleData;
	