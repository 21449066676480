/* eslint no-unused-vars: 0 */
import { html, svg, repeat, classMap, styleMap, ifDefined, unsafeHTML, scopeTag } from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import type UI5Element from "@ui5/webcomponents-base/dist/UI5Element.js";
	import type TableGroupRow from "../../TableGroupRow.js";
	import type { ClassMapValue } from "@ui5/webcomponents-base/dist/types.js";
	
function block0 (this: TableGroupRow, context: UI5Element, tags: string[], suffix: string | undefined) { return html`${ this.collapsible ? block1.call(this, context, tags, suffix) : block2.call(this, context, tags, suffix) }<div class="udex-table-grow-rows" @keydown="${this.handleEscapeKey}"><slot></slot></div>`;}
function block1 (this: TableGroupRow, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<button id="udex-table-group-opener" class="udex-table-grow-btn" aria-expanded="${ifDefined(this.expanded)}" @click="${this.toggleExpanded}"><span class="udex-table-grow"><${scopeTag("ui5-icon", tags, suffix)} class="udex-table-grow__icon" name="slim-arrow-right"></${scopeTag("ui5-icon", tags, suffix)}>${ifDefined(this.groupTitle)}</span></button>` : html`<button id="udex-table-group-opener" class="udex-table-grow-btn" aria-expanded="${ifDefined(this.expanded)}" @click="${this.toggleExpanded}"><span class="udex-table-grow"><ui5-icon class="udex-table-grow__icon" name="slim-arrow-right"></ui5-icon>${ifDefined(this.groupTitle)}</span></button>`;}
function block2 (this: TableGroupRow, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<div class="udex-table-grow-btn"><span class="udex-table-grow">${ifDefined(this.groupTitle)}</span></div>`;}


export default block0;