/* eslint no-unused-vars: 0 */
import { html, svg, repeat, classMap, styleMap, ifDefined, unsafeHTML, scopeTag } from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import type UI5Element from "@ui5/webcomponents-base/dist/UI5Element.js";
	import type SecondaryNavigationSubItem from "../../SecondaryNavigationSubItem.js";
	import type { ClassMapValue } from "@ui5/webcomponents-base/dist/types.js";
	
function block0 (this: SecondaryNavigationSubItem, context: UI5Element, tags: string[], suffix: string | undefined) { return html`${ this.isBack ? block1.call(this, context, tags, suffix) : block2.call(this, context, tags, suffix) } `;}
function block1 (this: SecondaryNavigationSubItem, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<button @click="${this.onBackItemClick}" role="menuitem" aria-label="${ifDefined(this.backButtonLabel)}" aria-haspopup="true" is-back class="udex-secondary-navigation__dropdown-item"><${scopeTag("ui5-icon", tags, suffix)} class="udex-secondary-navigation__dropdown-icon udex-secondary-navigation__dropdown-icon_back" name="slim-arrow-left"></${scopeTag("ui5-icon", tags, suffix)}>${ifDefined(this.label)}</button>` : html`<button @click="${this.onBackItemClick}" role="menuitem" aria-label="${ifDefined(this.backButtonLabel)}" aria-haspopup="true" is-back class="udex-secondary-navigation__dropdown-item"><ui5-icon class="udex-secondary-navigation__dropdown-icon udex-secondary-navigation__dropdown-icon_back" name="slim-arrow-left"></ui5-icon>${ifDefined(this.label)}</button>`;}
function block2 (this: SecondaryNavigationSubItem, context: UI5Element, tags: string[], suffix: string | undefined) { return html`${ this.hasItems ? block3.call(this, context, tags, suffix) : block4.call(this, context, tags, suffix) }`;}
function block3 (this: SecondaryNavigationSubItem, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<button @click="${this.onItemClick}" role="menuitem" aria-haspopup="true" ?sub-active="${this.isSubItemActive}" aria-label="${ifDefined(this.label)}" class="udex-secondary-navigation__dropdown-item">${ifDefined(this.label)}<${scopeTag("ui5-icon", tags, suffix)} class="udex-secondary-navigation__dropdown-icon" name="slim-arrow-right"></${scopeTag("ui5-icon", tags, suffix)}></button>` : html`<button @click="${this.onItemClick}" role="menuitem" aria-haspopup="true" ?sub-active="${this.isSubItemActive}" aria-label="${ifDefined(this.label)}" class="udex-secondary-navigation__dropdown-item">${ifDefined(this.label)}<ui5-icon class="udex-secondary-navigation__dropdown-icon" name="slim-arrow-right"></ui5-icon></button>`;}
function block4 (this: SecondaryNavigationSubItem, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<a @click="${this.onItemClick}" role="menuitem" href="${ifDefined(this.path)}" target="${ifDefined(this.target)}" ?active="${this.isActive}" class="udex-secondary-navigation__dropdown-item">${ifDefined(this.label)}</a>`;}


export default block0;