import type { StyleData } from "@ui5/webcomponents-base/dist/types.js";
import { registerThemePropertiesLoader } from "@ui5/webcomponents-base/dist/asset-registries/Themes.js";

import defaultThemeBase from "@ui5/webcomponents-theming/dist/generated/themes/sap_horizon/parameters-bundle.css.js";
import defaultTheme from "./sap_horizon/parameters-bundle.css.js";

registerThemePropertiesLoader("@ui5/webcomponents-theming", "sap_horizon", async () => defaultThemeBase);
registerThemePropertiesLoader("@sapudex/web-components", "sap_horizon", async () => defaultTheme);

const styleData: StyleData = {packageName:"@sapudex/web-components",fileName:"themes/TableRowBase.css.ts",content:`:host{display:grid;grid-template-columns:subgrid;grid-column:1 / -1}
`};
export default styleData;
	