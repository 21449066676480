import customElement from "@ui5/webcomponents-base/dist/decorators/customElement.js";
import slot from "@ui5/webcomponents-base/dist/decorators/slot.js";
import litRender from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import UDExListGroupHeaderBase from "./ListGroupHeaderBase.js";
import UDExFilterMenuItem from "./FilterMenuItem.js";

/**
 * @class
 *
 * <h3 class="comment-api-title">Overview</h3>
 *
 *
 * <h3>Usage</h3>
 *
 * For the <code>udex-filter-menu-group-header</code>
 * <h3>ES6 Module Import</h3>
 *
 * <code>import "@udex/web-components/dist/FilterMenuGroupHeader.js";</code>
 *
 * @constructor
 * @extends UDExListGroupHeaderBase
 * @public
 */

@customElement({
  tag: "udex-filter-menu-group-header",
  renderer: litRender,
})

class UDExFilterMenuGroupHeader extends UDExListGroupHeaderBase {
  /**
   * Slot for  list items.
   *
   * @slot items
   * @public
   */
  @slot({ type: UDExFilterMenuItem, "default": true })
    items!: Array<UDExFilterMenuItem>;

  getItems(): Array<UDExFilterMenuItem> {
    return this.getSlottedNodes<UDExFilterMenuItem>("items");
  }
}

UDExFilterMenuGroupHeader.define();

export default UDExFilterMenuGroupHeader;
