import customElement from "@ui5/webcomponents-base/dist/decorators/customElement.js";
import property from "@ui5/webcomponents-base/dist/decorators/property.js";
import litRender from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import Link from "@ui5/webcomponents/dist/Link.js";

import linkCss from "@ui5/webcomponents/dist/generated/themes/Link.css.js";
import LinkTemplate from "./generated/templates/LinkTemplate.lit.js";

// Styles
import LinkCss from "./generated/themes/Link.css.js";

export enum IconPlacement {
  start = "start",
  end = "end"
}

/**
 * @class
 *
 * <h3 class="comment-api-title">Overview</h3>
 *
 *
 * <h3>Usage</h3>
 *
 * For the <code>udex-link</code>
 * <h3>ES6 Module Import</h3>
 *
 * <code>import @udex/web-components/dist/Link.js";</code>
 *
 * @constructor
 * @author SAP SE
 * @extends Link
 * @tagname udex-link
 * @public
 */
@customElement({
  tag: "udex-link",
  renderer: litRender,
  styles: [linkCss, LinkCss],
  template: LinkTemplate,
})

class UDExLink extends Link {
    /**
     * Used as the label for background image.
     *
     * @public
    */
    @property({ type: String })
      icon?: string;

    /**
     * Used as the label for background image.
     *
     * @public
     * @default "end"
    */
    @property({ type: IconPlacement, defaultValue: IconPlacement.end })
      iconPlacement?: `${IconPlacement}`;

    get startIcon() {
      return this.icon && this.iconPlacement === IconPlacement.start;
    }

    get endIcon() {
      return this.icon && this.iconPlacement === IconPlacement.end;
    }

    get linkClass() {
      return this.icon ? "udex-link udex-link--with-icon" : "udex-link";
    }
}

UDExLink.define();

export default UDExLink;
