import UI5Element from "@ui5/webcomponents-base/dist/UI5Element.js";
import customElement from "@ui5/webcomponents-base/dist/decorators/customElement.js";
import slot from "@ui5/webcomponents-base/dist/decorators/slot.js";
import { getI18nBundle } from "@ui5/webcomponents-base/dist/i18nBundle.js";
import type I18nBundle from "@ui5/webcomponents-base/dist/i18nBundle.js";
import BusyIndicator from "@ui5/webcomponents/dist/BusyIndicator.js";
import litRender from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import property from "@ui5/webcomponents-base/dist/decorators/property.js";
import Integer from "@ui5/webcomponents-base/dist/types/Integer.js";
import getEffectiveScrollbarStyle from "@ui5/webcomponents-base/dist/util/getEffectiveScrollbarStyle.js";
import {
  isUp, isDown, isLeft, isRight,
} from "@ui5/webcomponents-base/dist/Keys.js";
import UDExTableHeaderRow from "./TableHeaderRow.js";
import UDExTableRow from "./TableRow.js";
import UDExTableCell from "./TableCell.js";
import UDExTableGroupRow from "./TableGroupRow.js";
import UDExTableHeaderCell from "./TableHeaderCell.js";
import { TABLE_NO_DATA_FOUND } from "./generated/i18n/i18n-defaults.js";
import TableTemplate from "./generated/templates/TableTemplate.lit.js";

// Styles
import TableCss from "./generated/themes/Table.css.js";

type TableBaseRow = UDExTableHeaderRow | UDExTableRow;

type KeyBoardItem = {
  id: string;
  group?: boolean;
  elements: Array<HTMLElement>;
}

/**
 * @class
 *
 * <h3 class="comment-api-title">Overview</h3>
 *
 * <h3>Usage</h3>
 *
 * For the <code>udex-table</code>
 * <h3>ES6 Module Import</h3>
 *
 * <code>import "@udex/web-components/dist/Table.js";</code>
 *
 * @constructor
 * @extends UI5Element
 * @public
 */
@customElement({
  tag: "udex-table",
  renderer: litRender,
  styles: [TableCss, getEffectiveScrollbarStyle()],
  template: TableTemplate,
  dependencies: [
    BusyIndicator,
    UDExTableHeaderRow,
    UDExTableRow,
    UDExTableCell,
    UDExTableGroupRow,
  ],
})

class UDExTable extends UI5Element {
  /**
   * Defines the height of the table.
   * Allows control over the maximum height.
   *
   * @default ""
   * @public
   */
  @property()
    maxHeight!: string;

  /**
   * Defines the accessible ARIA name of the table.
   *
   * @default undefined
   * @public
   */
  @property({ type: String })
    accessibleName?: string;

  /**
   * Defines the heading of the table.
   * @public
   */
  @property({ type: String })
    heading!: string;

  /**
   * Defines the text to be displayed when there are no rows in the component.
   * @public
   */
  @property()
    noDataText?: string;

  /**
   * Defines if the loading indicator should be shown.
   * @default false
   * @public
   */
  @property({ type: String })
    loading!: string;

  /**
   * Defines the delay in milliseconds, after which the loading indicator will show up for this component.
   * @default 1000
   * @public
   */
  @property({ defaultValue: 1000, type: Integer })
    loadingDelay!: string;

  /**
   * Sticks the toolbar and header row to the top of the table.
   *
   * @default false
   * @public
   */
  @property({ type: Boolean })
    stickyHeader!: boolean;

  /**
   * Sticks the first column to the left of the table.
   *
   * @default false
   * @public
   */
  @property({ type: Boolean })
    stickyColumn!: boolean;

  /**
   * Defines the rows of the table.
   * @public
   */
  @slot({ type: HTMLElement, "default": true })
    rows!: Array<UDExTableRow | UDExTableGroupRow>;

  /**
   * Defines the header row of the table.
   * @public
   */
  @slot({ type: HTMLElement })
    headerRow!: Array<UDExTableHeaderRow>;

  /**
   * Defines the actions of the table.
   * @public
   */
  @slot({ type: HTMLElement })
    actions!: Array<HTMLElement>;

  /**
   * Defines the custom visualization if there is no data available.
   * @public
   */
  @slot()
    noData!: Array<HTMLElement>;

  /**
   * Defines the footnotes of the table.
   * @public
   */
  @slot()
    footnotes!: Array<HTMLElement>;

  /**
   * Defines the table has vertical scrolling.
   *
   * @default false
   * @private
   */
  @property({ type: Boolean })
    _verticalOffset?: boolean;

  static i18nBundle: I18nBundle;

  static async onDefine() {
    UDExTable.i18nBundle = await getI18nBundle("sap-ui-webcomponents-bundle");
  }

  onAfterRendering() {
    if (this.stickyColumn) {
      this._setStickyAttrToCells();
    }

    this._setIndexToCells();

    if (this.headerRow) {
      this._setCellsHighlighter();
    }
  }

  _handleKeyDown(e: KeyboardEvent) {
    this.handleUpArrow(e);
    this.handleDownArrow(e);
    this.handleLeftArrow(e);
    this.handleRightArrow(e);
  }

  handleUpArrow(e: KeyboardEvent) {
    if (isUp(e)) {
      e.preventDefault();
      this.navigateRows(-1);
    }
  }

  handleDownArrow(e: KeyboardEvent) {
    if (isDown(e)) {
      e.preventDefault();
      this.navigateRows(1);
    }
  }

  handleRightArrow(e: KeyboardEvent) {
    if (isRight(e)) {
      e.preventDefault();
      this.navigateCells(1);
    }
  }

  handleLeftArrow(e: KeyboardEvent) {
    if (isLeft(e)) {
      e.preventDefault();
      this.navigateCells(-1);
    }
  }

  navigateRows(offset: number) {
    const items = this.getKeyboardNavigationItems();
    const activeItem = items.find(item => item.id === this.getParentRow()._id) as KeyBoardItem;
    const activeIndex = items.indexOf(activeItem);
    const nextItem = items[activeIndex + offset];

    nextItem?.elements[0]?.focus();
  }

  navigateCells(offset: number) {
    const parentRow = this.getParentRow();

    if (!(parentRow as UDExTableGroupRow).isGroup) {
      const interactiveElements = this.getInteractiveElements(parentRow as TableBaseRow);
      const currentIndex = interactiveElements.indexOf(<HTMLElement>document.activeElement);
      const targetIndex = currentIndex + offset;
      if (targetIndex >= 0 && targetIndex < interactiveElements.length) {
        interactiveElements[targetIndex]?.focus();
      }
    }
  }

  _onScroll() {
    const { scrollLeft, scrollTop } = this._tableHolder;

    if (this.stickyHeader) {
      this._verticalOffset = scrollTop > 0;
    }

    if (this.stickyColumn) {
      this._setHorizontalOffsetToCells(scrollLeft);
    }
  }

  _setCellsHighlighter(): void {
    const highlightedHeaderCells = this.headerRow.flatMap(header => {
      return header.cells
        .filter(cell => (cell as UDExTableHeaderCell).highlighted === true)
        .map(cell => cell._individualSlot);
    });

    this.rows.forEach(row => {
      if (!(row instanceof UDExTableGroupRow)) {
        row.cells.forEach(cell => {
          if (highlightedHeaderCells.includes(cell._individualSlot)) {
            cell.setAttribute("_highlighted", "");
          }
        });
      }
    });
  }

  _setStickyAttrToCells():void {
    [...this.headerRow, ...this.rows].forEach(row => {
      if (!(row instanceof UDExTableGroupRow)) {
        row.cells[0]._sticky = true;
      }
    });
  }

  _setIndexToCells(): void {
    [...this.headerRow, ...this.rows].forEach((row, index) => {
      row._index = index;
    });
  }

  _setHorizontalOffsetToCells(scrollLeft: number):void {
    [...this.headerRow, ...this.rows].forEach(row => {
      if (!(row instanceof UDExTableGroupRow)) {
        row.cells[0]._horizontalOffset = scrollLeft > 0;
      }
    });
  }

  getParentRow(): UDExTableRow | UDExTableHeaderRow | UDExTableGroupRow {
    const focusedElement = document.activeElement as HTMLElement;
    return focusedElement.closest("udex-table-row, udex-table-header-row, udex-table-group-row")!;
  }

  getKeyboardNavigationItems(): Array<KeyBoardItem> {
    const items: Array<KeyBoardItem> = [];

    const pushItemGroupToItems = (row: UDExTableHeaderRow | UDExTableRow | UDExTableGroupRow, collapsible: boolean | undefined) => {
      if (collapsible) {
        items.push(<KeyBoardItem>{
          id: row._id,
          elements: [row.shadowRoot?.querySelector("button")],
        });
      }
      (row as UDExTableGroupRow).rows.forEach(rowGroup => {
        items.push({
          id: rowGroup._id,
          group: true,
          elements: this.getInteractiveElements(rowGroup),
        });
      });
    };

    const pushItemBaseToItems = (row: UDExTableHeaderRow | UDExTableRow | UDExTableGroupRow) => {
      items.push(<KeyBoardItem>{
        id: row._id,
        group: false,
        elements: row.isGroup
          ? [row.shadowRoot?.querySelector("button")]
          : this.getInteractiveElements(row as TableBaseRow),
      });
    };

    [...this.headerRow, ...this.rows].forEach(row => {
      const { isGroup, _isOpen, collapsible } = row as UDExTableGroupRow;
      if (isGroup && _isOpen) {
        pushItemGroupToItems(row, collapsible);
      } else {
        pushItemBaseToItems(row);
      }
    });

    return items.filter(({ elements }) => elements.length);
  }

  getInteractiveElements(el: TableBaseRow): Array<HTMLElement> {
    return el.cells.flatMap(cell => Array.from(
      cell.querySelectorAll("button, a, udex-button, udex-link, udex-rating-indicator"),
    ));
  }

  get _effectiveNoDataText() {
    return this.noDataText || UDExTable.i18nBundle.getText(TABLE_NO_DATA_FOUND);
  }

  get _hasToolbar(): boolean {
    return this.heading.length > 0 || this.actions.length > 0;
  }

  get _tableMaxHeight(): string {
    return this.maxHeight ? this.maxHeight : "auto";
  }

  get _tableHolder(): HTMLElement {
    return this.shadowRoot?.getElementById("table") as HTMLElement;
  }

  get _gridTemplateColumns(): string {
    const cellsLength = this.headerRow?.[0]?.cells.length || (this.rows?.[0] as TableBaseRow).cells.length;
    return `repeat(${cellsLength}, auto)`;
  }

  get _styles() {
    const headerStyleMap = this.headerRow?.[0]?.cells?.reduce((headerStyles, headerCell) => {
      if (headerCell.horizontalAlign !== undefined) {
        headerStyles[`--horizontal-align-${headerCell._individualSlot}`] = headerCell.horizontalAlign;
      }
      return headerStyles;
    }, {} as { [key: string]: string });

    return {
      table: {
        "grid-template-columns": this._gridTemplateColumns,
        "max-height": this._tableMaxHeight,
        ...headerStyleMap,
      },
    };
  }
}

UDExTable.define();

export default UDExTable;
