/* eslint no-unused-vars: 0 */
import { html, svg, repeat, classMap, styleMap, ifDefined, unsafeHTML, scopeTag } from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import type UI5Element from "@ui5/webcomponents-base/dist/UI5Element.js";
	import type StatusMessage from "../../StatusMessage.js";
	import type { ClassMapValue } from "@ui5/webcomponents-base/dist/types.js";
	
function block0 (this: StatusMessage, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<div class="udex-status-message udex-status-message__supporting-text">${ this.iconFieldName ? block1.call(this, context, tags, suffix) : undefined }${ifDefined(this.supportingText)}</div>`;}
function block1 (this: StatusMessage, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<${scopeTag("ui5-icon", tags, suffix)} name="${ifDefined(this.iconFieldName)}" class="udex-status-message__icon"></${scopeTag("ui5-icon", tags, suffix)}>` : html`<ui5-icon name="${ifDefined(this.iconFieldName)}" class="udex-status-message__icon"></ui5-icon>`;}


export default block0;