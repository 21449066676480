import UI5Element from "@ui5/webcomponents-base/dist/UI5Element.js";
import customElement from "@ui5/webcomponents-base/dist/decorators/customElement.js";
import slot from "@ui5/webcomponents-base/dist/decorators/slot.js";
import property from "@ui5/webcomponents-base/dist/decorators/property.js";
import Icon from "@ui5/webcomponents/dist/Icon.js";
import "@ui5/webcomponents-icons/dist/AllIcons.js";
import litRender from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import TableCellHorizontalAlign from "./types/Table.js";

// Styles
import TableCellBaseCss from "./generated/themes/TableCellBase.css.js";

/**
 * @class
 * A class to serve as a foundation for the `UDExTableCell` and `UDExTableHeaderCell` classes.
 * @constructor
 * @abstract
 * @extends UI5Element
 * @public
 */

@customElement({
  renderer: litRender,
  styles: TableCellBaseCss,
  dependencies: [Icon],
})

abstract class UDExTableCellBase extends UI5Element {
  /**
   * Determines the horizontal alignment of table cells.
   * Note: All values valid for justify-content can be used not just the ones inside the enum.
   * @default undefined
   * @public
   */
  @property()
    horizontalAlign?: `${TableCellHorizontalAlign}`;

  /**
   * Defines the content of the component.
   * @public
   */
  @slot({ type: Node, "default": true })
    content!: Array<Node>;

  /**
   * Defines position sticky of the table cell.
   *
   * @private
   */
  @property({ type: Boolean })
    _sticky?: boolean;

  /**
   * Defines the table has horizontal scrolling.
   *
   * @default false
   * @private
   */
  @property({ type: Boolean })
    _horizontalOffset?: boolean;

  _individualSlot?: string;

  protected ariaRole: string = "gridcell";

  onBeforeRendering() {
    this.setAttribute("role", this.ariaRole);
  }
}

export default UDExTableCellBase;
