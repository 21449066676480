import UI5Element from "@ui5/webcomponents-base/dist/UI5Element.js";
import { isDesktop } from "@ui5/webcomponents-base/dist/Device.js";
import customElement from "@ui5/webcomponents-base/dist/decorators/customElement.js";
import property from "@ui5/webcomponents-base/dist/decorators/property.js";
import litRender from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import Integer from "@ui5/webcomponents-base/dist/types/Integer.js";

export enum Target {
  _blank = "_blank",
  _parent = "_parent",
  _self = "_self",
  _top = "_top",
}

export type NavItem = {
  path?: string;
  mobileLinkTitle?: string;
  target?: Target;
  title: string;
  active?: boolean;
  isBack?: boolean;
  items?: Array<NavItem>;
}

/**
 * @class
 *
 * A class to serve as a foundation
 * for the <code>UDExSecondaryNavigationItem</code> and <code>UDExSecondaryNavigationSubItem</code> classes.
 *
 * @constructor
 * @author SAP SE
 * @extends UI5Element
 * @public
 */

@customElement({
  renderer: litRender,
})

class UDExSecondaryNavigationItemBase extends UI5Element {
  /**
   * Defines active state for the item
   *
   * @public
   */
  @property({ type: Boolean })
    active!: boolean;

  /**
   * Defines path for the item
   *
   * @public
   */
  @property({ type: String })
    path!: string;

  /**
   * Defines target for the item
   *
   * @public
   */
  @property({ type: String })
    target!: string;

  /**
   * Defines subTitle for the item
   *
   * @public
   */
  @property({ type: String })
    subTitle!: string;

  /**
   * Defines label for the item
   *
   * @public
   */
  @property({ type: String })
    label!: string;

  /**
   * Defines the index for the item.
   *
   * @public
   */
  @property({ validator: Integer })
    index!: number;

  onEnterDOM(): void {
    if (isDesktop()) {
      this.setAttribute("desktop", "");
    }
  }

  getActiveSubItem(data: Array<NavItem>): NavItem | undefined {
    let activeItem;
    const findActiveItem = (array: Array<NavItem>) => {
      array.forEach(item => {
        if (item.active === true) {
          activeItem = item;
          return;
        }
        if (item.items) {
          findActiveItem(item.items);
        }
      });
    };

    findActiveItem(data);
    return activeItem;
  }
}

export default UDExSecondaryNavigationItemBase;
