import customElement from "@ui5/webcomponents-base/dist/decorators/customElement.js";
import property from "@ui5/webcomponents-base/dist/decorators/property.js";
import event from "@ui5/webcomponents-base/dist/decorators/event.js";
import litRender from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import UI5Icon from "@ui5/webcomponents/dist/Icon.js";
import { getI18nBundle } from "@ui5/webcomponents-base/dist/i18nBundle.js";
import "@ui5/webcomponents-icons/dist/slim-arrow-right.js";
import "@ui5/webcomponents-icons/dist/slim-arrow-left.js";
import UDExSecondaryNavigationItemBase, { NavItem } from "./SecondaryNavigationItemBase.js";
import { BACK_BUTTON_LABEL } from "./generated/i18n/i18n-defaults.js";

import SecondaryNavigationSubItemTemplate from "./generated/templates/SecondaryNavigationSubItemTemplate.lit.js";

// Styles
import SecondaryNavigationSubItemCss from "./generated/themes/SecondaryNavigationSubItem.css.js";

/**
 * @class
 *
 * <h3 class="comment-api-title">Overview</h3>
 *
 *
 * <h3>Usage</h3>
 *
 * For the <code>udex-secondary-navigation-sub-item</code>
 * <h3>ES6 Module Import</h3>
 *
 * <code>import "@udex/web-components/dist/SecondaryNavigationSubItem.js";</code>
 *
 * @constructor
 * @extends UDExSecondaryNavigationItemBase
 * @tagname udex-secondary-navigation-sub-item
 * @public
 */
@customElement({
  tag: "udex-secondary-navigation-sub-item",
  renderer: litRender,
  styles: SecondaryNavigationSubItemCss,
  template: SecondaryNavigationSubItemTemplate,
  dependencies: [UI5Icon],
})

/**
 * Fires when item is clicked
 *
 * @allowPreventDefault
 * @public
 */
@event("sub-item-click", {
  detail: {
    item: { type: HTMLElement },
  },
})

/**
 * Fires when back item is clicked
 *
 * @allowPreventDefault
 * @public
 */
@event("back-item-click")

class UDExSecondaryNavigationSubItem extends UDExSecondaryNavigationItemBase {
  /**
   * Defines active item.
   *
   * @public
   * @default false
   */
  @property({ type: Boolean })
    isActive!: boolean;

  /**
   * Defines back item.
   *
   * @public
   * @default false
   */
  @property({ type: Boolean })
    isBack!: boolean;

  /**
   * List of the nested items
   *
   * @default "[]"
   * @public
   */
  @property({ type: Object, multiple: true })
    nestedItems!: Array<NavItem>;

  static i18nBundle: any;

  static async onDefine(): Promise<void> {
    UDExSecondaryNavigationSubItem.i18nBundle = await getI18nBundle("udex-secondary-navigation-sub-item");
  }

  onItemClick(): void {
    this.fireEvent("sub-item-click", {
      item: this,
    });
  }

  onBackItemClick(): void {
    this.fireEvent("back-item-click");
  }

  get hasItems(): boolean {
    return !!this.nestedItems.length;
  }

  get isSubItemActive(): boolean {
    return !!this.getActiveSubItem(this.nestedItems)?.active;
  }

  get backButtonLabel(): string {
    const label = UDExSecondaryNavigationSubItem.i18nBundle.getText(BACK_BUTTON_LABEL) as string;
    return `${label} ${this.label}`;
  }
}

UDExSecondaryNavigationSubItem.define();

export default UDExSecondaryNavigationSubItem;
