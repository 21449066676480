/* eslint no-unused-vars: 0 */
import { html, svg, repeat, classMap, styleMap, ifDefined, unsafeHTML, scopeTag } from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import type UI5Element from "@ui5/webcomponents-base/dist/UI5Element.js";
	import type Breadcrumbs from "../../Breadcrumbs.js";
	import type { ClassMapValue } from "@ui5/webcomponents-base/dist/types.js";
	
function block0 (this: Breadcrumbs, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<nav class="udex-breadcrumbs ui5-breadcrumbs-root" aria-label="${ifDefined(this._accessibleNameText)}"><ol @focusin="${this._onfocusin}"><li class="udex-breadcrumbs__arrow-wrapper ui5-breadcrumbs-dropdown-arrow-link-wrapper"><button @click="${this._toggleDropdown}" class="udex-breadcrumbs__trigger ${ifDefined(this._triggerClass)}" accessible-role="button" aria-label="${ifDefined(this._dropdownArrowAccessibleNameText)}" aria-haspopup="true"><${scopeTag("ui5-icon", tags, suffix)} name="slim-arrow-down" title="${ifDefined(this._dropdownArrowAccessibleNameText)}"></${scopeTag("ui5-icon", tags, suffix)}></button></li>${ repeat(this._linksData, (item, index) => (item as typeof item & {_id?: any})._id || index, (item, index: number) => block1.call(this, context, tags, suffix, item, index)) }</ol></nav>` : html`<nav class="udex-breadcrumbs ui5-breadcrumbs-root" aria-label="${ifDefined(this._accessibleNameText)}"><ol @focusin="${this._onfocusin}"><li class="udex-breadcrumbs__arrow-wrapper ui5-breadcrumbs-dropdown-arrow-link-wrapper"><button @click="${this._toggleDropdown}" class="udex-breadcrumbs__trigger ${ifDefined(this._triggerClass)}" accessible-role="button" aria-label="${ifDefined(this._dropdownArrowAccessibleNameText)}" aria-haspopup="true"><ui5-icon name="slim-arrow-down" title="${ifDefined(this._dropdownArrowAccessibleNameText)}"></ui5-icon></button></li>${ repeat(this._linksData, (item, index) => (item as typeof item & {_id?: any})._id || index, (item, index: number) => block1.call(this, context, tags, suffix, item, index)) }</ol></nav>`;}
function block1 (this: Breadcrumbs, context: UI5Element, tags: string[], suffix: string | undefined, item: any, index: number) { return html`<li class="udex-breadcrumbs__link-wrapper ui5-breadcrumbs-link-wrapper" id="${ifDefined(item._id)}-link-wrapper">${ !item._isCurrentPageItem ? block2.call(this, context, tags, suffix, item, index) : undefined }</li>`;}
function block2 (this: Breadcrumbs, context: UI5Element, tags: string[], suffix: string | undefined, item: any, index: number) { return suffix ? html`<span class="udex-breadcrumbs__separator" aria-hidden="true"></span><${scopeTag("udex-link", tags, suffix)} @ui5-click="${ifDefined(this._onLinkPress)}" class="udex-breadcrumbs__item" href="${ifDefined(item.href)}" target="${ifDefined(item.target)}" id="${ifDefined(item._id)}-link" accessible-name="${ifDefined(item._accessibleNameText)}" data-ui5-stable="${ifDefined(item.stableDomRef)}">${ifDefined(item.innerText)}</${scopeTag("udex-link", tags, suffix)}>` : html`<span class="udex-breadcrumbs__separator" aria-hidden="true"></span><udex-link @ui5-click="${ifDefined(this._onLinkPress)}" class="udex-breadcrumbs__item" href="${ifDefined(item.href)}" target="${ifDefined(item.target)}" id="${ifDefined(item._id)}-link" accessible-name="${ifDefined(item._accessibleNameText)}" data-ui5-stable="${ifDefined(item.stableDomRef)}">${ifDefined(item.innerText)}</udex-link>`;}


export default block0;